import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { ImportReviewComponent } from './import-review/import-review.component';
import { ImportSuccessComponent } from './import-success/import-success.component';
import { UsersImportComponent } from './users-import.component';

import { SharedModule } from '../../shared';

const siteUsersComponents = [
   UsersImportComponent,
   ImportReviewComponent,
   ImportSuccessComponent,
];

@NgModule({
   declarations: siteUsersComponents,
   imports: [
      CommonModule,
      ReactiveFormsModule,
      SharedModule,
   ],
   providers: [],
   exports: siteUsersComponents,
})
export class SiteUsersAppModule {
   public static readonly components = siteUsersComponents;
   public static readonly rootComponent = UsersImportComponent;
}

export const SiteUsersAjsModule = angular.module('hybd.site.users', []);
