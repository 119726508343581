// Use ./hide-deleting.pipe.ts#HideDeletingPipe in Angular templates
export type DeletableEntity = Required<{$$deleting: boolean}>;

export class HideDeletingFilter {
   static readonly id = 'hideDeleting';
   static filter(): IFilterFunction {
      return (array: DeletableEntity[]) => {
         return array?.filter(item => !item.$$deleting);
      };
   }
}
