import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { SafeResourceUrl } from '@angular/platform-browser';
import { Observable, Subject, merge, of, throwError } from 'rxjs';
import { ReactplayerService } from '../../../../shared/services/reactplayer.service';
import { NodeCard } from '../interfaces/node-card.interface';
import { ReactplayerMessage } from '../interfaces/reactplayer-message.interface';
import { ClipData, Source } from '../interfaces/clip-data.interface';
import { catchError, filter, switchMap, take, takeUntil } from 'rxjs/operators';
import { MediaInfo } from '../interfaces/media-node.interface';
import { AlertService } from '../../../../shared/services/alert.service';
import { NodesFlatTreeService } from '../services/nodes-flat-tree.service';


export interface ReactPlayerDialogData {
   title: string;
   location?: string;
}

@Component({
   selector: 'bb-react-clip-name-confirm',
   templateUrl: 'react-clip-name-confirm-dialog.html',
   styleUrls: ['./react-clip-name-confirm-dialog.scss'],
})
export class ReactClipNameConfirmComponent {
  constructor(
    public dialogRef: MatDialogRef<ReactClipNameConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ReactPlayerDialogData) {}

  public onNoClick(): void {
   this.dialogRef.close();
  }
}


@Component({
   selector: 'bb-react-player',
   templateUrl: './react-player.component.html',
   styleUrls: ['./react-player.component.scss'],
})
export class ReactPlayerComponent implements AfterViewInit, OnInit, OnDestroy {
   @ViewChild('iframeVideoPlayer') iframeRef: ElementRef;
   @ViewChild('dialogContentArea') dialogContentAreaRef: ElementRef;
   public minimiseState = false;
   public iframeElement: HTMLIFrameElement;
   public dialogContentAreaElement: HTMLElement;
   public resizableWindowElement: HTMLElement;
   public dragableElement: HTMLElement;
   public playerUrlOrigin: string;
   public iframeUrl$: Observable<SafeResourceUrl> = new Observable<SafeResourceUrl>();
   public combinedObs$: Observable<MediaInfo>;
   public resizeObserver: ResizeObserver;
   public iframeDragEnabled = true;
   public exportButtonPresent = true;

   private boundaryOffsetX = 12;
   private boundaryOffsetY = 46;
   private clip: Observable<ClipData>;
   private mediaInfo: MediaInfo;
   private destroy$ = new Subject();
   private currentPath: string;

   constructor(
      public dialogRef: MatDialogRef<ReactPlayerComponent>,
      public dialog: MatDialog,
      public reactPlayerService: ReactplayerService,
      private nodeFlatTree: NodesFlatTreeService,
      private alertService: AlertService,
      @Inject(MAT_DIALOG_DATA) public data: ReactPlayerDialogData) {
         this.reciveMessage = this.reciveMessage.bind(this);
      }

   ngOnInit() {
      this.iframeUrl$ = this.reactPlayerService.getIframeUrl();
      const obs1$ = this.reactPlayerService.getNodeCard();
      const obs2$ = this.reactPlayerService.getMediaInfo();
      const combinedObjects = merge(obs1$, obs2$);
      this.combinedObs$ = combinedObjects
         .pipe(
            filter((data) =>  { if( data !== null) { return true;} }),
            switchMap((data) => {
               const mediaInfo: MediaInfo = { name: data.name, mediaId: data.mediaId, edl: data.edl, type: data.type };
               if('start' in data) { mediaInfo.start = data.start; };
               if('proxyWidth' in data) { mediaInfo.proxyWidth = data.proxyWidth; };
               if('proxyHeight' in data) { mediaInfo.proxyHeight = data.proxyHeight; };
               if('accountName' in data) { mediaInfo.accountName = data.accountName; };
               if('accountId' in data) { mediaInfo.accountId = data.accountId; };
               if('exportEnabled' in data) {
                  mediaInfo.exportEnabled = data.exportEnabled;
                  this.exportButtonPresent = data.exportEnabled;
               };
               return of(mediaInfo);
            }),
            catchError(error => {
               return throwError(error);
            }),
         );
      window.addEventListener('message', this.reciveMessage, false);
      this.nodeFlatTree.getBreadcrumbs().pipe(
         takeUntil(this.destroy$),
      ).subscribe(
         path => {
            if(path.length){
               this.currentPath = path[path.length-1].name;
            }
         }
      );
   }

   public ngOnDestroy() {
      this.resizeObserver.unobserve(this.resizableWindowElement);
      window.removeEventListener('message', this.reciveMessage);
      this.destroy$.next();
      this.destroy$.complete();
   }

   ngAfterViewInit() {
      this.iframeElement = this.iframeRef.nativeElement as HTMLIFrameElement;
      this.playerUrlOrigin = window.origin;
      this.iframeRef.nativeElement.addEventListener('load', this.onIframeLoad.bind(this));
   }

   public onIframeLoad() {
      setTimeout(() => {
         this.setVideo();
      }, 1000);
      this.resizableWindowElement = document.querySelector(".mat-dialog-content");
      this.default();
      this.dragableElement = document.querySelector(".cdk-overlay-pane");
      this.resizeObserver = new ResizeObserver(entries => {
         this.windowSize(entries[0]?.contentRect.width,entries[0]?.contentRect.height);
         this.dragableElement.style.width = (entries[0]?.contentRect.width + this.boundaryOffsetX) + "px";
         this.dragableElement.style.height = (entries[0]?.contentRect.height + this.boundaryOffsetY) + "px";
      });
      this.resizeObserver.observe(this.resizableWindowElement);
   }

   public reciveMessage(event) {
      if(event.data?.errorLoadingMedia) {
         this.alertService.show({
            text: `An error occurred opening the Player: ${event.data.errorLoadingMedia}`,
            type: 'danger',
         });
         throwError(event.data.errorLoadingMedia);
      } else
      if(event.data.jsplayerReady !== undefined) {
         this.handlePlayerReady(event.data.jsplayerReady);
      } else if(event.data.clipping !== undefined) {
         this.handleClipping(this.mapClip(event.data.clipping));
      }
   }

   // Drag and Drop Clip Creation will trigger this
   public startDragClipping() {
      this.postMessage({command: "requestInOutPoints"});
      this.reactPlayerService.initClip();
   }

   // This is for test only will be removed after drag and drop done.
   public startDirectClipping() {
      this.reactPlayerService.initClip();
      this.postMessage({command: "requestInOutPoints"});
      this.clip = this.reactPlayerService.getClip();
      this.clip.pipe(
         takeUntil(this.destroy$),
      ).subscribe(data => {
         if (data) {
            this.openDialog(data, this.mediaInfo.name + " (copy)");
         }
      });
   }

   public openDialog(clipData: ClipData, title: string): void {
      const dialogRef = this.dialog.open(ReactClipNameConfirmComponent, {
        width: '250px',
        data: {title: title, location: this.currentPath}
      });
      const top = this.dragableElement.getBoundingClientRect().top + 10;
      const left = this.dragableElement.getBoundingClientRect().left + this.dragableElement.getBoundingClientRect().width - 100;
      dialogRef.updatePosition({top:top + "px", left:left + "px"});
      dialogRef.afterClosed().pipe(
         takeUntil(this.destroy$),
      ).subscribe(result => {
         if (result) {
            clipData.name = result;
            this.reactPlayerService.createClip(null, clipData);
         }
      });
    }

   public handlePlayerReady(jsplayerReady: boolean): void {
      this.fitToContentArea();
         if(this.mediaInfo && this.mediaInfo?.start && this.mediaInfo.start === 'end') {
            this.postMessage({command: "seek", skipToEnd: true});
         }
         this.playVideo();
   }

   public handleClipping(clipping: ClipData): void {
      this.reactPlayerService.setClip(clipping);
   }

   // This only used while reacat side is under development and missing fields
   public mapClip(data: ClipData): ClipData {
      return {
         description: data?.description ? data.description: null,
         edl: data?.edl,
         fps: data?.fps ? data.fps: 0,
         fromNode: data?.fromNode ? data.fromNode: null,
         imageFrame: data?.imageFrame ? data.imageFrame: 0,
         imageJpegDataURL: data?.imageJpegDataURL,
         inFrame: data?.inFrame ? data.inFrame: 0,
         isSelection: data?.isSelection ? data.isSelection: false,
         length: data?.length ? data.length: 0,
         name: data?.name ? data.name: "New Clip",
         outFrame: data?.outFrame ? data.outFrame: 0,
         videoWidth: data?.videoWidth ? data.videoWidth: 0,
      };
   }

   public default(){
      this.dialogRef.updatePosition();
      setTimeout(() => {
         this.setControlsMode('editor');
         this.fitToContentArea();
      }, 1000);
   }

   public postMessage(data: ReactplayerMessage) {
      if(!this.iframeElement) {
         this.iframeElement = this.iframeRef?.nativeElement as HTMLIFrameElement;
      }
      if (this.iframeElement?.contentWindow) {
         if ( window?.xsrf ) {
            this.iframeElement.contentWindow.xsrf = window?.xsrf;
         }
         this.iframeElement.contentWindow.postMessage(data, this.playerUrlOrigin);
      }
   }

   public stopVideo() {
      this.postMessage({command: "stop"});
   }

   public pauseVideo() {
      this.postMessage({command: "stop"});
   }

   public playVideo() {
      this.postMessage({command: "play"});
   }

   public setControlsMode(newMode: string) { // minimal or editor
      this.postMessage({command: "controlsMode", mode: newMode});
   }

   public setVideo() {
      this.combinedObs$
         .pipe(
            takeUntil(this.destroy$),
         )
         .subscribe((data: MediaInfo) => {
            if(!data) {
               return;
            }
            this.mediaInfo = data;
            if(this.mediaInfo.mediaId) {
               this.postMessage({command: "loadSource", mediaId: this.mediaInfo.mediaId});
            } else if(this.mediaInfo.edl && this.mediaInfo.edl.id) {
               this.postMessage({command: "loadSource", edlId: this.mediaInfo.edl.id});
            }
         });
   }

   public seekTo(seconds: number) {
      this.postMessage({command: "seek", seconds: seconds});
   }

   public windowSize(width, height) {
      this.postMessage({command: "windowSize", width: width, height: height});
   }

   public fitToContentArea() {
      this.windowSize(this.resizableWindowElement.clientWidth,this.resizableWindowElement.clientHeight);

      if(!this.dragableElement) {
         this.dragableElement = document.querySelector(".cdk-overlay-pane");
      }
      this.dragableElement.style.width = (this.resizableWindowElement.clientWidth + this.boundaryOffsetX) + "px";
      this.dragableElement.style.height = (this.resizableWindowElement.clientHeight + this.boundaryOffsetY) + "px";
   }

}
