<h4 mat-dialog-title class="text-input-dialog-title">
   {{title}}
</h4>
<mat-dialog-content>
   <mat-form-field appearance="outline">
      <mat-label>{{message}}</mat-label>
      <input
         type="text"
         matInput
         required
         [errorStateMatcher]="matcher"
         [formControl]="inputTextFormControl"
      />
      <mat-error *ngIf="inputTextFormControl.invalid">
         {{ getErrorMessage() }}
      </mat-error>
   </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end" class="text-input-dialog-actions">
   <button *ngIf="cancelBtnLabel" mat-raised-button (click)="onDismiss()">{{cancelBtnLabel}}</button>
   <button mat-raised-button color="primary" (click)="onConfirm()" [disabled]="inputAbsent()">{{confirmBtnLabel}}</button>
</mat-dialog-actions>
