export class NiceSpaceFilter {
   static readonly id = 'niceSpace';
   static filter(): IFilterFunction {
      const sizes = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'];
      return (bytes: number | string): string => {
         if (typeof bytes === "string") bytes = parseInt(bytes, 10);
         if (bytes === 0) return '0 bytes';
         const i = Math.floor(Math.log(bytes) / Math.log(1024));
         if (i > 5) return '>1024 PB';
         return (bytes / Math.pow(1024, i)).toFixed(i === 0 ? 0 : 2) + ' ' + sizes[i];
      };
   }
}
