import { DOCUMENT } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import { ConfirmDialogComponent, ConfirmDialogModel } from '../dialogs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ErrorMessageService } from '../../services/error-message.service';
import { AlertService } from '../../services/alert.service';
import { PageService } from '../../services/page.service';

type ImportProcessState = 'idle'
                        | 'GDPRWarning'
                        | 'checking'
                        | 'loading'
                        | 'ready'
                        | 'importing'
                        | 'imported';
interface ApiResult {
   status: string;
   summary: string;
}

@Component({
   selector: 'bb-metadata',
   templateUrl: './metadata.component.html',
   styleUrls: ['./metadata.component.scss'],
   changeDetection: ChangeDetectionStrategy.Default,
})
export class MetadataComponent implements AfterViewInit {
   @Input() accountId?: string;

   public importProcessState: ImportProcessState = 'idle';

   private csvInput: HTMLInputElement;
   private dialogSubscription: Subscription;
   private uploadData: string;
   private page: Page;

   constructor(
      @Inject(DOCUMENT) private document: Document,
      private dialog: MatDialog,
      private errorMessageService: ErrorMessageService,
      private pageService: PageService,
      private alertService: AlertService,
      private http: HttpClient,
   ) {
      this.page = this.pageService.page;
   }

   ngAfterViewInit() {
      this.csvInput = this.document.getElementById('csvInput') as HTMLInputElement;
      this.csvInput.addEventListener('change', () => {
         this.importProcessState = 'GDPRWarning';
         this.openGDPRConfirmationDialog();
      });
      if (!this.accountId) {
         this.accountId = this.page.accountId;
      }
   }

   public openFileInputDialog = (): void => this.csvInput.click();

   private clearImportData(): void {
      this.importProcessState = 'idle';
      this.uploadData = '';

      // Must reset csvInput.value or Chrome won't trigger the onchange event
      // if the same file is picked twice in a row.
      this.csvInput.value = '';
   }

   private openConfirmDialog(data: ConfirmDialogModel, onClosedCallback: (confirmed: boolean) => void): void {
      this.dialogSubscription?.unsubscribe();
      const dialogRef = this.dialog.open<ConfirmDialogComponent, ConfirmDialogModel, boolean>(
         ConfirmDialogComponent,
         { width: '50%', panelClass: ['confirm-dialog-component'], data },
      );
      this.dialogSubscription = dialogRef.afterClosed().subscribe(onClosedCallback);
   }

   private openGDPRConfirmationDialog(): void {
      const dialogData: ConfirmDialogModel = {
         title: 'GDPR Warning',
         message: 'By clicking "Proceed", you agree that you are the Data Controller and Blackbird plc. is the Data Processor.',
         confirmBtnLabel: 'Proceed',
         cancelBtnLabel: 'Cancel',
      };
      const onClosed = (confirmed: boolean) => confirmed ? this.chosenCSVFile() : this.clearImportData();
      this.openConfirmDialog(dialogData, onClosed);
   }

   private openFileExtensionWarningDialog(file: File): void {
      const dialogData: ConfirmDialogModel = {
         title: 'File Extension Warning',
         message: 'The imported file\'s name extension suggests the file does not contain correct comma-separated values. Do you want to proceed anyway?',
         confirmBtnLabel: 'Yes',
         cancelBtnLabel: 'No',
      };
      const onClosed = (confirmed: boolean) => confirmed ? this.readCSVFile(file) : this.clearImportData();
      this.openConfirmDialog(dialogData, onClosed);
   }

   private chosenCSVFile(): void {
      this.uploadData = "";
      this.importProcessState = 'checking';

      const file = this.csvInput.files[0];
      if (file.name.toLowerCase().endsWith('.csv')) {
         this.readCSVFile(file);
      } else {
         this.openFileExtensionWarningDialog(file);
      }
   }

   private readCSVFile(file: File): void {
      const reader = new FileReader();
      reader.onload = () => {
         /* We are not doing our own parse of this yet. When we have a
            grid UI for direct editing of the data here, probably we
            do csv parsing here and use the json version of the API
            instead, so we can highlight the actual changed entries
            for confirmation.

         this.papa.parse(reader.result as string, {
            skipEmptyLines: true, // https://github.com/mholt/PapaParse/issues/447
            complete: results => this.checkUsers(results.data),
            error: error => this.reportError(error),
         });
         */
         this.uploadData = reader.result as string;
         this.uploadDataToApi(true);
      };
      reader.readAsText(file);
   }

   private uploadDataToApi(dryRun: boolean): void {
      this.importProcessState = dryRun ? 'loading' : 'importing';
      const httpOptions = {
         headers: new HttpHeaders({
            'Content-Type':  'text/csv',
            'X-Api-Version': '20210705'
         })
      };
      this.http.post<ApiResult>('/api/mediaUpdate?format=json&accountId=' + this.page.accountId + '&dryRun=' + dryRun, this.uploadData, httpOptions)
         .subscribe({
            next: (result) => {
               if (result.status==="no changes") {
                  this.importProcessState = 'idle';
                  const dialogData: ConfirmDialogModel = {
                     title: 'No changes to do',
                     message: result.summary,
                     confirmBtnLabel: 'Ok',
                  };
                  this.openConfirmDialog(dialogData, (_confirmed: boolean) => this.clearImportData());
               } else if (dryRun) {
                  if (result.status !== "dry run") {
                     window.console.log(result);
                     return this.reportError("Unexpected status from dry run of import: "+result.status);
                  }
                  this.importProcessState = 'ready';
                  const dialogData: ConfirmDialogModel = {
                     title: 'Really do these changes:',
                     message: result.summary,
                     confirmBtnLabel: 'Yes',
                     cancelBtnLabel: 'No',
                  };
                  const onClosed = (confirmed: boolean) => confirmed ? this.uploadDataToApi(false) : this.clearImportData();
                  this.openConfirmDialog(dialogData, onClosed);
               } else {
                  if (result.status !== "done") {
                     window.console.log(result);
                     return this.reportError("Unexpected status from import: "+result.status);
                  }
                  this.importProcessState = 'imported';
                  const dialogData: ConfirmDialogModel = {
                     title: ' import complete',
                     message: result.summary,
                     confirmBtnLabel: 'Ok',
                  };
                  this.openConfirmDialog(dialogData, (_confirmed: boolean) => this.clearImportData());
               }
            },
            error: (error) => {
               this.reportError("Failed request to cloud for " + (dryRun ? "dry run of import" : "import") + ": " + this.errorMessageService.errorMessage(error));
               this.clearImportData();
            }
         });
   }
   private reportError(message: string): void {
      this.alertService.show({
         text: message,
         neverRemove: true,
         type: 'danger',
      });
   }
}
