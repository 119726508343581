import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

@Component({
   selector: 'bb-click-to-edit',
   templateUrl: 'click-to-edit.component.html',
   styleUrls: ['click-to-edit.component.scss'],
})
export class ClickToEditComponent implements OnChanges {
   @Input() initialText: string;
   @Output() newTextSubmitted = new EventEmitter<string>();

   public text: string;

   ngOnChanges() {
      this.text = this.initialText;
   }

   public submitNewText(): void {
      if (this.initialText === this.text) return;
      this.initialText = this.text;
      this.newTextSubmitted.emit(this.initialText);
   }
}
