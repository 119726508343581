import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

import { LoginDialogComponent } from '../components/dialogs';
import { LoginDialogLockService } from '../services/login-dialog-lock.service';


@Injectable()
export class LoginTimeoutInterceptor implements HttpInterceptor {

   private dialogRef: MatDialogRef<LoginDialogComponent, void> | null = null;

   constructor(
      private dialog: MatDialog,
      @Inject('LoginDialogLockService') private loginDialogLockService: LoginDialogLockService,
   ) {}

   intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      return next.handle(httpRequest)
         .pipe(
            catchError((error) => {
               if (
                  error instanceof HttpErrorResponse
                  && error.status === 401
                  && !location.pathname.includes('login') // Already on the login form page
                  && this.loginDialogLockService.acquireLockForLib('ng')
               ) {
                  if (!this.dialogRef) {
                     this.dialogRef = this.dialog.open<LoginDialogComponent, void, void>(
                        LoginDialogComponent,
                        { width: '60%' },
                     );
                  }

                  return this.dialogRef.afterClosed()
                     .pipe(
                        tap(() => {
                           this.loginDialogLockService.relinquishLock();
                           this.dialogRef = null;
                        }),
                        // Retry this request, whether it was the one that triggered the login dialog or not
                        switchMap(() => next.handle(httpRequest)),
                     );
               }

               return throwError(error);
            }),
         );
   }
}
