import { ConnectedPosition, Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

import { Directive, ElementRef, HostListener, Input, OnChanges, OnDestroy, SimpleChanges, TemplateRef } from '@angular/core';
import { TooltipTemplateComponent } from './tooltip-template/tooltip-template.component';


type TooltipTrigger = 'mouseover' | 'focus';

@Directive({
   selector: '[bbCustomTooltip]',
})
export class CustomToolTipDirective implements OnChanges, OnDestroy {
   /**
    * Pass a template reference to this directive like so:
    * <div [bbCustomTooltip]="fooTemplate">...</div>
    * <ng-template #fooTemplate>
    *    ...content to show in the tooltip...
    * </ng-template>
    */
   @Input('bbCustomTooltip') templateRef: TemplateRef<any>;

   /*
    * Override the default positioning if desired
    */
   @Input() customTooltipPosition: ConnectedPosition = {
      originX: 'center',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'top',
      offsetX: 15,
   };

   @Input() customTooltipTriggerEvent: TooltipTrigger = 'mouseover';

   private _overlayRef: OverlayRef;

   constructor(
      private _overlay: Overlay,
      private _overlayPositionBuilder: OverlayPositionBuilder,
      private _elementRef: ElementRef,
   ) {}
   /**
    * Shows the tooltip by instantiating the TooltipTemplateComponent, attaching
    * it to the overlay reference and assigning its input to the given template reference.
    */
   @HostListener('mouseenter')
   mouseShow() {
      if (this.customTooltipTriggerEvent === 'mouseover') {
         this.showTooltip();
      }
   }
   @HostListener('focusin')
   focusShow() {
      if (this.customTooltipTriggerEvent === 'focus') {
         this.showTooltip();
      }
   }

   /**
    * Closes the tooltip by detaching the overlay from the view
    */
   @HostListener('mouseleave')
   @HostListener('focusout')
   hide() {
      this.closeToolTip();
   }

   ngOnChanges(changes: SimpleChanges) {
      if (changes.customTooltipPosition && this._overlayRef?.hasAttached()) {
         this.closeToolTip();
         this.showTooltip();
      }
   }

   ngOnDestroy() {
      this.closeToolTip();
   }

   private showTooltip(): void {
      const positionStrategy = this._overlayPositionBuilder
                                    .flexibleConnectedTo(this._elementRef)
                                    .withPositions([this.customTooltipPosition]);
      this._overlayRef = this._overlay.create({positionStrategy});

      // Attach the component if it is not already attached to the overlay
      if (!this._overlayRef.hasAttached()) {
         const componentPortal = new ComponentPortal(TooltipTemplateComponent);
         const tooltipRef = this._overlayRef.attach(componentPortal);
         tooltipRef.instance.templateRef = this.templateRef;
      }
   }

   private closeToolTip(): void {
      this._overlayRef?.detach();
   }
}
