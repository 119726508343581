import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { rawDataKey } from '../constants/raw-data';


@Injectable()
export class ResponseNormaliserInterceptor implements HttpInterceptor {
   intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      // If raw-data header is present, don't send header to server but return full response
      // If raw-data header is *not* present, return .results only from response when received
      const rawData = !!httpRequest.headers.get(rawDataKey);
      const headers = httpRequest.headers.delete(rawDataKey);
      const httpReq = httpRequest.clone({ headers });

      return next.handle(httpReq).pipe(
         map(event => {
            if (event instanceof HttpResponse && !rawData) {
               event = event.clone({ body: event.body?.results });
            }
            return event;
         }),
      );
   }
}
