import { Pipe, PipeTransform } from '@angular/core';
import { DurationFilter } from './duration.filter';

@Pipe({
   name: DurationFilter.id,
})
export class DurationPipe implements PipeTransform {
   transform(timeInSeconds: number): string {
      return DurationFilter.filter()(timeInSeconds);
   }
}
