import { APP_INITIALIZER, NgModule } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CommonModule, I18nPluralPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DynamicIoModule as DynamicComponentsIOModule, DynamicModule as DynamicComponentsModule} from 'ng-dynamic-component';
import { DragDropModule } from '@angular/cdk/drag-drop';

/* Hybrid components */
import { FbdnIconComponent } from './components/fbdn-icon';
import { FbdnAccordionComponent } from './directives/fbdn-accordion';
import { AutoUpdatingImgComponent } from './components/auto-updating-img/auto-updating-img.component';
import { EditRoleDropdownComponent } from './components/edit-role-dropdown/edit-role-dropdown.component';

/* Pure Angular */
import { LoginApiService } from './api/login.api.service';
import { ColumnsApiService } from './services/columns.api.service';
import { AccountsApiService } from './api/accounts/accounts.api.service';
import { ExtraDataApiService } from './components/extra-data/extra-data.api.service';
import { ApiCacheService } from './utils/cache';
import { UsersApiService } from './api/users.api.service';
import { EdgeServersApiService } from './services/edge-servers-api.service';
import { ApiUpdatesService } from './utils/updates/api-updates.service';
import { UpdatesService } from './utils/updates/updates.service';
import { LoginDialogLockService } from './services/login-dialog-lock.service';
import { PublishHelpApiService } from './api/publish-help/publish-help.api.service';
import { AccountUsersApiService } from './services/account-users.api.service';
import { EditorDataApiService } from './services/editor-data.api.service';
import { SitesApiService } from './services/sites.api.service';
import { ApiConfigService } from './services/api-config.service';
import { EndpointCardsService } from './services/edge-endpoint-cards.service';
import { EndpointsApiService } from './services/endpoints.api.service';
import { AsyncCacheService } from './utils/async-cache.service';
import { ArrivalsFolderEndpointAssignmentsApiService } from './services/arrivals-folder-endpoint-assignments-api.service';
import { IngestQueueItemsService } from './services/ingest-queue-items.service';
import { ArrivalsFoldersApiService } from './services/arrivals-folders-api.service';
import { ErrorMessageService } from './services/error-message.service';
import { NotificationApiService } from './api/notifications.api.service';
import { AlertService } from './services/alert.service';
import { SvgIconService } from './components/svg-icon/svg-icon.service';
import { ReactplayerService } from './services/reactplayer.service';
import { SkeletonCacheService } from './utils/skeleton-cache.service';
import { SaveTransactionService } from './utils/save-transaction/save-transaction.service';
import { PublishingButtonApiService } from './services/publishing-button-api.service';
import { StatusApiService } from './services/status.api.service';

import { PieRingComponent } from './components/pie-ring/pie-ring.component';
import { ApiPaginatorComponent } from './components/api-paginator/api-paginator.component';
import { FormButtonsComponent } from './components/form-buttons/form-buttons.component';
import { UploadButtonComponent } from './components/upload-button/upload-button.component';
import { AccountButtonComponent } from './components/account-ingest-button/account-button.component';
import { ConfirmDialogComponent, LoginDialogComponent, TextInputDialogComponent } from './components/dialogs';
import { ExtraDataComponent } from './components/extra-data/extra-data.component';
import { TableComponent } from './components/table/table.component';
import { ResizeColumnDirective } from './components/table/resize-column.directive';
import { DynamicFormComponent } from './dynamic-form/ng/dynamic-form.component';
import { ClickToEditComponent } from './components/click-to-edit/click-to-edit.component';
import { MetaColumnsComponent } from './components/meta-columns/meta-columns.component';
import { MetaColumnsEditorComponent } from './components/meta-columns-editor/meta-columns-editor.component';
import { MetadataComponent } from './components/metadata/metadata.component';
import { AutocompleteItemComponent } from './components/autocomplete-item/autocomplete-item.component';
import { LoadingComponent } from './components/loading/loading.component';
import { AddRemoveManagersComponent } from './components/add-remove-managers/add-remove-managers.component';
import { MetaUploadComponent } from './components/metaupload/metaupload.component';
import { DynamicFormButtonComponent } from './components/dynamic-form-button/dynamic-form-button.component';
import { TabNotificationsComponent } from './components/tab-notifications/tab-notifications.component';
import { SvgIconComponent } from './components/svg-icon/svg-icon.component';
import { ReactPlayerComponent } from '../media-manager/ng/media-manager/react-player/react-player.component';
import { PublishLocationSelectorComponent } from '../publishing-buttons/publish-location-selector.component';
import { IconUploadDialogComponent } from './components/upload-dialog/upload-dialog.component';
import { NewPublishingButtonDialogComponent } from './components/dialogs/new-publishing-button/new-publishing-button-dialog.component';
import { TabStatusComponent } from '../side-bar/tab-status/tab-status.component';

import { MenuDirectionDirective } from './directives/menu-direction/menu-direction.directive';
import { StopPropagationDirective } from './directives/stop-propagation/stop-propagation.directive';
import { TooltipTemplateComponent } from './directives/custom-tooltip/tooltip-template/tooltip-template.component';
import { CustomToolTipDirective } from './directives/custom-tooltip/custom-tooltip.directive';
import { DragSelectDirective } from './directives/drag-select/drag-select.directive';

import { DurationPipe } from './filters/duration.pipe';
import { NiceSpacePipe } from './filters/nice-space.pipe';
import { HideDeletingPipe } from './filters/hide-deleting.pipe';
import { AlwaysPluralisePipe } from './filters/always-pluralise.pipe';
import { FormatDurationPipe } from './filters/format-duration.pipe';
import { NaturalDatePipe } from './filters/natural-date.pipe';
import { BpsPipe } from './filters/bps.pipe';
import { OrderByPipe } from './filters/order-by.pipe';

/* For <tags-input + <auto-complete directives in angular.js dynamicForm */
import '../../../lib/ng-tags-input.min.js';

import { SharedAjsModule } from './shared.module.ajs';
import { MaterialModule } from '../material.module';
import { OverlayModule } from '@angular/cdk/overlay';
import { PageService } from './services/page.service';


export function initApiConfig(apiConfigService: ApiConfigService) {
   return () => apiConfigService.fetchConfigSettings().toPromise();
}

// Convenience function for getting angular.js services
// or factories into an Angular module
const angularJsProvider = (token: string) => ({
   provide: token,
   useFactory: (i: $injector) => i.get(token),
   deps: ['$injector'],
});

const hybridComponents = [
   FbdnIconComponent,
   FbdnAccordionComponent,
];

const sharedDeclarables = [
   ...hybridComponents,
   FormButtonsComponent,
   UploadButtonComponent,
   AccountButtonComponent,
   ConfirmDialogComponent,
   LoginDialogComponent,
   TextInputDialogComponent,
   MenuDirectionDirective,
   StopPropagationDirective,
   CustomToolTipDirective,
   TooltipTemplateComponent,
   TableComponent,
   ResizeColumnDirective,
   OrderByPipe,
   NiceSpacePipe,
   AlwaysPluralisePipe,
   DurationPipe,
   HideDeletingPipe,
   FormatDurationPipe,
   NaturalDatePipe,
   BpsPipe,
   DynamicFormComponent,
   ExtraDataComponent,
   PieRingComponent,
   ApiPaginatorComponent,
   ClickToEditComponent,
   MetaColumnsComponent,
   MetaColumnsEditorComponent,
   MetadataComponent,
   AutocompleteItemComponent,
   AutoUpdatingImgComponent,
   LoadingComponent,
   AddRemoveManagersComponent,
   DragSelectDirective,
   MetaUploadComponent,
   DynamicFormButtonComponent,
   EditRoleDropdownComponent,
   TabNotificationsComponent,
   SvgIconComponent,
   ReactPlayerComponent,
   PublishLocationSelectorComponent,
   IconUploadDialogComponent,
   NewPublishingButtonDialogComponent,
   TabStatusComponent,
];

@NgModule({
   imports: [
      CommonModule,
      FormsModule,
      ReactiveFormsModule,
      OverlayModule,
      MaterialModule,
      DragDropModule,
      DynamicComponentsModule,
      DynamicComponentsIOModule,
   ],

   /* Declarables in this module */
   declarations: sharedDeclarables,

   /* Services, factories, injection tokens */
   providers: [
      // Pipes must also be providers if using in component TS
      I18nPluralPipe,
      FormatDurationPipe,
      NaturalDatePipe,
      NiceSpacePipe,

      angularJsProvider('NotificationService'),
      angularJsProvider('I18nService'),
      angularJsProvider('http'),
      angularJsProvider('Page'),
      angularJsProvider('AccountUsersApi'),
      angularJsProvider('UsersApi'),
      angularJsProvider('User'),
      angularJsProvider('SaveTransaction'),
      angularJsProvider('NavigationService'),
      angularJsProvider('_'),
      angularJsProvider('UrlService'),
      angularJsProvider('LoginDialogLockService'),
      angularJsProvider('ApiUpdates'),

      ApiUpdatesService,
      UpdatesService,
      LoginDialogLockService,

      ExtraDataApiService,
      LoginApiService,
      ColumnsApiService,
      AccountsApiService,
      EdgeServersApiService,
      UsersApiService,
      ApiCacheService,
      PublishHelpApiService,
      AccountUsersApiService,
      EditorDataApiService,
      SitesApiService,
      EndpointCardsService,
      EndpointsApiService,
      AsyncCacheService,
      ArrivalsFolderEndpointAssignmentsApiService,
      IngestQueueItemsService,
      ArrivalsFoldersApiService,
      ErrorMessageService,
      ApiConfigService,
      NotificationApiService,
      AlertService,
      PageService,
      SvgIconService,
      ReactplayerService,
      SkeletonCacheService,
      SaveTransactionService,
      PublishingButtonApiService,
      StatusApiService,
      {
         provide: APP_INITIALIZER,
         useFactory: initApiConfig,
         deps: [ApiConfigService],
         multi: true,
      },
   ],

   /* Declarables and modules used by consumers of this module */
   exports: [
      ...sharedDeclarables,
      MaterialModule,
      DragDropModule,
      FormsModule,
      ReactiveFormsModule,
   ],
})
export class SharedModule {
   public static readonly declarables = sharedDeclarables;
}

export const SharedHybridAjsModule = angular.module('hybd.shared', [
   'ngTagsInput', // Provided by ng-tags-input.min.js
   SharedAjsModule.name,
]);

// These Angular components are downgraded so they can be used:
//   - in angular.js templates within the hybrid component tree
//   - as entrypoints on raw/serverside HTML in a hybrid app page
SharedHybridAjsModule
   .directive('bbSvgIcon', downgradeComponent({component: SvgIconComponent}))
   .directive('bbLoading', downgradeComponent({component: LoadingComponent}))
   .directive('bbUploadButton', downgradeComponent({component: UploadButtonComponent}))
   .directive('bbAccountButton', downgradeComponent({component: AccountButtonComponent}))
   .directive('bbExtraData', downgradeComponent({component: ExtraDataComponent}))
   .directive('bbMetadata', downgradeComponent({component: MetadataComponent}))
   .directive('bbMetaColumns', downgradeComponent({component: MetaColumnsComponent}))
   .directive('bbAddRemoveManagers', downgradeComponent({component: AddRemoveManagersComponent}))
   .directive('bbMetaUpload', downgradeComponent({component: MetaUploadComponent}))
   .directive('bbPublishLocationSelector', downgradeComponent({component: PublishLocationSelectorComponent}))
   .directive('bbTabNotifications', downgradeComponent({component: TabNotificationsComponent}))
   .directive('bbTabStatus', downgradeComponent({component: TabStatusComponent}));
