export const ForceDisconnectComponent: IComponentOptions = {
   bindings: {
      disconnectUser: '<'
   },
   templateUrl: '/furniture/js/app/src/account/users/force-disconnect.component.html',
   controllerAs: 'vm',
   controller: ['$scope', '$modal', function(_$scope: IScope, $modal: any) {
      const vm = this;
      vm.showForceDisconnectDialog = function(): void {
         $modal.open({
            templateUrl: 'force-disconnect-dialog-template',
            controller: 'ForceDisconnectDialog',
            resolve: {
               disconnectUser: () => vm.disconnectUser,
            }
         });
      };
   }]
};
