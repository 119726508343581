export const uploadConfig = ['flowFactoryProvider', 'fileSupportProvider',
                           function(flowFactoryProvider, fileSupportProvider) {
   flowFactoryProvider.defaults = {
      // Disable chunking by setting chunk to 1TB
      chunkSize: 1 * 1028 * 1028 * 1028 * 1028,
      testChunks: false,
      simultaneousUploads: 1,
      permanentErrors: [403, 404, 413, 415, 500, 501],
      fileParameterName: 'uploadfile',
      singleFile: !fileSupportProvider.$get().multiple
   };
}];
