import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
   providedIn: 'root',
})
export class ErrorMessageService {

   public errorMessage(error: any, foldbackMessage?: string): string {
      window.console.error(error);
      return (error?.error?.message) ? error.error.message + ((error?.error?.error) ? ' (' + error.error.error + ')': ''):
         (error?.message) ? error.message:
         (typeof(error) === 'string') ? error:
         (foldbackMessage) ? foldbackMessage: 'an error has occurred';
   }
}
