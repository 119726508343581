const ngModule = angular.module('bb.click-to-edit-factory', []);

ngModule.factory('ClickToEditFactory', ['$document', '$timeout', function($document, $timeout) {
   function getTemplate(type, withButtons) {
      if (type !== 'input' && type !== 'textarea') return '';
      return '<form class="form-inline click-to-edit">' +
               '<' + type + (type === 'input' ? ' type="text"' : '') +
               ' ng-show="view.editorEnabled" ng-model="view.editableValue" ng-enter="save()" ng-cancel="disableEditor()" ' +
               (withButtons ? '' : 'ng-blur="disableEditor()" ') +
               'class="form-control" stop-propagation="click">' +
               '</' + type + '>' +
               '<div ng-hide="view.editorEnabled" ng-click="enableEditor(false, $event)" class="form-control-static show-editor">' +
                  '{{value}}' +
               '</div>' +
         (withButtons ? '<div class="buttons">' +
                  '<button ng-show="view.editorEnabled" type="submit" class="btn btn-primary btn-ok" ng-click="save()"></button>' +
                  '<button ng-show="view.editorEnabled" type="button" class="btn btn-primary btn-remove" ng-click="disableEditor()"></button>' +
               '</div>' : '') +
            '</form>';
   }

   return {
      get: function(type, withButtons) {
         return {
            replace: true,
            require: 'ngModel',
            scope: {
               value: "=ngModel",
               disabled: "=ngDisabled"
            },
            template: getTemplate(type, withButtons),
            link: function link(scope, element, attrs, controller) {
               function disableIfClickedOutside(e) {
                  if (!element.is(e.target) && element.has(e.target).length === 0) {
                     scope.disableEditor();
                     scope.$apply();
                  }
               }
               scope.view = {
                  editableValue: scope.value,
                  editorEnabled: false
               };

               scope.enableEditor = function(select, event) {
                  if (scope.disabled) {
                     return;
                  }

                  if (type === 'textarea') {
                     element.find('textarea').css('min-height', element.find('div.form-control-static').outerHeight());
                  }

                  scope.view.editorEnabled = true;
                  scope.view.editableValue = scope.value;
                  $document.on('click', disableIfClickedOutside);

                  $timeout(function() {
                     var el = element.find(type);
                     el.focus();
                     if (select) {
                        el.select();
                     }

                  }, 0, false);

                  if (event) {
                     event.stopPropagation();
                  }
               };

               scope.disableEditor = function() {
                  scope.view.editorEnabled = false;
                  $document.off('click', disableIfClickedOutside);
               };

               scope.save = function() {
                  controller.$setViewValue(scope.view.editableValue);
                  scope.disableEditor();
               };

               if (attrs.clickToEditId) {
                  scope.$parent.$on('clickToEdit::' + attrs.clickToEditId, function() {
                     scope.enableEditor(true);
                  });
               }
            }
         };
      }
   };
}]);

export default ngModule;
