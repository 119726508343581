<input id="csvInput" type="file" style="display: none"/>
<button
   *ngIf="isSitePage()"
   (click)="openFileInputDialog()"
   type="button"
   class="import-button"
   [disabled]="importProcessState != 'idle'"
>
   <span *ngIf="importProcessState == 'idle'; else loading">
      Import from CSV
   </span>
   <ng-template #loading>
      <fbdn-icon class="button-icon" icon="loading"></fbdn-icon>
   </ng-template>
</button>
