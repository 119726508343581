<section
   class="react-player"
   [ngClass]="{'minimised': minimiseState}"
   (dragstart)="minimise()"
   (dragend)="default()"
   *ngIf="combinedObs$ | async as combinedObs;"
   >
   <h2 mat-dialog-title class="title"
      cdkDragRootElement=".cdk-overlay-pane"
      cdkDrag
      cdkDragHandle
      >{{combinedObs.name}}</h2>
   <mat-dialog-actions class="actions">
      <div *ngIf="exportButtonPresent" (click)="startDirectClipping()">
         <span class="material-icon">publish</span>
      </div>
      <div [mat-dialog-close]="true" cdkFocusInitial>
         <span class="material-icon">close</span>
      </div>
   </mat-dialog-actions>
   <mat-dialog-content #dialogContentArea class="mat-typography dialog-content">
      <iframe #iframeVideoPlayer *ngIf="iframeUrl$ | async as iframeUrl"
         class="iframe-video-player" width="100%" height="100%"
         (load)="setVideo()"
         [src]="iframeUrl"
         id="jsplayer" name="jsplayer" scrolling="no" frameborder="0" allowfullscreen="true"
         webkitallowfullscreen="true" mozallowfullscreen="true" class="react-player-iframe">
      </iframe>
   </mat-dialog-content>
</section>

