
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpParamsOptions } from '@angular/common/http';
import { AsyncCacheService } from '../utils/async-cache.service';
import { SaveTransactionService } from '../utils/save-transaction/save-transaction.service';
import { PublishingButton, PublishingButtonDto, NewPublishingButton, PublishingButtonBase } from '../types/publishing-buttons/publishing-button.interface';
import { PublishModule, PublishModuleMap, Role } from '../types';
import { PageService } from './page.service';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Icon, Node, Location, Network, PerformActionResult, SourceDestinationModules } from '../types/publishing-buttons';
import { DynamicSchema } from '../dynamic-form/ng/dynamic-schema';
import { StatefulObject } from '../utils/save-transaction';
import { IconsObj } from '../../publishing-buttons/interfaces';

interface Phase {
   id: string;
   location?: {[key: string]: any};
   postNodes: [];
   preNodes: [];
   primaryNetwork: Network;
   secondaryNetwork: Network;
}
interface StratifiedNetwork {
   processNodes: Node[];
   destinationNode: Node;
}

interface roleRestriction {
   roleId: string;
   roleName: string;
   allow: boolean;
}

@Injectable({
   providedIn: 'root',
})
export class PublishingButtonApiService {

   private asyncCache: any;
   private page: Page;
   private id = 0;

   constructor(
      private asyncCacheService: AsyncCacheService<PublishingButton>,
      private httpClient: HttpClient,
      private saveTransactionService: SaveTransactionService,
      private pageService: PageService,
   )
   {
      this.asyncCache = this.asyncCacheService.initAsyncCache('PublishingButtonApiService');
      this.page = this.pageService.page;
   }

   public fetchLocations(): Observable<Location[]> {
      const api = this.httpClient.get<Location[]>((this.page.context === 'site') ? '/api/sites/' + this.page.siteId + '/publishLocations': '/api/accounts/' + this.page.accountId + '/publishLocations');
      const cacheId = (this.page.context === 'site') ? 'sitelocations-' + this.page.siteId : 'accountLocations-' + this.page.accountId;
      return this.asyncCache.fetch(cacheId, api);
   };

   public fetchSiteLocations(): Observable<Location[]> {
      const api = this.httpClient.get<Location[]>('/api/sites/' + this.page.siteId + '/publishLocations');
      return this.asyncCache.fetch('sitelocations-' + this.page.siteId, api);
   };

   public fetchAccountLocations(): Observable<any> {
      const api = this.httpClient.get<Location[]>('/api/accounts/' + this.page.accountId + '/publishLocations');
      return this.asyncCache.fetch('accountlocations-'+this.page.accountId, api);
   };

   // Get a text key that can be used to index and identify locations both returned
   // by the methods above those returned inside buttons
   public getLocationKey(location: Location): string {
      return location.type + ':' + (location.id || '');
   }

   public fetchModules(kind: string): Observable<PublishModuleMap> {
      const params: any = {};
      params.kind = kind;
      const url = (this.page.context === 'site') ? '/api/sites/' + this.page.siteId + '/publishModules': '/api/accounts/' + this.page.accountId + '/publishModules';
      const api = this.httpClient.get<PublishModule[]>(url, { params: this.ToHttpParams(params)});
      const cacheId = (this.page.context === 'site') ? 'siteModules-' + this.page.siteId: 'accountModules-' + this.page.accountId;
      return this.asyncCache.fetch(cacheId, api)
         .pipe(
            map((modules: PublishModule[]) =>  { return this.indexModules(modules); }),
         );
   }
   public fetchSiteModules(kind: string): Observable<PublishModuleMap> {
      const params: any = {};
      params.kind = kind;
      const api = this.httpClient.get<PublishModule[]>('/api/sites/' + this.page.siteId + '/publishModules', { params: this.ToHttpParams(params)});
      return this.asyncCache.fetch('siteModules-' + this.page.siteId, api)
         .pipe(
            map((modules: PublishModule[]) =>  { return this.indexModules(modules); }),
         );
   }

   public fetchAccountModules(kind: string): Observable<PublishModuleMap> {
      const params: any = {};
      params.kind = kind;
      const api = this.httpClient.get<PublishModule[]>('/api/accounts/' + this.page.accountId + '/publishModules', { params: this.ToHttpParams(params)});
      return this.asyncCache.fetch('accountModules-' + this.page.accountId, api)
         .pipe(
            map((modules: PublishModule[]) => {
               return this.indexModules(modules);
            })
         );
   }

   public fetchButtons(includeAll?: boolean): Observable<any> {
      const params: any = {};
      params.phases = 'expand';
      if(this.page.context === 'account' && includeAll === true) {
         params.includeAll = true;
      }
      const api = this.httpClient.get<PublishingButton[]>((this.page.context === 'site') ? '/api/sites/' + this.page.siteId + '/publishButtons': '/api/accounts/' + this.page.accountId + '/publishButtons', { params: this.ToHttpParams(params)});
      const cacheId = (this.page.context === 'site') ? 'siteButtons-'+this.page.siteId: 'accountButtons-'+this.page.accountId;
      return this.asyncCache.fetch(cacheId, api)
         .pipe(
            map((buttons: PublishingButton[]) => {
               buttons.forEach(button => this.unmarshallButton(button));
               return buttons;
            }),
         );
   }


   public fetchSiteButtons(): Observable<any> {
      const params: any = {};
      params.phases = 'expand';
      const api = this.httpClient.get<PublishingButton[]>('/api/sites/' + this.page.siteId + '/publishButtons', { params: this.ToHttpParams(params)});
      return this.asyncCache.fetch('siteButtons-'+this.page.siteId, api)
         .pipe(
            map((buttons: PublishingButton[]) => {
               buttons.forEach(button => this.unmarshallButton(button));
               return buttons;
            }),
         );
   }

   public fetchAccountButtons(includeAll: boolean): Observable<any> {
      const params: any = {};
      params.phases = 'expand';
      params.includeAll = !includeAll;
      const api = this.httpClient.get<PublishingButton[]>('/api/accounts/' + this.page.accountId + '/publishButtons', { params: this.ToHttpParams(params)});
      return this.asyncCache.fetch('accountButtons-'+this.page.accountId, api)
         .pipe(
            map((buttons: PublishingButton[]) => {
               buttons.forEach(button => this.unmarshallButton(button));
               return buttons;
            }),
         );
   }

   public fetchIcons(): Observable<IconsObj> {
      const api = this.httpClient.get<Icon[]>((this.page.context === 'site') ? '/api/sites/' + this.page.siteId + '/publishIcons': '/api/accounts/' + this.page.accountId + '/publishIcons');
      const cacheId = (this.page.context === 'site') ? 'siteIcons-'+this.page.siteId: 'accountIcons-'+this.page.accountId;
      return this.asyncCache.fetch(cacheId, api)
         .pipe(
            map((icons: Icon[]) => { return this.indexIcons(icons);})
         );
   }

   public fetchSiteIcons(): Observable<any> {
      const api = this.httpClient.get<Icon[]>('/api/sites/' + this.page.siteId + '/publishIcons');
      return this.asyncCache.fetch('siteIcons-'+this.page.siteId, api)
         .pipe(
            map((icons: Icon[]) => { return this.indexIcons(icons);})
         );
   }

   public fetchAccountIcons(): Observable<any> {
      const api = this.httpClient.get<Icon[]>('/api/accounts/' + this.page.accountId + '/publishIcons');
      return this.asyncCache('accountIcons-'+this.page.accountId, api)
         .pipe(
            map((icons: Icon[]) => { return this.indexIcons(icons);}),
         );
   }

   public fetchButtonRoleRestrictions(button: PublishingButton, roles: Role[]): Observable<any> {
      const buttonId = button.$$temporaryId || button.id;

      const api = button.$$temporaryId ? of(this.newRestrictions(roles)): this.httpClient.get('/api/publishButtons/' + buttonId + '/roleRestrictions');

      return this.asyncCache.fetch(`restrictions-${buttonId}`, api);
   }

   public saveButtonRoleRestrictions(button: PublishingButton): Observable<any>{
      return this.httpClient.post('/api/publishButtons/' + button.id + '/roleRestrictions', button.restrictions);
   }

   public fetchButtonStatus(button: PublishingButton): Observable<any> {
      return !button.id ? of({ numberOfPublishedItems: 0 }) : this.httpClient.get('/api/publishButtons/' + button.id + '/status');
   }

   public createButton(button: PublishingButton): Observable<any> {
      const api = (this.page.context === 'site') ? '/api/sites/' + this.page.siteId + '/publishButtons': '/api/accounts/' + this.page.accountId + '/publishButtons';
      return this.saveTransactionService.saveObject(button, () => {
         return this.httpClient.post<PublishingButtonBase>(api, this.marshallButton(button))
         .pipe(
            map((results) => {
               button.id = results.id;
               return results;
            }),
         );
      });
   }

   public createSiteButton(button: PublishingButton): Observable<any> {
      return this.saveTransactionService.saveObject(button, () => {
         return this.httpClient.post<PublishingButtonBase>('/api/sites/' + this.page.siteId + '/publishButtons', this.marshallButton(button))
         .pipe(
            map((results) => {
               button.id = results.id;
               return results;
            }),
         );
      });
   }

   public createAccountButton(button: PublishingButton): Observable<any> {
      return this.saveTransactionService.saveObject(button, () => {
         return this.httpClient.post<PublishingButtonBase>('/api/accounts/' + this.page.accountId + '/publishButtons', this.marshallButton(button))
            .pipe(
               map((results) => {
                  button.id = results.id;
                  return results;
               }),
            );
      });
   }

   public deletePublishIcon(icon: any): Observable<any | undefined> {
      return this.saveTransactionService.saveObject(icon, () => {
         return this.httpClient.post<Icon>('/api/publishIcons/' + icon.id + '/trash', {});
      });
   };

   public getUploadIconUrl(): string {
      return (this.page.context === 'site')? '/api/sites/' + this.page.siteId + '/publishIcons': '/api/accounts/' + this.page.accountId + '/publishIcons';
   }

   public saveButton(button: PublishingButton): Observable<any> {
      return this.saveTransactionService.saveObject(button, () => {
         return this.httpClient.post('/api/publishButtons/' + button.id, this.marshallButton(button));
      });
   }

   public saveButtonName(button: PublishingButton): Observable<any> {
      const api = this.httpClient.post('/api/publishButtons/' + button.id, { id: button.id, name: button.name });
      return this.saveTransactionService.save(button, 'name', () => {
         return api;
      });
   };

   public performAction(node: Node, action: string, state: string): Observable<PerformActionResult> {
      const url = (this.page.context === 'site')? '/api/sites/' + this.page.siteId + '/publishModules/' + node.moduleId + '/actions/' + action: '/api/accounts/' + this.page.accountId + '/publishModules/' + node.moduleId + '/actions/' + action;
      return this.httpClient.post<PerformActionResult>(url, { options: node.options, data: {state: state} });
   }

   public deleteButton(button: PublishingButton, collectionToUpdate: PublishingButton[]): Observable<any> {
      button.$$deleting = true;
      return !button.id ? of() : this.httpClient.post('/api/publishButtons/' + button.id + '/trash', {})
      .pipe(
         catchError(error => {
            button.$$deleting = false;
            return throwError(error);
         }),
         map(results => {
            const idx = collectionToUpdate.indexOf(button);
            if (idx >= 0) collectionToUpdate.splice(idx,1);
            return results;
         }),
      );
   }

   public networkNextNodeLocalId(network: Network): number {
      const nodes = Object.values(network.nodes).filter(node => node !== null);
      return (Math.max(...nodes.map(node => node.nodeLocalId)) || 0) + 1;
   }

   public networkRemoveNode(network: Network, node: Node): void {
      delete network.nodes[node.nodeLocalId];
      network.connections = network.connections.filter(conn => conn[0] !== node.nodeLocalId && conn[1] !== node.nodeLocalId);
   }

   public saveEnabled(publishButton: PublishingButton): Observable<any> {
      return this.saveTransactionService.save(publishButton, 'enabled', (publishingButton: PublishingButton) => {
         return this.httpClient.post('/api/publishButtons/' + publishingButton.id, { enabled: publishingButton.enabled } );
      });
   }

   public validateNetwork(network: Network, getModule: (id: string) => PublishModule): string[] {
      // This is supposed to be doing roughly the same as 'validate' in buttons.py
      // We only have modules that are legal for us, others will have 'isUnknown' set.
      const problems = []; // Return a list of problems. (strings)
      network.connections.forEach((conn: [number, number]) => {
         if (!network.nodes[conn[0]] || !network.nodes[conn[1]]) {
            problems.push("Hanging connection "+conn); // UI should not do this
         }
      });
      if (problems.length) return problems; // no point checking anything else when have bad connections
      Object.entries(network.nodes).forEach(([key, node]: [string, Node]) => {
         const module = getModule(node.moduleId);
         if (module.isUnknown) {
            problems.push("Invalid module "+node.moduleId);
         }
         const inputConnections =  network.connections.filter((conn) => conn[1]===node.nodeLocalId);
         const outputConnections = network.connections.filter((conn) => conn[0]===node.nodeLocalId);
         if (inputConnections.length < module.minInputModules) {
            problems.push("Too few inputs for "+module.name);
         }
         if (module.maxInputModules !== null && inputConnections.length > module.maxInputModules) {
            problems.push("Too many inputs for "+module.name);
         }
         inputConnections.forEach(conn => {
            const inputNode = network.nodes[conn[0]];
            const inputModule = getModule(inputNode.moduleId);
            if (!module.selectableInputModuleIds.includes(inputModule.id) && !module.nonSelectableInputModuleIds.includes(inputModule.id)) {
               problems.push(inputModule.name + " cannot be connected to " + module.name);
            }
         });
         if ((module.type==="process" || module.type==='source') && !outputConnections.length) {
            problems.push(module.name + " must go to a destination");
         }
      });
      return problems;
   }

   public getNewButton(chosenModules: SourceDestinationModules, getModule: (id: string) => PublishModule): PublishingButton {
      const network = {nodes: {}, connections: []};
      const destinationModule = chosenModules.destination;
      const destinationNode = {
         moduleId: destinationModule.id,
         options: DynamicSchema.defaultValuesForSchema(destinationModule.schema),
         nodeLocalId: 1,
      };
      network.nodes[destinationNode.nodeLocalId] = destinationNode;
      const sourceModule = chosenModules.source;
      const sourceNode = {
         moduleId: sourceModule.id,
         options: DynamicSchema.defaultValuesForSchema(sourceModule.schema),
         nodeLocalId: 0
      };
      // We will only be adding the sourceNode to network if it is actually used
      let preNodes = []; // gets switched to [sourceNode] if any process module needs it
      const processNodes = [];
      let nextNodeId = 2;
      destinationModule.defaultInputModuleIds.forEach((moduleId: string) => {
         const nodeId = nextNodeId;
         nextNodeId += 1;
         const processSchema = getModule(moduleId).schema;
         const node = {
            moduleId: moduleId,
            options: DynamicSchema.defaultValuesForSchema(processSchema),
            nodeLocalId: nodeId
         };
         network.nodes[nodeId] = node;
         if (getModule(moduleId).minInputModules) {
            // Process module does want input, so we will use sourceNode
            preNodes = [sourceNode];
            network.nodes[sourceNode.nodeLocalId] = sourceNode;
            network.connections.push([sourceNode.nodeLocalId, nodeId]);
         }
         network.connections.push([nodeId, destinationNode.nodeLocalId]);
         processNodes.push(node);
      });
      const button: NewPublishingButton = {
         $$state: 'new',
         $$temporaryId: ++this.id,
         id: null,
         name: destinationModule.name,
         description: destinationModule.description,
         keepFor: null,
         enabled: true,
         phases: {
            0: {
               phase: {
                  id: null,
                  hasComplicatedNetwork: false, // New buttons can start off with automatically managed networks
                  primaryNetwork: {
                     destinationNode: destinationNode,
                     processNodes: processNodes
                  },
                  secondaryNetworks: [],
                  preNodes: preNodes,
                  postNodes: [],
                  network: network
               },
               location: {
                  type: 'cloud',
                  id: null,
               },
               inputs: {}
            }
         }
      };
      return this.unmarshallButton(button);
   }

   private indexModules(modules: PublishModule[]): PublishModuleMap {
      return modules.reduce((acc, module) => {
         acc[module.id] = module;
         return acc;
      }, {} as PublishModuleMap);
   }

   private indexIcons(icons: Icon[]): { [id: string]: Icon } {
      return icons.reduce((acc, icon) => {
         acc[icon.id] = icon;
         return acc;
      }, {} as { [id: string]: Icon });
   }

   private marshallButton(button: PublishingButton): PublishingButtonDto {
      const result = JSON.parse(JSON.stringify(button));
      if (button.$$stratified) {
         // Save using the "stratified" network api if possible
         const phase = {
            id: result.phaseId,
            primaryNetwork: result.primaryNetwork,
            secondaryNetworks: result.secondaryNetworks,
            preNodes: result.preNodes,
            postNodes: result.postNodes
         };

         result.phases = {};
         result.phases[result.phaseLocalId || "0"] = {
            phase: phase,
            inputs: {}
         };
      }
      Object.keys(result).forEach(key => {
         if (key.startsWith('$$')) {
            delete result[key];
         }
      });
      // button location applies to all phases
      if (button.location) {
         Object.values(result.phases).forEach((phase: Phase) => {
            phase.location = { type: button.location.type, id: button.location.id };
         });
      }

      // otherwise we will just save result.phases directly
      delete result.location;
      delete result.phaseLocalId;
      delete result.phaseId;
      delete result.primaryNetwork;
      delete result.secondaryNetworks;
      delete result.preNodes;
      delete result.postNodes;
      delete result.destinationNodes;
      delete result.processNodes;
      delete result.hasBadNetwork;
      delete result.hasComplicatedNetwork;
      delete result.restrictions;
      delete result.viewUrl;
      delete result.__hiddenSource;
      return result;
   }

   private newRestrictions(roles: Role[]): roleRestriction[] {
      return roles.map((role: Role) => {
         return {
            roleId: role.id,
            roleName: role.name,
            allow: true
         };
      });
   }

   private ToHttpParams(params: any): HttpParams {
      let httpParams = new HttpParams();
      for (const key of Object.keys(params)) {
        const value = params[key];
        if (Array.isArray(value)) {
          // If the value is an array, convert it to a comma-separated string
          httpParams = httpParams.set(key, value.join(','));
        } else if (typeof value === 'object') {
          // If the value is an object, convert it to a JSON string
          httpParams = httpParams.set(key, JSON.stringify(value));
        } else {
          // For other types (string, number, etc.), just append them as-is
          httpParams = httpParams.set(key, value.toString());
        }
      }
      return httpParams;
   }

   private unmarshallButton(button: PublishingButtonDto | NewPublishingButton): PublishingButton {
      const unmarshalledButton = button as PublishingButton;
      if (unmarshalledButton.$$structured) return unmarshalledButton;

      const phaseLocalIds = Object.keys(unmarshalledButton.phases);
      if (phaseLocalIds.length === 1) {
         const buttonPhase = unmarshalledButton.phases[phaseLocalIds[0]]; // Object that links the button to the phase and has button-specific info
         const phase = buttonPhase.phase; // Phase itself
         unmarshalledButton.hasBadNetwork = phase.hasBadNetwork || ""; // force to empty string so we can regenerate it without triggering change detection
         unmarshalledButton.hasComplicatedNetwork = phase.hasComplicatedNetwork;
         unmarshalledButton.phaseId = phase.id;
         unmarshalledButton.phaseLocalId = phaseLocalIds[0];
         unmarshalledButton.location = buttonPhase.location;

         // We will replace all the nodes that came from the API JSON
         // into stratified parts with shared objects from the
         // un-stratified parts so both UIs are updating the same
         // options data
         const _sharedNode = (node: Node) => {
            return phase.network.nodes[node.nodeLocalId];
         };

         const _makeNetworkUseSharedNodes = (stratifiedNetwork: StratifiedNetwork) => {
            stratifiedNetwork.processNodes = stratifiedNetwork.processNodes.map(node => _sharedNode(node));
            stratifiedNetwork.destinationNode = _sharedNode(stratifiedNetwork.destinationNode);
            return  {destinationNode: stratifiedNetwork.destinationNode, processNodes:  stratifiedNetwork.processNodes};
         };

         if (!(unmarshalledButton.hasBadNetwork || unmarshalledButton.hasComplicatedNetwork)) {
            if (phase.secondaryNetworks.length === 0) {
               unmarshalledButton.primaryNetwork = _makeNetworkUseSharedNodes(phase.primaryNetwork);
               unmarshalledButton.secondaryNetworks = phase.secondaryNetworks.map(net => _makeNetworkUseSharedNodes(net));
               unmarshalledButton.preNodes = phase.preNodes.map(node => _sharedNode(node));
               unmarshalledButton.processNodes = unmarshalledButton.primaryNetwork.processNodes;
               unmarshalledButton.destinationNodes = [unmarshalledButton.primaryNetwork.destinationNode];
               unmarshalledButton.postNodes = phase.postNodes.map(node => _sharedNode(node));
               unmarshalledButton.$$stratified = true;
            } else {
               unmarshalledButton.hasComplicatedNetwork = "Button has " + phase.secondaryNetworks.length + " secondary networks";
            }
         }

      } else {
         unmarshalledButton.hasComplicatedNetwork = "Button has " + phaseLocalIds.length + " phases";
      }

      Object.entries(unmarshalledButton.phases).forEach(([phaseId, phase]) => {
         Object.entries(phase.phase.network.nodes).forEach(([id, node]: [string, Node]) => {
            node.nodeLocalId = +id;
         });
      });
      unmarshalledButton.$$structured = true;
      unmarshalledButton.$$deleting = false;
      return unmarshalledButton;
   }
}
