import HttpModule from '../fbdn-angular-modules/http';
import { NotificationService } from '../services/notification.service';

interface UpdateDataProgress {
   itemuid: string;
   progress: number;
   report: string;
   target: number;
}

interface UpdateData {
   modified?: string[];
   progress?: UpdateDataProgress;
}

export interface Update {
   channel: string;
   data: UpdateData;
   timestamp: string;
}

const ngModule = angular.module('bb.utils.updates', [
   HttpModule.name,
]);

ngModule.factory('Updates', ['http', '$q', '$window', '_', '$timeout', '$log', 'NotificationService',
                           function(http, $q: IQService, $window: IWindowService, _: Lodash, $timeout: ITimeoutService, $log: ILogService, notificationService: NotificationService) {
   var eventId = null;
   var eventIdCreatedPromise = null;
   var callbacksForChannels = {};

   var createFailureCount = 0;
   var subscribeFailureCount = 0;
   var longpollFailureCount = 0;
   var MAX_RETRY_TIME = 32;

   function scheduleRetry(fn, failureCount) {
      var retryTime = $window.Math.min($window.Math.pow(2, failureCount-1), MAX_RETRY_TIME) * 1000;
      var syncAngularModel = false;
      return $timeout(fn, retryTime, syncAngularModel);
   }

   function deleteEventId() {
      if (eventId) {
         http({
            method: 'POST',
            url: '/api/events/' + eventId + '/delete'
         });
         eventId = null;
      }
   }
   angular.element($window).on('unload', deleteEventId);

   function createEventId(eventIdInstanceIsOutOfDate) {
      if (eventIdCreatedPromise) return eventIdCreatedPromise;

      deleteEventId();

      return eventIdCreatedPromise = http({
         method: 'POST',
         url: '/api/events/create',
         data: {}
      }).then(function(result) {
         eventId = result.eventId;
         createFailureCount = 0;
         var subscribeChannels = _.map(callbacksForChannels, 'channel');
         subscribeToChannels(subscribeChannels).then(() => {
            if (eventIdInstanceIsOutOfDate) {
               for (var channel in callbacksForChannels) {
                  _.forEach(callbacksForChannels[channel].callbacks, function(callback) {
                     callback({
                        channel: channel,
                        data: ''
                     });
                  });
               }
            }
            longpoll();
         });
         return subscribeChannels;
      }, function() {
         eventIdCreatedPromise = null;
         return scheduleRetry(() => {
            createEventId(eventIdInstanceIsOutOfDate);
         }, ++createFailureCount);
      });
   }

   function setEventIdInstanceToOutOfDate() {
      eventIdCreatedPromise = null;
      createEventId(true);
   }

   function removeDuplicateUpdates(updates: Update[]): Update[] {
      return _.uniqBy(updates, obj => {
         return [obj.channel, obj.timestamp, obj.data.modified].join();
      });
   }

   function longpoll() {
      http({
         method: 'GET',
         url: '/api/events/' + eventId + '/longpoll'
      }).then(function(updates) {
         updates = removeDuplicateUpdates(updates);
         longpollFailureCount = 0;
         _.forEach(updates, function(update) {
            if (update.channel in callbacksForChannels) {
               _.forEach(callbacksForChannels[update.channel].callbacks, function(callback) {
                  // Catch exceptions here so that updates do not mysteriously stop after one error
                  try {
                     callback(update);
                  } catch (exc) {
                     window.console.error("Exception processing update:", update);
                     window.console.error(exc);
                  }
               });
            }
         });
         longpoll();
      }, function(error) {
         if (error.status === 504) {
            // success but nothing happened
            longpollFailureCount = 0;
            longpoll();
         } else if (error.status === 502 || error.status === 0 || error.status === -1) {
            // 0 on chrome or -1 on firefox when no http response due to network failure
            scheduleRetry(longpoll, ++longpollFailureCount);
         } else if (error.status === 410) {
            setEventIdInstanceToOutOfDate();
         } else {
            $log.error("Unknown longpoll result "+JSON.stringify(error));
            notificationService.show({
               type: 'danger',
               text: 'Error fetching updates: ' + error.message,
               neverRemove: true,
               noDuplicates: true
            });
         }
      });
   }

   function subscribeToChannels(channels) {
      // returns subscribe done promise
      if (!channels.length) {
         return $q.when();
      }
      return http({
         method: 'POST',
         url: '/api/events/' + eventId + '/subscribe',
         data: {
            channels: channels
         }
      }).then(function(results) {
         subscribeFailureCount = 0;
         return results;
      }, function(error) {
         if (error.status === 410) {
            setEventIdInstanceToOutOfDate();
            // our promise goes on to fail and that's ok, createEventId will subscribe and poll
         } else if (error.status === 502 || error.status === 0 || error.status === 401) {
            return scheduleRetry(_.partial(subscribeToChannels, channels), ++subscribeFailureCount);
         } else {
            $log.error("Unknown subscribe result "+JSON.stringify(error));
            notificationService.show({
               type: 'danger',
               text: 'Error subscribing to updates',
               neverRemove: true,
               noDuplicates: true
            });
         }
         return null;
      });
   }

   return function(callback) {

      function subscribe(channels) {
         var newChannels = [];
         _.forEach(channels, function(channel) {
            if (!(channel.channel in callbacksForChannels)) {
               callbacksForChannels[channel.channel] = {
                  channel: channel,
                  callbacks: []
               };
               newChannels.push(channel);
            }
            if (!_.includes(callbacksForChannels[channel.channel].callbacks, callback)) {
               callbacksForChannels[channel.channel].callbacks.push(callback);
            }
         });

         if (newChannels.length) {
            createEventId(false).then(function(subscribedChannels) {
               subscribeToChannels(_.difference(newChannels, subscribedChannels));
            });
         }
      }

      function unsubscribe(channels) {
         _.forEach(channels, function(channel) {
            _.remove(callbacksForChannels[channel.channel].callbacks, callback);
         });
      }

      createEventId(false);

      return {
         subscribe: subscribe,
         unsubscribe: unsubscribe
      };
   };
}]);

export default ngModule;
