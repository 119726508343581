import { Pipe, PipeTransform } from '@angular/core';
import { NiceSpaceFilter } from './nice-space.filter';

@Pipe({
   name: NiceSpaceFilter.id,
})
export class NiceSpacePipe implements PipeTransform {
   transform(bytes: number | string): string {
      return NiceSpaceFilter.filter()(bytes);
   }
}
