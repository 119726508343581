import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

import { Pagination } from './pagination.interface';


type PageNumber = 'first' | 'next' | 'previous' | 'last' | number;

interface PageSizeOption {
   label: string;
   value: number | 'all';
}

@Component({
   selector: 'bb-api-paginator',
   templateUrl: './api-paginator.component.html',
   styleUrls: ['./api-paginator.component.scss'],
})
export class ApiPaginatorComponent implements OnInit, OnChanges {
   @Input() totalRowCount: number;
   @Input() pageSize: 10 | 20 | 50 | 100;
   @Input() loading: boolean;
   @Input() resetOnTotalRowChange = true;
   @Input() hideAllItems = false;
   @Input() hideFirstLast = false;
   @Input() resetStart = false;
   @Input() hideTotalItems: string;
   @Input() nextPageDisabled: boolean;

   @Output() paginationChanged = new EventEmitter<Pagination>();

   public pageNumber: number;
   public start: number;
   public finish: number;
   public pageSizeOptions: PageSizeOption[];
   public pageNumberOptions: number[] = [];
   public totalItems: string;

   ngOnInit() {
      this.pageSizeOptions = [
         { label: '10',  value: 10 },
         { label: '20',  value: 20 },
         { label: '50',  value: 50 },
         { label: '100', value: 100 },
      ];
      if (!this.hideAllItems) {
         this.pageSizeOptions.push({ label: 'All', value: this.totalRowCount });
      }
      this.pageNumber = 0;
      this.start = 0;
      this.finish = this.pageSize;
      this.totalItems = (this.hideTotalItems !== null ) ? this.hideTotalItems : this.totalRowCount.toString();
      this.updatePageNumbering();
   }

   ngOnChanges(changes: SimpleChanges) {
      if ((changes.totalRowCount && this.resetOnTotalRowChange === true) || changes.resetStart) {
         this.pageNumber = 0;
         this.start = 0;
         this.finish = this.pageSize;
         this.resetStart = false;
      }
      if(changes.totalRowCount || changes.hideTotalItems) {
         this.totalItems = (this.hideTotalItems !== null ) ? this.hideTotalItems : this.totalRowCount.toString();
         this.updatePageNumbering();
      }
   }

   public changePageSize(pageSize: number): void {
      const pageWithSameResults = Math.floor(this.start / pageSize);
      this.goToPage(pageWithSameResults);
   }

   public goToPage(pageNumber: PageNumber): void {
      if (typeof pageNumber === 'number') {
         this.pageNumber = pageNumber;
      } else {
         this.pageNumber = {
            first: 0,
            previous: this.pageNumber - 1,
            next: this.pageNumber + 1,
            last: this.pageNumberOptions.length - 1,
         }[pageNumber];
      }

      this.updatePageNumbering();

      this.paginationChanged.emit({
         startIndex: this.pageNumber * this.pageSize,
         pageSize: this.pageSize,
      });
   }

   private updatePageNumbering(): void {
      if( this.pageNumber * this.pageSize >= this.totalRowCount) {
         this.pageNumber = Math.ceil(this.totalRowCount / this.pageSize) - 1;
      }
      this.start = (this.pageNumber * this.pageSize) + 1;
      const finish = (this.pageNumber * this.pageSize) + this.pageSize;
      this.finish = finish >= this.totalRowCount ? this.totalRowCount : finish;

      const pageCount = Math.ceil(this.totalRowCount / this.pageSize);
      this.pageNumberOptions = Array.from({ length: pageCount }, (el, ix) => ix);
   }
}
