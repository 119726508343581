interface FbdnContextmenuEvent extends Event {
   fbdnContextMenuHandled: boolean;
}

export class FbdnContextmenuDirective implements IDirective {
   restrict = 'A';

   constructor(private $timeout: ITimeoutService){}

   static factory(...deps: [ITimeoutService]) {
      return new FbdnContextmenuDirective(...deps);
   }

   link = (scope: IScope, element: JQLite, attr: IAttributes) => {
      element.bind('contextmenu', (event: JQueryEventObject) => {
         if (attr.value && !scope.$eval(attr.value)) return;
         event.preventDefault();
         if ((event.originalEvent as FbdnContextmenuEvent).fbdnContextMenuHandled) return;

         // Allow the event to bubble up to document before context menu is created
         // so that it can attach a handler to document that will not run on this event
         this.$timeout(() => {
            // Communicate to any fbdn-contextmenu-body in our scope or children
            scope.$broadcast('fbdnContextmenu:open', {
               left: event.pageX,
               top: event.pageY,
               target: event.target,
               handled: false
            });
         });

         // Essentially "stop progagation" for fbdnContextmenu handlers
         (event.originalEvent as FbdnContextmenuEvent).fbdnContextMenuHandled = true;
      });
   };
}

FbdnContextmenuDirective.factory.$inject = ['$timeout'];
