<ng-container *ngIf="{
   formGroup: formGroup$ | async,
   rules: rules$ | async
} as state">
   <ng-container *ngIf="changePasswordComplete else passwordForm">
      <p>Your password has been changed as requested.</p>
      <p>Continue to the <a href="/login">login page</a>.</p>
   </ng-container>

   <ng-template #passwordForm>
      <section *ngIf="resetType === 'currentPassword'">
         To change your password, enter your current password and desired new password
      </section>
      <section *ngIf="resetType === 'just_reset'">
         <h3>Reset Password</h3>
         You can now reset the password for the user "<b>{{username}}</b>".
      </section>
      <section *ngIf="resetType === 'registration'">
         <h3>Set Password</h3>
         Registration will be complete after setting the password for the user "<b>{{username}}</b>".
      </section>

      <form
         *ngIf="state.formGroup && state.rules"
         [formGroup]="state.formGroup"
      >
         <mat-form-field *ngIf="resetType === 'currentPassword'">
            <mat-label>Current Password</mat-label>
            <input matInput type="password" formControlName="currentPassword" required/>
            <mat-error *ngIf="state.formGroup.get('currentPassword').errors?.required">
               This field cannot be empty
            </mat-error>
         </mat-form-field>

         <section formGroupName="newPasswordGroup">
            <mat-form-field
               [bbCustomTooltip]="passwordStrength"
               [customTooltipPosition]="{overlayX: 'end', overlayY: 'bottom', offsetX: tooltipOffsetX, offsetY: tooltipOffsetY}"
               customTooltipTriggerEvent="focus"
            >
               <mat-label>New Password</mat-label>
               <input matInput type="password" formControlName="newPassword" required/>
               <mat-error *ngIf="state.formGroup.get('newPasswordGroup.newPassword').errors?.required">
                  This field cannot be empty
               </mat-error>
            </mat-form-field>

            <ng-template #passwordStrength>
               <bb-password-strength
                  [form]="state.formGroup"
                  [rules]="state.rules"
                  [position]="tooltipOffsetX < 0 ? 'above' : 'side'"
               ></bb-password-strength>
            </ng-template>

            <mat-form-field>
               <mat-label>Confirm New Password</mat-label>
               <input
                  matInput
                  type="password"
                  formControlName="confirmNewPassword"
                  [errorStateMatcher]="groupErrorMatcher"
                  required
               />
               <mat-error *ngIf="state.formGroup.get('newPasswordGroup').errors?.noMatch">
                  This password does not match
               </mat-error>
            </mat-form-field>
         </section>

         <button
            mat-raised-button
            color="primary"
            type="button"
            (click)="setPassword(state.formGroup)"
            [disabled]="!state.formGroup.valid"
         >
            <span *ngIf="!submitting">Set Password</span>
            <bb-loading *ngIf="submitting"></bb-loading>
         </button>
      </form>
   </ng-template>
</ng-container>
