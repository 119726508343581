// Use ./pluralise.pipe.ts#AlwaysPluralisePipe in Angular templates


/*
   ALWAYS pluralises.
   Appends 's' if word does not end in 's'

   @Deprecated
   Does not account for words which do not
   pluralise by simply adding 's'.

   Only used on endpoint type names in Edge
   Server Services + Endpoints pages.
   Current usage is not broken because all
   currently available endpoint names either
   pluralise with trailing 's' or word remains
   unchanged in plural form, e.g 'access'.
*/

export class AlwaysPluraliseFilter {
   static readonly id = 'alwaysPluralise';
   static filter(): IFilterFunction {
      return (value: string): string  => {
         if (!value) return '';
         return value.charAt(value.length - 1) === 's'
            ? value
            : `${value}s`;
      };
   };
}
