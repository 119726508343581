import { Directive, OnInit, Renderer2, Input, ElementRef, ChangeDetectorRef } from "@angular/core";

@Directive({
   selector: '[bbResizeColumn]',
})
export class ResizeColumnDirective implements OnInit {
   @Input("bbResizeColumn") resizeable: boolean;
   @Input() index: number;

   private startX: number;
   private startWidth: number;
   private column: HTMLElement;
   private table: HTMLElement;
   private pressed: boolean;

  constructor(
      private renderer: Renderer2,
      private el: ElementRef,
      private cdr: ChangeDetectorRef,
      ) {
   }

   ngOnInit() {
      if (this.resizeable) {
         this.column = this.el.nativeElement;
         const row = this.renderer.parentNode(this.column);
         const thead = this.renderer.parentNode(row);
         this.table = this.renderer.parentNode(thead);

         const resizer = this.renderer.createElement("span");
         this.renderer.setStyle(resizer, 'cursor', 'col-resize');
         this.renderer.setStyle(resizer, 'width', '20px');
         this.renderer.setStyle(resizer, 'height', '100%');
         this.renderer.setStyle(resizer, 'position', 'absolute');
         this.renderer.setStyle(resizer, 'right', '-10px');
         this.renderer.setStyle(resizer, 'top', 0);
         this.renderer.setStyle(resizer, 'z-index', 1);
         this.renderer.appendChild(this.column, resizer);
         this.renderer.listen(resizer, "mousedown", this.onMouseDown);
         this.renderer.listen(this.table, "mousemove", this.onMouseMove);
         this.renderer.listen("document", "mouseup", this.onMouseUp);
      }
   }

   onMouseDown = (event: MouseEvent) => {
      this.pressed = true;
      this.startX = event.pageX;
      this.startWidth = this.column.offsetWidth;
   };

   onMouseMove = (event: MouseEvent) => {
      const offset = 35;
      if (this.pressed && event.buttons) {
         this.renderer.setStyle(this.table, '-moz-user-select', 'none');
         this.renderer.setStyle(this.table, '-ms-user-select', 'none');
         this.renderer.setStyle(this.table, '-ms-user-select', 'none');
         this.renderer.setStyle(this.table, 'user-select', 'none');
         this.renderer.setStyle(this.table, 'cursor', 'col-resize');
         // Calculate width of column
         const width =
         this.startWidth + (event.pageX - this.startX - offset);

         const tableCells = Array.from(this.table.querySelectorAll(".mat-row")).map(
         (row: any) => row.querySelectorAll(".mat-cell").item(this.index)
         );
         // Set table header width
         this.renderer.setStyle(this.column, "width", `${width}px`);

         // Set table cells width
         for (const cell of tableCells) {
            this.renderer.setStyle(cell, "width", `${width}px`);
         }
         this.cdr.detectChanges();
      }
   };

   onMouseUp = (event: MouseEvent) => {
      if (this.pressed) {
         this.pressed = false;
         this.renderer.removeStyle(this.table, '-moz-user-select');
         this.renderer.removeStyle(this.table, '-ms-user-select');
         this.renderer.removeStyle(this.table, '-ms-user-select');
         this.renderer.removeStyle(this.table, 'user-select');
         this.renderer.removeStyle(this.table, 'cursor');
      }
   };
}
