export const typeaheadRequestOnFocusAndSelect: InjectableDirectiveFactory = ['$timeout', function($timeout) {
   return {
      require: 'ngModel',
      link: function(scope: any, element, attr, ngModel) {
         function forceTriggerPopup(value) {
            $timeout(function() {
               ngModel.$setViewValue(null);
               ngModel.$setViewValue(value || '');
            });
         }
         scope.typeaheadOnFocus = function() {
            forceTriggerPopup(ngModel.$viewValue);
         };
         scope.typeaheadOnSelect = function(value) {
            forceTriggerPopup(value);
         };
      }
   };
}];
