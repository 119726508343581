export const jointDiagramDirective = ['_', function(_: Lodash) {
   return {
      restrict: 'E',
      scope: {
         config: '<',
         events: '<',
      },
      link: function link(scope, element) {
         var diagram = null;
         scope.$watch("config", function() {
            if (diagram) {
               diagram.remove();
            }
            if (!scope.config) return;
            // Manually create a div to give to joint because
            // diagram.remove() deletes the element and do not want
            // to annoy angular
            var div = document.createElement("div");
            element[0].appendChild(div);
            scope.config.el = div;
            diagram = new joint.dia.Paper(scope.config);
            _.forEach(scope.events, (handler, event) => diagram.on(event, handler));
         });
      }
   };
}];
