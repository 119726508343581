export const formName: InjectableDirectiveFactory = ['$parse', function($parse) {
   // This directive is used in dynamic-form's template to
   // expose the controller into a scope variable. (I do not know the
   // reason this is necessary)
   // ^ Oh dear! Let's remove/refactor this out FP-20662
   return {
      require: '?^form', // attempt to locate form controller searching parents of element
      // When switching selected card after switching fbdn-tabs, there is a window during which the
      // controller is not available, so to avoid angular compile warnings, uses "?" here
      link: function(scope, element, attrs, formController) {
         if (formController) {
            $parse(attrs.formName).assign(scope, formController);
         }
      }
   };
}];
