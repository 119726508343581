import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
   selector: 'bb-form-buttons',
   templateUrl: './form-buttons.component.html',
   styleUrls: ['./form-buttons.component.scss'],
   changeDetection: ChangeDetectionStrategy.Default,
})
export class FormButtonsComponent {
   @Input() formEntity: Required<{$$saving: boolean; id: string}>;

   @Output() submitClicked = new EventEmitter<void>();
   @Output() discardClicked = new EventEmitter<void>();

   public discard(): void {
      this.discardClicked.emit();
   }

   public submit(): void {
      this.submitClicked.emit();
   }
}
