import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { EdgeServer, EdgeServerType } from '../../edge-servers/types';
import { NestedFieldValue } from '../dynamic-form/ng/types';
import { AsyncCacheService } from '../utils/async-cache.service';
import { SkeletonCacheService } from '../utils/skeleton-cache.service';
import { UsersApiService } from '../api/users.api.service';



export interface EdgeActionResults {
   action: 'display-message';
   autoRemove: false;
   message: string;
   preFormatted: true;
   type: 'info';
}

export interface NestedEdgeSettings {
   contact: NestedFieldValue<string>;
   location: NestedFieldValue<string>;
   notes: NestedFieldValue<string>;
   expectedonline: NestedFieldValue<boolean>;
   maxingests: NestedFieldValue<number>;
   maxthings: NestedFieldValue<number>;
   customOptions: NestedFieldValue<string>;
}

export interface SaveEdgeDto {
   id: string;
   name: string;
   options: NestedEdgeSettings;
   userId: string;
}

export type CreateEdgeDto = Omit<SaveEdgeDto, 'id'>;

export type EdgeContext = 'site' | 'cloud' | 'user';

@Injectable({
   providedIn: 'root',
})
export class EdgeServersApiService {
   private asyncCache: any;
   private skeletonCache: any;

   constructor(
      private http: HttpClient,
      private asyncCacheService: AsyncCacheService<EdgeServer>,
      private skeletonCacheService: SkeletonCacheService,
      private usersApiService: UsersApiService,
   ) {
      this.asyncCache = this.asyncCacheService.initAsyncCache('EdgeServers');
      this.skeletonCache = this.skeletonCacheService.initSkeletonCache("EdgeServers");
   }

   public unmarshall(edgeServer) {
      return this.skeletonCache.unmarshall.bind(this.skeletonCache, edgeServer)();
   }

   public getSkeleton(edgeServer) {
      return this.skeletonCache.get.bind(this.skeletonCache, edgeServer)();
   }

   // merged old fetch and fetchEdge: default mode work as it was originally working the old fetch (always calling the api)
   public fetchEdges(context: EdgeContext, contextualId?: string, forceFetch: boolean=false, edgeType="server"): Observable<EdgeServer[]> {
      let path: string;
      if (context === 'cloud') {
         path = '/api/edgeServers';
      } else if (context === 'site') {
         path = `/api/sites/${contextualId}/edgeServers`;
      } else if (context === 'user') {
         path = `/api/users/${contextualId}/edgeServers`;
      } else {
         throw new Error(`Invalid context: ${context}`);
      }


      const params = new HttpParams().set('type', edgeType);
      const pathWithQuery = path + '?' + params.toString();
      const getEdges$ = this.http.get<EdgeServer[]>(path, { params });
      return this.asyncCache.fetch(pathWithQuery, getEdges$, forceFetch) as Observable<EdgeServer[]>;
   }

   public fetchEdge(edgeServerId: string, forceFetch: boolean=false): Observable<EdgeServer> {
      const getEdge$ = this.http.get<EdgeServer>('/api/edgeServers/' + edgeServerId);
      return this.asyncCache.fetch("edge-server-" + edgeServerId, getEdge$, forceFetch);
   }

   public fetchEdgeTypes(): Observable<EdgeServerType[]> {
      const edgeTypes$ = this.http.get<EdgeServerType[]>('/api/edgeServerTypes');
      return this.asyncCache.fetch("edge-types", edgeTypes$);
   }

   public connectToSupport(edgeServer: EdgeServer): Observable<void> {
      edgeServer.$$connecting = true;
      return this.http.post<void>('/api/edgeServers/' + edgeServer.id + '/supportConnection', {})
         .pipe(
            finalize(() => edgeServer.$$connecting = false ),
         );
   }

   public saveEdgeServer(edgeDto: SaveEdgeDto): Observable<EdgeServer> {
      return this.http.post<EdgeServer>(`/api/edgeServers/${edgeDto.id}`, edgeDto);
   }

   public createEdgeServer(edgeDto: CreateEdgeDto): Observable<EdgeServer> {
      return this.http.post<EdgeServer>(`/api/users/${edgeDto.userId}/edgeServers`, edgeDto);
   }

   public saveName(edgeId: string, name: string): Observable<EdgeServer> {
      return this.http.post<EdgeServer>(`/api/edgeServers/${edgeId}`, {name});
   }

   public deleteEdgeServer(edgeId: string): Observable<void> {
      return this.http.post<void>(`/api/edgeServers/${edgeId}/trash`, null);
   }

   public getMachineLoad(edgeId: string): Observable<EdgeActionResults> {
      return this.http.post<EdgeActionResults>(`/api/edgeServers/${edgeId}/actions/machineLoad`, {});
   }

   public runSpeedTest(edgeId: string): Observable<EdgeActionResults> {
      return this.http.post<EdgeActionResults>(`/api/edgeServers/${edgeId}/actions/runNewEdgeSpeedTest`, {});
   }

   public getSpeedTestResults(edgeId: string): Observable<EdgeActionResults> {
      return this.http.post<EdgeActionResults>(`/api/edgeServers/${edgeId}/actions/getEdgeSpeedTestResults`, {});
   }
}
