import { Coordinate } from "./coordinate.interface";

interface FbdnDraggableScope extends IScope {
   isDragged(node: any): boolean;
   makeFixedCopyOfDraggable(extraCss: any): { element: any; startOffset: any };
}

export const FbdnDraggable: InjectableDirectiveFactory = ['$rootScope', '$document', '$timeout', '_', 'DragAndDropService', function($rootScope, $document, $timeout, _, dragAndDropService) {
   return {
      restrict: 'A',
      require: '?^fbdnDraggableContainer',
      link: function(scope: FbdnDraggableScope, element: JQLite, attrs: IAttributes, draggableContainerController) {
         var pressEvents = 'touchstart mousedown';
         var moveEvents = 'touchmove mousemove';
         var releaseEvents = 'touchend mouseup';

         var data = null;
         var dataMulti = null;

         var pressTimer = null;
         var offset = null;
         var state = null;
         var initialMousePosition = null;
         var disabled = false;

         var draggingElement = null;

         element.prop('draggable', false);

         if (attrs.fbdnDraggableDisabled) {
            scope.$watch(attrs.fbdnDraggableDisabled, function(_disabled: boolean) {
               disabled = _disabled;
            });
         }

         element.on(pressEvents, onPress);

         scope.isDragged = function isDragged(node) {
            return node && node.$$draggedParent === element.parent()[0];
         };


         function onPress(evt: JQueryMouseEventObject): void {
            if (disabled) return;
            if (!isLeftButton(evt)) {
               if (state) {
                  onRelease(evt);
               }
               return;
            }
            $document.on(releaseEvents, onRelease);
            evt.preventDefault();

            if (state === null) {
               state = 'pressStarted';
               pressTimer = $timeout(function() {
                  state = 'pressed';
                  $document.on(moveEvents, onMove);
               }, 100);

               initialMousePosition = getMousePosition(evt);
            }
         }

         var broadcastMoveEvent = _.throttle(function(currentMousePosition) {
            if (state === 'dragging') {
               $rootScope.$broadcast('draggable:move', {
                  mousePosition: currentMousePosition,
                  element: element,
                  data: data,
                  dataMulti: dataMulti && dataMulti.length > 1 ? dataMulti : null
               });
            }
         }, 100);

         scope.makeFixedCopyOfDraggable = function(extraCss) {
            var startOffset = element.offset();
            var newElement = element.clone()
               .css(_.extend({
                  width: element.outerWidth(),
                  height: element.outerHeight(),
                  left: startOffset.left,
                  top: startOffset.top,
                  position: 'fixed'
               }, extraCss));
            if (draggableContainerController) {
               draggableContainerController.getElement().append(newElement);
            } else {
               element.after(newElement);
            }
            return {
               element: newElement,
               startOffset: startOffset
            };
         };

         function onMove(evt: JQueryMouseEventObject): void {
            if (state) {
               evt.preventDefault();
               var currentMousePosition = getMousePosition(evt);
            }

            if (state === 'pressed') {
               if (distance(currentMousePosition, initialMousePosition) > 5) {
                  state = 'dragging';

                  data = scope.$eval(attrs.fbdnDraggable);
                  dataMulti = attrs.fbdnDraggableMulti && scope.$eval(attrs.fbdnDraggableMulti);

                  data.$$draggedParent = element.parent()[0];
                  dragAndDropService.startDrag({
                     mousePosition: initialMousePosition,
                     element: element,
                     data: data,
                     dataMulti: dataMulti && dataMulti.length > 1 ? dataMulti : null
                  });

                  offset = {
                     x: element.offset().left - initialMousePosition.x,
                     y: element.offset().top - initialMousePosition.y
                  };

                  if (attrs.fbdnDraggableElementFactory) {
                     draggingElement = scope.$eval(attrs.fbdnDraggableElementFactory)(data, offset);
                  } else if (dataMulti && dataMulti.length > 1) {
                     draggingElement = angular.element('<div></div>')
                        .addClass('dragging-multi')
                        .html(`<span>${dataMulti.length}</span>`);
                  } else {
                     draggingElement = element.clone()
                        .css('width', element.outerWidth())
                        .css('height', element.outerHeight());
                  }
                  draggingElement.addClass('dragging');
                  element.addClass('dragged');

                  if (draggableContainerController) {
                     draggableContainerController.getElement().append(draggingElement);
                  } else {
                     element.after(draggingElement);
                  }
                  scope.$apply();
               }
            }

            if (state === 'dragging') {
               moveElement(currentMousePosition);
               broadcastMoveEvent(currentMousePosition);
            }
         }

         function onRelease(evt: JQueryMouseEventObject): void {
            if (isLeftButton(evt)) {
               evt.preventDefault();
            }

            if (state === 'pressStarted') {
               $timeout.cancel(pressTimer);
               state = null;
            }

            if (state === 'pressed') {
               $document.off(moveEvents, onMove);
               state = null;
            }

            if (state === 'dragging') {
               $rootScope.$broadcast('draggable:end', {
                  mousePosition: getMousePosition(evt),
                  element: element,
                  data: data,
                  dataMulti: dataMulti && dataMulti.length > 1 ? dataMulti : null
               });
               stopDragging();
            }

            element.removeClass('dragged');

            $document.off(releaseEvents, onRelease);
         }

         function isLeftButton(evt: JQueryMouseEventObject): boolean {
            return (evt.type === 'mousedown' || evt.type === 'mouseup') && evt.button === 0;
         }

         function distance(position1: Coordinate, position2: Coordinate): number {
            var distanceX = position1.x - position2.x;
            var distanceY = position1.y - position2.y;
            return Math.max(Math.abs(distanceX), Math.abs(distanceY));
         }

         function getMousePosition(evt: JQueryMouseEventObject): Coordinate {
            return {
               x: evt.pageX,
               y: evt.pageY
            };
         }

         function stopDragging(): void {
            $document.off(moveEvents, onMove);
            data.$$draggedParent = null;
            if (draggingElement) draggingElement.remove();
            draggingElement = null;
            offset = null;
            state = null;
            scope.$apply();
         }

         function moveElement(mousePosition: Coordinate): void {
            let left: number;
            let top: number;
            if (dataMulti && dataMulti.length > 1) {
               left = mousePosition.x - document.body.scrollLeft;
               top = mousePosition.y - document.body.scrollTop;
            } else {
               left = mousePosition.x + offset.x - document.body.scrollLeft;
               top = mousePosition.y + offset.y - document.body.scrollTop;
            }
            draggingElement.css({
               left: left,
               top: top,
               position: 'fixed',
               'z-index': 99999
            });
         }
      }
   };
}];
