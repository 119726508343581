import { NgModule } from '@angular/core';
import { OverlayModule } from '@angular/cdk/overlay';
import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MAT_DATE_FORMATS, MAT_RIPPLE_GLOBAL_OPTIONS, MatDateFormats, MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogConfig, MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions, MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarConfig, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MAT_TABS_CONFIG, MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule, MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import {MatGridListModule} from '@angular/material/grid-list';

const matDateFormats: MatDateFormats = {
   parse: {
      dateInput: 'L',
   },
   display: {
      dateInput: 'L',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
   },
};

const matFormFieldDefaultOptions: MatFormFieldDefaultOptions = {
   appearance: 'outline',
   floatLabel: 'always',
};

const matSnackBarConfig: MatSnackBarConfig = {
   duration: 3000,
   horizontalPosition: 'end',
};

const materialModules = [
   ClipboardModule,
   CdkTableModule,
   OverlayModule,
   CommonModule,
   MatAutocompleteModule,
   MatButtonModule,
   MatCardModule,
   MatCheckboxModule,
   MatChipsModule,
   MatDatepickerModule,
   MatNativeDateModule,
   MatDialogModule,
   MatDividerModule,
   MatExpansionModule,
   MatFormFieldModule,
   MatIconModule,
   MatInputModule,
   MatMenuModule,
   MatPaginatorModule,
   MatRadioModule,
   MatRippleModule,
   MatSelectModule,
   MatSliderModule,
   MatSlideToggleModule,
   MatSidenavModule,
   MatSnackBarModule,
   MatProgressSpinnerModule,
   MatSortModule,
   MatTabsModule,
   MatTableModule,
   MatTooltipModule,
   MatTreeModule,
   MatToolbarModule,
   MatBadgeModule,
   MatButtonToggleModule,
   MatProgressBarModule,
   MatGridListModule,
];

@NgModule({
   imports: materialModules,
   exports: materialModules,
   providers: [
   {
      provide: MAT_DATE_FORMATS,
      useValue: matDateFormats,
   },
   {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: {
         showDelay: 1000,
      },
   },
   {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: matFormFieldDefaultOptions,
   },
   {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: matSnackBarConfig,
   },
   {
      provide: MAT_RIPPLE_GLOBAL_OPTIONS,
      useValue: {
         disabled: true,
      },
   },
   {
      provide: MAT_TABS_CONFIG,
      useValue: {
         animationDuration: '0ms',
      },
   },
   {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
         ...new MatDialogConfig(),
         maxWidth: 'auto',
      },
   },
   {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
         horizontalPosition: 'center',
         verticalPosition: 'top',
      }
   }],
})
export class MaterialModule {}
