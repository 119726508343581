import { FbdnContextmenuDirective } from './fbdn-contextmenu.directive';
import { FbdnContextmenuBodyDirective } from './fbdn-contextmenu-body.directive';

const ngModule = angular.module('bb.fbdn-contextmenu', []);

ngModule
   .directive('fbdnContextmenu', FbdnContextmenuDirective.factory)
   .directive('fbdnContextmenuBody',FbdnContextmenuBodyDirective.factory);

export default ngModule;
