import PublishingButtonApiModule from '../../../publishing-buttons/publishing-button-api.factory';
import FbdnEllipsisModule from '../../../shared/components/fbdn-ellipsis/fbdn-ellipsis.component';
import { Network } from '../../../publishing-buttons/interfaces/';
import { PublishModuleName as ModuleName, PublishModuleMap as Modules } from '../../../shared/types';

type TargetOrigins = {
   [key in ModuleName]?: string[];
};

const ngModule = angular.module('bb.publish-button-description', [
   PublishingButtonApiModule.name,
   FbdnEllipsisModule.name,
]);

const PublishButtonDescriptionComponent = {
   bindings: {
      network: '<',
      modules: '<',
      cropDescription: '<',
   },
   templateUrl: '/furniture/js/app/src/shared/components/publish-button-description/publish-button-description.component.html',
   controllerAs: 'vm',
   controller: class PublishButtonDescriptionController {
      static $inject = ['_'];

     /* Input */ public network: Network;
     /* Input */ public modules: Modules;
     /* Input */ public cropDescription: boolean;

      public description: string;
      private cachedNetwork: Network;

      constructor(private _: Lodash) {}

      $onInit() {
         this.cachedNetwork = JSON.parse(JSON.stringify(this.network));
         this.handleUpdate();
      }

      $doCheck() {
         if (this.connectionsChanged() || this.nodesChanged()) {
            this.handleUpdate();
         }
      }

      private generateDescription(): string {
         const nodes = this.network.nodes;
         const connections = this.network.connections
            .map(([conn1, conn2]) => {
               const originModule: ModuleName = nodes[conn1.toString()].moduleId;
               const targetModule: ModuleName = nodes[conn2.toString()].moduleId;
               if (this.modules[originModule].type === 'process') {
                  return { origin: originModule, target: targetModule };
               }
            })
            .filter(conn => !!conn);

         const targetOrigins: TargetOrigins = {};
         connections.forEach((connection, index) => {
            const sharedTargetConnection = connections.find((c, i) => i !== index && c.target === connection.target);
            if (sharedTargetConnection && targetOrigins[sharedTargetConnection.target]) {
               targetOrigins[sharedTargetConnection.target].push(connection.origin);
            } else {
               targetOrigins[connection.target] = [connection.origin];
            }
         });

         const connectionDescriptions = Object.keys(targetOrigins)
            .sort()
            .map(targetKey => {
               const originNames = targetOrigins[targetKey].map(originId => this.modules[originId].name);
               return this.andNames(this.pluraliseFormatNames(originNames)) + ' to ' + this.modules[targetKey].name;
            });

         const sourcelessDestinations = (Object.keys(nodes) as ModuleName[]) // https://github.com/Microsoft/TypeScript/issues/12870
            .filter(key => (this.modules[nodes[key].moduleId].type === 'destination' && this.modules[nodes[key].moduleId].maxInputModules === 0))
            .map(key => this.modules[nodes[key].moduleId].name);

         if (sourcelessDestinations.length) {
            connectionDescriptions.unshift(' to ' + this.pluraliseFormatNames(sourcelessDestinations).join(' and to '));
         }

         return connectionDescriptions.length ? 'Publish ' + connectionDescriptions.join("; ") : '';
      }

      private andNames(names: string[]): string {
         let andNames = names.pop();
         names.forEach((name, index) => {
            andNames += ((index === names.length - 1) ? ' and ' : ', ') + name;
         });
         return andNames;
      }

      // Turns array of non-unique words into unique list by replacing non-unique words with a single pluralisation
      private pluraliseFormatNames(names: string[]): string[] {
         const pluralisedNames = names.map((name, index) => {
            // TODO use pluralise filter FP-20700
            if (names.some((x, ix) => x === name && ix !== index)) {
               name += name.slice(-1) !== 's' ? 's' : '';
               return `multiple ${name}`;
            }
            else return name;
         });
         return this._.uniq(pluralisedNames);
      }

      private handleUpdate(): void {
         this.cachedNetwork = JSON.parse(JSON.stringify(this.network));
         this.description = this.generateDescription();
      }

      private connectionsChanged(): boolean {
         if (this.network.connections.length !== this.cachedNetwork.connections.length) return true;
         return !this.network.connections.every((connection, index) => {
            return connection[0] === this.cachedNetwork.connections[index][0] && connection[1] === this.cachedNetwork.connections[index][1];
         });
      }

      private nodesChanged(): boolean {
         if (Object.keys(this.network.nodes).length !== Object.keys(this.cachedNetwork.nodes).length) return true;
         return !Object.values(this.network.nodes).every(networkNode => {
            const currentModuleIds = Object.values(this.cachedNetwork.nodes).map(node => node.moduleId);
            return currentModuleIds.includes(networkNode.moduleId);
         });
      }
   }
};

ngModule.component('publishButtonDescription', PublishButtonDescriptionComponent);

export default ngModule;
