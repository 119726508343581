export const FbdnDragSelectItem: InjectableDirectiveFactory = ['$parse', function($parse: IParseService) {
   return {
      restrict: 'A',
      link: function(scope: IScope, element: JQLite, attrs: IAttributes) {
         var callback = $parse(attrs.fbdnDragSelectItem);

         element.on('mousedown', function(e) {
            e.stopPropagation();
         });

         scope.$on('dragSelect::move', function(e, topLeft, bottomRight) {
            var offset = element.offset();
            var width = element.width();
            var height = element.height();
            var elementTopLeft = {
               x: offset.left,
               y: offset.top
            };
            var elementBottomRight = {
               x: offset.left + width,
               y: offset.top + height
            };
            var isTouching = !(elementTopLeft.x >= bottomRight.x || elementBottomRight.x <= topLeft.x ||
                               elementTopLeft.y >= bottomRight.y || elementBottomRight.y <= topLeft.y);

            if (isTouching) {
               callback(scope);
            }
         });
      }
   };
}];
