import { NavigationService } from '../../shared/utils/navigation/navigation.service';
import { AccountUsersApi } from '../../shared/api/account-users-api.factory';
import { DisconnectUser } from './disconnect-user.interface';

interface ForceDisconnectDialogScope extends IScope {
   state: string;
   disconnectUser: DisconnectUser;
   messages: {
      afterDisconnect: string;
   };
   cancel: () => void;
   disconnect: () => void;
}

export const ForceDisconnectDialogController: Injectable<IControllerConstructor> = [
      '$scope', '$modalInstance', '$filter', '$window', 'User', 'AccountUsersApi', 'NavigationService', 'disconnectUser',
      function($scope: ForceDisconnectDialogScope, $modalInstance: any, $filter: IFilterService, $window: IWindowService,
               User: User, accountUsersApi: AccountUsersApi, navigationService: NavigationService, disconnectUser) {
   $scope.state = 'editing';
   $scope.disconnectUser = disconnectUser;
   $scope.messages = {
      afterDisconnect: "You have been disconnected from this account by " + User.fullName + " at " + $filter('date')(new $window.Date(), 'h:mm:ss a, MMM d y.')
   };

   $scope.cancel = () => $modalInstance.dismiss();

   $scope.disconnect = function() {
      $scope.state = 'submitting';

      accountUsersApi.disconnect({
         sessionId: disconnectUser.sessionId,
         message: $scope.messages.afterDisconnect
      }).then(() => navigationService.reload($scope.disconnectUser.fullName + ' has been disconnected'))
        .catch(() => navigationService.reload('Sorry, unable to disconnect ' + $scope.disconnectUser.fullName));
   };
}];
