import { NavigationService } from '../navigation/navigation.service';
import NavigationModule from '../navigation/navigation.module';

export interface WatchedObject<T = any> {
   $$saved: Partial<T>;
   $$dirty: boolean;
}

export interface NewStatefulObject {
   $$state: 'new' | 'clean' | 'creating' | 'saving';
   $$temporaryId: string | number;
}

export interface StatefulObject<T> extends NewStatefulObject {
   $$saved: Partial<T>;
   $$saving: boolean;
   $$savingStates: any;
   $$dirty: boolean;
   $$deepKeys?: (keyof T)[];
   $$deleting?: boolean;
}

export interface StatefulWatchedObject<T = any> extends StatefulObject<T>, WatchedObject<T> {}

export interface SaveTransaction {
   prepare<T>(object: T, deepKeys?: (keyof T)[]): T;
   save<T extends StatefulObject<T>>(object: T, key: keyof T, setter: any): any;
   saveObject<T extends StatefulObject<T>>(object: T, setter: (obj: T) => Promise<any>, withRollback?: boolean): Promise<any>;
   saveCollectionKey(collection, key, setter): Promise<any>;
   saveCollection(collection, setter): any;
   rollbackProperty<T extends StatefulObject<T>>(object: T, key: keyof T): void;
   rollbackObject<T extends StatefulObject<T>>(object: T): void;
   registerDirtyWatchers<T extends WatchedObject>(objectGetter: () => T[], keysToIgnore?: string[], scope?: any): number;
}

const ngModule = angular.module('bb.utils.save-transaction', [
   NavigationModule.name,
]);

ngModule.factory('SaveTransaction', ['$q', 'NavigationService', '_', function($q: IQService, navigationService: NavigationService, _: Lodash): SaveTransaction {
   const api: SaveTransaction = this;

   function _saveSaved(object) {
      if (object.$$managedBySkeletonCache) return;
      object.$$saved = _.cloneDeep(_.omitBy(object, (value, key) => key.startsWith('$$')));
   }

   api.prepare = (object, deepKeys) => {
      _.defaults(object, {
         $$deepKeys: deepKeys,
         $$savingStates: {},
         $$saving: false,
         $$deleting: false,
         $$dirty: false,
         $$state: 'clean'
      });
      _saveSaved(object);
      return object;
   };

   api.save = (object, key, setter) => {
      if (object?.$$saved && object[key] === object.$$saved[key]) return $q.reject();
      object.$$saving = true;
      object.$$savingStates[key] = 'saving';
      return setter(object, key).then(function(result) {
         object.$$saved[key] = _.cloneDeep(object[key]);
         return result;
      }, function(error) {
         api.rollbackProperty(object, key);
         return $q.reject(error);
      }).finally(function() {
         delete object.$$savingStates[key];
         if (_.isEmpty(object.$$savingStates)) {
            object.$$saving = false;
         }
      });
   };

   api.saveObject = (object, setter, withRollback = false) => {
      object.$$saving = true;
      var original = _.clone(object);
      object.$$state = object.$$state === 'new' ? 'creating' : 'saving';
      return setter(object).then(function(result) {
         _saveSaved(object);
         object.$$state = 'clean';
         return result;
      }, function(error) {
         object.$$state = original.$$state;

         if (withRollback) {
            angular.extend(object, _.cloneDeep(_.pickBy(object.$$saved, function(value, key) {
               if (key.indexOf('$$') === 0) return false;
               return true;
            })));
         }
         return $q.reject(error);
      }).finally(function() {
         if (_.isEmpty(object.$$savingStates)) {
            object.$$saving = false;
         }
      });
   };

   api.saveCollectionKey = (collection, key, setter) => {
      var originalCollection = _.clone(collection);
      _.forEach(originalCollection, function(object) {
         object.$$saving = true;
         object.$$savingStates[key] = 'saving';
      });

      return setter(collection, key).then(function(result) {
         _.forEach(originalCollection, function(object) {
            object.$$saved[key] = object[key];
         });
         return result;
      }, function(error) {
         _.forEach(originalCollection, function(object) {
            api.rollbackProperty(object, key);
         });
         return $q.reject(error);
      }).finally(function() {
         _.forEach(originalCollection, function(object) {
            delete object.$$savingStates[key];
            if (_.isEmpty(object.$$savingStates)) {
               object.$$saving = false;
            }
         });
      });
   };

   api.saveCollection = (collection, setter) => {
      var originalCollection = _.clone(collection);
      var originalStates = _.map(originalCollection, '$$state');

      _.forEach(originalCollection, function(object) {
         object.$$saving = true;
         object.$$state = object.$$state === 'new' ? 'creating' : 'saving';
      });

      return setter(collection).then(function(result) {
         _.forEach(originalCollection, function(object) {
            _saveSaved(object);
            object.$$state = 'clean';
         });
         return result;
      }, function(error) {
         _.forEach(originalStates, function(state, i) {
            originalCollection[i].$$state = state;
         });
         return $q.reject(error);
      }).finally(function() {
         _.forEach(originalCollection, function(object) {
            if (_.isEmpty(object.$$savingStates)) {
               object.$$saving = false;
            }
         });
      });
   };

   api.rollbackProperty = (object, key) => {
      object[key] = _.includes(object.$$deepKeys, key) ? _.cloneDeep(object.$$saved[key]) : object.$$saved[key];
   };

   function _removeRootLevelAdditions(object) {
      // Remove any properties added to root of object since $$saved
      // _.assign removes nested additions (as does a shallow replace)
      Object.keys(object).slice().forEach(key => {
         if (key.startsWith('$$')) return;
         if (!object.$$saved.hasOwnProperty(key)) {
            delete object[key];
         }
      });
   }

   api.rollbackObject = object => {
      _removeRootLevelAdditions(object);
      _.assign(object, _.cloneDeep(object.$$saved));
   };

   /*
      This will initialise a new interval timer _every_ call, so you should
      only register a collection once.
      We will need to tear down the dirty watchers (i.e. clearInterval) on
      component destroy, when the pages consuming this are frontend routed.
      Returns the timer's id which consumers can use for this.
   */
   api.registerDirtyWatchers = (objectGetter, keysToIgnore = [], scope?) => {
      return window.setInterval(() => {
         const objects = objectGetter();
         if (objects) {
            if (Array.isArray(objects)) {
               objects.forEach(object => {
                  const dirty = object.$$saved !== undefined &&
                     !angular.equals(
                        _.omit(object, keysToIgnore),
                        _.omit(object.$$saved, keysToIgnore),
                     );

                  object.$$dirty = dirty;
                  if (object.$$dirty) {
                     navigationService.addToWarningList(object);
                  } else {
                     navigationService.removeFromWarningList(object);
                  }

                  // Trigger change detection for legacy angular.js
                  if (scope) {
                     scope.$apply();
                  }
               });
            } else {
               throw new Error('objectGetter must return an array');
            }
         }
      }, 100);
   };

   return api;
}]);

export default ngModule;
