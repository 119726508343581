const ngModule = angular.module('bb.tpl', []);

ngModule.factory('tpl', ['$document', '$compile', '$parse', '$q', function($document, $compile, $parse, $q) {
   // Create unique Ids based on a string and a uniquely associated integer
   var ids = {};
   function newId(base) {
      var id_base = angular.copy(base);
      if (!id_base) {
         id_base = "id";
      }

      // Replace forbidden characters
      id_base = id_base.replace(/-/g, "_");

      if (!ids[id_base]) {
         ids[id_base] = 0;
      }

      return id_base + ids[id_base]++;
   }

   function toKebabCase(str) {
      return str.replace(/([a-z0-9])([A-Z])/g, '$1-$2').toLowerCase();
   }

   /* Instantiates and appends an Angular directive to any existing
    * DOM element identified as the first match returned by
    * $document.find(<anchor>) where <anchor> is a function argument
    * and returns a handler for this new element. The directive must
    * export its scope through its "scope" attribute. See dialog.module.ts
    * and templates/ng_dialog.html for an example of compatible directives.
    *
    * Arguments:
    *    options: dictionary containing all parameters (see Parameters
    *    section below).
    *
    * Return:
    *    A promise that, when resolved, provides a handler to the newly
    *    created angular element. The handler has the member fields
    *    below:
    *       scope: angular scope of the newly created element
    *       element: angular element pointing to the newly instanciated
    *          directive.
    *
    * Parameters:
    *    anchor (string, mandatory):
    *       String passed to $document.find() to find the parent DOM
    *       element of the angular directive element to be created.
    *       Will use the first match found as parent. The parent must
    *       exist within some angular controller context. Failure to
    *       use a parent in a angular controller context results in
    *       undefined behavior.
    *    template (string, mandatory):
    *       name of the angular directive to instantiate. The directive
    *       must export its scope through the "scope" attribute. See
    *       dialog.module.ts and templates/ng_dialog.html for an
    *       example of such directive. Failure to comply with this
    *       requirement results in undefined behavior.
    *    attr (dictionary, optional):
    *       key-value pairs of attributes to append to the new element.
    *    args (dictionary, optional):
    *       List of javascript objects passed to init() function of the
    *       newly created element's angular scope. Default arguments
    *       include "dom" which is the DOM element containing everything
    *       generated by createTemplate. This is only used if the scope
    *       of the templates instantiated has the function
    *       "init(args) { ... }" defined. It is run after the scope is
    *       created but before inner elements are.
    *    innerHTML (string, optional):
    *       HTML code to include in the new element.
    *
    * Example:
    *
    * tpl.createTemplate({
    *    anchor: 'body',
    *    template: 'fbdn-job-log-display'
    * }).then((handler) => {
    *    logWindow = handler;
    * });
    *
    */
   function createTemplate(options) {
      var anchor = options.anchor;

      // Synthetize a new element like
      // <my-elem instance="some_unique_id" foo="bar">innerHTML</my-elem>
      // Begin with opening, instance and attributes
      var id = newId(options.template);
      var template = '<' + options.template + ' scope="' + id + '"';
      if (options.attr) {
         for (var attr in options.attr) {
            template += " " + toKebabCase(attr) + '="' + options.attr[attr] + '"';
         }
      }
      template += ">";

      // ... now add innerHTML ...
      if (options.innerHTML) {
         template += options.innerHTML;
      }

      // ... and close element.
      template += "</" + options.template + ">";

      // Find anchor in document and append new element to it
      var anchorDom = $document.find(anchor).eq(0);
      var anchorAng = angular.element(anchorDom);
      var anchorScope = anchorAng.scope();
      var angEl = angular.element(template);
      var domEl = angEl.appendTo(anchorDom);

      // Commit new element and gets its instance member from within the anchor scope
      $compile(domEl)(anchorScope);
      $parse(id)(anchorScope);

      // Return a promise that gets resolved whenever the element is ready to be used
      return $q(function(resolve) {
         anchorScope.$watch(id, function (scope) {
            if (scope) {
               // This makes args available to the html angular template
               scope.args = options.args;

               // Since the action above does not make args visible in the final
               // scope, we need to pass it again through some proxy function we
               // assume exists
               if (scope.init && options.args) {
                  scope.init(Object.assign({dom: domEl[0]}, options.args));
               }

               resolve({
                  scope: scope, // private scope of the new element
                  element: angEl // useful to remove element
               });
            }
         });
      });
   }

   return {
      createTemplate: createTemplate
   };
}]);

export default ngModule;
