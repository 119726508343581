<div>
   <a (click)="toggleStatus()" type="button" class="tab-label" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
      <bb-svg-icon
         class="button-icon" icon="status"
         [matBadge]="model.filteredObjects.length"
         [matBadgeHidden]="!model.filteredObjects.length"
         matBadgeColor="warn"
         matBadgeSize="small"
         >
         status
      </bb-svg-icon>
    Status
   </a>
   <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayOpen]="popoverOpen"
      [cdkConnectedOverlayFlexibleDimensions]="true"
      [cdkConnectedOverlayViewportMargin]="8"
      [cdkConnectedOverlayPositions]="positionPairs"
      [cdkConnectedOverlayHasBackdrop]="true"
      [cdkConnectedOverlayPush]="true"
      (backdropClick)="popoverOpen = false"
   >
      <div class="popover-status-body">
         <div class="toggle" *ngIf="model.ready && (model.filteredObjects.length != model.objects.length || showAll)">
            <button type="button" (click)="toggleShowAll()" class="btn btn-sm">{{showAll ? 'Hide expected' : 'Show all'}}</button>
         </div>
         <ul class="errors">
            <ng-container *ngIf="!model.ready; else mReady">
               <li class="good center-li">Loading status...</li>
            </ng-container>
            <ng-template #mReady>
               <ng-container *ngIf="!model.filteredObjects.length && !showAll; else showFStatuses">
                  <li class="good center-li">Everything is OK</li>
               </ng-container>
               <ng-template #showFStatuses>
                  <li *ngFor="let modelObject of objectsToRender()" class="status-level-{{modelObject.$$statusLevel}} center-li">
                     <a *ngIf="validLink(modelObject); else justDisplay" [href]="getUrl(modelObject)" class="text-wrap">
                        <span class="btn btn-sm text-name">{{modelObject.name}}</span>
                        <span class="btn btn-sm text-error">{{modelObject.message || 'Offline'}}</span>
                     </a>
                     <ng-template #justDisplay>
                        <div class="text-wrap">
                           <span class="btn btn-sm text-name">{{modelObject.name}}</span>
                           <span class="btn btn-sm text-error">{{modelObject.message || 'Offline'}}</span>
                        </div>
                     </ng-template>
                  </li>
               </ng-template>
            </ng-template>
         </ul>
      </div>
   </ng-template>
</div>