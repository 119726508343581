import { DragAndDropService } from './drag-and-drop.service';
import { i18n } from './i18n.service';
import { LoginDialogLockService } from './login-dialog-lock.service';
import { NotificationService } from './notification.service';

const ngModule = angular.module('bb.services', []);

ngModule
   .service('NotificationService', NotificationService)
   .service('DragAndDropService', DragAndDropService)
   .service('I18nService', i18n)
   .service('LoginDialogLockService', LoginDialogLockService);

export default ngModule;
