import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FileColumn, LoggingColumn, PageContext, ColumnContext, ColumnsDto, MetaColumnsDto } from '../components/types/metadata-column.interface';
import { Observable } from 'rxjs';
import { EmptyObject } from '../types';
import { PageService } from './page.service';

@Injectable({
   providedIn: 'root',
})
export class ColumnsApiService {

   public readonly commonLoggingColumns = [{
      displayName: 'Shot',
      internalName: 'Shot',
      hidden: false
   }, {
      displayName: 'Sync',
      internalName: 'Sync',
      hidden: false
   }];
   public readonly commonFileColumns= [{
      displayName: 'Disk label',
      internalName: '_IMPORTDISKLAB',
      hidden: false
   }, {
      displayName: 'Start timecode',
      internalName: 'starttimecode',
      hidden: true
   }, {
      displayName: 'End timecode',
      internalName: 'endtimecode',
      hidden: true
   }, {
      displayName: 'Source locator',
      internalName: 'sourcelocator',
      hidden: true
   }];
   private page: Page;

   constructor(
      private pageService: PageService,
      @Inject('_') private _: Lodash,
      private http: HttpClient
   ) {
      this.page = this.pageService.page;
   }

   public addLoggingColumnDefaults(column: LoggingColumn | EmptyObject = {}): LoggingColumn {
      return this._.defaults(column, {
         displayName: '',
         internalName: '',
         hidden: false,
         title: false,
         media: false,
         presets: []
      });
   }

   public addFileColumnDefaults(column: FileColumn | EmptyObject = {}): FileColumn {
      return this._.defaults(column, {
         displayName: '',
         internalName: '',
         hidden: false,
         searchable: false,
         editable: false
      });
   }

   public convertFileColumnsToLoggingColumns(fileColumns: FileColumn[]): LoggingColumn[] {
      return fileColumns.map( fileColumn => {
         const loggingColumn = this.addLoggingColumnDefaults();
         loggingColumn.media = true;
         loggingColumn.internalName = fileColumn.internalName;
         loggingColumn.displayName = fileColumn.displayName;
         return loggingColumn;
      });
   }

   public setColumns(pageContext: PageContext, columnContext: ColumnContext, columns: MetaColumnsDto[], autoInherit: boolean): Observable<void> {
      const colCtx = columnContext === 'file-columns' ? 'fileColumns' : 'loggingColumns';
      const path = pageContext === 'Site'
         ? `/api/sites/${this.page.siteId}/${colCtx}`
         : `/api/accounts/${this.page.accountId}/${colCtx}`;
      return this.http.post<void>(path, {columns: columns, autoInherit: autoInherit});
   }

   public getColumns(pageContext: PageContext, columnContext: ColumnContext): Observable<ColumnsDto<MetaColumnsDto>> {
      const colCtx = columnContext === 'file-columns' ? 'fileColumns' : 'loggingColumns';
      const path = pageContext === 'Site'
         ? `/api/sites/${this.page.siteId}/${colCtx}`
         : `/api/accounts/${this.page.accountId}/${colCtx}`;
      return this.http.get<ColumnsDto<MetaColumnsDto>>(path);
   }

   public getDefaultColumns(pageContext: PageContext, columnContext: ColumnContext): Observable<ColumnsDto<MetaColumnsDto>> {
      const colCtx = columnContext === 'file-columns' ? 'defaultFileColumns' : 'defaultLoggingColumns';
      const path = pageContext === 'Site'
         ? `/api/sites/${this.page.siteId}/${colCtx}`
         : `/api/accounts/${this.page.accountId}/${colCtx}`;
      return this.http.get<ColumnsDto<MetaColumnsDto>>(path);
   }

   public getDefaultLoggingColumns(context: PageContext): Observable<ColumnsDto<LoggingColumn>> {
      return this.http.get<ColumnsDto<LoggingColumn>>((context === 'Site'
         ? '/api/sites/' + this.page.siteId
         : '/api/accounts/' + this.page.accountId) + '/defaultLoggingColumns');
   }

   public getDefaultFileColumns(context: PageContext): Observable<ColumnsDto<FileColumn>> {
      return this.http.get<ColumnsDto<FileColumn>>((context === 'Site'
         ? '/api/sites/' + this.page.siteId
         : '/api/accounts/' +  this.page.accountId) + '/defaultFileColumns');
   }

   public getFileColumns(context: PageContext): Observable<ColumnsDto<FileColumn>> {
      return this.http.get<ColumnsDto<FileColumn>>((context === 'Site'
         ? '/api/sites/' + this.page.siteId
         : '/api/accounts/' + this.page.accountId) + '/fileColumns');
   }
   public getLoggingColumns(context: PageContext): Observable<ColumnsDto<LoggingColumn>> {
      return this.http.get<ColumnsDto<LoggingColumn>>((context === 'Site'
         ? '/api/sites/'+ this.page.siteId
         : '/api/accounts/' + this.page.accountId) + '/loggingColumns');
   }
}

