const ngModule = angular.module('bb.stop-propagation', []);

ngModule.directive('stopPropagation', function() {
   return {
      link: function(scope, element, attrs) {
         element.on(attrs.stopPropagation, e => {
            e.stopPropagation();
         });
      }
   };
});

export default ngModule;
