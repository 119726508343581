import UserModule from '../../user.factory';
import HttpModule from '../../fbdn-angular-modules/http';

import { fbdnDialog } from './fbdn-dialog.directive';
import { fbdnLoginDialog } from './fbdn-login.dialog.directive';
import { fbdnSimpleChoiceDialog } from './fbdn-simple-choice-dialog.directive';

const ngModule = angular.module('bb.dialog', [
   UserModule.name,
   HttpModule.name
]);

ngModule
   .directive('fbdnDialog', fbdnDialog)
   .directive('fbdnLoginDialog', fbdnLoginDialog)
   .directive('fbdnSimpleChoiceDialog', fbdnSimpleChoiceDialog);

export default ngModule;
