export const FbdnDraggablePrevent: InjectableDirectiveFactory = function() {
   return {
      restrict: 'A',
      require: '?^fbdnDraggable',
      link: function(scope, element: JQLite) {
         var pressEvents = 'touchstart mousedown';

         element.on(pressEvents, onPress);

         function onPress(evt: JQueryMouseEventObject): void {
            // Do not propagate to a containing fbdn-draggable but leave default action so form controls work
            evt.stopPropagation();
         }
      }
   };
};
