/* @Deprecated Use Angular MenuDirectionDirective if containing page is hybrid or pure Angular */
export abstract class MenuDirectionBase {
   private readonly openUp = 'open-up';

   constructor(protected $window: IWindowService) {}

   protected setOpenDirectionClass(menu: JQLiteShim): void {
      // To check on original position
      menu.removeClass(this.openUp);

      const window = angular.element(this.$window) as JQLiteShim;

      const offset = menu.offset();
      const viewportBottom = window.scrollTop() + window.height();
      const openUp = !(offset.top > 0 && offset.top + menu.outerHeight() < viewportBottom);

      if (openUp) {
         menu.addClass(this.openUp);
      }
   }
}
