<div class="form-group publish-server" *ngIf="button.location || reportLength(invalidreport)">
   <mat-form-field appearance="outline" class="form-control-group {{reportLength(invalidreport) ? 'has-error' : ''}} form-maxsize">
      <mat-label class="control-label">Where to run</mat-label>
      <mat-select
         *ngIf="button.location"
         (ngModelChange)="locationChange($event)"
         [ngModel]="getSelectedLocation()"
         name="location"
      >
         <mat-option *ngFor="let location of selectablelocations" [value]="location">
            {{location.name}}
         </mat-option>
      </mat-select>
      <p *ngIf="reportLength(invalidreport)" class="help-block">
         <em>{{button.location.name}}</em> will not be able to run this publishing button because
         <span class="help-block-list">
            <span *ngFor="let item of invalidreport; trackBy: index | keyvalue">
               <span *ngIf="item.error === 'unknown-location'">
                  the location is not available
               </span>
               <span *ngIf="item.error !== 'unknown-location'">
                  the module <em>{{modules[item.moduleId].name}}</em> {{item.error === 'needs-upgrade' ? 'must be upgraded' : 'is not installed'}}
               </span>
            </span>
         </span>.
      </p>
   </mat-form-field>
</div>
