import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { forkJoin, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { EdgeServer } from '../../edge-servers/types';
import { EdgeServersApiService } from '../../shared/services/edge-servers-api.service';
import { ApiUpdatesService } from '../../shared/utils/updates/api-updates.service';
import * as Notifications from '../notifications/notifications.actions';
import * as EdgeServers from '.';
import { ErrorMessageService } from '../../shared/services/error-message.service';

function getEdgeTypeToShow() {
   // Support secret extra URL query for viewing other edge server types, eg ?type=browser
   return (new URL(""+document.location)).searchParams.get("type") || "server";
}

@Injectable()
export class EdgeServersEffects {

   /* Load */
   loadEdges$ = createEffect(() => this.actions$.pipe(
      ofType(EdgeServers.loadEdges),
      mergeMap(({context, contextualId}) => forkJoin([
         this.edgeServersApiService.fetchEdges(context, contextualId, true, getEdgeTypeToShow()),
         this.edgeServersApiService.fetchEdgeTypes(),
      ]).pipe(
         tap(([edges]) => !this.apiUpdates && this.initApiUpdates(edges)),
         map(([edges, edgeTypes]) => ({ type: EdgeServers.loadEdgesSuccess.type, edges, edgeTypes })),
         catchError((error) => of({ type: EdgeServers.loadEdgesFail.type, errorMessage: this.errorMessageService.errorMessage(error) })),
      )),
   ));

   fetchEdge$ = createEffect(() => this.actions$.pipe(
      ofType(EdgeServers.fetchEdge),
      switchMap(({id}) => this.edgeServersApiService.fetchEdge(id)),
      map(edge => ({ type: EdgeServers.upsertEdge.type, edge })),
   ));

   /* Save */
   saveEdge$ = createEffect(() => this.actions$.pipe(
      ofType(EdgeServers.saveEdge),
      mergeMap(({edge}) => this.edgeServersApiService.saveEdgeServer(edge).pipe(
         map(e => ({ type: EdgeServers.saveEdgeSuccess.type, edge: e })),
         catchError((error) => of({
            type: Notifications.showNotification.type,
            notificationType: 'danger',
            notificationText: `There was an error saving the edge: ${this.errorMessageService.errorMessage(error)}`,
         })),
      )),
   ));
   saveEdgeSuccessNotification$ = createEffect(() => this.actions$.pipe(
      ofType(EdgeServers.saveEdgeSuccess),
      map(({edge}) => ({
         type: Notifications.showNotification.type,
         notificationType: 'success',
         notificationText: `The edge server "${edge.name}" has been saved`,
      })),
   ));

   saveEdgeName$ = createEffect(() => this.actions$.pipe(
      ofType(EdgeServers.saveEdgeName),
      mergeMap(({id, name}) => this.edgeServersApiService.saveName(id, name).pipe(
         map(edge => ({ type: EdgeServers.updateEdgeName.type, id: edge.id, name: edge.name })),
         catchError((error) => of({
            type: Notifications.showNotification.type,
            notificationType: 'danger',
            notificationText: (name.length > 60) ? "Name too long" : this.errorMessageService.errorMessage(error),
         })),
      )),
   ));

   /* Create */
   createEdge$ = createEffect(() => this.actions$.pipe(
      ofType(EdgeServers.createEdge),
      mergeMap(({edge, tempId}) => this.edgeServersApiService.createEdgeServer(edge).pipe(
         map((createdEdge) => ({ type: EdgeServers.createEdgeSuccess.type, edge: createdEdge, tempId })),
         catchError((error) => of({
            type: EdgeServers.createEdgeFail.type,
            notificationType: 'danger',
            errorMessage: this.errorMessageService.errorMessage(error)
         })),
      )),
   ));

   createEdgeSuccessNotification$ = createEffect(() => this.actions$.pipe(
      ofType(EdgeServers.createEdgeSuccess),
      map(({edge}) => ({
         type: '[Notifications] Show Notification',
         notificationType: 'success',
         notificationText: `The edge server "${edge.name}" has been created`,
      })),
   ));

   /* Delete */
   deleteEdge$ = createEffect(() => this.actions$.pipe(
      ofType(EdgeServers.deleteEdge),
      mergeMap(({id}) => this.edgeServersApiService.deleteEdgeServer(id).pipe(
         map(() => ({ type: EdgeServers.deleteEdgeSuccess.type, id })),
         catchError((error) => of({
            type: Notifications.showNotification.type,
            notificationType: 'danger',
            notificationText: `There was an error deleting the edge: ${this.errorMessageService.errorMessage(error)}`,
         })),
      )),
   ));
   removeEdgeAfterDeleting$ = createEffect(() => this.actions$.pipe(
      ofType(EdgeServers.deleteEdgeSuccess),
      map(({id}) => ({ type: EdgeServers.removeEdgeFromStore.type, id })),
   ));
   deleteEdgeSuccessNotification$ = createEffect(() => this.actions$.pipe(
      ofType(EdgeServers.deleteEdgeSuccess),
      map(() => ({
         type: Notifications.showNotification.type,
         notificationType: 'success',
         notificationText: 'The edge server has been deleted',
      })),
   ));

   /* Update store */
   updatePersistedEdge$ = createEffect(() => this.actions$.pipe(
      ofType(EdgeServers.saveEdgeSuccess, EdgeServers.createEdgeSuccess),
      map(({edge}) => ({ type: EdgeServers.upsertEdge.type, edge })),
   ));

   private apiUpdates: any;

   constructor(
      private actions$: Actions,
      private store: Store,
      private edgeServersApiService: EdgeServersApiService,
      private apiUpdatesService: ApiUpdatesService,
      private errorMessageService: ErrorMessageService,
   ) {}

   private initApiUpdates(edges: EdgeServer[]): void {

      this.apiUpdates = this.apiUpdatesService.initApiUpdatesService('EdgeServers', (edge: EdgeServer) => {
         this.store.dispatch(EdgeServers.fetchEdge({ id: edge.id }));
      });
      this.apiUpdates.subscribeToCollection({ results: edges });
   }
}
