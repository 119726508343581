import { createSelector } from '@ngrx/store';

import { EdgeServer } from '../../edge-servers/types';
import { AppState } from '../app.state';


const edgesState = (appState: AppState) => appState.edgeServersState;
export const selectEdges = createSelector(edgesState, (state) => state.edges);
export const selectEdge = createSelector(
   selectEdges,
   (edges: EdgeServer[], edgeId: string): EdgeServer | void => {
      return edges.find(edge => edge.id === edgeId);
   },
);
export const selectEdgesLoading = createSelector(edgesState, (state) => state.loading);
export const selectEdgesLoadError = createSelector(edgesState, (state) => state.error);
export const selectPersistingEdge = createSelector(edgesState, (state) => state.persistEdgeLoading);
export const selectEdgeTypes = createSelector(edgesState, (state) => state.edgeTypes);
