import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { forkJoin, Observable, Subject } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';


import { UsersApiService } from '../../../shared/api/users.api.service';
import { AccountsApiService } from '../../../shared/api/accounts/accounts.api.service';
import { KeyboardApiService } from '../../../shared/services/keyboard-api.service';
import { KeyboardExpansionInfo } from '../../../shared/types/keyboard.interface';
import { ParentChildInvalidMatcher } from '../../../shared/utils/forms/parent-child-invalid-matcher';
import { AccountRelationship } from '../../../store/users';
import * as Users from '../../../store/users';
import { AlertService } from '../../../shared/services/alert.service';
import { PageService } from '../../../shared/services/page.service';


interface KeyboardMap {
   id: string;
   accountId: string;
   name: string;
}


@Component({
   selector: 'bb-keyboard-expansion',
   templateUrl: './keyboard-expansion.component.html',
   styleUrls: ['./keyboard-expansion.component.scss',],
})
export class KeyboardExpansionComponent implements OnInit, OnDestroy {
   public displayedColumns = ['mapname', 'downloadoption', 'exportaccount', 'newexportname', 'exportaction'];
   public managedAccounts: AccountRelationship[] = [];
   public keyboardMaps: KeyboardMap[] = [];
   public form: FormGroup;
   public accountName: string;
   public matcher = new ParentChildInvalidMatcher();

   private destroy$ = new Subject<void>();
   private page: Page;

   constructor(
      private alertService: AlertService,
      private pageService: PageService,
      private fb: FormBuilder,
      private keyboardApiService: KeyboardApiService,
      private accountsApiService: AccountsApiService,
      private usersApiService: UsersApiService,
      private store: Store,
   ) {
      this.page = this.pageService.page;
   }

   ngOnInit() {
      this.resetMaps();
      this.store.dispatch(Users.loadMe(true));
   }

   ngOnDestroy() {
      this.destroy$.next();
      this.destroy$.complete();
   }

   public isMapNameInvalid(index: number): boolean {
      const fa: FormArray = this.form.get('expansionMap') as FormArray;
      const ifg: FormGroup = fa.at(index) as FormGroup;
      return ifg.invalid;
   }

   public getErrorMessage(index: number): string {
      const fa: FormArray = this.form.get('expansionMap') as FormArray;
      const ifg: FormGroup = fa.at(index) as FormGroup;
      if (ifg.getError('duplicatedMapName')) {
         return 'Name duplicated';
      }
      else if (ifg.get('expansionMapNewName').getError('required')) {
         return 'Name missing';
      }
   }

   public exportMap(kmap: KeyboardMap, index: number): void {
      const ctl = (this.form.get('expansionMap') as FormArray).at(index);
      const newName = ctl.get('expansionMapNewName').value;
      const newAccount = ctl.get('expansionMapNewAccount').value;

      this.keyboardApiService.exportKeyboardMap(newAccount.id, newName, kmap.id)
         .pipe(
            takeUntil(this.destroy$),
         )
         .subscribe(() => {
            this.alertService.show({
               text: 'New key map name saved successfully',
               type: 'success',
            });
            const ar = this.form.get('expansionMap') as FormArray;
            ar.clear();
            this.resetMaps();
         });
   }

   private createFormArray(): FormArray {
      return new FormArray(this.keyboardMaps.map(item => new FormGroup(
         {
            expansionMapNewAccount: new FormControl(this.managedAccounts.find(acc => acc.id === item.accountId)),
            expansionMapNewName: new FormControl('', Validators.required)
         }, { asyncValidators: this.newMapNameValidator() },
      )));
   }

   private newMapNameValidator(): AsyncValidatorFn {
      return (control: AbstractControl): Observable<ValidationErrors> => {
         const newAccount = control.get('expansionMapNewAccount').value;
         const newMapName = control.get('expansionMapNewName').value;
         return this.keyboardApiService.getKeyboardExpansions(newAccount.id)
            .pipe(
               map((keyExpansionMaps: KeyboardExpansionInfo[]) => {
                  const duplicateKeyMap = keyExpansionMaps.find(element => element.name === newMapName);
                  return  (duplicateKeyMap !== undefined)
                     ? { duplicatedMapName:  true }
                     : null;
               })
            );
      };
   }

   private resetMaps(): void {
      const selectMe$ = this.store.select(Users.selectMe).pipe(
         filter(me => !!me),
         take(1),
      );

      forkJoin({
         me: selectMe$,
         accountInfo: this.accountsApiService.fetch(this.page.accountId),
         kbMaps: this.keyboardApiService.getKeyboardExpansions(),
      })
         .pipe(
            this.alertService.notifyOnError('loading keyboard expansions'),
            takeUntil(this.destroy$),
         )
         .subscribe(({me, accountInfo, kbMaps}) => {
            this.managedAccounts = me.accounts.filter(account => account.isManager);
            this.accountName = accountInfo.name;
            this.keyboardMaps = kbMaps;
            this.form = this.fb.group({ expansionMap: this.createFormArray() });
         });
   }
}
