const ngModule = angular.module('bb.input', []);

// fix up autofocus attribute to work with angular stuff
ngModule.directive('autofocus', ['$timeout', function($timeout) {
   return {
      restrict: 'A',
      link: function($scope, $element) {
         $timeout(function() {
            $element[0].focus();
         });
      }
   };
}]);

ngModule.directive('ngEnter', function() {
   return {
      link: function(scope, element, attrs) {
         element.bind("keydown keypress", function(event) {
            if (event.which === 13) {
               scope.$apply(function() {
                  scope.$eval(attrs.ngEnter);
               });
               event.preventDefault();
            }
         });
      }
   };
});

ngModule.directive('ngCancel', function() {
   return {
      link: function(scope, element, attrs) {
         element.bind("keydown keypress", function(event) {
            if (event.which === 27) {
               scope.$apply(function() {
                  scope.$eval(attrs.ngCancel);
               });
               event.preventDefault();
            }
         });
      }
   };
});

ngModule.directive('autofocusCaretAtEnd', ['$timeout', function($timeout) {

   function setCaretAtEnd(element, setFocus) {
      $timeout(function() {
         var el = element[0];
         el.selectionStart = el.selectionEnd = el.value.length;
         if (setFocus) {
            el.focus();
         }
      });
   }

   return {
      require: '?ngModel',
      link: function(scope, element, attrs, ngModelController) {
         if (ngModelController) {
            var deregister = scope.$watch(attrs.ngModel, function(newVal, oldVal) {
               var initialising = newVal === oldVal;
               if (initialising) {
                  setCaretAtEnd(element);
                  deregister();
               }
            });
         } else {
            setCaretAtEnd(element, true);
         }
      }
   };
}]);

export default ngModule;
