import { Component, Input, TemplateRef } from '@angular/core';

/**
 * This component gets rendered using the Angular CDK OverlayModule, and is instantiated
 * by the CustomTooltipDirective. Consumers should not use this component directly.
 *
 * This component's @Input templateRef is assigned by the CustomTooltipDirective rather
 * than the usual way of passing it in within a template.
 */
@Component({
   selector: 'bb-tooltip-template',
   templateUrl: './tooltip-template.component.html',
})
export class TooltipTemplateComponent {
   /**
    * The template reference passed to CustomTooltipDirective by the consumer template
    */
   @Input() templateRef: TemplateRef<any>;
}
