import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


interface ApiCache {
   [path: string]: Observable<any>;
}

/**
 * Cache designed for endpoints with non-entity responses
 *
 * DO use for endpoints responding with static data, data that does not change much
 * or differ between users, e.g. roles, permissions, password policy.
 *
 * DO NOT use for endpoints responding with a list of "entities" like Users, Accounts.
 * Instead use NGRX.
 */
@Injectable({
   providedIn: 'root',
})
export class ApiCacheService {
   private cache: ApiCache = {};

   public set<T>(path: string, obs: Observable<T>): Observable<T> {
      this.cache[path] = obs;
      return obs;
   }

   public get<T>(path: string): Observable<T> {
      return this.cache[path];
   }
}

