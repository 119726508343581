import { DOCUMENT } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Papa } from 'ngx-papaparse';
import { Subscription, forkJoin } from 'rxjs';

import { ConfirmDialogComponent, ConfirmDialogModel } from '../../shared/components/dialogs';
import { ImportReviewComponent, ImportReviewData } from './import-review/import-review.component';
import { ImportSuccessComponent, ImportSuccessData } from './import-success/import-success.component';
import { ImportedUser } from './imported-user.interface';
import { AlertService } from '../../shared/services/alert.service';
import { UsersApiService, CSVUser } from '../../shared/api/users.api.service';
import { PageService } from '../../shared/services/page.service';

type Fullname = string;
type Company = string;
type Email = string;
type CSVUserTuple = [Fullname, Company, Email];

type ImportProcessState = 'idle'
                        | 'GDPRWarning'
                        | 'checking'
                        | 'loading'
                        | 'ready'
                        | 'importing'
                        | 'imported';

@Component({
   selector: 'bb-users-import',
   templateUrl: './users-import.component.html',
   styleUrls: ['./users-import.component.scss'],
   changeDetection: ChangeDetectionStrategy.Default,
})
export class UsersImportComponent implements AfterViewInit {
   public importProcessState: ImportProcessState = 'idle';

   private csvInput: HTMLInputElement;
   private dialogSubscription: Subscription;
   private reviewUsersList: ImportedUser[] = [];
   private page: Page;

   constructor(
      @Inject(DOCUMENT) private document: Document,
      private papa: Papa,
      private dialog: MatDialog,
      private pageService: PageService,
      private usersApiService: UsersApiService,
      private alertService: AlertService,
   ) {
      this.page = this.pageService.page;
   }

   ngAfterViewInit() {
      this.csvInput = this.document.getElementById('csvInput') as HTMLInputElement;
      this.csvInput.addEventListener('change', () => {
         this.importProcessState = 'GDPRWarning';
         this.openGDPRConfirmationDialog();
      });
   }

   public isSitePage = (): boolean => !!this.page.siteId;

   public openFileInputDialog = (): void => this.csvInput.click();

   private abortImportProcess(): void {
      this.importProcessState = 'idle';

      // Must reset csvInput.value or Chrome won't trigger the onchange event
      // if the same file is picked twice in a row.
      this.csvInput.value = '';
   }

   private openConfirmDialog(data: ConfirmDialogModel, onClosedCallback: (confirmed: boolean) => void): void {
      this.dialogSubscription?.unsubscribe();
      const dialogRef = this.dialog.open<ConfirmDialogComponent, ConfirmDialogModel, boolean>(
         ConfirmDialogComponent,
         { width: '50%', panelClass: ['confirm-dialog-component'], data },
      );
      this.dialogSubscription = dialogRef.afterClosed().subscribe(onClosedCallback);
   }

   private openGDPRConfirmationDialog(): void {
      const dialogData: ConfirmDialogModel = {
         title: 'GDPR Warning',
         message: 'By clicking "Proceed", you agree that you are the Data Controller and Blackbird plc. is the Data Processor.',
         confirmBtnLabel: 'Proceed',
         cancelBtnLabel: 'Cancel',
      };
      const onClosed = (confirmed: boolean) => confirmed ? this.loadUsersFromCSVFile() : this.abortImportProcess();
      this.openConfirmDialog(dialogData, onClosed);
   }

   private openFileExtensionWarningDialog(file: File): void {
      const dialogData: ConfirmDialogModel = {
         title: 'File Extension Warning',
         message: 'The imported file\'s name extension suggests the file does not contain correct comma-separated values. Do you want to proceed anyway?',
         confirmBtnLabel: 'Yes',
         cancelBtnLabel: 'No',
      };
      const onClosed = (confirmed: boolean) => confirmed ? this.doReadCSVUserList(file) : this.abortImportProcess();
      this.openConfirmDialog(dialogData, onClosed);
   }

   private openImportReviewDialog(): void {
      this.dialogSubscription?.unsubscribe();
      const data: ImportReviewData = {
         reviewUsersList: this.reviewUsersList,
         importableUsersCount: this.reviewUsersList.filter(user => user.success).length,
      };
      const dialogRef = this.dialog.open<ImportReviewComponent, ImportReviewData, boolean>(
         ImportReviewComponent,
         { width: '80%', disableClose: true, data },
      );
      this.dialogSubscription = dialogRef.afterClosed()
         .subscribe(confirmed => confirmed ? this.importReviewedUsers() : this.abortImportProcess());
   }

   private openImportSuccessDialog(importedUsers: ImportedUser[]): void {
      this.dialogSubscription?.unsubscribe();
      const data: ImportSuccessData = { importedUsers };
      const dialogRef = this.dialog.open<ImportSuccessComponent, ImportSuccessData, void>(
         ImportSuccessComponent,
         { width: '80%', disableClose: true, data },
      );
      this.dialogSubscription = dialogRef.afterClosed().subscribe(() => {
         this.concludeImportProcess();
      });
   }

   private loadUsersFromCSVFile(): void {
      this.reviewUsersList = [];
      this.importProcessState = 'checking';

      const file = this.csvInput.files[0];
      if (file.name.toLowerCase().endsWith('.csv')) {
         this.doReadCSVUserList(file);
      } else {
         this.openFileExtensionWarningDialog(file);
      }
   }

   private doReadCSVUserList(file: File): void {
      const reader = new FileReader();
      reader.onload = () => {
         this.papa.parse(reader.result as string, {
            skipEmptyLines: true, // https://github.com/mholt/PapaParse/issues/447
            complete: results => this.checkUsers(results.data),
            error: error => this.reportError(error),
         });
      };
      reader.readAsText(file);
   }

   private checkUsers(rows: CSVUserTuple[]): void {
      const [columns, ...users] = rows;

      const csvUsers: CSVUser[] = users.map(user => {
         const [fullname, company, email] = user;
         return {fullname, company, email};
      });

      if (['fullname', 'company', 'email'].every(col => columns.indexOf(col) !== -1)) {
         this.importProcessState = 'loading';
         const importList = this.usersApiService.importUsers(
            csvUsers,
            this.page.siteId,
            true,
            this.addUsersToReview.bind(this)
         );
         forkJoin(importList).subscribe(
            () => {
               this.importProcessState = 'ready';
               this.openImportReviewDialog();
            }
         );
      } else {
         this.abortImportProcess();
         this.alertService.show({
            text: `CSV Format Error: Columns required are "fullname", "company" and "email". \
                  See documentation for more information about valid user import CSV files: `,
            link: {
               text: 'Adding Multiple Users',
               href: '/furniture/userguide/control/latest/site.html#_adding_multiple_users',
            },
            neverRemove: true,
            type: 'danger',
         });
      }
   }

   private importReviewedUsers(): void {
      this.importProcessState = 'importing';
      const reviewSuccessUsers = this.reviewUsersList.filter(user => user.success);

      const onSuccess = (importedUsers: ImportedUser[]) => {
         this.importProcessState = 'imported';
         this.openImportSuccessDialog(importedUsers);
      };
      const importList = this.usersApiService.importUsers(
         reviewSuccessUsers,
         this.page.siteId,
         false,
         onSuccess,
      );
      forkJoin(importList).subscribe();
   }

   private addUsersToReview(users: ImportedUser[]): void {
      const seenEmails = new Set(_.map(this.reviewUsersList, 'email'));
      users.forEach(user => {
         // If the user is valid, further check if its email address was not
         // used already in the current full user import
         if (user.success && seenEmails.has(user.email)) {
            user.success = false;
            user.message = 'This email address was already used in this batch';
         }
      });

      this.reviewUsersList = this.reviewUsersList.concat(users);
   }

   private reportError(message: string): void {
      this.alertService.show({
         text: 'Failed: ' + message,
         neverRemove: true,
         type: 'danger',
      });
   }

   private concludeImportProcess(): void {
      this.abortImportProcess();
      location.reload();
   }
}
