// Priorities are defined to run directives in order:
// verticalExpandRemaining / verticalLimitToRemaining
// then fbdnTabs

function heightStyleDirective(styleName: 'maxHeight' | 'height') {
   return ['$window', '_', '$timeout', function($window, _, $timeout) {
      return {
         priority: 3,
         restrict: 'C',
         link: {
            // Runs before child directive pre-linking functions
            pre: function(scope, element) {
               function resize() {
                  var siblingHeight = 0;
                  _.forEach(element.siblings(), function(sibling) {
                     if (angular.element(sibling).css('position') === 'absolute') return;
                     if (angular.element(sibling).hasClass('vertical-expand-remaining')) return;
                     siblingHeight += angular.element(sibling).outerHeight(true);
                  });
                  var parentHeight = element.parent().height();
                  element.css(styleName, parentHeight - siblingHeight);
               }

               scope.$on('reflow', function() {
                  $timeout(resize);
               });
            },
            // Sibling elements might not have been in existance during
            // pre-linking function
            post: function(scope) {
               scope.$broadcast('reflow');
            }
         },
         controller: ['$scope', '$element', function($scope, $element) {
            if (!$element.parent().controller('verticalExpandRemaining')) {
               angular.element($window).on('resize', function() {
                  $scope.$broadcast('reflow');
               });
            }
         }]
      };
   }];
}

const ngModule = angular.module('bb.height-style-directives', []);

ngModule
   .directive('verticalExpandRemaining', heightStyleDirective('height'))
   .directive('verticalLimitToRemaining', heightStyleDirective('maxHeight'));

export default ngModule;
