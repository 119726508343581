import AsyncCacheModule, { AsyncCache } from '../utils/async-cache.factory';

export interface CloudApi {
   fetchPermissions(): Promise<CloudPermissions>;
}

interface CloudPermissions {
   canChangeMediaData: boolean;
   canConnectEdgeServersToSupport: boolean;
 }

const ngModule = angular.module('bb.api.cloud', [
   AsyncCacheModule.name,
]);

ngModule.factory('CloudApi', ['AsyncCache', function(asyncCache: AsyncCache) {
   const api: CloudApi = this;

   const cache = asyncCache('CloudApi');

   api.fetchPermissions = () => {
      return cache.fetch('cloud-permissions', {
         method: 'GET',
         url: '/api/permissions'
      });
   };

   return api;
}]);

export default ngModule;
