import { HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, take} from 'rxjs/operators';
import { Notification, NotificationType } from '../types';
import { ErrorMessageService } from './error-message.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class AlertService {
   private notifications: Record<NotificationType, BehaviorSubject<Notification[]>> = {
      danger: new BehaviorSubject<Notification[]>([]),
      warning: new BehaviorSubject<Notification[]>([]),
      info: new BehaviorSubject<Notification[]>([]),
      success: new BehaviorSubject<Notification[]>([]),
   };
   private _totalAlertsCount$ = new BehaviorSubject<number>(0);
   private _totalAlertsCountByType$: Record<NotificationType, BehaviorSubject<number>> = {
      danger: new BehaviorSubject<number>(0),
      warning: new BehaviorSubject<number>(0),
      info: new BehaviorSubject<number>(0),
      success: new BehaviorSubject<number>(0),
   };

   constructor(
      private errorMessageService: ErrorMessageService,
      private _snackBar: MatSnackBar
   ){}

   public getNotifications(): Record<NotificationType, BehaviorSubject<Notification[]>> {
      const notifications = {};
      Object.keys(this.notifications).forEach((type: NotificationType) => {
         notifications[type] = this.getNotificationsByType(type);
      });
      return notifications as Record<NotificationType, BehaviorSubject<Notification[]>>;
   }

   public getTotalAlertsCount(): Observable<number> {
      return this._totalAlertsCount$;
   }

   public getTotalAlertsCountByType(): Record<NotificationType, BehaviorSubject<number>> {
      return this._totalAlertsCountByType$;
   }

   public show(notification: Notification, duration: number = 10): void {
      if (!this.notifications[notification.type].value.some(
            ({text, type, source}) => (text === notification.text && type === notification.type && source === notification.source))){
         notification.count = 1;
         notification.created = new Date();
         this.notifications[notification.type].next([notification, ...this.notifications[notification.type].value]);
         if(duration > 0) {
            this._snackBar.open(notification.text, 'x', {
               duration: duration * 1000,
               horizontalPosition: 'right',
               verticalPosition: 'top',
               panelClass: ['alert', 'alert-' + notification.type]
            });
         }
      }  else {
         of(notification).pipe(
            switchMap(alert => this.findNotification(alert)),
            take(1),
            map(data => {
               data[0].count = data[0].count + 1;
               data[0].created = new Date();
               return data;
            }),
         ).subscribe(
            data => {
               this.notifications[notification.type].next(data);
            }
         );
      }
      this._totalAlertsCountByType$[notification.type].next(this._totalAlertsCountByType$[notification.type].value+1);
      this._totalAlertsCount$.next(this._totalAlertsCount$.value+1);
   }

   public remove(notification: Notification) {
      this.notifications[notification.type].pipe(
         map(data => data.filter(item => item !== notification)),
         take(1)
      ).subscribe(
         data => this.notifications[notification.type].next(data)
      );
      this._totalAlertsCountByType$[notification.type].next(
         this._totalAlertsCountByType$[notification.type].value - notification.count
      );
      this._totalAlertsCount$.next(this._totalAlertsCount$.value - notification.count);
   }

   /**
    * @param [action='fetching the data'] the action that the error has occurred on.
    * @returns pipeable operator which takes the source observable and pipes catchError, triggering
    * a notification message before throwing error to the next handler (probably the console).
    */
   public notifyOnError<T>(action='fetching the data') {
      return (source: Observable<T>): Observable<T> => {
         return source.pipe(
            catchError((error: HttpErrorResponse) => {
               this.show({
                  type: 'danger',
                  text: `An error occurred while ${action}: ${this.errorMessageService.errorMessage(error)}`,
               });
               return throwError(error);
            }),
         );
      };
   }

   private getNotificationWithoutFilter(type: NotificationType): Observable<Notification[]> {
      return this.notifications[type];
   }

   private getNotificationsByType(type: NotificationType): Observable<Notification[]> {
      return this.getNotificationWithoutFilter(type).pipe(
        map(data => Array.from(new Set(data))), //distinct notifications
      );
   }

   private findNotification(notification: Notification): Observable<Notification[]> {
      return this.notifications[notification.type].pipe(
         map(data => data.filter(
            item => (item.text === notification.text &&
            item.type === notification.type && item.source === notification.source))),
      );
   }
}
