import { MenuDirectionBase } from './menu-direction-base.directive';

/* @Deprecated Use Angular MenuDirectionDirective if containing page is hybrid or pure Angular */
export class MouseenterMenuDirectionDirective extends MenuDirectionBase  {
   constructor($window: IWindowService) {
      super($window);
   }

   static factory(...deps: [IWindowService]) {
      return new MouseenterMenuDirectionDirective(...deps);
   }

   link = (_scope: IScope, element: JQLite) => {
      element.on('mouseenter', () => {
         const menu = element.find('.dropdown-menu') as JQLiteShim;
         if (menu.length) {
            this.setOpenDirectionClass(menu);
         }
      });
   };
}

MouseenterMenuDirectionDirective.factory.$inject = ['$window'];
