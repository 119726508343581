import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
   selector: 'bb-account-button',
   templateUrl: './account-button.component.html',
   styleUrls: ['./account-button.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountButtonComponent {
   @Input() accountId: string;
}
