import { createAction, props } from '@ngrx/store';

import { EdgeServer, EdgeServerType } from '../../edge-servers/types';
import { CreateEdgeDto, EdgeContext, SaveEdgeDto } from '../../shared/services/edge-servers-api.service';


export const loadEdges = createAction(
   '[Edge Servers] Load Edges',
   props<{context: EdgeContext; contextualId?: string}>(),
);
export const loadEdgesSuccess = createAction(
   '[Edge Servers] Load Edges Success',
   props<{edges: EdgeServer[]; edgeTypes: EdgeServerType[]}>(),
);
export const loadEdgesFail = createAction(
   '[Edge Servers] Load Edges Fail',
   props<{errorMessage: string}>(),
);

export const fetchEdge = createAction(
   '[Edge Servers] Fetch',
   props<{id: string}>(),
);

export const saveEdge = createAction(
   '[Edge Servers] Save Edge',
   props<{edge: SaveEdgeDto}>(),
);
export const saveEdgeSuccess = createAction(
   '[Edge Servers] Save Edge Success',
   props<{edge: EdgeServer}>(),
);
export const saveEdgeFail = createAction(
   '[Edge Servers] Save Edge Fail',
   props<{errorMessage: string}>(),
);
export const saveEdgeName = createAction(
   '[Edge Servers] Save Edge Name',
   props<{id: string; name: string}>(),
);

export const createEdge = createAction(
   '[Edge Servers] Create Edge',
   props<{edge: CreateEdgeDto; tempId: string}>(),
);
export const createEdgeSuccess = createAction(
   '[Edge Servers] Create Edge Success',
   props<{edge: EdgeServer; tempId: string}>(),
);
export const createEdgeFail = createAction(
   '[Edge Servers] Create Edge Fail',
   props<{errorMessage: string}>(),
);

export const deleteEdge = createAction(
   '[Edge Servers] Delete Edge',
   props<{id: string}>(),
);
export const deleteEdgeSuccess = createAction(
   '[Edge Servers] Delete Edge Success',
   props<{id: string}>(),
);
export const deleteEdgeFail = createAction(
   '[Edge Servers] Delete Edge Fail',
   props<{errorMessage: string}>(),
);
export const removeEdgeFromStore = createAction(
   '[Edge Servers] Remove Edge From Store',
   props<{id: string}>(),
);

export const upsertEdge = createAction(
   '[Edge Servers] Upsert Edge',
   props<{edge: EdgeServer}>(),
);
export const updateEdgeName = createAction(
   '[Edge Servers] Update Edge Name',
   props<{id: string; name: string}>(),
);
