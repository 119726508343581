export const slideable: InjectableDirectiveFactory = ['$rootScope', function($rootScope: IRootScopeService) {
   return {
      restrict: 'A',
      link: function(scope: IScope, element: JQLite, attrs: IAttributes) {
         if (!scope.$eval(attrs.slideable)) return;

         function setClass(): void {
            element[hidden ? 'removeClass' : 'addClass']('slideable-open');
         }

         const itemToHide = element.next() as JQLiteShim;
         itemToHide.hide();
         let hidden = true;
         setClass();
         element.on('click', () => {
            itemToHide[hidden ? 'slideDown' : 'slideUp'](
               () => $rootScope.$broadcast('reflow')
            );
            hidden = !hidden;
            setClass();
         });
      }
   };
}];
