import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PublishModule } from '../../../types';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SourceDestinationModules } from '../../../../publishing-buttons/interfaces';
import { MatDialogModule } from '@angular/material/dialog';
export interface NewPublishingButtonModel {
   sourceModules: PublishModule[];
   destinationModules: PublishModule[];
}

@Component({
   selector: 'bb-new-publishing-button-dialog',
   templateUrl: './new-publishing-button-dialog.component.html',
   styleUrls: ['./new-publishing-button-dialog.component.scss'],
})
export class NewPublishingButtonDialogComponent implements OnInit {
   public newButtonMap: FormGroup;
   public sourceModules: PublishModule[];
   public destinationModules: PublishModule[];
   public modelReady = false;

   constructor(
      public dialogRef: MatDialogRef<NewPublishingButtonDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: NewPublishingButtonModel,
      private fb: FormBuilder,
   ) {
      this.sourceModules = data.sourceModules;
      this.destinationModules = data.destinationModules;
   }

   ngOnInit(): void {
      this.newButtonMap = this.fb.group({
            sourceModule: [null, Validators.required],
            destinationModule: [null, Validators.required]
         });
      this.newButtonMap.get('sourceModule').setValue(this.sourceModules[0]);
      this.newButtonMap.get('destinationModule').setValue(this.destinationModules[0]);
      this.modelReady = true;
   }

   public onConfirm(): void {
      const sourceDestModules: SourceDestinationModules = {source: this.newButtonMap.get('sourceModule').value, destination: this.newButtonMap.get('destinationModule').value};
      this.dialogRef.close(sourceDestModules);
   }

   public onDismiss(): void {
      this.dialogRef.close(false);
   }

   public invalidForm(): boolean {
      return this.newButtonMap.get('sourceModule').hasError('required') || this.newButtonMap.get('destinationModule').hasError('required');
   }
}

