import NavigationModule from '../utils/navigation/navigation.module';

import { fileSupportProvider } from './file-support.provider';
import { uploadConfig } from './upload.config';
import { uploadController } from './upload.controller';

import '../../../../lib/ng-flow.min.js';

const ngModule = angular.module('bb.upload', [
   'flow', // provided by ng-flow.min.js
   'ui.bootstrap', // provided by ui-bootstrap-tpls-0.13.4.min.js
   NavigationModule.name,
]);

ngModule
   .config(uploadConfig)
   .provider('fileSupport', fileSupportProvider)
   .controller('UploadController', uploadController);

export default ngModule;
