import { createSelector } from '@ngrx/store';

import { AppState } from '../app.state';
import { adapter } from './users.reducer';
import { User } from './user.interface';


const usersState = (appState: AppState) => appState.usersState;

export const {
   selectIds,
   selectEntities,
   selectAll,
   selectTotal,
} = adapter.getSelectors(usersState);

export const selectMe = createSelector(usersState, ({me}) => me);

export const selectUsersSearching   = createSelector(usersState, (state) => state.usersSearching);
export const selectUsersSearchError = createSelector(usersState, (state) => state.usersSearchError);

// Props selectors are deprecated from v12. Factory selectors recommended
// https://timdeschryver.dev/blog/parameterized-selectors#update-2021-05-09
export const selectUsersByQuery = (query: string) => createSelector(
   usersState,
   (state): User[] | undefined => state.usersSearches[query],
);
export const selectUserById = (id: string | null) => createSelector(selectEntities, (entities) => id && entities[id]);
export const selectUserByUsername = (username: string) => createSelector(selectEntities, (entities) => {
   return Object.values(entities).find(entity => entity.username === username);
});

export const selectLoadUserError = createSelector(usersState, ({userLoadingError}) => userLoadingError);
