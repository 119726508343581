import { pluralFilter } from './plural.filter';
import { AlwaysPluraliseFilter } from './pluralise.filter';
import { DurationFilter } from './duration.filter';
import { HideDeletingFilter } from './hide-deleting.filter';
import { NiceSpaceFilter } from './nice-space.filter';

const ngModule = angular.module('bb.filters', []);

ngModule
   .filter('plural', pluralFilter)
   .filter(AlwaysPluraliseFilter.id, AlwaysPluraliseFilter.filter)
   .filter(HideDeletingFilter.id, HideDeletingFilter.filter)
   .filter(NiceSpaceFilter.id, NiceSpaceFilter.filter)
   .filter(DurationFilter.id, DurationFilter.filter);

export default ngModule;
