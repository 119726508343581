import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { CreateServiceDto, EdgeService, ExistingServiceValues, SaveServiceDto } from './types';
import { EdgeServicesApiService } from '../../shared/services/edge-services.api.service';
import { ApiUpdatesService } from '../../shared/utils/updates/api-updates.service';
import * as Notifications from '../notifications/notifications.actions';
import * as EdgeServices from './edge-server-services.actions';
import { EditableServiceValues, TemporaryEdgeService } from '../../edge-services/store/edge-service-cards/types';
import { ErrorMessageService } from '../../shared/services/error-message.service';


@Injectable()
export class EdgeServerServicesEffects {

   /* Load */
   loadEdgeServices$ = createEffect(() => this.actions$.pipe(
      ofType(EdgeServices.loadEdgeServices),
      switchMap(({edgeId}) => this.edgeServicesApi.fetchServices(edgeId).pipe(
         tap(services => !this.apiUpdates && this.initApiUpdates(services)),
         map(services => ({ type: EdgeServices.loadEdgeServicesSuccess.type, services })),
         catchError((error: HttpErrorResponse) => of({
            type: EdgeServices.loadEdgeServicesFail.type,
            errorMessage: this.errorMessageService.errorMessage(error),
         })),
      )),
   ));

   fetchService$ = createEffect(() => this.actions$.pipe(
      ofType(EdgeServices.loadEdgeService),
      switchMap(({id}) => this.edgeServicesApi.fetchService(id)),
      map((service) => ({ type: EdgeServices.upsertService.type, service })),
   ));

   loadServiceTypesForEdge$ = createEffect(() => this.actions$.pipe(
      ofType(EdgeServices.loadServiceTypes),
      switchMap(({edgeId}) => this.edgeServicesApi.fetchServiceTypes(edgeId).pipe(
         map((serviceTypes) => ({ type: EdgeServices.loadServiceTypesSuccess.type, serviceTypes })),
         catchError((error: HttpErrorResponse) => of({
            type: Notifications.showNotification.type,
            notificationType: 'danger',
            notificationText: `There was an error loading the service types: ${this.errorMessageService.errorMessage(error)}`,
         })),
      )),
   ));

   loadServiceTemplatesForEdge$ = createEffect(() => this.actions$.pipe(
      ofType(EdgeServices.loadServiceTemplates),
      switchMap(({edgeId}) => this.edgeServicesApi.fetchServiceTemplates(edgeId).pipe(
         map((serviceTemplates) => ({ type: EdgeServices.loadServiceTemplatesSuccess.type, serviceTemplates })),
         catchError((error: HttpErrorResponse) => of({
            type: Notifications.showNotification.type,
            notificationType: 'danger',
            notificationText: `There was an error loading the service templates: ${this.errorMessageService.errorMessage(error)}`,
         })),
      )),
   ));


   /* Save */
   saveService$ = createEffect(() => this.actions$.pipe(
      ofType(EdgeServices.saveService),
      map(({service, values}) => this.constructSaveServiceDto(service, values)),
      mergeMap((saveServiceDto) => this.edgeServicesApi.saveService(saveServiceDto).pipe(
         map(service => ({ type: EdgeServices.saveServiceSuccess.type, service })),
         catchError((error: HttpErrorResponse) => of({
            type: EdgeServices.saveServiceFail.type,
            errorMessage: this.errorMessageService.errorMessage(error),
         })),
      )),
   ));
   saveServiceSuccessNotification$ = createEffect(() => this.actions$.pipe(
      ofType(EdgeServices.saveServiceSuccess),
      map(({ service }) => ({
         type: Notifications.showNotification.type,
         notificationType: 'success',
         notificationText: `The service "${service.name}" has been saved`,
      })),
   ));
   saveServiceFail$ = createEffect(() => this.actions$.pipe(
      ofType(EdgeServices.saveServiceFail),
      map(({errorMessage}) => ({
         type: Notifications.showNotification.type,
         notificationType: 'danger',
         notificationText: `There was an error saving the service: ${errorMessage}`,
      })),
   ));
   saveServiceName$ = createEffect(() => this.actions$.pipe(
      ofType(EdgeServices.saveServiceName),
      mergeMap(({id, name}) => this.edgeServicesApi.saveServiceName(id, name).pipe(
         map(service => ({ type: EdgeServices.updateServiceName.type, id: service.id, name: service.name })),
      )),
   ));

   /* Create */
   createService$ = createEffect(() => this.actions$.pipe(
      ofType(EdgeServices.createService),
      map(({service, values, edgeServerId}) => ({
         createServiceDto: { edgeServerId, ...this.constructCreateServiceDto(service, values) },
         tempId: service.$$temporaryId,
      })),
      mergeMap(({createServiceDto, tempId}) => this.edgeServicesApi.createService(createServiceDto).pipe(
         map((service) => ({ type: EdgeServices.createServiceSuccess.type, service, tempId })),
         catchError((error: HttpErrorResponse) => of({
            type: EdgeServices.createServiceFail.type,
            errorMessage: this.errorMessageService.errorMessage(error),
         })),
      )),
   ));
   createServiceSuccessNotification$ = createEffect(() => this.actions$.pipe(
      ofType(EdgeServices.createServiceSuccess),
      map(({service}) => ({
         type: Notifications.showNotification.type,
         notificationType: 'success',
         notificationText: `The service "${service.name}" has been created`,
      })),
   ));
   createServiceFail$ = createEffect(() => this.actions$.pipe(
      ofType(EdgeServices.createServiceFail),
      map(({errorMessage}) => ({
         type: Notifications.showNotification.type,
         notificationType: 'danger',
         notificationText: `There was an error creating the service: ${errorMessage}`,
      })),
   ));

   /* Update store */
   upsertService$ = createEffect(() => this.actions$.pipe(
      ofType(EdgeServices.saveServiceSuccess, EdgeServices.createServiceSuccess),
      map(({service}) => ({ type: EdgeServices.upsertService.type, service })),
   ));

   /* Delete */
   deleteService$ = createEffect(() => this.actions$.pipe(
      ofType(EdgeServices.deleteService),
      mergeMap(({ id }) => this.edgeServicesApi.deleteService(id).pipe(
         map(() => ({ type: EdgeServices.deleteServiceSuccess.type, id })),
         catchError(error => of({ type: EdgeServices.deleteServiceFail.type, errorMessage: this.errorMessageService.errorMessage(error) })),
      )),
   ));
   removeServiceAfterDeleting$ = createEffect(() => this.actions$.pipe(
      ofType(EdgeServices.deleteServiceSuccess),
      map(({id}) => ({ type: EdgeServices.removeServiceFromStore.type, id })),
   ));
   deleteServiceSuccessNotification$ = createEffect(() => this.actions$.pipe(
      ofType(EdgeServices.deleteServiceSuccess),
      map(() => ({
         type: Notifications.showNotification.type,
         notificationType: 'success',
         notificationText: 'The service has been deleted',
      })),
   ));
   deleteServiceFail$ = createEffect(() => this.actions$.pipe(
      ofType(EdgeServices.deleteServiceFail),
      map(({errorMessage}) => ({
         type: Notifications.showNotification.type,
         notificationType: 'danger',
         notificationText: `There was an error deleting the service: ${errorMessage}`,
      })),
   ));

   private apiUpdates: any;

   constructor(
      private actions$: Actions,
      private store: Store,
      private edgeServicesApi: EdgeServicesApiService,
      private apiUpdatesService: ApiUpdatesService,
      private errorMessageService: ErrorMessageService,
   ) {}

   private initApiUpdates(services: EdgeService[]): void {
      this.apiUpdates = this.apiUpdatesService.initApiUpdatesService('EdgeServices', ({id}: EdgeService) => {
         this.store.dispatch(EdgeServices.loadEdgeService({ id }));
      });
      this.apiUpdates.subscribeToCollection({ results: services });
   }

   private constructSaveServiceDto(service: EdgeService, values: EditableServiceValues): SaveServiceDto {
      const { id, edgeServerId, name, typeId } = service;
      const options = this.convertToNestedValues(values);
      return { id, edgeServerId, name, typeId, options };
   }

   private constructCreateServiceDto(
      service: TemporaryEdgeService,
      values?: EditableServiceValues,
   ): Omit<CreateServiceDto, 'edgeServerId'> {
      const { name, typeId, serviceGroupId } = service;
      const options = values ? this.convertToNestedValues(values) : {};
      return { name, typeId, options, serviceGroupId };
   }

   private convertToNestedValues(formValues: EditableServiceValues): ExistingServiceValues {
      return Object.entries(formValues).reduce((accum, curr) => {
         const [fieldName, value] = curr;
         return {
            [fieldName]: { value },
            ...accum,
         };
      }, {});
   }
}
