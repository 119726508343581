import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { KeyboardExpansionInfo } from '../types/keyboard.interface';
import { PageService } from './page.service';

@Injectable({
   providedIn: 'root',
})
export class KeyboardApiService {
   private page: Page;
   constructor(
      private pageService: PageService,
      private http: HttpClient
   ) {
      this.page = this.pageService.page;
    }

   public getKeyboardExpansions(accountId?: string): Observable<KeyboardExpansionInfo[]> {
      accountId ??= this.page.accountId;
      return this.http.get<KeyboardExpansionInfo[]>(`/api/keyboardExpansions?accountId=${accountId}`);
   }

   public exportKeyboardMap(destAccountId: string, destName: string, cloneFromId: string): Observable<void> {
      return this.http.post<void>('/api/keyboardExpansions', {accountId: destAccountId, name: destName, cloneFromId: cloneFromId});
   }
}
