<form [formGroup]="autocompleteForm">
   <mat-form-field appearance="outline">
      <mat-label *ngIf="label">{{label}}</mat-label>
      <button *ngIf="resetAutocompleteButton"
            class="reset-autocomplete"
            mat-icon-button
            (click)="resetAndReturn()"
            >
            <span class="material-icon"> cancel </span>
      </button>
      <div class="autocomplete-input">
         <input
            type="text"
            matInput
            formControlName="autocomplete"
            [required] = "!!required"
            [matAutocomplete]="autoGroup"
            #autoCompleteInput="matAutocompleteTrigger"
         >
         <mat-error>
            {{getErrorMessage()}}
         </mat-error>
      </div>
      <mat-autocomplete #autoGroup="matAutocomplete" (optionSelected)="optionChanged($event.option.value)">
         <ng-container *ngFor="let group of groupOptions">
            <ng-container *ngIf="group.groupName; else options">
               <mat-optgroup [label]="group.groupName">
                  <ng-container *ngTemplateOutlet="options"></ng-container>
               </mat-optgroup>
            </ng-container>

            <ng-template #options>
               <ng-container *ngIf="group.children.length; else noResults">
                  <mat-option *ngFor="let child of group.children" [value]="child.value" [disabled]="child?.disabled">
                     <span class="mat-option-text">
                        <span>
                           <mat-hint *ngIf="child.preLabel">{{child.preLabel}}</mat-hint>
                           {{child.value}}
                        </span>
                        <span *ngIf="child.additionalInfo">{{child.additionalInfo}}</span>
                        <span *ngIf="child.label"><mat-hint>({{child.label}})</mat-hint></span>
                     </span>
                  </mat-option>
               </ng-container>
               <ng-template #noResults>
                  <mat-option disabled>
                     <span class="mat-option-text">
                        No results found
                     </span>
                  </mat-option>
               </ng-template>
            </ng-template>
         </ng-container>
      </mat-autocomplete>
   </mat-form-field>
</form>
