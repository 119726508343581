import { Component, Input, OnInit } from "@angular/core";

@Component({
    selector: 'bb-meta-upload',
    templateUrl: './metaupload.component.html',
    styleUrls: ['./metaupload.component.scss'],
})
export class MetaUploadComponent implements OnInit{
    @Input() accountName: string;
    public files: FileList;
    public fileSupportAll = false;

    ngOnInit(): void {
        this.checkFileSupportAll();
    }
    checkFileSupportAll() {
        const api = window.File && window.FileReader && window.FileList && window.Blob;
        let multiple = 'multiple' in document.createElement('input');
        // iOS has a bug selecting multiple video files
        const iOS = /(iPad|iPhone|iPod)/g.test(window.navigator.userAgent);
        multiple = multiple && !iOS;
        if (api && multiple){
            this.fileSupportAll = true;
        }
    }
    fileChanged(event: Event) {
        const target = event.target as HTMLInputElement;
        this.files = target.files;
    }
}
