<form *ngIf="form && schema" [formGroup]="form" (ngSubmit)="submitForm()">
   <div *ngFor="let ctl of form.controls | keyvalue: keyInsertionOrder" class="field-container">
      <ng-container *ngTemplateOutlet="formFields; context: {
         ctlName: ctl.key,
         schemaItem: schema[ctl.key],
         nestedGroupCtls: ctl.value.controls
      };"></ng-container>

      <ng-template #formFields let-ctlName="ctlName" let-nestedGroupCtls="nestedGroupCtls" let-schemaItem="schemaItem">
         <ng-container [ngSwitch]="getFieldType(schemaItem)">
            <ng-container *ngSwitchCase="'checkbox'" >
               <mat-checkbox
                  *ngIf="showWhen(schemaItem?.showWhen)"
                  [formControl]="form.get(ctlName)"
                  [disabled]="schemaItem?.readonly"
                  >
                  {{schemaItem.inlineTitle || schemaItem.label}}
               </mat-checkbox>
            </ng-container>

            <ng-container *ngSwitchCase="'autocomplete'">
               <ng-container *ngIf="showWhen(schemaItem?.showWhen) && filteredFormOptions$ && (filteredFormOptions$[ctlName] | async) as filteredOptions">
                  <bb-autocomplete-item *ngIf="filteredOptions"
                     [groupOptions]="filteredOptions.options"
                     [validators]="schemaItem.validators"
                     [asyncValidators]="schemaItem.asyncValidators"
                     [label]="schemaItem.label"
                     [inValue]="form.get(ctlName).value"
                     (inValueChange)="formValueUpdate($event, ctlName)"
                     [required]="true"
                     [disabled]="schemaItem?.readonly"
                  ></bb-autocomplete-item>
                  <mat-hint
                     *ngIf="filteredOptions && filteredOptions.asyncLoading"
                     class="options-loading"
                  >
                     <bb-loading></bb-loading>
                  </mat-hint>
               </ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="'typeahead-dir'">
               <ng-container *ngIf="showWhen(schemaItem?.showWhen) && filteredFormOptions$ && (filteredFormOptions$[ctlName] | async) as filteredOptions">
                  <bb-autocomplete-item *ngIf="filteredOptions"
                     [groupOptions]="filteredOptions.options"
                     [validators]="schemaItem.validators"
                     [asyncValidators]="schemaItem.asyncValidators"
                     [label]="schemaItem.label"
                     [inValue]="form.get(ctlName).value"
                     (inValueChange)="formValueUpdate($event, ctlName)"
                     (selectionChanged)="formValueUpdate($event, ctlName)"
                     [required]="schemaItem.required"
                     [disabled]="schemaItem?.readonly"
                  ></bb-autocomplete-item>
                  <mat-hint
                     *ngIf="filteredOptions && filteredOptions.asyncLoading"
                     class="options-loading"
                  >
                     <bb-loading></bb-loading>
                  </mat-hint>
               </ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="'toggle'">
               <div *ngIf="showWhen(schemaItem?.showWhen)">
                  <mat-label class="mat-slide-toggle-label">
                     {{schemaItem.label}}
                  </mat-label>
                  <mat-slide-toggle [formControl]="form.get(ctlName)" color="primary" [disabled]="schemaItem?.readonly">
                     {{form.get(ctlName).value ? 'Yes' : 'No'}}
                  </mat-slide-toggle>
               </div>
            </ng-container>

            <div *ngSwitchCase="'textarea'" class="maxwidth">
               <div *ngIf="showWhen(schemaItem?.showWhen)">
                  <mat-form-field appearance="outline">
                     <mat-label>{{schemaItem.label}}</mat-label>
                     <textarea matInput [required]="schemaItem.required" [formControl]="form.get(ctlName)" [disabled]="schemaItem?.readonly"></textarea>
                     <mat-error *ngIf="form">
                        {{getErrorMessage(ctlName, schemaItem)}}
                     </mat-error>
                  </mat-form-field>
               </div>
            </div>

            <div *ngSwitchCase="'select'" class="maxwidth">
               <div *ngIf="showWhen(schemaItem?.showWhen)">
                  <mat-form-field appearance="outline">
                     <mat-label>{{schemaItem.label}}</mat-label>
                     <mat-select [formControl]="form.get(ctlName)" placeholder="--- Select an option ---" [disabled]="schemaItem?.readonly">
                        <mat-option *ngFor="let option of schemaItem.valueLabels" [value]="option">
                           {{option.label}}
                        </mat-option>
                     </mat-select>
                  </mat-form-field>
               </div>
            </div>

            <form *ngSwitchCase="'radio'">
               <div class="custom-radio-group-outline" *ngIf="showWhen(schemaItem?.showWhen)">
                  <mat-label class="radio-label">{{schemaItem.label}}</mat-label>
                  <mat-radio-group [formControl]="form.get(ctlName)" [disabled]="schemaItem?.readonly" class="custom-radio-group">
                     <mat-radio-button *ngFor="let option of schemaItem.valueLabels" [value]="option">
                        {{option.label}}
                     </mat-radio-button>
                  </mat-radio-group>
               </div>
            </form>

            <div *ngSwitchCase="'select-combo'">
               <div *ngIf="showWhen(schemaItem?.showWhen)">
                  <mat-form-field appearance="outline">
                     <mat-label>{{schemaItem.label}}</mat-label>
                     <mat-select [formControl]="form.get(ctlName)" [disabled]="schemaItem?.readonly">
                        <mat-select-trigger>
                           {{form.get(ctlName).value}}
                        </mat-select-trigger>
                        <ng-container *ngIf="filteredFormOptions$ && (filteredFormOptions$[ctlName] | async) as filteredOptions">
                           <ng-container *ngIf="filteredOptions">
                              <ng-container *ngFor="let group of filteredOptions.options">
                                 <ng-container *ngIf="group.groupName; else options">
                                    <mat-optgroup [label]="group.groupName">
                                       <ng-container *ngTemplateOutlet="options"></ng-container>
                                    </mat-optgroup>
                                 </ng-container>
                                 <ng-template #options>
                                    <ng-container *ngIf="group.children.length; else noResults">
                                       <mat-option *ngFor="let child of group.children" [value]="child.value" [disabled]="child?.disabled">
                                          <span class="spaced-options">
                                             <span>
                                                <mat-hint *ngIf="child.preLabel">{{child.preLabel}}</mat-hint>
                                                {{child.value}}
                                             </span>
                                             <span *ngIf="child.additionalInfo">{{child.additionalInfo}}</span>
                                             <span *ngIf="child.label"><mat-hint>({{child.label}})</mat-hint></span>
                                          </span>
                                       </mat-option>
                                    </ng-container>
                                    <ng-template #noResults>
                                       <mat-option disabled>
                                          <span class="mat-option-text">
                                             No results found
                                          </span>
                                       </mat-option>
                                    </ng-template>
                                 </ng-template>
                              </ng-container>
                           </ng-container>
                        </ng-container>
                     </mat-select>
                  </mat-form-field>
               </div>
            </div>

            <div *ngSwitchCase="'button'" class="form-button">
               <bb-form-button *ngIf="showWhen(schemaItem?.showWhen)" [btnLabel]="schemaItem.label"  [formControl]="form.get(ctlName)" [disabled]="schemaItem?.readonly" [action]="schemaItem?.action"></bb-form-button>
            </div>

            <mat-accordion *ngSwitchCase="'group'">
               <div *ngIf="showWhen(schemaItem?.showWhen)">
                  <mat-expansion-panel
                     [expanded]="!schemaItem?.hideable === true || isExpansionPanelOpen(ctlName) ? true : false"
                     (opened)="expansionPanelOpened(ctlName, true)"
                     (closed)="expansionPanelOpened(ctlName, false)"
                  >
                     <mat-expansion-panel-header collapsedHeight="40px" expandedHeight="40px">
                        <mat-panel-title>
                           {{schemaItem.label}}
                        </mat-panel-title>
                     </mat-expansion-panel-header>

                     <ng-container *ngFor="let nestedCtl of nestedGroupCtls | keyvalue: keyInsertionOrder">
                        <ng-container
                           *ngTemplateOutlet="formFields; context: {
                              ctlName: ctlName + '.' + nestedCtl.key,
                              schemaItem: schemaItem.groupFields[nestedCtl.key]
                           }"
                        >
                        </ng-container>
                     </ng-container>
                  </mat-expansion-panel>
               </div>
            </mat-accordion>

            <div *ngSwitchCase="'number'" class="maxwidth">
               <div *ngIf="showWhen(schemaItem?.showWhen)">
                  <mat-form-field appearance="outline">
                     <mat-label>{{schemaItem.label}}</mat-label>
                     <input matInput type="number" [required]="schemaItem.required" [formControl]="form.get(ctlName)" [disabled]="schemaItem?.readonly"/>
                     <mat-error *ngIf="form">
                        {{getErrorMessage(ctlName, schemaItem)}}
                     </mat-error>
                  </mat-form-field>
               </div>
            </div>

            <div *ngSwitchCase="'multiple'">
               <div *ngIf="showWhen(schemaItem?.showWhen)">
                  <mat-form-field appaerance="outline">
                     <mat-label>{{schemaItem.label}}</mat-label>
                     <mat-chip-list #chipList>
                     <mat-chip
                        *ngFor="let selChips of form.get(ctlName).value"
                        [removable]="true"
                        (removed)="removeMChip(selChips, ctlName)"
                        >
                        {{selChips.label}}
                        <button matChipRemove class="icon-button">
                           <span class="material-icon">
                              cancel
                           </span>
                        </button>
                     </mat-chip>
                     <input
                        #chipsInput
                        [formControl]="chipInputControl"
                        [matChipInputFor]="chipList"
                        [matAutocomplete]="autoChips"
                        [placeholder]="schemaItem.placeholder"
                        (matChipInputTokenEnd)="addMChip($event, ctlName, schemaItem)"
                        (input)="chipsFilter($event, ctlName, schemaItem)"
                        >
                  </mat-chip-list>
                  <mat-autocomplete #autoChips="matAutocomplete" (optionSelected)="chipSelect($event, ctlName)">
                     <mat-option *ngFor="let option of chipsFilteredOptions" [value]="option">
                        {{option.label}}
                     </mat-option>
                  </mat-autocomplete>
               </mat-form-field>
               </div>
            </div>
            <!--
               Dynamically setting input [type]="variable" does not work https://github.com/angular/angular/issues/13243
               so the type attributes should be statically set (as is done above for type="number")
             -->
            <div *ngSwitchDefault class="maxwidth">
               <div *ngIf="getFieldType(schemaItem) !== 'hidden' && showWhen(schemaItem?.showWhen)">
                  <mat-form-field appearance="outline">
                     <mat-label>{{schemaItem.label}}</mat-label>
                     <!--
                        [required] is set as an attribute purely to show the asterisk. With Angular13,
                        setting Validators.required on the FormControl is enough to show the asterisk,
                        so this attribute should be removed from all mat-form-fields.
                        https://github.com/angular/components/pull/23362
                     -->
                     <input
                        matInput
                        [type]="getFieldType(schemaItem)"
                        [required]="schemaItem.required"
                        [formControl]="form.get(ctlName)"
                        [disabled]="schemaItem?.readonly"
                     />
                     <mat-error *ngIf="form">
                        {{getErrorMessage(ctlName, schemaItem)}}
                     </mat-error>
                  </mat-form-field>
               </div>
            </div>
         </ng-container>

         <p
            *ngIf="schemaItem.maxLength"
            class="character-counter"
            [ngClass]="{invalid: hasError(ctlName, 'maxlength'), touched: form.get(ctlName).touched}"
         >
            {{characterCounter(ctlName)}}
         </p>
      </ng-template>
   </div>

   <mat-toolbar *ngIf="submitConfig && this?.form.dirty" class="submit-toolbar">
      <button mat-raised-button type="submit" color="primary" [disabled]="submitting || form.invalid">
         {{submitting ? submitConfig.inProgressLabel : submitConfig.submitLabel}}
      </button>
   </mat-toolbar>
</form>
