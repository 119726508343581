import { DOCUMENT } from '@angular/common';
import { AfterContentInit, AfterViewInit, ContentChild, ContentChildren, Directive, ElementRef, EventEmitter, Inject, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

class Vector2 {
   x: number;
   y: number;
}
type RectangleVector = {
   start: Vector2;
   end: Vector2;
};
class Rectangle {
   start: Vector2;
   end: Vector2;
   constructor(startX = 0, startY = 0, endX = 0, endY = 0) {
      this.start = new Vector2();
      this.end = new Vector2();
      this.set(startX, startY, endX, endY);
   }

   set(startX, startY, endX, endY) {
      this.start.x = startX;
      this.start.y = startY;
      this.end.x = endX;
      this.end.y = endY;
   }

   normalized(): RectangleVector {
      const normalized = {
         start: {
            x: Math.min(this.start.x, this.end.x),
            y: Math.min(this.start.y, this.end.y),
         },
         end: {
            x: Math.max(this.start.x, this.end.x),
            y: Math.max(this.start.y, this.end.y),
         }
      };
      return normalized;
   }

   getWidth(): number {
      return Math.abs(this.start.x - this.end.x);
   }

   getHeight(): number {
      return Math.abs(this.start.y - this.end.y);
   }

   isCollision(other: Rectangle) {
      const normalized = this.normalized();
      return normalized.start.x < other.end.x && other.start.x < normalized.end.x &&
            normalized.start.y < other.end.y && other.start.y < normalized.end.y;
   }
}

// Usage Example:

// <section bbDragSelect (selectedElements)="selectionUpdate($event)" >
//    <div class="dragSelector" #DragSelector></div>
//    <div *ngFor="item of items" #DragSelectableItem bbDragSelectItem [dragItemId]="item.id"></div>
// </section>

// On the container add bbDragSelect - container used to clamp the selector
// On the container (selectedElements)="your-call($event)" - to recive updates from selections string[]
// Mark the div #DragSelector which is used to draw the selection area whil dragging the mouse.
// Add some styling for the drag selector div {background-color: rgba(255, 0, 0 , 0.2);border: 4px red dashed;}
// Mark the selectable items with #DragSelectableItem
// Selectable items also need an id set [dragItemId]="card.path" this id will be returned by (selectedElements) Event.

@Directive({
   selector: '[bbDragSelectItem]'
})
export class DragSelectDirectiveItem {
   @Input() dragItemId: string;
   constructor(
      private elementRef: ElementRef,
   ) { }
}

@Directive({
   selector: '[bbDragSelect]'
})
export class DragSelectDirective implements AfterContentInit {

   @ContentChildren("DragSelectableItem", { descendants: true }) contentChildren!: QueryList<DragSelectDirectiveItem>;
   @ContentChild('DragSelector', { read: ElementRef }) dragSelector: ElementRef;
   @Output() selectedElements: EventEmitter<string[]> = new EventEmitter();

   private holderElement: HTMLElement;
   private subscriptions: Subscription[] = [];
   private selectionBox: Rectangle = new Rectangle();
   private clicked = false;
   private dragging = false;
   private minDistance = 5;
   private selectables: any[];

   constructor(
      private elementRef: ElementRef,
      @Inject(DOCUMENT) private document: Document
   ) { }

   public ngAfterContentInit(): void {
      this.selectables = this.contentChildren.toArray();
      this.contentChildren.changes.subscribe(items => {
         this.selectables = items;
      });

      this.holderElement = this.elementRef.nativeElement as HTMLElement;
      const clientRect = this.elementRef.nativeElement.getBoundingClientRect();
      // sets the selection box to the top left corner of the holder container
      this.selectionBox.set(clientRect.left, clientRect.top, clientRect.left, clientRect.top);
      this.initDrag();
      this.drawSelectionBox();
   }

   private initDrag(): void {
      const dragStart$ = fromEvent<MouseEvent>(this.elementRef.nativeElement, 'mousedown');
      const dragEnd$ = fromEvent<MouseEvent>(this.document, 'mouseup');
      const drag$ = fromEvent<MouseEvent>(this.document, 'mousemove').pipe(
         takeUntil(dragEnd$)
      );

      let dragSub: Subscription;

      const dragStartSub = dragStart$.subscribe((event: MouseEvent) => {
         this.clicked = true;
         if(this.isSelectionEmpty(event)) {  // checking if click is landed on box or empty space
            // sets the box minimized to the clicked position ready to drag
            this.selectedElements.emit([]); // making selections empty if clicked on empty space.
            this.selectionBox.set(event.pageX, event.pageY, event.pageX, event.pageY);
            this.drawSelectionBox();

            dragSub = drag$.subscribe((mouseEvent: MouseEvent) => {
               if (this.clicked && !this.dragging &&
                  (Math.abs(this.selectionBox.start.x - mouseEvent.pageX) < this.minDistance ||
                     Math.abs(this.selectionBox.start.y - mouseEvent.pageY) < this.minDistance
                  )
               ) {
                  this.dragging = true;
               }
               if (this.dragging) {
                  mouseEvent.preventDefault();
                  this.selectionBox.end = { x: mouseEvent.pageX, y: mouseEvent.pageY };
                  this.drawSelectionBox();
               }
            });
         }
      });

      const dragEndSub = dragEnd$.subscribe(() => {
         if (this.dragging) {
            this.drawSelectionBox();

            dragSub?.unsubscribe();

            this.selectedElements.emit(this.getCollisions());
         }
         this.dragging = false;
         this.drawSelectionBox();
      });

      this.subscriptions.push.apply(this.subscriptions, [
         dragStartSub,
         dragSub,
         dragEndSub,
      ]);
   }
   private isSelectionEmpty(event: MouseEvent): boolean {
      let onEmptySpace = true;
      this.selectables.forEach(box => {
        const boxRect = box.nativeElement.getBoundingClientRect();
        if ( boxRect.x <= event.x && event.x <= boxRect.x + boxRect.width &&
         boxRect.y <= event.y && event.y <= boxRect.y + boxRect.height) {
            onEmptySpace = false;
        }
      });
      return onEmptySpace;
   }

   private clampToParentBox(childRect: RectangleVector): Rectangle {
      const box = new Rectangle();
      const holderRect = this.elementRef.nativeElement.getBoundingClientRect();
      box.start = {
         x: Math.max(holderRect.left, childRect.start.x),
         y: Math.max(holderRect.top, childRect.start.y)
      };
      box.end = {
         x: Math.min(holderRect.right, childRect.end.x),
         y: Math.min(holderRect.bottom, childRect.end.y)
      };
      return box;
   }

   private drawSelectionBox() {
      if (this.dragging) {
         const normalizedBox = this.selectionBox.normalized();
         const box = this.clampToParentBox(normalizedBox);
         this.dragSelector.nativeElement.setAttribute('style',
            'position: fixed; display: static; z-index: 3000; left: ' + box.start.x + 'px; top: ' + box.start.y + 'px; width: ' + box.getWidth() + 'px; height: ' + box.getHeight() + 'px;');
      } else {
         this.dragSelector.nativeElement.setAttribute('style', 'position: fixed; display: none; z-index: 3000;');
      }

   }

   private getCollisions(): string[] {
      const collosions = [];
      this.selectables.forEach(box => {
         const rect = box.nativeElement.getBoundingClientRect();
         if (this.getCollision(rect.left, rect.right, rect.top, rect.bottom)) {
            collosions.push(box);
         }
      });
      return collosions.map(item => item.nativeElement.dragItemId);
   }

   private getCollision(startX, endX, startY, endY): boolean {
      return this.selectionBox.isCollision(new Rectangle(startX, startY, endX, endY));
   }

   private removeEvents(): void {
      this.subscriptions.forEach((subscription) => subscription?.unsubscribe());
   }

}
