import { Coordinate } from "./coordinate.interface";

interface DragObject {
   mousePosition: Coordinate;
   data: any;
   element: JQLite;
   dataMulti: any;
}

export const FbdnDroppableScroll: InjectableDirectiveFactory = ['$rootScope', '$timeout', function($rootScope, $timeout) {
   return {
      restrict: 'A',
      link: function(scope: IScope, element: JQLite) {
         var THRESHOLD = 30;
         var ANIMATION_DELAY = 10;

         var scrollTimer = null;
         var scrollBy = 0;

         scope.$on('draggable:move', onDragMove);

         function onDragMove(evt: IAngularEvent, dragObject: DragObject): boolean {
            if (element === dragObject.element) return false;

            var x = dragObject.mousePosition.x;
            var y = dragObject.mousePosition.y;
            scrollBy = getScrollBy(x, y);
            if (scrollBy && !scrollTimer) {
               scrollTimer = $timeout(continueScrolling, ANIMATION_DELAY);
            }
         }

         function continueScrolling(): void {
            if (scrollBy && scroll()) {
               scrollTimer = $timeout(continueScrolling, ANIMATION_DELAY);
            } else {
               scrollTimer = null;
            }
         }

         function scroll(): boolean {
            var newScrollPosition = element[0].scrollTop + scrollBy;
            if (newScrollPosition < 0) {
               element[0].scrollTop = 0;
               return false;
            }
            if (newScrollPosition > element[0].scrollTop + element[0].clientHeight) {
               element[0].scrollTop = element[0].scrollTop + element[0].clientHeight;
               return false;
            }
            element[0].scrollTop = newScrollPosition;
            return true;
         }

         function getScrollBy(x: number, y: number): number {
            var bounds = element.offset();
            var bottom = bounds.top + element.outerHeight();
            var right = bounds.left + element.outerWidth();
            if (!(x >= bounds.left && x <= right && y <= bottom && y >= bounds.top)) return 0;
            if (y <= bounds.top + THRESHOLD) return -(THRESHOLD - (y - bounds.top));
            if (y >= bottom - THRESHOLD) return THRESHOLD - (bottom - y);
            return 0;
         }
      }
   };
}];
