import { NotificationService } from '../../services/notification.service';
import { NotificationType } from '../../types/notification.interface';

const ngModule = angular.module('bb.show-server-notification', []);

// Exists purely to relay serverside notifications to frontend notification-area component
const showServerNotificationComponent = {
   bindings: {
      message: '@',
      undoId: '@',
      type: '@',
   },
   controllerAs: 'vm',
   template: '',
   controller: class ShowServerNotificationController {
      static $inject = ['NotificationService', '$timeout'];

      /* Input */ public message: string;
      /* Input */ public undoId?: string;
      /* Input */ public type?: NotificationType;

      constructor(
         private notificationService: NotificationService,
         private $timeout: ITimeoutService,
      ) {}

      $onInit() {
         this.$timeout(() => {
            this.notificationService.show({
               text: this.message,
               undoId: this.undoId,
               type: this.type || 'success'
            });
         });
      }
   }
};

ngModule.component('showServerNotification', showServerNotificationComponent);

export default ngModule;
