<section
   *ngIf="getPasswordStrength(form, rules) as passwordStrength"
   class="password-strength-container"
   [ngClass]="position"
>
   Strength: {{passwordStrength.text}}
   <mat-progress-bar
      mode="determinate"
      [color]="getProgressBarColor(passwordStrength.type)"
      [value]="passwordStrength.value"
   ></mat-progress-bar>

   Your password must:
   <ul>
      <li
         *ngFor="let rule of rules | keyvalue: keyInsertionOrder"
         [ngClass]="{ valid: ruleValidates(form, rule.key) }"
      >
         {{rule.value.message}}
      </li>
   </ul>
</section>
