import { Injectable } from '@angular/core';
import { AbstractControlOptions, AsyncValidatorFn, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';

import { Schema, SchemaItem } from '../schema.interface';
import { FieldValue } from './types/field-value.type';
import { FormControlField } from './types/form-control-field.interface';
import { CardFormItem, FormOptions, FormSchema, OptionValues } from '../../types';

export interface BBDynamicFormObj {
   schema: Schema;
   form: FormGroup;
   actions: {[key: string]: string};
   $$saved: any;
   $$dirty: boolean;
};
export interface BBDynamicFormObjs {
   [key: string]: BBDynamicFormObj;
}

@Injectable({
   providedIn: 'root',
})
export class FormHelperService {

   constructor(
      private formBuilder: FormBuilder,
   ) {}

   public buildForm<T>(schema: Schema, values: {[key in keyof T]: any}): FormGroup {
      const formData = Object.entries(schema).reduce((accum, curr) => {
         const [fieldName, schemaItem] = curr as [string, SchemaItem];

         if (schemaItem.type === 'group') {
            accum[fieldName] = this.buildForm<T>(schemaItem.groupFields, values);
            return accum;
         } else {
            accum[fieldName] = this.getField<T>(schemaItem, fieldName, values);
            return accum;
         }
      }, {});
      return this.formBuilder.group(formData);
   }

   // Converts  from they python schema + form Array to a format that allow to generate an Angular FormGroup compatible
   // with the bb-dynamic-form
   public convertCardFormToSchema(formArray: CardFormItem[], schema: FormSchema, optionals: FormOptions): Schema {
      const roTypes = ['staticText', 'readonly'];
      const schemaFormGroup: Schema = {};
      for (const elm of formArray) {
         const elmName = elm.name || elm.action || elm.label;
         if (('type' in elm && elm.type !== 'hidden') || (schema[elmName].type !== 'hidden')) {
            schemaFormGroup[elmName] =  {
               ...(('type' in elm && { type: (roTypes.includes(elm.type)) ? 'text': elm.type, readonly: roTypes.includes(elm.type) || undefined }) || { type: schema[elm.name].type}),
               ...('label' in elm && { label: elm.label }),
               ...('inlineTitle' in elm && { inlineTitle: elm.inlineTitle}),
               ...((elm.name in schema && schema[elm.name].defaultValue && { defaultValue: schema[elm.name].defaultValue }) || (elm?.type !== 'group' && { defaultValue: ''})),
               ...('required' in elm && { required: elm.required }),
               ...('requiredIf' in elm && { requiredIf: elm.requiredIf }),
               ...('maxLength' in elm && { maxLength: elm.maxLength }),
               ...('minLength' in elm && { minLength: elm.minLength }),
               ...('minValue' in elm && { minValue: elm.minValue }),
               ...('maxValue' in elm && { maxValue: elm.maxValue }),
               ...('allowNull' in elm && { allowNull: elm.allowNull }),
               ...('customLength' in elm && { customLength: elm.customLength }),
               ...('valueLabels' in elm && { valueLabels: elm.valueLabels }),
               ...('selectOnly' in elm && { selectOnly: elm.selectOnly }),
               ...('hideable' in elm && { hideable: elm.hideable }),
               ...('showWhen' in elm && { showWhen: elm.showWhen }),
               ...('placeholder' in elm && { placeholder: elm.placeholder }),
               ...('action' in elm && {action: 'true'}),
               ...('allowedValues' in elm && { allowedValues: elm.allowedValues }),
               ...(elm.name in optionals && optionals[elm.name]?.disabled && { readonly: optionals[elm.name].disabled }),
               ...('items' in elm && { groupFields: this.convertCardFormToSchema(elm.items, schema, optionals)}),
            };
         }
      }
      return schemaFormGroup;
   }

   public getBbFormObjs(form: CardFormItem[], schema: FormSchema, optionals: FormOptions): BBDynamicFormObj {
      const bbSchema = this.convertCardFormToSchema(form, schema, optionals);
      const bbValues = {};
      for (const key in optionals) {
         bbValues[key] = optionals[key].value;
      }
      const bbActions= this.getActionSetFromFormItems(form, bbValues);
      const bbForm = this.buildForm(bbSchema, bbValues);
      return { schema: bbSchema, form: bbForm, actions: bbActions, $$saved: null, $$dirty: false} as BBDynamicFormObj;
   }

   public getActionSetFromFormItems(formArray: CardFormItem[], formValues: OptionValues): {[key: string]: string} {
      let actionsSet = {};
      for (const elm of formArray) {
         if (elm.type === 'group') {
            actionsSet = {...actionsSet, ...this.getActionSetFromFormItems(elm.items, formValues)};
         }
         else {
            const elmName = elm.name || elm.action || elm.label;
            if ('action' in elm) {
               actionsSet[elmName] = elm.action;
            }
         }
      }
      return actionsSet;
   }

   private getField<T>(
      schemaItem: SchemaItem,
      fieldName: string,
      values: {[key in keyof T]: any},
   ): [FormControlField, AbstractControlOptions] {
      const validators: ValidatorFn[] = [];
      const asyncValidators: AsyncValidatorFn[] = [];

      if (schemaItem.required) {
         validators.push(Validators.required);
      }
      if (schemaItem.hasOwnProperty('minLength')) {
         validators.push(Validators.minLength(schemaItem.minLength));
      }
      if (schemaItem.hasOwnProperty('maxLength')) {
         validators.push(Validators.maxLength(schemaItem.maxLength));
      }
      if (schemaItem.hasOwnProperty('minValue')) {
         validators.push(Validators.min(schemaItem.minValue));
      }
      if (schemaItem.hasOwnProperty('maxValue')) {
         validators.push(Validators.max(schemaItem.maxValue));
      }
      if (schemaItem.type === 'emailAddress') {
         validators.push(Validators.email);
      }
      if ('validators' in schemaItem) {
         validators.push(...schemaItem.validators);
      }
      if ('asyncValidators' in schemaItem) {
         asyncValidators.push(...schemaItem.asyncValidators);
      }

      let value: any;
      if (schemaItem.valueLabels) {
         if (Array.isArray(values[fieldName])) {
            value  = [] as string[];
            values[fieldName].forEach(item => {
               value.push(schemaItem.valueLabels.find(vl => vl.value === item));
            });
         }
         else {
            value = schemaItem.valueLabels.find(vl => vl.value === values[fieldName]);
         }
      } else if (values[fieldName] !== undefined) {
         value = values[fieldName];
      } else if (schemaItem.defaultValue !== undefined) {
         value = schemaItem.defaultValue;
      } else {
         throw new Error('No valid existing or default value');
      }

      const field: FormControlField = {
         value,
         disabled: schemaItem.readonly, // Until FormControl has `readonly` https://github.com/angular/angular/issues/11447
      };
      return [field, { validators, asyncValidators }];
   }
}
