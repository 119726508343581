import HttpModule from '../fbdn-angular-modules/http';
import AsyncCacheModule, { AsyncCache } from '../utils/async-cache.factory';
import { Me, UsersSearchParams } from '../../store/users';

interface ImportUser {
   company: string;
   email: string;
   fullname: string;
}

interface UserImportData {
   dryRun: boolean;
   siteId: string;
   users: ImportUser[];
}

export interface User {
   id: string;
   fullname?: string;
   username?: string;
   address?: string;
   company?: string;
   email?: string;
   extId?: string;
   phone?: string;
   site?: string;
   $$state?: string;
   $$promise?: Promise<any>;
}

const ngModule = angular.module('bb.api.users', [
   HttpModule.name,
   AsyncCacheModule.name,
]);

ngModule.factory('UsersApi', ['$q', 'http', '_', 'AsyncCache', function($q: IQService, http, _: Lodash, asyncCache: AsyncCache) {
   const cache = asyncCache('UsersApi');
   var userCache = {};

   function getSkeleton(userId) {
      if (userId in userCache) return userCache[userId];
      var skeleton = {
         id: userId ? userId : null,
         $$state: userId ? 'uninitialised' : 'clean',
         $$promise: null
      };
      if (userId) userCache[userId] = skeleton;
      return skeleton;
   }

   function mergeIntoSkeleton(user: User): User {
      var skeleton = getSkeleton(user.id);
      skeleton.$$state = 'clean';
      return _.extend(skeleton, user);
   }

   function handleError(user: User, error) {
      user.$$state = 'error';
      return $q.reject(error);
   }

   function fetch(userId: string): Promise<User> {
      var skeleton = getSkeleton(userId);
      if (userId && (skeleton.$$state === 'uninitialised' || skeleton.$$state === 'error')) {
         skeleton.$$state = 'loading';
         skeleton.$$promise = http({
            method: 'GET',
            url: '/api/users/' + userId
         }).then(mergeIntoSkeleton, _.partial(handleError, skeleton));
      } else {
         skeleton.$$promise = skeleton.$$promise || $q.when(skeleton);
      }
      return skeleton.$$promise;
   }

   // Takes an array of user structures { fullname, email, company }, and a siteId. If dryRun is
   // true, then just check if the user can be imported; otherwise (set to false, default) it
   // import the user and sends a registration email to the email address provided. If additional
   // fields "username" and "dupekey" are provided for each user instance and if the pair is
   // valid, will try to use "username" as username for the associated user. Otherwise (if
   // either "username" or "dupekey" is not provided, or if the pair is not valid) will create
   // a new username.
   //
   // Returns a promise that resolves when all users have been submitted for import to the cloud
   // Users attempted to import will be reported through callback "reportUsers", with additional
   // member fields "success", "status", "username" and "dupekey". "success" is set to true when
   // the user has been imported and false otherwise, regardless of dryRun argument. "status"
   // gives a descriptive string on the reason why a user has not been imported, or "OK" if it
   // was imported. Upon error, execute callback "reportError" with a string explaining the error
   // that occurred. "username" is the username created for the user imported and "dupekey" is
   // a random key associated to the username.
   function importUsers(importedUsers, siteId, dryRun = false, reportUsers, reportError) {
      var chunks: ImportUser[][] = _.chunk(importedUsers, 100);
      var promises = [];
      chunks.forEach(users => {
         const data: UserImportData = { users, dryRun, siteId };

         promises.push(http({
            method: 'POST',
            url: '/api/users',
            data: data
         }).then((results) => {
            reportUsers(results);
         }, (error) => {
            reportError(error.message);
            return $q.when();
         }));
      });

      return Promise.all(promises);
   }

   function searchUsers(searchParams: UsersSearchParams) {
      const params: UsersSearchParams = _.pick(searchParams, ['siteId', 'accountId', 'searchText', 'type', 'offset']);
      params.limit = 10;
      return http({
         method: 'GET',
         url: '/api/users',
         params: params,
         _rawData: true
      }).then(function(results) {
         results.results = _.map(results.results, mergeIntoSkeleton);
         return $q.resolve(results);
      }, () => {
         return $q.reject("There was an error fetching users");
      });
   }

   function findSiteUsers(siteId, searchParams) {
      return searchUsers(_.assign({siteId: siteId}, searchParams));
   }

   function populateSkeleton(skeleton) {
      return fetch(skeleton.id);
   }

   function fetchMe(): Promise<Me> {
      return cache.fetch('users-', {
         method: 'GET',
         url: '/api/me',
      });
   }

   return {
      fetch: fetch,
      fetchMe: fetchMe,
      searchUsers: searchUsers,
      findSiteUsers: findSiteUsers,
      getSkeleton: getSkeleton,
      populateSkeleton: populateSkeleton,
      importUsers: importUsers
   };
}]);

export default ngModule;
