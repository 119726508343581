import { Pipe, PipeTransform } from '@angular/core';
import { AlwaysPluraliseFilter } from './pluralise.filter';

@Pipe({
   name: AlwaysPluraliseFilter.id,
})
export class AlwaysPluralisePipe implements PipeTransform {
   transform(value: string): string {
      return AlwaysPluraliseFilter.filter()(value);
   }
}
