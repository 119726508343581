import HttpModule from '../fbdn-angular-modules/http';

const ngModule = angular.module('bb.utils.async-cache', [HttpModule.name]);

export interface AsyncCache {
   (namespace: string): {
      fetch<T>(key: string, fetcher: any, forceFetch?: boolean): Promise<T>;
      find<T>(key: string): T;
   };
}

ngModule.factory('AsyncCache', ['$q', 'http', function($q: IQService, http): AsyncCache {

   // We can re-access the same fetch cache named by a namespace
   const cacheByNamespace = {};
   const pendingRefetchesByNamespace = {};

   return function(namespace) {
      // Promises for in-flight or completed previous fetches
      const cache = cacheByNamespace[namespace] ??= {};
      // Promises for not-yet-started re-fetches
      const pendingRefetches = pendingRefetchesByNamespace[namespace] ??= {};

      function fetch(key, fetcher, forceFetch) {
         // forceFetch means that the client wants a newly started
         // fetch rather than the previously cached results, but we do
         // not want to start a new fetch while any previous one is
         // still in flight. We can also merge any not yet started
         // forceFetch requests.

         if (forceFetch && pendingRefetches[key]) return pendingRefetches[key];

         if (cache[key] && !forceFetch) return cache[key];

         if (!angular.isFunction(fetcher)) {
            var httpParams = fetcher;
            fetcher = function() {
               return http(httpParams);
            };
         }

         if (cache[key] && forceFetch) {
            // Need to create a promise for the new fetch that will not start until previous one done
            const deferred =  $q.defer();
            pendingRefetches[key] = deferred.promise;
            cache[key].finally(() => {
               cache[key] = deferred.promise;
               delete pendingRefetches[key];
               fetcher().then(result => deferred.resolve(result), error => deferred.reject(error));
            });
            return deferred.promise;
         }

         var promise = fetcher().catch(function(error) {
            delete cache[key];
            return $q.reject(error);
         });

         cache[key] = promise;
         return promise;
      }

      return {
         fetch: fetch,
         find: function(key) {
            return cache[key];
         }
      };
   };
}]);

export default ngModule;
