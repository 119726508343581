import FbdnPopNextToWindowComponentModule from './fbdn-pop-next-to-window.component';

const ngModule = angular.module('bb.fbdn-pop-next-to', [
   FbdnPopNextToWindowComponentModule.name,
]);

ngModule.factory('fbdnPopNextTo', ['$rootScope', '$q', '$templateRequest', '$controller', '$timeout', '$compile', '$document',
                                   function($rootScope, $q, $templateRequest, $controller, $timeout, $compile, $document) {
   var openedPopover = null;
   var offEvents = 'wheel touch keydown mousedown';
   var popoverOptions = null;
   var restoreFocusElement = null;

   function open(options) {
      if (openedPopover) {
         _reject();
      }
      openedPopover = {};
      openedPopover.deferred =  $q.defer();
      openedPopover.scope = (options.scope || $rootScope).$new();

      openedPopover.openingDeferred = $q.defer();

      var instance = {
         result: openedPopover.deferred.promise,
         resolve: _resolve,
         reject: _reject,
         openingPromise: openedPopover.openingDeferred.promise,
         domEl: undefined,
      };

      if (options.controller) {
         $controller(options.controller, {
            $scope: openedPopover.scope,
            $popoverInstance: instance,
            $controllerData: options.controllerData
         });
      }
      popoverOptions = options;

      var body = $document.find('body').eq(0);

      return _getTemplate(options).then(template => {
         openedPopover.domEl = angular.element('<fbdn-pop-next-to-window></fbdn-pop-next-to-window>')
            .html(template)
            .css('visibility', 'hidden')
            .appendTo(body);

         $compile(openedPopover.domEl)(openedPopover.scope);

         const popoverContainer = openedPopover.domEl.find('.popover');
         if ('popoverClass' in options) {
            popoverContainer.addClass(options.popoverClass);
         }

         function positionPopover() {
            let position;
            let arrowPositionTop;
            let arrowPositionBottom;

            if (options.position) {
               position = {left: options.position.x, top: options.position.y};
               arrowPositionTop = arrowPositionBottom = options.position.y;
            } else {
               position = options.attachElement.offset();
               position.top += options.attachElement.outerHeight() / 2;
               position.left += options.attachElement.outerWidth();

               arrowPositionTop  = options.attachElement.offset().top;
               arrowPositionBottom = options.attachElement.offset().top + options.attachElement.outerHeight();
            }

            position.top -= popoverContainer.outerHeight(true) / 2;

            position.top = Math.min(angular.element(window).height() - popoverContainer.outerHeight(true), position.top);
            position.left = Math.min(angular.element(window).width() - popoverContainer.outerWidth(true), position.left);

            position.top = Math.max(0, position.top);
            position.left = Math.max(0, position.left);

            popoverContainer
               .css(position)
               .css('visibility', 'visible');

            arrowPositionTop = Math.max(0, arrowPositionTop);
            arrowPositionBottom = Math.min(angular.element(window).height(), arrowPositionBottom);

            const arrowPosition = arrowPositionTop + (arrowPositionBottom - arrowPositionTop) / 2 - position.top;

            const arrowDomEl = openedPopover.domEl.find('.popover-arrow');
            arrowDomEl.css('top', arrowPosition - arrowDomEl.outerHeight() / 2);

            const focusElement = openedPopover.domEl.find('*[autofocus]');
            if (focusElement.length > 0) {
               restoreFocusElement = document.activeElement;
               $timeout(() => focusElement.focus());
            } else {
               restoreFocusElement = null;
            }
         }

         $timeout(function() {
            openedPopover.scope.$broadcast('reflow');
            angular.element(document).on(offEvents, _closeListener);

            instance.domEl = openedPopover.domEl;
            openedPopover.openingDeferred.resolve(instance.domEl);
         }, 0, false);

         openedPopover.scope.$on('reflow', function() {
            openedPopover.scope.$evalAsync(function() {
               if (openedPopover) {
                  positionPopover();
               }
            });
         });

         return instance;
      });
   }

   var _closeListener = function(event) {
      var insidePopover = angular.element(event.target).parents().index(openedPopover.domEl) !== -1;
      if (insidePopover) return;

      // Ignore keys different then escape
      if (event.type === "keydown" && event.which !== 27) return;

      if (popoverOptions.preventCloseOnClick) {
         if (angular.element(event.target).parents().index(popoverOptions.attachElement) !== -1) {
            return;
         }
      }
      _reject();
   };

   function _resolve(result) {
      openedPopover.deferred.resolve(result);
      _close();
   }

   function _reject(reason?) {
      openedPopover.deferred.reject(reason);
      _close();
   }

   function _close() {
      openedPopover.domEl.remove();
      openedPopover.scope.$destroy();
      openedPopover = null;
      angular.element(document).off(offEvents, _closeListener);
      if (restoreFocusElement) restoreFocusElement.focus();
   }

   function _getTemplate(options) {
      if (!options.template && !options.templateUrl) {
         throw new Error('One of template or templateUrl options is required.');
      }

      return options.template ? $q.when(options.template) : $templateRequest(options.templateUrl);
   }

   return {
      open: open
   };
}]);

export default ngModule;
