export const ApiEndpointConfig = {
   nodes: '/api/nodes',
   nodesTransferType: '/api/nodesTransferType',
   nodesTransfer: '/api/nodesTransfer',
   media: (mediaId: string) => '/api/media/' + mediaId,
   mediaContent: (mediaId: string) => '/api/media/' + mediaId + '/content',
   mediaData: (mediaId: string, accountId: string) => '/api/media/' + mediaId + '/data?accountId=' + accountId,
   edlSources: (id: string) => '/api/edls/' + id + '/sources' ,
   edlPreviousVersions: (id: string) => '/api/edls/' + id + '/previousVersions',
   edlContent: (id: string) => '/api/edls/' + id + '/content',
   publishRepublish: (mediaId: string) => '/api/publishes/' + mediaId + '/republish',
   admin: (id?: string, userId?: string) => '/api/cloud/userAccess' + ((userId) ? ('/' + userId) : ''),
   sitegroup: (id: string, userId?: string) => '/api/sitegroups/' + id + '/userAccess' + ((userId) ? ('/' + userId) : ''),
   site: (id: string, userId?: string) => '/api/sites/' + id + '/userAccess' + ((userId) ? ('/' + userId) : ''),
   account: (id: string, userId?: string) => '/api/accounts/' + id + '/userAccess' + ((userId) ? ('/' + userId) : '')
};
