<h3 mat-dialog-title>
   Password for {{user.fullName}} ({{user.username}}) required
</h3>

<mat-dialog-content>
   <div class="message">
      {{message}}
   </div>

   <form *ngIf="inputMode === 'password'" [formGroup]="passwordFormGroup" (ngSubmit)="login()">
      <mat-form-field>
         <mat-label>Password</mat-label>
         <input matInput type="password" formControlName="password"/>
         <mat-error>This field is required</mat-error>
      </mat-form-field>

      <button
         mat-raised-button
         type="submit"
         color="primary"
         [disabled]="passwordFormGroup.invalid"
      >
         Login
      </button>
   </form>

   <form *ngIf="inputMode === 'mfaCode'" [formGroup]="mfaCodeFormGroup" (ngSubmit)="verify()">
      <mat-form-field>
         <mat-label>Verification Code</mat-label>
         <input matInput type="mfaCode" formControlName="mfaCode" autocomplete="one-time-code" inputmode="numeric"/>
         <mat-error>This field is required</mat-error>
      </mat-form-field>

      <button
         mat-raised-button
         type="submit"
         color="primary"
         [disabled]="mfaCodeFormGroup.invalid"
      >
         Verify
      </button>
   </form>
</mat-dialog-content>
