import {Component, Inject, OnInit} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdditionalOptions } from '../interfaces/publish-additional-option-type';


@Component({
  selector: 'bb-publish-additional-option-dialog',
  templateUrl: './publish-additional-option-dialog.html',
  styleUrls: ['./publish-additional-option-dialog.scss'],
})
export class PublishAdditionalOptionDialogComponent implements OnInit  {
    public publishOptions: AdditionalOptions[];
    public dialogTitle: string;
    form: FormGroup;
    constructor(
        private fb: FormBuilder,
        private dialogRef: MatDialogRef<PublishAdditionalOptionDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.publishOptions = data.publishOptions.form;
        this.dialogTitle = data.publishOptions.title;
    }
    ngOnInit() {
        const formFields = {};
        this.publishOptions.forEach(element => {
            formFields[element.name] = [''];
        });
        this.form = this.fb.group(formFields);
    }

    publish() {
        this.dialogRef.close(this.form.value);
    }

    close() {
        this.dialogRef.close();
    }
}
