import HttpModule from '../fbdn-angular-modules/http';
import PageModule from '../utils/page.factory';
import UserModule from '../user.factory';
import { Roles } from '../types';
import { AsyncCache } from '../utils/async-cache.factory';

interface UserSummary {
   extId: string | null;
   fullname: string;
   id: string;
   roleId: string;
   siteId: string;
   username: string;
}

interface Disconnection {
   message: string;
   sessionId: string;
}

const ngModule = angular.module('bb.api.account-users', [
   HttpModule.name,
   PageModule.name,
   UserModule.name,
]);

export interface AccountUsersApi {
   getAccountUsers(): IPromise<UserSummary[]>;
   getAccountRoles(): IPromise<Roles>;
   setAccountUsers(data: UserSummary[]): IPromise<UserSummary[]>;
   disconnect(data: Disconnection): IPromise<void>;
}

ngModule.factory('AccountUsersApi', ['http', 'AsyncCache', 'Page', 'User', '_',
                                    function(http, asyncCache: AsyncCache, Page: Page, User: User, _: Lodash): AccountUsersApi {
   const cache = asyncCache('AccountUsersApi');
   return {
      getAccountUsers: () => {
         return cache.fetch(`account-users-${Page.accountId}`, {
            method: 'GET',
            url: `/api/accounts/${Page.accountId}/userAccess`,
         });
      },

      getAccountRoles: () => {
         return cache.fetch('account-roles', {
            method: 'GET',
            url: '/api/account_roles',
            params: {
               siteId: Page.siteId,
            }
         }).then(_.partialRight(_.keyBy, 'id'));
      },

      setAccountUsers: data => {
         return http({
            method: 'POST',
            url: `/api/accounts/${Page.accountId}/userAccess`,
            data: { data },
         });
      },

      disconnect: data => {
         return http({
            method: 'POST',
            url: '/api/disconnection',
            data: {
               byUserId: User.id,
               accountId: Page.accountId,
               sessionId: data.sessionId,
               message: data.message,
            }
         });
      }
   };
}]);

export default ngModule;
