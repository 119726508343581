import { createAction, props } from '@ngrx/store';

import { NotificationType } from '../../shared/types';

export const showNotification = createAction(
   '[Notifications] Show Notification',
   props<{
      notificationType: NotificationType;
      notificationText: string;
      neverRemove?: boolean;
      noDuplicates?: boolean;
   }>(),
);
