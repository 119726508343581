import { Component, Inject, Input, OnChanges, OnDestroy } from '@angular/core';
import { AccountDto } from '../../api/accounts/account.interface';
import { AccountsApiService } from '../../api/accounts/accounts.api.service';
import { EditorDataApiService } from '../../services/editor-data.api.service';
import { LaunchMode, Role } from '../../types';
import { EditRole } from './edit-role-interface';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AccountUsersApiService } from '../../services/account-users.api.service';
import { AlertService } from '../../services/alert.service';

@Component({
   selector: 'bb-edit-role-dropdown',
   templateUrl: './edit-role-dropdown.component.html',
   styleUrls: ['./edit-role-dropdown.component.scss'],
})
export class EditRoleDropdownComponent implements OnChanges, OnDestroy {
   @Input() displayMode: 'compact' | 'large';
   @Input() nodeId: string;
   @Input() data: AccountDto; // called 'data' and not account as used as DynamicComponent. Dynamic components when rendered receive the table row as 'data'
   @Input() accountId: string;

   public accountLaunchMode: LaunchMode;
   public defaultRole: EditRole;
   public editRoles: EditRole[];
   public launchPrefix: string;

   private destroy$ = new Subject();

   constructor(
      private accountsApiService: AccountsApiService,
      private accountUsersApiService: AccountUsersApiService,
      private editorDataApiService: EditorDataApiService,
      private alertService: AlertService,
   ){}

   ngOnChanges(): void {
      if (!this.data && !this.accountId) {
         throw new Error('Please pass either account or accountId or data to EditRoleDropdownComponent');
      }
      this.launchPrefix = this.displayMode === 'large'? 'Launch': '';
      this.accountUsersApiService.getAccountRoles()
      .pipe(
         takeUntil(this.destroy$)
      )
      .subscribe(
         roles => this.setRoles(roles)
      );
      if (!this.data && this.accountId){
         this.accountsApiService.fetch(this.accountId)
         .pipe(takeUntil(this.destroy$))
         .subscribe(
            account => this.setAccount(account)
         );
      }
      this.editorDataApiService.getDefaultLaunchMode(this.data.defaultEditMode)
      .pipe(
         takeUntil(this.destroy$),
      )
      .subscribe(
         mode => {
            this.accountLaunchMode = mode;
         }
      );
   }

   ngOnDestroy(): void {
      this.destroy$.next(true);
      this.destroy$.complete();
   }

   setAccount(account: AccountDto): void {
      this.data = account;
   }

   public launchEditor(role: EditRole): void {

      const params = {...this.accountLaunchMode.params};  //cloning object. alternative to lodash.clone
      if (this.nodeId) {
         const idBits = this.nodeId.split(',');
         if (idBits.length >= 5) {
            // id should be of the form <basetype>,<nodetype> ... <itemId>,<listId>,<accountId>
            params.openfile = idBits[idBits.length - 3];
            params.openfilelist = idBits[idBits.length - 2];
            // The editor only supports one library so we just have to hope this is it
            if (idBits[1] === 'lib') params.openfilelist += '.lib';
         }
         else {
            // Should not happen unless / until we change the id format
            this.alertService.show({
               text: `Unexpected node id "${this.nodeId}" - editor cannot open item`,
               type: 'danger',
            });
            return;
         }
      }

      params.roleid = role.id;
      const urlObject = new URL(window.document.URL);

      let paramsString = new URLSearchParams(params as unknown as URLSearchParams).toString();
      paramsString = paramsString.length ? '?' + paramsString : '';

      const url = window.location.protocol + '//' + window.location.host + '/'
         + window.encodeURIComponent(this.data.name) + '/'
         + this.accountLaunchMode.action + paramsString;
      this.accountLaunchMode.launchFn(url);
   }

   private setRoles(roles: Role[]): void {
      this.editRoles = roles;
      this.editRoles.sort((a, b) => a.name > b.name ? 1 : -1);
      this.defaultRole = this.editRoles.length? this.editRoles[0]: {} as EditRole;
   }
}
