import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { PublishModuleMap } from '../shared/types';
import { InvalidReport, PublishLocation } from './interfaces';
import { PublishingButton } from './publishing-button-api.factory';

@Component({
   selector: 'bb-publish-location-selector',
   templateUrl: './publish-location-selector.component.html',
   styleUrls: ['./publish-location-selector.component.scss'],
   changeDetection: ChangeDetectionStrategy.Default,
})
export class PublishLocationSelectorComponent {
   @Input() button: PublishingButton;
   @Input() invalidreport: InvalidReport;
   @Input() selectablelocations: PublishLocation[];
   @Input() modules: PublishModuleMap;

   @Output() locationchanged = new EventEmitter<PublishLocation>();

   public getSelectedLocation(): PublishLocation {
      return this.selectablelocations.find(loc => loc.id === this.button.location.id);
   }

   public reportLength(report: InvalidReport): boolean {
      return !!(Object.keys(report).length);
   }

   public locationChange(location: PublishLocation): void {
      this.locationchanged.emit(location);
   }
}
