export const fbdnLoginDialog: InjectableDirectiveFactory = ['http', 'User', function(http, User) {
   return {
      restrict: 'E',
      transclude: false,
      templateUrl: 'fbdn-login-dialog-template',
      scope: {
         title: '@',
         dialogStyle: '@',
         containerStyle: '@',
         scope: '=',
         message: '@'
      },
      link: function(scope: any) {
         scope.scope = scope;
         var domElem = null;
         let mfaPassword = null;
         scope.inputMode = 'password';

         scope.init = function(args) {
            // Collect directive tag and remove it from its parent when dialog is closed
            domElem = args.dom;
            scope.dialog.registerEvent('close', () => {
               domElem.parentNode.removeChild(domElem);
            });
         };

         scope.login = function(password) {
            return http({
               method: 'POST',
               url: '/api/login',
               data: {
                  username: User.username,
                  password: password
               }
            }).then((results) => {
               if (results.status === 'valid') {
                  scope.dialog.close();
               } else if (results.status === 'invalidmfa') {
                  mfaPassword = password;
                  scope.inputMode = 'mfaCode';
                  scope.message = results.error;
               } else {
                  mfaPassword = null;
                  scope.inputMode = 'password';
                  scope.message = results.error;
               }
            }, error => {
               mfaPassword = null;
               scope.inputMode = 'password';
               scope.message = error.message;
            });
         };

         scope.verify = function(mfaCode) {
            return http({
               method: 'POST',
               url: '/api/login',
               data: {
                  username: User.username,
                  password: mfaPassword,
                  mfaCode: mfaCode
               }
            }).then((results) => {
               if (results.status === 'valid') {
                  scope.dialog.close();
               } else if (results.status === 'invalidmfa') {
                  scope.message = results.error;
               } else {
                  mfaPassword = null;
                  scope.inputMode = 'password';
                  scope.message = results.error;
               }
            }, error => {
               mfaPassword = null;
               scope.inputMode = 'password';
               scope.message = error.message;
            });
         };
      }
   };
}];
