const ngModule = angular.module('bb.fbdn-ellipsis', []);

const FbdnEllipsisComponent = {
   bindings: {
      text: '<',
   },
   controllerAs: 'vm',
   template: '',
   controller: class FbdnEllipsisController {
      static $inject = ['$element', '_'];

      /* Input */ public text: string;

      private readonly ELLIPSIS = " ...";
      private resizeObserver = new ResizeObserver(this._.throttle(this.init.bind(this), 250));
      private element: HTMLElement;

      constructor(
         private $element: IRootElementService,
         private _: Lodash,
      ) {
         this.element = this.$element[0];
      }

      $onInit() {
         this.resizeObserver.observe(this.element);
      }

      $onChanges() {
         this.init();
      }

      $onDestroy() {
         this.resizeObserver.unobserve(this.element);
      }

      private init(): void {
         if (!this.text) {
            return;
         }
         this.addTextToElement(this.text);
      }

      private addTextToElement(text: string): void {
         this.$element.text(text);
         if (this.isOverflowed(this.$element)) {
            this.addEllipsis(text);
         }
      }

      private addEllipsis(text: string): void {
         let truncatedText = text.slice(0, -(this.ELLIPSIS.length + 1));
         truncatedText += this.ELLIPSIS;
         this.addTextToElement(truncatedText);
      }

      private isOverflowed(thisElement: IRootElementService): boolean {
         return thisElement[0].scrollHeight > thisElement[0].clientHeight;
      }
   }
};

ngModule.component('fbdnEllipsis', FbdnEllipsisComponent);

export default ngModule;
