import { fbdnActiveTabDirective } from './fbdn-active-tab.directive';
import { fbdnTabDirective } from './fbdn-tab.directive';
import { fbdnTabsDirective } from './fbdn-tabs.directive';

const ngModule = angular.module('bb.tabs', []);

ngModule
   .directive('fbdnTabs', fbdnTabsDirective)
   .directive('fbdnTab', fbdnTabDirective)
   .directive('fbdnActiveTab', fbdnActiveTabDirective);

export default ngModule;
