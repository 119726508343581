import FbdnLeftEllipsisItemModule from './fbdn-left-ellipsis-item.directive';

const ngModule = angular.module('bb.fbdn-left-ellipsis-set', [FbdnLeftEllipsisItemModule.name]);

ngModule.directive('fbdnLeftEllipsisSet', ['$window', '_', function($window: IWindowService, _: Lodash) {
   return {
      controller: ['$scope', '$element', function($scope: IScope, $element: IRootElementService) {
         const elementSet = [];
         this.register = function register(element: IRootElementService): void {
            elementSet.push(element);
         };

         this.deregister = function deregister(element: IRootElementService): void {
            elementSet.splice(elementSet.indexOf(element), 1);
            element.remove();
         };

         this.recompute = function recompute(): void {
            showAllElements();
            buildEllipsis();
         };

         $scope.$watchCollection(() => elementSet, this.recompute);
         angular.element($window).on('resize', _.debounce(this.recompute, 100));


         function buildEllipsis(): void {
            if (isOverflowed()) {
               if (!elementSet.length) return;

               let childToRemoveIdx = 0;
               while (childToRemoveIdx < elementSet.length && isOverflowed()) {
                  elementSet[childToRemoveIdx].hide();
                  ++childToRemoveIdx;
               }
               tryToShowElement(childToRemoveIdx-1);
            }
         }

         function showAllElements(): void {
            _.forEach(elementSet, (element: IRootElementService) => {
               element.show();
               element.text(element.attr('title'));
            });
         }

         function tryToShowElement(elementIdx: number): void {
            const element = elementSet[elementIdx];
            const text = element.attr('title');
            let beginIndex = text.length - 1;

            element.text('…' + text.substring(beginIndex));
            element.show();

            while (!isOverflowed() && beginIndex > 0) {
               --beginIndex;
               element.text('…' + text.substring(beginIndex));
            }

            if (beginIndex === text.length -1) {
               element.hide();
            } else {
               element.text('…' + text.substring(beginIndex + 1));
            }
         }

         function isOverflowed(): boolean {
            if (!elementSet.length) return false;
            return $element[0].scrollWidth > $element[0].clientWidth;
         }
      }]
   };
}]);

export default ngModule;
