import ServicesModule from '../services/services.module';

import { dynamicFormContainer } from './dynamic-form-container.directive';
import { dynamicForm } from './dynamic-form.directive';
import { dynamicName } from './dynamic-name.directive';
import { dynamicSchema } from './dynamic-schema.factory';
import { formName } from './form-name.directive';
import { formValidation } from './form-validation.factory';
import { popup } from './popup.factory';
import { slideable } from './slideable.directive';
import { typeaheadRequestOnFocusAndSelect } from './typeahead-request-on-focus-and-select.directive';

const ngModule = angular.module('bb.dynamic-form', [
   ServicesModule.name,
]);

ngModule
   .directive('dynamicFormContainer', dynamicFormContainer)
   .directive('dynamicForm', dynamicForm)
   .directive('formName', formName)
   .directive('dynamicName', dynamicName)
   .directive('slideable', slideable)
   .directive('typeaheadRequestOnFocusAndSelect', typeaheadRequestOnFocusAndSelect)
   .factory('DynamicSchema', dynamicSchema)
   .factory('FormValidation', formValidation)
   .factory('Popup', popup);

export default ngModule;
