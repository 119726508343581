import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { AlertService } from '../../services/alert.service';

export interface IconUploadDialogModel {
   siteId?: string;
   accountId?: string;
   siteGroupId?: string;
}

@Component({
  selector: 'bb-icon-upload-dialog',
  templateUrl: './upload-dialog.component.html',
  styleUrls: ['./upload-dialog.component.scss']
})
export class IconUploadDialogComponent {
   @ViewChild('fileInput') fileInput!: ElementRef;
   public selectedFile: File | null = null;
   public uploadProgress = 0;
   public uploadInProgress = false;

   private id: string;
   private idType: string;

   constructor(
      public dialogRef: MatDialogRef<IconUploadDialogComponent>,
      private alertService: AlertService,
      private http: HttpClient,
      @Inject(MAT_DIALOG_DATA) public data: IconUploadDialogModel
   ) {
      if (data.accountId) {
         this.idType = "account";
         this.id = data.accountId;
      }
      else if (data.siteId) {
         this.idType = "site";
         this.id = data.siteId;
      }
      else if (data.siteGroupId) {
         this.idType = "siteGroup";
         this.id = data.siteGroupId;
      }
   }

   public onFileSelected(event: any): void {
      const file = event.target.files[0];
      if (file) {
         this.selectedFile = file;
      }
   }

   public openFileExplorer(): void {
      this.fileInput.nativeElement.click();  // Programmatically trigger file input
    }

    public onUpload(): void {
      if (this.selectedFile) {
         const formData = new FormData();
         formData.append('uploadfile', this.selectedFile);
         this.uploadInProgress = true;
         const url = ((this.idType === "account") ? '/api/accounts/' : '/api/sites/') + this.id + '/publishIcons';
         this.http.post(url, formData, {
            reportProgress: true,
            observe: 'events'
         }).subscribe(
            event => {
               if (event.type === HttpEventType.UploadProgress) {
                  if (event.total) {
                     this.uploadProgress = Math.round(100 * (event.loaded / event.total));
                  }
               }
               else if (event.type === HttpEventType.Response) {
                  this.uploadInProgress = false;
                  this.alertService.show(
                     {
                        text: 'Icon successfully uploaded -',
                        type: 'success'
                     });
                  this.dialogRef.close(this.selectedFile);
               }
            },
            error => {
               this.alertService.show(
                  {
                     text: 'Unable to upload icon - ' + error,
                     type: 'warning'
                  });
                  this.uploadInProgress = false;
            },
         );
      };
   }

   public onCancel(): void {
      this.dialogRef.close();
   }
}
