import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { catchError, map, switchMap, takeUntil } from "rxjs/operators";
import { BehaviorSubject, Observable, Subject, of } from "rxjs";
import { NotificationData } from "./notifications.interface";
import { ErrorMessageService } from "../services/error-message.service";
import { AlertService } from "../services/alert.service";

@Injectable({
    providedIn: 'root',
 })
 export class NotificationApiService implements OnDestroy{
    private notifications$ = new BehaviorSubject<NotificationData[]>([]);
    private destroy$ = new Subject<void>();

    constructor(
       private http: HttpClient,
       private errorMessageService: ErrorMessageService,
       private alertService: AlertService,
    ) {}

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public fetchNotifications(userId: string): Observable<NotificationData[]>{
        this.http.get<NotificationData[]>('/api/users/' + userId + '/notifications').pipe(
                map(data =>  data.map(item => {
                    item.created = new Date(item.created);
                    return item;
                })),
                catchError(err => this.handleError(err, of({} as NotificationData[]))),
            takeUntil(this.destroy$),
        ).subscribe(
            data => this.notifications$.next(data)
        );
        return this.notifications$.asObservable();
    }

    public getNotifications(): Observable<NotificationData[]>{
        return this.notifications$.asObservable();
    }

    public acknowledgeNotification(UserId: string, notification: NotificationData): void {
        this.http.post<null>('/api/users/' + UserId + '/notifications/' + notification.id, {
            status: "acknowledged"
         }).pipe(
            catchError(err => this.handleError(err, of({} as null))),
         ).subscribe(
            _ => {
                this.notifications$.next([...this.notifications$.getValue().filter(item => notification !== item)]);
            }
         );
    }

    private handleError(error: any, returnValue: Observable<any>): Observable<any> {
        this.alertService.show({
            type: 'danger',
            text: this.errorMessageService.errorMessage(error)
        });
        return returnValue;
    }
}
