// Submit events converted to angular "ngModel::submitAttempted"
// event so extended ngModel can intercept and show errors on pristine
export const NgSubmitDirective = function() {
   return {
      require: 'form',
      link: function(scope, element, _attrs, formController) {
         element.bind('submit', function() {
            formController.$submitAttempted = true;
            scope.$broadcast('ngModelController::submitAttempted');
         });
      }
   };
};
