export const PopupPromiseController: Injectable<IControllerConstructor> = [
   '$scope', '$popoverInstance', '$controllerData',
   function($scope, $popoverInstance, $controllerData) {
   angular.extend($scope, $controllerData);

   $scope.resolve = function(result) {
      $popoverInstance.resolve(result);
   };

   $scope.cancel = function() {
      $popoverInstance.reject();
   };
}];
