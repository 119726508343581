<div *ngIf="btnAction; else normalBtn">
   <button class="dynamic-form-button-action"
      mat-raised-button
      (mousedown)="btnPress()"
      (mouseup)="btnRelease()"
      [disabled]="disabled">{{btnLabel}}
   </button>
</div>
<ng-template #normalBtn>
   <button class="dynamic-form-button"
      mat-stroked-button
      (mousedown)="btnPress()"
      (mouseup)="btnRelease()"
      [disabled]="disabled">{{btnLabel}}
   </button>
</ng-template>