import { Component, OnInit, OnDestroy, ViewChild, Inject } from "@angular/core";
import { AlertService } from "../../../shared/services/alert.service";
import { KeyShortcutRequest, KeyShortcutSetDto } from "../../../shared/types/key-shortcuts.interface";
import { MatTable } from "@angular/material/table";
import { EditorState } from "../../../shared/components/types/editor-state.type";
import { KeyShortcutsApiService } from "../../../shared/services/key-shortcuts.api.service";
import { finalize, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";

export interface DialogData {
    levels: KeyShortcutSetDto[];
}

@Component({
    selector: 'bb-key-shortcut-confirmation-dialog',
    templateUrl: './key-shortcut-confirmation-dialog.html',
})
export class KeyshortcutDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<KeyshortcutDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) {}
}

@Component({
    selector: 'bb-key-shortcuts',
    templateUrl: './key-shortcuts.component.html',
    styleUrls: ['./key-shortcuts.component.scss',],
 })
export class KeyShortcutsComponent implements OnInit, OnDestroy {
    @ViewChild(MatTable) keyShortcutsTable: MatTable<KeyShortcutSetDto[]>;

    public displayedColumns = ['delete','level', 'parent', 'name', 'visible', 'isDefault'];
    public dataSource: KeyShortcutSetDto[];
    public originalDataSource: KeyShortcutSetDto[];
    public levelOptions = [
        {value: 'user', name: 'Myself'},
        {value: 'account', name: 'Account'},
        {value: 'site', name: 'Site'},
        {value: 'cloud', name: 'Whole cloud'},
    ];
    public state: EditorState;
    public updateList: KeyShortcutSetDto[];
    public removeList: string[];
    private destroy$ = new Subject<void>();
    private selectedDefault: string;
    private dialogCloudLevels = ['site', 'account', 'cloud'];
    private originalDefault: string;

    constructor(
        private alertService: AlertService,
        private keyShortcutsApiService: KeyShortcutsApiService,
        public dialog: MatDialog,
    ) {}

    ngOnInit(): void {
        this.state = 'loading';
        this.reset();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public discardChanges(): void {
        this.dataSource = this.originalDataSource.map(x => Object.assign({}, x));
        this.updateList = [];
        this.removeList = [];
        this.refresh();
    }

    public selectionChanged(changedElem: KeyShortcutSetDto): void {
        if (this.updateList.filter(item => item.id === changedElem.id).length === 0){
            this.updateList.push(changedElem);
        }
    }

    public changeDefault(changedElem: KeyShortcutSetDto): void {
        this.selectionChanged(changedElem);
    }

    public save(){
        const uniqueLevels = [];
        const dialogRef = this.dialog.open(KeyshortcutDialogComponent, {
            data: {levels: this.dataSource.filter(item =>
                {
                    if((this.updateList.indexOf(item) > -1 || this.removeList.indexOf(item.id) > -1)
                    && this.dialogCloudLevels.indexOf(item.level) > -1 && uniqueLevels.indexOf(item.level) === -1) {
                        uniqueLevels.push(item.level);
                        return true;
                    }
                    return false;
                })
            },
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                if (this.updateList.length) {
                    const formats = this.updateList.map(elem => this.pack(elem));
                    this.keyShortcutsApiService.setBatchKeyshortcutSet(formats)
                    .pipe(
                        this.alertService.notifyOnError('saving KeyShortcut set'),
                        finalize(() => this.state = 'ready'),
                        takeUntil(this.destroy$),
                    ).subscribe(
                        () => {
                            this.reset();
                        }
                    );
                }
                if (this.removeList.length) {
                    this.removeList.map(
                        keyShortcutId => this.keyShortcutsApiService.trashKeyshortcutSet(keyShortcutId)
                        .pipe(
                            this.alertService.notifyOnError('Deleting keyshortcut'),
                            finalize(() => this.state = 'ready'),
                            takeUntil(this.destroy$),
                        ).subscribe(() => {
                            this.reset();
                        })
                    );
                }
                this.alertService.show({
                    text: 'Key Shortcuts data has been saved successfully',
                    type: 'success',
                });
                this.state = 'submitting';
            }
        });
    }

    public remove(elm: KeyShortcutSetDto): void {
        this.dataSource.splice(this.dataSource.indexOf(elm), 1);
        this.removeList.push(elm.id);
        this.refresh();
    }

    private refresh(): void {
        this.keyShortcutsTable.renderRows();
    }

    private reset(): void {
        this.updateList = [];
        this.removeList = [];
        this.dataSource = [];
        this.originalDataSource = [];
        this.state = 'loading';
        this.keyShortcutsApiService.getKeyshortcutsSets()
        .pipe(
            this.alertService.notifyOnError('loading Key Shortcuts'),
            finalize(() => this.state = 'ready'),
            takeUntil(this.destroy$),
        )
        .subscribe(shortCuts => {
            this.unpack(shortCuts);
            this.state = 'ready';
         });
    }

    private unpack(keys: KeyShortcutSetDto[]): void {
        const sortedKeys = keys.sort(function(a, b) {
            if (a.name < b.name){
              return -1;
            }
            if (a.name > b.name){
              return 1;
            }
            return 0;
        });
        sortedKeys.forEach(key => {
            if(key.isDefault) {
                this.selectedDefault = key.id;
                this.originalDefault = key.id; // To avoid updating default unnecessarily
            }
            this.dataSource.push(key);
            this.originalDataSource.push(Object.assign({}, key));
            if (key.children.length) {
                this.unpack(key.children);
            }
        });
    }

    private pack(elm: KeyShortcutSetDto): any {
        const packed: KeyShortcutRequest = {
            id: elm.id,
            name: elm.name,
            parentId: elm.parent,
            visible:elm.visible,
            level: elm.level,
        };
        if (!elm.parent) {
            packed.parentId = null;
        }
        if (this.selectedDefault === elm.id && this.originalDefault !== this.selectedDefault) {
            packed.isDefault = true;
        }
        return packed;
    }
}
