import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { delay, retryWhen, takeUntil } from 'rxjs/operators';
import { Me } from '../../../../store/users';

import { InvalidLogin, LoginApiService, ValidLogin } from '../../../api/login.api.service';


@Component({
   selector: 'bb-login-dialog',
   templateUrl: './login-dialog.component.html',
   styleUrls: ['./login-dialog.component.scss'],
})
export class LoginDialogComponent implements OnDestroy {
   public passwordFormGroup = new FormGroup({password: new FormControl('', Validators.required)});
   public mfaCodeFormGroup = new FormGroup({mfaCode: new FormControl('', Validators.required)});
   public message = 'Your session has timed out. Please enter the password again.';
   public inputMode: 'password' | 'mfaCode' = 'password';

   private acceptedPassword: string | null = null;
   private destroy$ = new Subject<void>();

   constructor(
      public dialogRef: MatDialogRef<LoginDialogComponent>,
      @Inject('User') public user: User,
      private loginApiService: LoginApiService,
      private http: HttpClient,
   ) {
      this.dialogRef.disableClose = true;

      // Close dialog if logged in, checked every 30 seconds
      this.http.get<Me>('/api/me')
         .pipe(
            retryWhen(errors => errors.pipe(delay(30000))),
            takeUntil(this.destroy$),
         )
         .subscribe(() => {
            this.dialogRef.close();
         });
   }

   ngOnDestroy() {
      this.destroy$.next();
      this.destroy$.complete();
   }

   get passwordValue(): string {
      return this.passwordFormGroup.get('password').value;
   }

   get mfaValue(): string {
      return this.mfaCodeFormGroup.get('mfaCode').value;
   }

   public login(): void {
      this.loginApiService.login(this.passwordValue)
         .pipe(takeUntil(this.destroy$))
         .subscribe({
            next: response => this.handleLogin(response),
            error: error => this.resetOnError(error.message),
         });
   }

   public verify(): void {
      this.loginApiService.verifyMfa(this.acceptedPassword, this.mfaValue)
         .pipe(takeUntil(this.destroy$))
         .subscribe({
            next: response => this.handleMfa(response),
            error: error => this.resetOnError(error.message),
         });
   }

   private handleLogin(login: ValidLogin | InvalidLogin): void {
      if (login.status === 'valid') {
         this.dialogRef.close();
      } else if (login.status === 'invalidmfa') {
         this.acceptedPassword = this.passwordValue;
         this.inputMode = 'mfaCode';
         this.message = login.error;
      } else {
         this.resetOnError(login.error);
      }
   }

   private handleMfa(mfaLogin: ValidLogin | InvalidLogin): void {
      if (mfaLogin.status === 'valid') {
         this.dialogRef.close();
      } else if (mfaLogin.status === 'invalidmfa') {
         this.message = mfaLogin.error;
      } else {
         this.resetOnError(mfaLogin.error);
      }
   }

   private resetOnError(errorMessage: string): void {
      this.acceptedPassword = null;
      this.inputMode = 'password';
      this.message = errorMessage;
   }
}
