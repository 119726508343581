import '../fbdn-angular-modules/http';
import '../utils/page.factory';

const ngModule = angular.module('bb.api.columns', [
   'bb.http',
   'bb.utils.page',
]);

// TODO add column type when file column types exist FP-19014
ngModule.factory('ColumnsApi', ['_', 'http', 'Page', function(_: Lodash, http: any, Page: any) {
   const commonLoggingColumns = [{
      displayName: 'Shot',
      internalName: 'Shot'
   }, {
      displayName: 'Sync',
      internalName: 'Sync'
   }];

   const commonFileColumns = [{
      displayName: 'Disk label',
      internalName: '_IMPORTDISKLAB'
   }, {
      displayName: 'Start timecode',
      internalName: 'starttimecode',
      hidden: true
   }, {
      displayName: 'End timecode',
      internalName: 'endtimecode',
      hidden: true
   }, {
      displayName: 'Source locator',
      internalName: 'sourcelocator',
      hidden: true
   }];

   function addLoggingColumnDefaults(column) {
      return _.defaults(column, {
         displayName: '',
         internalName: '',
         hidden: false,
         title: false,
         media: false,
         presets: []
      });
   }

   function addFileColumnDefaults(column) {
      return _.defaults(column, {
         displayName: '',
         internalName: '',
         hidden: false,
         searchable: false,
         editable: false
      });
   }

   function newLoggingColumn() {
      return addLoggingColumnDefaults({});
   }

   function newFileColumn() {
      return addFileColumnDefaults({});
   }

   function convertFileColumnsToLoggingColumns(fileColumns) {
      return _.map(fileColumns, function(fileColumn) {
         var loggingColumn = newLoggingColumn();
         loggingColumn.media = true;
         loggingColumn.internalName = fileColumn.internalName;
         loggingColumn.displayName = fileColumn.displayName;
         return loggingColumn;
      });
   }

   function getSiteLoggingColumns() {
      return http({
         method: 'GET',
         url: '/api/sites/' + Page.siteId + '/loggingColumns'
      });
   }

   function getDefaultSiteLoggingColumns() {
      return http({
         method: 'GET',
         url: '/api/sites/' + Page.siteId + '/defaultLoggingColumns'
      });
   }

   function setSiteLoggingColumns(columns, autoInherit: boolean) {
      return http({
         method: 'POST',
         url: '/api/sites/' + Page.siteId + '/loggingColumns',
         data: {
            columns: columns,
            autoInherit: autoInherit
         }
      });
   }

   function getSiteFileColumns() {
      return http({
         method: 'GET',
         url: '/api/sites/' + Page.siteId + '/fileColumns'
      });
   }

   function getDefaultSiteFileColumns() {
      return http({
         method: 'GET',
         url: '/api/sites/' + Page.siteId + '/defaultFileColumns'
      });
   }

   function getSiteFileColumnsAsLoggingColumns() {
      return getSiteFileColumns().then(function(result) {
         result.columns = convertFileColumnsToLoggingColumns(result.columns);
         return result;
      });
   }

   function setSiteFileColumns(columns, autoInherit: boolean) {
      return http({
         method: 'POST',
         url: '/api/sites/' + Page.siteId + '/fileColumns',
         data: {
            columns: columns,
            autoInherit: autoInherit
         }
      });
   }

   function getAccountLoggingColumns(accountId: string) {
      return http({
         method: 'GET',
         url: '/api/accounts/' + (accountId || Page.accountId) + '/loggingColumns'
      });
   }

   function getDefaultAccountLoggingColumns() {
      return http({
         method: 'GET',
         url: '/api/accounts/' + Page.accountId + '/defaultLoggingColumns'
      });
   }

   function setAccountLoggingColumns(columns, autoInherit: boolean) {
      return http({
         method: 'POST',
         url: '/api/accounts/' + Page.accountId + '/loggingColumns',
         data: {
            columns: columns,
            autoInherit: autoInherit
         }
      });
   }

   function getAccountFileColumns(accountId?: string) {
      return http({
         method: 'GET',
         url: '/api/accounts/' + (accountId || Page.accountId) + '/fileColumns'
      });
   }

   function getDefaultAccountFileColumns() {
      return http({
         method: 'GET',
         url: '/api/accounts/' + Page.accountId + '/defaultFileColumns'
      });
   }

   function getAccountFileColumnsAsLoggingColumns() {
      return getAccountFileColumns().then(function(result) {
         result.columns = convertFileColumnsToLoggingColumns(result.columns);
         return result;
      });
   }

   function setAccountFileColumns(columns, autoInherit: boolean) {
      return http({
         method: 'POST',
         url: '/api/accounts/' + Page.accountId + '/fileColumns',
         data: {
            columns: columns,
            autoInherit: autoInherit
         }
      });
   }

   return {
      addLoggingColumnDefaults: addLoggingColumnDefaults,
      addFileColumnDefaults: addFileColumnDefaults,
      commonFileColumns: commonFileColumns,
      commonLoggingColumns: commonLoggingColumns,
      newFileColumn: newFileColumn,
      newLoggingColumn: newLoggingColumn,
      Site: {
         getLoggingColumns: getSiteLoggingColumns,
         setLoggingColumns: setSiteLoggingColumns,
         getDefaultLoggingColumns: getDefaultSiteLoggingColumns,
         getFileColumns: getSiteFileColumns,
         getFileColumnsAsLoggingColumns: getSiteFileColumnsAsLoggingColumns,
         getDefaultFileColumns: getDefaultSiteFileColumns,
         setFileColumns: setSiteFileColumns
      },
      Account: {
         getLoggingColumns: getAccountLoggingColumns,
         setLoggingColumns: setAccountLoggingColumns,
         getDefaultLoggingColumns: getDefaultAccountLoggingColumns,
         getFileColumns: getAccountFileColumns,
         getFileColumnsAsLoggingColumns: getAccountFileColumnsAsLoggingColumns,
         getDefaultFileColumns: getDefaultAccountFileColumns,
         setFileColumns: setAccountFileColumns
      }
   };
}]);

export default ngModule;
