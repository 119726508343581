export const fbdnAccordionComponent: IComponentOptions = {
   transclude: true,
   template: `<ng-transclude class="panelled-page"/>`,
   controllerAs: 'vm',
   controller: ['$scope', '$rootScope', '_', function($scope: IScope, $rootScope: IRootScopeService, _: Lodash) {
      const self = this;
      const panelControllers: IController[] = [];
      let state = 'pre-init';

      self.add = function(panelController: IController) {
         panelControllers.push(panelController);
         scheduleInit();
      };

      self.remove = function(panelController: IController) {
         _.pull(panelControllers, panelController);
         if (panelControllers.length === 0) {
            state = 'pre-init';
         }
      };

      self.reflow = function() {
         $rootScope.$broadcast('reflow');
      };

      self.closeOther = function(openPanelController: IController, immediate: boolean, openPanelElement?: HTMLElement) {
         const other = _.filter(panelControllers, function(panelController: IController) {
               return openPanelController !== panelController;
         });
         _.forEach(other, function(panelController: IController) {
            panelController.close(immediate, openPanelElement);
         });
      };
      // Supports initialising the accordion panels
      // even if panel elements added in later digest loop
      function scheduleInit(): void {
         if (state === 'pre-init') {
            $scope.$evalAsync(function() {
               const activeController = panelControllers.find(ctrl => ctrl.isActive());
               const initial = activeController || panelControllers[0];
               initial.open(true);
               state = 'initialised';
            });
            state = 'init-scheduled';
         }
      }
   }]
};
