import { HttpClient } from "@angular/common/http";
import { Injectable, Inject } from "@angular/core";
import { Observable } from "rxjs";
import { KeyShortcutSetDto } from "../types/key-shortcuts.interface";
import { PageService } from "./page.service";

@Injectable({
    providedIn: 'root',
 })
 export class KeyShortcutsApiService {
    private page: Page;
    constructor(
        private pageService: PageService,
        @Inject('User') private user: User,
        private http: HttpClient
    ) {
        this.page = this.pageService.page;
     }

    public getKeyshortcutsSets(): Observable<KeyShortcutSetDto[]>{

        return this.http.get<KeyShortcutSetDto[]>('/api/keyshortcuts', {params: {
            accountId : this.page.accountId,
            siteId    : this.page.siteId,
            userId    : this.user.id
        }});
    }

    public setDefaultKeyshortcutsSet(keyShortcutSetId: string): Observable<KeyShortcutSetDto> {

        return this.http.post<KeyShortcutSetDto>('/api/defaultKeyshortcuts', {
            data: {
                keyshortcutId : keyShortcutSetId,
                accountId     : this.page.accountId,
                siteId        : this.page.siteId
             }
        });
    }

    public setKeyshortcutSet(keyShortcutSetId: string, data: any): Observable<KeyShortcutSetDto> {
        data.siteId    = this.page.siteId;
        data.accountId = this.page.accountId;
        data.userId    = this.user.id;
        return this.http.put<KeyShortcutSetDto>('/api/keyshortcuts/' + keyShortcutSetId, data);
    }

    public setBatchKeyshortcutSet(data): Observable<any> {
        return this.http.put<any>('/api/keyshortcuts', data.map(elem => {
            elem.siteId    = this.page.siteId;
            elem.accountId = this.page.accountId;
            elem.userId    = this.user.id;
            return elem;
        }));
    }

    public trashKeyshortcutSet(keyShortcutSetId: string): any {
        return this.http.post('/api/keyshortcuts/' + keyShortcutSetId + '/trash', {});
    }

    public getKeyshortcutAccessLevel(): Observable<KeyShortcutSetDto[]>{

        return this.http.get<KeyShortcutSetDto[]>('/api/keyshortcutsAccessLevel', {
            params: {
                accountId : this.page.accountId,
                siteId    : this.page.siteId,
                userId    : this.user.id
            }
        });
    }

    public getDefaultLevel(): string{
        if (this.page.accountId) {
            return 'account';
         } else if (this.page.siteId) {
            return 'site';
         } else {
            return 'cloud';
         }
    }
 }
