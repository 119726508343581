const ngModule = angular.module('bb.combo', []);

ngModule.directive('combo', ['$window', '$timeout', '_', function($window, $timeout, _) {
   // Select2 requires an ID on all options
   var latestId = 0;
   function id(option) {
      return option.$$comboId;
   }
   function attachId(object) {
      object = object || {};
      object.$$comboId = object.$$comboId || (++latestId);
      return object;
   }
   function attachAll(options) {
      angular.forEach(options, function(item) {
         attachId(item);
         attachAll(item.children);
      });
      return options;
   }

   return {
      restrict: 'E',
      template: '<input>',
      require: 'ngModel',
      scope: {
         'options': '=comboOptions',
         'placeholder': '=comboPlaceholder',
         'querySearchingString': '=comboQuerySearchingString',
         'queryNoMatchesString': '=comboQueryNoMatchesString',
         // Ideally would use ngRequired, but unable to override its validity
         'required': '=comboRequired',
         'changeType': '=comboChangeType',
         'parse': '&comboParse',
         'format': '&comboFormat',
         'selectOnly': '=comboSelectOnly',
         'createNewItem': '&comboCreateNewItem',
         'pattern': '@comboPattern',
         'model': '=ngModel',
         'disabled': '=ngDisabled'
      },

      link: function(scope, element, attrs, ngModelController) {
         var select2El = element.children().first();

         var select2Options = {
            'initSelection': initSelection,
            'id': id,
            'formatSelection': (item, container, escapeMarkup) => _formatItem(item, escapeMarkup),
            'formatResult': (item, container, query, escapeMarkup) => _formatItem(item, escapeMarkup),
            'placeholder': scope.placeholder
         };

         // Allow custom text to be entered
         function createSearchChoice(term) {
            return scope.format({
               modelValue: attachId(scope.createNewItem({term: term}))
            });
         }

         function _formatItem(item, escapeMarkup) {
            const subText = item.$$comboSubText
               ? `<span class="combo-subtext">(${escapeMarkup(item.$$comboSubText)})</span>`
               : '';
            return `${escapeMarkup(item.$$comboText)}${subText}`;
         }

         function initSelection(element, callback) {
            callback(attachId(scope.format({modelValue: ngModelController.$modelValue})));
         }

         // React to changes in the model, after $formatters
         var isChanging = false;
         ngModelController.$render = function() {
            function render() {
               select2El.val(ngModelController.$viewValue && ngModelController.$viewValue.$$comboText ? attachId(ngModelController.$viewValue).$$comboId : '').trigger('change');
            }
            isChanging ? $timeout(render) : render();
         };

         // React to changes in the view
         element.bind('change', function(e) {
            var modelChange = !e.added && !e.removed;
            if (modelChange) return;
            isChanging = true;
            if (scope.changeType === 'merge') {
               // Merge the current values into the existing model
               angular.extend(ngModelController.$modelValue, e.added);
               ngModelController.$setViewValue(ngModelController.$modelValue);
            } else {
               ngModelController.$setViewValue(e.added);
            }

            scope.$apply();
            isChanging = false;
         });

         // Parse/format can be passed in from parent controller
         if (attrs.comboParse) {
            ngModelController.$parsers.push(function(viewValue, validateOnly) {
               return scope.parse({viewValue: viewValue, validateOnly: validateOnly});
            });
         }

         if (attrs.comboFormat) {
            ngModelController.$formatters.push(function(modelValue, validateOnly) {
               return scope.format({modelValue: modelValue, validateOnly: validateOnly});
            });
         } else {
            scope.format = function formattingFunction(val) {
               return val.modelValue;
            };
         }

         // Suggestions need to be formatted
         function formatAll(suggestions) {
            return _.map(suggestions, function(suggestion) {
               suggestion = angular.copy(suggestion);
               if (suggestion.children) {
                  suggestion.children = formatAll(suggestion.children);
               }
               return scope.format({modelValue: suggestion});
            });
         }


         function setValidity(option) {
            // Element is seen as populated if it has a text label
            // ideally use ngModelController.$isEmpty, but couldn't get
            // it working
            var valid = !scope.required || (option && !!option.$$comboText);
            ngModelController.$setValidity('required', valid);

            if (scope.pattern) {
               // Allow the comboPattern to be of /regex/flags format
               // to be consistent with ngPattern
               // Pattern extracts arguments for RegExp constructor
               var match = scope.pattern.match(/^\/(.*)\/([gim]*)$/);
               var regexp = new $window.RegExp(match[1], match[2]);
               ngModelController.$setValidity('pattern', regexp.test(option.$$comboText));
            }
         }
         ngModelController.$parsers.push(function(viewValue) {
            setValidity(viewValue);
            return viewValue;
         });
         // Angular runs formatters in reverse
         ngModelController.$formatters.unshift(function(modelValue) {
            setValidity(modelValue);
            return modelValue;
         });

         // React to ngDisabled
         scope.$watch('disabled', function(disabled) {
            select2El.select2('enable', !disabled);
         });

         scope.$watchCollection('options', function() {
            init();
         });

         scope.$watch('options.data', function() {
            init();
         });

         function init() {
            angular.extend(select2Options, scope.options);
            if (attrs.comboData) {
               select2Options.data = formatAll(attachAll(scope.$parent.$eval(attrs.comboData)));
            } else if (scope.options.data) {
               select2Options.data = formatAll(attachAll(angular.copy(scope.options.data)));
            }

            if (attrs.comboCreateNewItem) {
               select2Options.createSearchChoice = createSearchChoice;
            }

            if (scope.querySearchingString) {
               select2Options.formatSearching = function() {
                  return scope.querySearchingString;
               };
            }

            if (scope.queryNoMatchesString) {
               select2Options.formatNoMatches = function() {
                  return scope.queryNoMatchesString;
               };
            }

            if (scope.selectOnly) {
               select2Options.minimumResultsForSearch = -1;
            }

            if (('comboQuery' in attrs && scope.$parent.$eval(attrs.comboQuery)) || scope.options.query) {
               select2Options.query = function(query) {
                  // Required http config option _rawData = true in order to have access
                  // to offset returned from server

                  const queryFunc = 'comboQuery' in attrs
                     ? scope.$parent.$eval(attrs.comboQuery)
                     : scope.$parent.$eval(attrs.comboOptions).query;

                  queryFunc(query.term, query.context ? query.context.nextOffset : null).then(function(results) {
                     query.context = query.context || {};
                     query.context.nextOffset = results.nextOffset;
                     query.callback({
                        results: formatAll(attachAll(results.results)),
                        more: 'nextOffset' in results,
                        context: query.context
                     });
                  });
               };
            }

            select2El.select2(select2Options);
            select2El.select2('enable', !scope.disabled);
         }
      }
   };
}]);

export default ngModule;
