import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';

import { AccountDto, NameReservation, ResponseAccountDto, Workflow } from './account.interface';
import { RAW_DATA } from '../../constants/raw-data';
import { Assignment } from '../../types/assignment.interface';
import { AsyncCacheService } from '../../utils/async-cache.service';
import { Skeleton } from '../../types/skeleton.interface';
import { ErrorMessageService } from '../../services/error-message.service';
import { AlertService } from '../../services/alert.service';
import { SkeletonCacheService } from '../../utils/skeleton-cache.service';


@Injectable({
   providedIn: 'root',
})
export class AccountsApiService {
   private asyncCache: any;
   private skeletonCache: any;

   constructor(
      private skeletonCacheService: SkeletonCacheService,
      private http: HttpClient,
      private errorMessageService: ErrorMessageService,
      private asyncCacheService: AsyncCacheService<Assignment>,
      private alertService: AlertService,
   ) {
      this.asyncCache = this.asyncCacheService.initAsyncCache('AccountsApiService');
      this.skeletonCache = this.skeletonCacheService.initSkeletonCache('AccountsApiService');
   }

   public fetch(accountId: string, forceFetch=false): Observable<AccountDto> {
      const path = `/api/accounts/${accountId}`;
      const api = this.http.get<AccountDto>(path).pipe(
         map((account: AccountDto) => this.skeletonCache.unmarshall(account)),
         catchError(err => this.handleError(err, of({} as AccountDto)))
      );
      return this.asyncCache.fetch('account-' + accountId, api, forceFetch);
   }

   public fetchWorkflows(accountId: string, forceFetch=false): Observable<Workflow[]>{
      const path = `/api/accounts/${accountId}/workflows`;
      const api = this.http.get<Workflow[]>(path).pipe(
         catchError(err => this.handleError(err, of([{}] as Workflow[]))),
      );
      return this.asyncCache.fetch('account-' + accountId + '-workflows', api, forceFetch);
   }

   public updateAccount(accountId: string, accountData: Partial<AccountDto>): Observable<AccountDto> {
      return this.http.post<AccountDto>(`/api/accounts/${accountId}`, accountData).pipe(
         catchError(err => this.handleError(err, of({} as AccountDto))),
         map((account: AccountDto) => this.skeletonCache.unmarshall(account)),
      );
   }

   public getAccounts(queryParams: any, rawResponse = true, forceFetch: boolean = false): Observable<ResponseAccountDto> {
      const path = '/api/accounts';
      let options = {};
      if (queryParams) {
         options = {
            params: new HttpParams({fromObject: queryParams}),
            headers: rawResponse ? RAW_DATA : {},
         };
      }
      return this.http.get<ResponseAccountDto>(path, options).pipe(
         map((response: ResponseAccountDto) => this.skeletonCache.unmarshallCollection(response)),
         catchError(err => this.handleError(err, of({} as ResponseAccountDto))),shareReplay(1),
      );
   }

   public cloneFrom(accountData: Partial<AccountDto>): Observable<AccountDto>  {
      return this.http.post<AccountDto>(`/api/accounts`, accountData)
         .pipe(
            catchError(err => this.handleError(err, of({} as NameReservation))),
            map((account: AccountDto) => this.skeletonCache.unmarshall(account)),
      );
   }

   public reserveName(name: string, reserveKey: string, cloneFrom: string): Observable<NameReservation> {
      return this.http.post<NameReservation>(`/api/accounts`, {reserveKey, name, cloneFrom})
      .pipe(
         catchError(err => this.handleError(err, of({} as NameReservation))),
      );
   }

   public unreserveName(name: string, unreserveKey: string, cloneFrom: string): Observable<NameReservation>  {
      return this.http.post<NameReservation>(`/api/accounts`, {unreserveKey, name, cloneFrom}).pipe(
         catchError(err => this.handleError(err, of({} as NameReservation))),
      );
   }

   public calculateMaxVideoResolution(workflows: Workflow[]) {
      // we set a sensible minimum here just so our client
      // does not have to muck about. This is the default from
      // media-player-config.
      var maxVideoWidth = 640;
      var maxVideoHeight = 360;
      const maxVideoRegexp = /^([0-9]*)x([0-9]*)$/;
      for (const workflow of workflows) {
         if (workflow.editor?.maxVideo) {
            const m = maxVideoRegexp.exec(workflow.editor.maxVideo);
            if (m) {
               const width = parseInt(m[1], 10);
               const height = parseInt(m[2], 10);
               // rather than maxing width and height independently,
               // we'll choose the size with most total pixels, as
               // that is how videolib imposes its limits.
               if (width*height > maxVideoWidth*maxVideoHeight) {
                  maxVideoWidth = width;
                  maxVideoHeight = height;
               }
            }
         }
      }
      return {width: maxVideoWidth, height: maxVideoHeight};
   }

   public populateSkeleton(skeleton: Skeleton, forceFetch=false): Observable<AccountDto> {
      if (skeleton.id) {
         return this.fetch(skeleton.id, forceFetch);
      }
      return of(skeleton) as Observable<AccountDto>;
   }

   public getSkeleton(accountId?: string): any {
      return this.skeletonCache.get(accountId);
   }

   private handleError(error: any, returnValue: Observable<any>): Observable<any> {
      this.alertService.show({
          type: 'danger',
          text: this.errorMessageService.errorMessage(error)
      });
      return returnValue;
   }
}
