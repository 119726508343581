import { Injectable, Inject } from '@angular/core';
import { Observable, of, from } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { SaveTransactionService } from '../utils/save-transaction/save-transaction.service';
import { Endpoint } from '../types/endpoint.interface';
import { AsyncCacheService } from '../utils/async-cache.service';
import { HttpClient } from '@angular/common/http';
import { DynamicSchema } from '../dynamic-form/ng/dynamic-schema';
import { ArrivalsFolder, ArrivalsFolderTypes } from '../api/arrivals-folder.interface';
import { Skeleton } from '../types/skeleton.interface';
import { ArrivalsFolderType } from '../api/arrivals-folder.interface';
import { StatefulObject } from '../utils/save-transaction/transaction-objs';

interface Cache {
   [key: string]: Skeleton | ArrivalsFolder;
}

interface Results {
   accountId: string;
   id: string;
   name: string;
   options: any;
   priority: number;
   typeId: string;
}

@Injectable({
   providedIn: 'root',
})
export class ArrivalsFoldersApiService {
   private asyncCache: any;
   private cache: Cache = {};
   private id = 0;

   constructor(
      private saveTransactionService: SaveTransactionService,
      private httpClient: HttpClient,
      private asyncCacheService: AsyncCacheService<Endpoint>,
   ) {
      this.asyncCache = this.asyncCacheService.initAsyncCache('ArrivalsFoldersApiService');
   }

   public fetchTypesForAccount(accountId: string, forceFetch: boolean = false): Observable<ArrivalsFolderTypes> {
      const api = this.httpClient.get<ArrivalsFolderType[]>('/api/accounts/' + accountId + '/arrivalsFolderTypes')
         .pipe(
            map((ArrivalsFoldersTArr) => {
               const arrivalsObj: ArrivalsFolderTypes = {};
               ArrivalsFoldersTArr.forEach(ArrObj => arrivalsObj[ArrObj.id] = ArrObj);
               return arrivalsObj;
            }),
         );
      return this.asyncCache.fetch('arrivals-folder-types-' + accountId, api, forceFetch);
   };

   public createDefaultArrivalsFolder(accountId: string, name: string): Observable<ArrivalsFolder> {
      return this.httpClient.post<ArrivalsFolder>('/api/accounts/' + accountId + '/arrivalsFolders', { name: name });
   };

   public fetchForAccount(accountId: string, forceFetch: boolean = false): Observable<ArrivalsFolder[]> {
      const api = this.httpClient.get<ArrivalsFolder[]>('/api/accounts/' + accountId + '/arrivalsFolders');
      return this.asyncCache.fetch('account-arrivals-folders-' + accountId, api, forceFetch)
         .pipe(
            map((afs: ArrivalsFolder[]) => afs.map((af) => this.unmarshall(af))),
         );
   };

   public fetch(arrivalsFolderId: string, forceFetch: boolean = false): Observable<ArrivalsFolder> {
      const api = this.httpClient.get<ArrivalsFolder>('/api/arrivalsFolders/' + arrivalsFolderId);
      return this.asyncCache.fetch('account-arrivals-folder-' + arrivalsFolderId, api, forceFetch)
         .pipe(
            map((af) => this.unmarshall(af)),
         );
   };

   public getNewArrivalsFolder(accountId: string, typeId: string, priority, schema: any): ArrivalsFolder {
      return this.unmarshall({
         $$temporaryId: 'new-arrivals-folder-' + ++this.id,
         $$state: 'new',
         $$dirty: true,
         accountId: accountId,
         typeId: typeId,
         name: 'New arrivals folder',
         priority: priority,
         options: DynamicSchema.defaultValuesForSchema(schema),
      });
   };

   public createArrivalsFolder(accountId: string, arrivalsFolder: any): Observable<any> {
      const api = this.httpClient.post<ArrivalsFolder>('/api/accounts/' + accountId + '/arrivalsFolders', this.marshall(arrivalsFolder));
      return this.saveTransactionService.saveObject(arrivalsFolder, () => {
         return api
            .pipe(
               map((results: Results) => {
               arrivalsFolder.id = results.id;
               this.cache[arrivalsFolder.id] = arrivalsFolder;
               return results;
            }));
      });
   };

   public saveArrivalsFolder(arrivalsFolder: any): Observable<StatefulObject<ArrivalsFolder>> {
      const api = this.httpClient.post<ArrivalsFolder>('/api/arrivalsFolders/' + arrivalsFolder.id, arrivalsFolder);
      return this.saveTransactionService.saveObject(arrivalsFolder, () => {
         return api;
      });
   };

   public saveArrivalsFolderName(arrivalsFolder: any): Observable<any> {
      const api = this.httpClient.post<any>('/api/arrivalsFolders/' + arrivalsFolder.id, { id: arrivalsFolder.id, name: arrivalsFolder.name });
      return this.saveTransactionService.save(arrivalsFolder, 'name', () => {
         return api;
      });
   };

   public deleteArrivalsFolder(arrivalsFolder: ArrivalsFolder, arrivalsFolders: ArrivalsFolder[]): Observable<null> {
      arrivalsFolder.$$deleting = true;
      const isNewArrivalsFolder = (arrivalsFolder.$$state === 'new' || arrivalsFolder.$$state === 'creating');
      const api = this.httpClient.post<null>('/api/arrivalsFolders/' + arrivalsFolder.id + '/trash', {});

      return (isNewArrivalsFolder ? of(null) : api
         .pipe(
            catchError(error => {
               arrivalsFolder.$$deleting = false;
               throw error;
            }),
            map((results) => {
               arrivalsFolders.splice(arrivalsFolders.findIndex(x => x.id === arrivalsFolder.id), 1);
               return results;
            }),
         ));
   };

   public getSkeleton(arrivalsFolderId: string): Skeleton {
      if (arrivalsFolderId in this.cache) return this.cache[arrivalsFolderId];
      const skeleton = {
         id: arrivalsFolderId ? arrivalsFolderId : null,
         $$state: arrivalsFolderId ? 'uninitialised' : 'clean'
      };
      if (arrivalsFolderId) {
         this.cache[arrivalsFolderId] = skeleton;
      }
      return skeleton;
   };

   private mapWithPick(objects, keys: string[]) {
      return objects.map(obj => this.pick(obj, keys));
    }

   private pick(af: ArrivalsFolder[], keys: string[]) {
      return keys.reduce((result, key) => {
        if (key in af) {
          result[key] = af[key];
        }
        return result;
      }, {});
   }

   private unmarshall(arrivalsFolder: Partial<ArrivalsFolder>): ArrivalsFolder {
      this.saveTransactionService.prepare(arrivalsFolder, ['options']);
      return this.mergeIntoSkeleton(arrivalsFolder as ArrivalsFolder);
   }

   private marshall(arrivalsFolder: ArrivalsFolder): Omit<ArrivalsFolder, 'id'> {
      const objCopy = {...arrivalsFolder};
      delete objCopy.id;
      return objCopy;
   }

   private mergeIntoSkeleton(arrivalsFolder: ArrivalsFolder): ArrivalsFolder {
      const skeleton = this.getSkeleton(arrivalsFolder.id);
      skeleton.$$state = 'clean';
      return Object.assign(skeleton, arrivalsFolder);
   }
}
