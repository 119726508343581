import { notificationAreaComponent } from './notification-area.component.ajs';
import ServicesModule from '../../services/services.module';

const ngModule = angular.module('bb.notification-area', [
   ServicesModule.name,
]);

ngModule.component('notificationArea', notificationAreaComponent);

export default ngModule;
