<h2 mat-dialog-title class="upload-title">Upload Icon</h2>
<mat-dialog-content>
   <input #fileInput type="file" style="display: none" (change)="onFileSelected($event)" />
   <button mat-raised-button class="upload-button" (click)="openFileExplorer()">Choose icon</button>
   <span class="file-selected" *ngIf="selectedFile">{{ selectedFile.name }}</span>
   <mat-progress-bar *ngIf="uploadInProgress" mode="determinate" [value]="uploadProgress"></mat-progress-bar>
</mat-dialog-content>
<mat-dialog-actions class="action-buttons">
   <button mat-stroked-button class="cancel-button" (click)="onCancel()">Cancel</button>
   <button mat-raised-button class="upload-button" (click)="onUpload()" [disabled]="!selectedFile || uploadInProgress">Upload</button>
</mat-dialog-actions>
