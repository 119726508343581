<div>
   <div class="card">
      <h4 class="mat-dialog-title">
         Batch user creation
      </h4>
      <div>
         <p *ngIf="data.importableUsersCount > 0">
            {{ data.importableUsersCount }} out of {{ data.reviewUsersList.length | i18nPlural: usersPluralMap }}
            will be imported when proceeding with import. Details in the list below.
         </p>
         <p *ngIf="data.importableUsersCount <= 0">
            No users out of {{ data.reviewUsersList.length }} can be imported.
         </p>
         <div class="table-container">
            <table class="users" *ngIf="data.reviewUsersList.length">
               <thead>
                  <tr>
                     <td>#</td>
                     <td>Full name</td>
                     <td>Company</td>
                     <td>Email address</td>
                     <td>Status</td>
                  </tr>
               </thead>
               <tbody>
                  <tr
                     *ngFor="let u of data.reviewUsersList; let i = index"
                     [ngClass]="u.success ? 'add-success' : 'add-failure'"
                  >
                     <td>{{ i + 1 }}</td>
                     <td>{{ u.fullname }}</td>
                     <td>{{ u.company }}</td>
                     <td>{{ u.email }}</td>
                     <td>{{ u.message }}</td>
                  </tr>
               </tbody>
            </table>
         </div>
         <div class="buttons-container">
            <button mat-raised-button (click)="abortImportProcess()">
               Cancel
            </button>
            <button mat-raised-button color="primary" (click)="importReviewedUsers()" [disabled]="data.importableUsersCount <= 0">
               Proceed with import
            </button>
         </div>
      </div>
   </div>
</div>
