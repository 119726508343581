import { MenuDirectionBase } from './menu-direction-base.directive';

/* @Deprecated Use Angular MenuDirectionDirective if containing page is hybrid or pure Angular */
export class ClickMenuDirectionDirective extends MenuDirectionBase {
   constructor($window: IWindowService, private $q: IQService) {
      super($window);
   }

   static factory(...deps: [IWindowService, IQService]) {
      return new ClickMenuDirectionDirective(...deps);
   }

   link = (_scope: IScope, element: JQLite) => {
      element.bind('click', () => {
         this.onMenuReady(element)
            .then(el => this.setOpenDirectionClass(el));
      });
   };

   private onMenuReady(element: JQLite): IPromise<JQLiteShim> {
      // TODO FP-19134 convert polling to event-driven
      // and move to the click menu's module
      return this.$q(resolve => {
         const waitForElement = () => {
            // Dropdown menu should be a sibling to the trigger element
            const menuElem = element.parent().find('.dropdown-direction-menu') as JQLiteShim;
            let reqId: number;

            if (menuElem.length) {
               resolve(menuElem);
               this.$window.cancelAnimationFrame(reqId);
            } else {
               reqId = this.$window.requestAnimationFrame(waitForElement);
            }
         };

         waitForElement();
      });
   }
}

ClickMenuDirectionDirective.factory.$inject = ['$window', '$q'];
