<div *ngIf="state ==='loading' && !dataSource?.length" class="content-loading">
   <fbdn-icon icon="loading"></fbdn-icon>
</div>
<div *ngIf="dataSource?.length">
   <mat-table [dataSource]="dataSource" class="mat-elevation-z0">
      <ng-container matColumnDef="delete">
         <th mat-header-cell *matHeaderCellDef></th>
         <td mat-cell *matCellDef="let element">
            <button class="btn-link format-remove" (click)="remove(element.id)">
               <span class="material-icon">
                  cancel
               </span>
            </button>
         </td>
      </ng-container>
      <ng-container matColumnDef="level">
         <th mat-header-cell *matHeaderCellDef> Level </th>
         <td mat-cell *matCellDef="let element">
            <div class="form-cell">
               <mat-form-field appearance="outline">
                  <mat-select [(value)]="element.level" (selectionChange)="selectionChanged(element)">
                     <mat-option *ngFor="let option of levelOptions" [value]="option.value">{{option.name}}</mat-option>
                  </mat-select>
               </mat-form-field>
            </div>
         </td>
      </ng-container>
      <ng-container matColumnDef="name">
         <th mat-header-cell *matHeaderCellDef> name </th>
         <td mat-cell *matCellDef="let element">
            <div class="form-cell">
               <mat-form-field appearance="outline">
                  <input matInput [(ngModel)]="element.name" (change)="selectionChanged(element)" name="name"/>
               </mat-form-field>
            </div>
         </td>
      </ng-container>
      <ng-container matColumnDef="parent">
         <th mat-header-cell *matHeaderCellDef> Parent Key Shortcut </th>
         <td mat-cell *matCellDef="let element">
            <div class="form-cell">
               <mat-form-field appearance="outline">
                  <mat-select [(value)]="element.parent" (selectionChange)="selectionChanged(element)">
                     <mat-option [value]=""></mat-option>
                     <div *ngFor="let option of dataSource">
                        <mat-option  *ngIf="option.id !== element.id && option.parent !== element.id" [value]="option.id">{{option.name}}</mat-option>
                     </div>
                  </mat-select>
               </mat-form-field>
            </div>
         </td>
      </ng-container>
      <ng-container matColumnDef="visible">
         <th mat-header-cell *matHeaderCellDef> Visible? </th>
         <td mat-cell *matCellDef="let element">
            <div class="form-cell">
                  <mat-checkbox class="example-margin"
                     [(ngModel)]="element.visible"
                     (change)="selectionChanged(element)"
                     [disabled]="element.id === selectedDefault">
                  </mat-checkbox>
            </div>
         </td>
      </ng-container>
      <ng-container matColumnDef="isDefault">
         <th mat-header-cell *matHeaderCellDef> Default </th>
         <td mat-cell *matCellDef="let element">
            <div class="form-cell">
                  <mat-radio-group [(ngModel)]="selectedDefault" (change)="changeDefault(element)">
                     <mat-radio-button [value]="element.id"></mat-radio-button>
                 </mat-radio-group>
            </div>
         </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
   </mat-table>
</div>
<div class="save-format" *ngIf="(removeList.length > 0 || updateList.length > 0) && state !== 'submitting'">
   <button mat-raised-button color="primary" type="submit" (click)="save()">
      Save Changes
   </button>
   <button mat-stroked-button color="primary" (click)="discardChanges()">
      Discard Changes
   </button>
</div>
