import HttpModule from '../../fbdn-angular-modules/http';
import { HelpResultOptions, PublishHelp, PublishHelpResult } from './publish-help.interface';

const ngModule = angular.module('bb.api.publish', [
   HttpModule.name,
]);

/*
   Duplicated in Angular as PublishHelpApiService.
   Remove this when MAM is in Angular and can inject the Angular service instead.
*/
export class PublishHelpApiAjs {
   static $inject = ['http'];

   constructor(private http: any) {}

   getPublishHelp(mediaId: string): Promise<PublishHelp> {
      return this.http({
         method: 'GET',
         url: `/api/publishes/${mediaId}/help`,
      });
   };

   postPublishHelp(mediaId: string, options: HelpResultOptions): Promise<PublishHelpResult> {
      return this.http({
         method: 'POST',
         url: `/api/publishes/${mediaId}/help`,
         data: { options },
      });
   };
}

ngModule.service('PublishHelpApi', PublishHelpApiAjs);

export default ngModule;
