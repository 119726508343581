<mat-form-field appearance="legacy" class="page-size-options">
   <mat-label>Items per page:</mat-label>
   <mat-select [(ngModel)]="pageSize" (selectionChange)="changePageSize($event.value)">
      <mat-option *ngFor="let pageSizeOption of pageSizeOptions" [value]="pageSizeOption.value">
         {{pageSizeOption.label}}
      </mat-option>
   </mat-select>
</mat-form-field>

<section class="results-numbers">
   {{start}} - {{finish}} of {{totalItems}}
</section>

<button
   *ngIf="!hideFirstLast"
   mat-button
   type="button"
   (click)="goToPage('first')"
   class="btn"
   [disabled]="(pageNumber === 0) || loading"
>
   <span class="material-icon">first_page</span>
</button>
<button
   mat-button
   type="button"
   (click)="goToPage('previous')"
   class="btn"
   [disabled]="(pageNumber === 0) || loading"
>
   <span class="material-icon">navigate_before</span>
</button>

<mat-form-field appearance="legacy" class="page-number-options">
   <mat-select [(ngModel)]="pageNumber" (selectionChange)="goToPage($event.value)">
      <mat-option *ngFor="let pageNumberOption of pageNumberOptions" [value]="pageNumberOption">
         {{pageNumberOption + 1}}
      </mat-option>
   </mat-select>
</mat-form-field>

<button
   mat-button
   type="button"
   (click)="goToPage('next')"
   class="btn"
   [disabled]="(pageNumber === pageNumberOptions.length - 1) || loading || nextPageDisabled"
>
   <span class="material-icon">navigate_next</span>
</button>

<button
   *ngIf="!hideFirstLast"
   mat-button
   type="button"
   (click)="goToPage('last')"
   class="btn"
   [disabled]="(pageNumber === pageNumberOptions.length - 1) || loading"
>
   <span class="material-icon">last_page</span>
</button>

