export class i18n {
   private readonly i18ns = {
      pbnotconn1: "Edge server",
      pbnotconn2: "not connected",
      mediaexpired: "Media has expired"
   };

   public i18n(text = 'unknown error'): string {
      for (const [key, value] of Object.entries(this.i18ns)) {
         text = text.replace("<"+key+">", value);
      }
      return text;
   };
}
