import { forkJoin, from, Observable, of, Subject } from 'rxjs';
import { concatMap, finalize, map, takeUntil, tap } from 'rxjs/operators';

import { NotificationService } from '../shared/services/notification.service';
import { PublishModule, PublishModuleMap } from '../shared/types';
import { Form } from '../shared/types/form.interface';
import { ErrorHandler } from '../shared/utils/error-handler';
import { SaveTransaction, WatchedObject } from '../shared/utils/save-transaction/save-transaction.factory';
import { Icon, InvalidReport, Location, Network, Node, PerformActionResult, PublishLocation, SourceDestinationModules } from './interfaces';
import { PublishingButton } from './publishing-button-api.factory';

interface MultiSelectionForm extends WatchedObject {
   name: string;
   schema: any;
   form: Form[];
   values: {[name: string]: any};
   nodes: Node[];
};

interface PublishingButtonsManagerScope extends IScope {
   search: { term: string };
   buttons: PublishingButton[];
   modules: PublishModuleMap;
   modelReady: boolean;
   iconUploading: boolean;
   keepForChoices: { value: number; label: string }[];
   networkDiagram: any;
   uiInNetworkMode: boolean;
   saveAfterIconUploaded: boolean;
   forms: Forms;
   invalidReport: InvalidReport;
   postModules: PublishModule[];
   icons: Icon[];
   selectedButton: PublishingButton;
   selectableInputModules: PublishModule[];
   multiSelection: Set<PublishingButton>;
   multiSelectionForms: MultiSelectionForm[];
   multiSelectionDirtyInterval: any;
   performAction: Promise<PerformActionResult>;
   postModule: PublishModule;
   getUploadIconUrl: string;
   networkDiagramEvents: any;
   selectedNetwork: Network;
   selectedNode: Node;
   libsLoading: boolean;
   _selectableLocationsCache: PublishLocation[];
   createButton(): void;
   addProcessNode(): void;
   openDeletePublishingButtonDialog(button: PublishingButton, event: JQLite): void | { title: string; description: string; extraDescription: string; buttons: PublishingButton[] };
   buttonLocationChange(location: PublishLocation): void;
   getSelectableLocations(button: PublishingButton): PublishLocation[];
   onSaveCreateClick(button: PublishingButton, event: JQLite): void;
   openCardMenu(button: PublishingButton): void;
   getLocationKey(location: Location): string;
   onDiscardClick(button: PublishingButton, event: JQLite): void;
   saveButton(): void;
   length(object: any): number;
   outputFormatList(button: PublishingButton): string[];
   canHaveMultipleProcessNodeFormats(button: PublishingButton): boolean;
   removePostNode(postNode: Node): void;
   addPostNode(): void;
   canAddPostNode(): boolean | number;
   getUploadError(file: { errorMessage: string }): string;
   deletePublishIcon(icon: Icon): void;
   saveButtonName(): void;
   canDeleteIcon(icon: Icon): boolean;
   deleteProcessNode(processNode: Node): void;
   canAddProcessNode(): boolean;
   canDeleteProcessNode(): boolean;
   setupNetworkGraph(network: Network): void;
   openNewPublishingButtonDialog(): void;
   selectButton(button: PublishingButton): void;
   multiSelectButton(button: PublishingButton): void;
   multiSelectionFormDoUpdates(form: MultiSelectionForm): void;
   cardDoubleClick(button: PublishingButton): void;
   openNetworkView(button: PublishingButton): void;
   iconSaveError(error?: string): void;
   cardBackgroundClick(): void;
   buttonProcessNodesChanged(button: PublishingButton, processNode?: Node): void;
   getNetworkMenu(menuParams: MenuParams): {title: string; list: {name: string; description: string}[]; menuAction: any} | any;
   launchDiscardChangesConfirm(button: PublishingButton): void;
   allModulesKnown(button: PublishingButton): boolean;
   cardClick(button: PublishingButton, event: JQLite): void;
   iconStartUpload(uploadFn: any): void;
   setButtonIcon(button: PublishingButton, iconId: string): void;
   saveIconToButton($message: string, button: PublishingButton): void;
   $onDestroy(): void;
}

interface NodeBox {
   attributes: NodeBoxAttributes;
   changed: { ports: Ports };
   cid: string;
   collection: any;
   graph: any;
   id: string;
   ports: any;
   getPorts(): PortItem[];
}

interface NodeBoxAttributes {
   id: string;
   type: string;
   angle: number;
   attrs: any;
   portMarkup: string;
   ports: Ports;
   position: { x: number; y: number };
   size: { width: number; height: number };
   z: number;
}

interface MenuParams {
   left: number;
   top: number;
}

interface Forms {
   selectedButtonForm?: any;
   nameForm?: any;
}

interface Ports {
   groups: any;
   items: PortItem[];
}

interface PortItem {
   id: string;
   group: string;
   args: any;
}

export const publishingButtonsManagerComponent = {
   templateUrl: 'publishing-buttons-template',
   controllerAs: 'vm',
   controller: ['$q', '$scope', '$modal', 'Page', 'SaveTransaction', 'PublishingButtonApi', 'AccountUsersApi',
               '_', 'DynamicSchema', '$timeout', '$document', 'pluralFilter', 'NotificationService',
               function($q: IQService, $scope: PublishingButtonsManagerScope, $modal: any, Page: Page, saveTransaction: SaveTransaction,
                        PublishingButtonApi: any, AccountUsersApi: any, _: Lodash, DynamicSchema, $timeout: ITimeoutService,
                        $document: IDocumentService, pluralFilter: (cnt: number, str: string) => string, notificationService: NotificationService
   ) {
      const vm: PublishingButtonsManagerScope = this;
      const modelPromises = [];
      let networkLibsEvaluated = false;
      vm.libsLoading = false;
      const destroy$ = new Subject<void>();

      vm.search = { term: '' }; // https://stackoverflow.com/questions/12618342/ng-model-does-not-update-controller-value
      vm.modelReady = false;
      vm.iconUploading = false;
      vm.saveAfterIconUploaded = false;
      vm.forms = {};
      vm.invalidReport = {};
      vm.buttons = null;
      vm.modules = null;
      vm.icons = null;
      vm.getLocationKey = PublishingButtonApi.getLocationKey;
      vm.selectedButton = null;
      vm.selectableInputModules = [];
      vm.postModules = null;
      vm.getUploadIconUrl = PublishingButtonApi.getUploadIconUrl;

      const daySeconds = 60*60*24;
      vm.keepForChoices = [{value: null, label: "Ever"}];
      // Start at 2 days because we don't expect 1 day to work very accurately
      for (let i=2; i<=30; i++) {
         vm.keepForChoices.push({value: i*daySeconds, label: i + " days"});
      }
      let roles = null;

      let locations: PublishLocation[] | null = null;
      let locationsByKey = null;

      vm.$onDestroy = () => {
         destroy$.next();
         destroy$.complete();
      };

      function getModule(moduleId: string): PublishModule {
         if (!(moduleId in vm.modules)) {
            vm.modules[moduleId] = {
               id: moduleId,
               name: "Unknown module '"+moduleId+"'",
               description: "Unknown module '"+moduleId+"'",
               isUnknown: true,
               selectableInputModuleIds: [],
               nonSelectableInputModuleIds: [],
               defaultInputModuleIds: [],
               minInputModules: 0,
               maxInputModules: null,
               allowedSecondaryDestinationIds: [],
               iconId: null,
               form: [{label: "Unknown module '"+moduleId+"'", type: "staticText"}]
               // Omit schema to keep any options unchanged
            };
         }
         return vm.modules[moduleId];
      }

      function discardChanges(): void {
         if (vm.selectedButton.id) {
            saveTransaction.rollbackObject(vm.selectedButton);
            vm.selectButton(vm.selectedButton);
         } else { // Discarding new/uncreated button
            _.remove(vm.buttons, {$$temporaryId: vm.selectedButton.$$temporaryId});
            vm.selectButton(null);
         }
      }

      function _addNewButton(modules: SourceDestinationModules): Promise<any> {
         const button = PublishingButtonApi.getNewButton(modules, getModule);
         vm.buttonProcessNodesChanged(button);
         return PublishingButtonApi.fetchButtonRoleRestrictions(button, roles).then((restrictions) => {
            button.restrictions = restrictions;
            vm.buttons.push(button);
            vm.selectButton(button);
         });
      }

      vm.allModulesKnown = button => {
         return !_.some(button.phases, phase =>
                        _.some(phase.phase.network.nodes, node =>
                                 getModule(node.moduleId).isUnknown));
      };

      vm.selectButton = button => {
         vm.selectedButton = button || null;
         vm.uiInNetworkMode = false;
         vm.selectedNode = null;
         if (!vm.selectedButton) return;
         vm.multiSelection = null;
         if (vm.multiSelectionDirtyInterval) {
            window.clearInterval(vm.multiSelectionDirtyInterval);
            vm.multiSelectionDirtyInterval = null;
         }

         if (vm.selectedButton.$$stratified) {
            const selectedDestinationModule = getModule(vm.selectedButton.primaryNetwork.destinationNode.moduleId);
            vm.selectableInputModules = selectedDestinationModule.selectableInputModuleIds
               .map(moduleId => getModule(moduleId));
         } else {
            vm.selectableInputModules = [];
         }

         vm.selectedButton.$$state = vm.selectedButton.$$state || (vm.selectedButton.id ? 'clean' : 'new');
         vm.selectedNetwork = vm.selectedButton.phases[0].phase.network;

         // If first time being selected then prepare (save) the button for comparison
         if (vm.selectedButton.$$saved === undefined) {
            saveTransaction.prepare(
               vm.selectedButton,
               [
                  'preNodes',
                  'processNodes',
                  'destinationNodes',
                  'postNodes',
                  'primaryNetwork',
                  'secondaryNetworks',
                  'restrictions',
                  'phases',
               ]
            );
         }
      };

      vm.multiSelectButton = button => {
         if (vm.multiSelectionDirtyInterval) {
            window.clearInterval(vm.multiSelectionDirtyInterval);
            vm.multiSelectionDirtyInterval = null;
         }
         if (vm.multiSelection.has(button)) {
            vm.multiSelection.delete(button);
            if (!vm.multiSelection.size) {
               vm.multiSelection = null;
               return;
            }
         } else {
            vm.multiSelection.add(button);
         }
         // Now we need to scan all the buttons to find any common components we can configure at once.
         var commonModules: { [moduleId: string]: Node[] } = null;
         vm.multiSelection.forEach(oneButton => {
            var intersectionModules = {};
            for (const [_nodeId, node] of Object.entries(oneButton.phases[0].phase.network.nodes) as [[string, Node]]) {
               if (commonModules === null) {
                  intersectionModules[node.moduleId] = [node];
               } else {
                  var nodeArray = commonModules[node.moduleId];
                  if (nodeArray) {
                     nodeArray.push(node);
                     intersectionModules[node.moduleId] = nodeArray;
                  }
               }
            }
            commonModules = intersectionModules;
         });
         const multiSelectionForms: MultiSelectionForm[] = [];
         for (const [moduleId, nodes] of Object.entries(commonModules)) {
            const commonValues = {};
            const disableFormItemsWithDifferingValues = (formItems: any[]) => {
               const modifiedForm = [];
               for (const formItem of formItems) {
                  const key = formItem.name;
                  if (formItem.type==="group") {
                     // we need to recursively do groups
                     modifiedForm.push({...formItem, items: disableFormItemsWithDifferingValues(formItem.items)});
                     continue;
                  }
                  // Now check that all the nodes have the same value for this
                  const value = nodes[0].options[key].value;
                  if (nodes.every(node => node.options[key].value === value)) {
                     commonValues[key] = {value};
                     modifiedForm.push(formItem);
                  } else {
                     commonValues[key] = {disabled: true, value: "VARIOUS"}; // weirdly our forms put disabled in values
                     modifiedForm.push({...formItem, readOnly: true});
                  }
               }
               return modifiedForm;
            };
            const form = {
               name: vm.modules[moduleId].name,
               schema: vm.modules[moduleId].schema,
               form: disableFormItemsWithDifferingValues(vm.modules[moduleId].form),
               values: commonValues,
               nodes: nodes,
            };
            saveTransaction.prepare(form, ["values"]);
            multiSelectionForms.push(form as MultiSelectionForm);
         }
         vm.multiSelectionDirtyInterval = saveTransaction.registerDirtyWatchers(() => multiSelectionForms, [], $scope);
         // The fbdn-tabs thing seems weirdly broken, and directly
         // changing the list of tabs results in old tabs not
         // disappearing and new ones being duplicates. So we do this
         // horrible ugly dancing hack to give it time to fix
         // itself. Please remove this when possible!
         vm.multiSelectionForms = [];
         window.setTimeout(() => vm.multiSelectionForms = multiSelectionForms, 100);
      };

      vm.multiSelectionFormDoUpdates = form => {
         for (const node of form.nodes) {
            for (const [key, value] of Object.entries(form.values)) {
               if (!value.disabled) {
                  node.options[key].value = value.value;
               }
            }
         }

         const promises: Promise<any>[] = [];
         const n = vm.multiSelection.size; // read this before we launch promises so we can use it in the message
         vm.multiSelection.forEach(oneButton => {
            promises.push(PublishingButtonApi.saveButton(oneButton));
         });

         Promise.all(promises).then(() => {
            notificationService.show({
               text: '' + n + ' publishing buttons saved',
               type: 'success'
            });
            saveTransaction.prepare(form, ["values"]);
         }).catch((error: string) => {
            notificationService.show({
               text: 'Unable to save ' + n + 'publishing buttons - ' + error,
               type: 'danger'
            });
         });
      };

      vm.launchDiscardChangesConfirm = button => {
         $modal.open({
            templateUrl: 'choice-modal-template',
            controller: 'ChoiceModalController',
            resolve: {
               asyncConfigGetter: () => () => {
                  const newButton = !(button && button.id);
                  return $q.when({
                     title: newButton ? 'Discard button' : 'Discard unsaved changes',
                     description: `Are you sure you want to discard ${newButton ? '' : 'all unsaved changes to'} this publishing button?`,
                     buttons: [
                        {
                           label: newButton ? 'Discard button' : 'Discard changes',
                           action: discardChanges,
                           style: 'primary',
                        },
                     ],
                  });
               },
            },
         }).result
            .then(() => closeCardMenu())
            .catch(ErrorHandler.swallowModalCloseErrors);
      };

      // As fbdn-contextmenu needs clicks to propagate to document
      // level, card clicks propagate to the background too, so use
      // this variable so only clicks that were not on a card will
      // clear the selection
      let clickWasOnCard = false;

      function closeCardMenu(): void {
         if (vm.selectedButton) {
            vm.selectedButton.$$cardMenuOpen = false;
         }
      }

      $document.on('click', event => {
         // Close if click is not on a card's menu trigger icon OR within the menu
         if (!event.target.matches('.publish-button .more-menu-trigger svg')
            && !event.target.matches('.publish-button .more-menu-trigger use')
            && !event.target.matches('.publish-button .more-menu li')
            && (vm.selectedButton && vm.selectedButton.$$cardMenuOpen)
         ) {
            closeCardMenu();
            // Required for clicks outside of this scope (e.g. on sidebar)
            $scope.$apply();
         }
      });

      vm.cardBackgroundClick = () => {
         if (!clickWasOnCard) {
            closeCardMenu();
            vm.selectButton(null);
         }
         clickWasOnCard = false;
      };

      let pendingSelectionTimeoutPromise = null;

      vm.cardClick = (button, event) => {
         clickWasOnCard = true;
         if (button !== vm.selectedButton) {
            closeCardMenu();
         }

         if (!vm.uiInNetworkMode) {
            if ((event.shiftKey || event.ctrlKey) && button !== vm.selectedButton) {
               if (!vm.multiSelection) {
                  vm.multiSelection = new Set();
                  if (vm.selectedButton) vm.multiSelection.add(vm.selectedButton);
               }
               vm.selectButton(null);
               vm.multiSelectButton(button);
               window.getSelection().removeAllRanges(); // clear the accidental shift-text selection
               return;
            }
            vm.selectButton(button);
            return;
         }
         if (button === vm.selectedButton) return;
         // If a card is zoomed, need to allow time for a possible
         // double click on the other card before we re-arrange the cards
         $timeout.cancel(pendingSelectionTimeoutPromise);
         pendingSelectionTimeoutPromise = $timeout(() => vm.selectButton(button), 500);
      };

      vm.openNetworkView = (button) => {
         vm.selectButton(button);
         vm.uiInNetworkMode = true;
         // In future might have multiple phases and an interface to switch through them,
         // or display as one very big network with groups. (Some jointjs support for this)
         // But for the moment, display only the one phase.
         vm.setupNetworkGraph(vm.selectedNetwork);
         $timeout(() => document.getElementById("card-" + vm.selectedButton.id).scrollIntoView());
      };

      vm.cardDoubleClick = button => {
         if (button === vm.selectedButton && vm.uiInNetworkMode) return;
         // Remove any accidental text selection that double click has made
         if (window.getSelection().empty) { // Chrome
            window.getSelection().empty();
         } else if (window.getSelection().removeAllRanges) { // Firefox
            window.getSelection().removeAllRanges();
         }
         $timeout.cancel(pendingSelectionTimeoutPromise);
         vm.openNetworkView(button);
      };
      const boxWidth = 100;
      const boxHeight = 50;
      const boxMargin = 30;

      function getReadableStream(reader: ReadableStreamDefaultReader<Uint8Array>): ReadableStream<Uint8Array> {
         return new ReadableStream({
            start(controller) {
               return pump();
               function pump() {
                  return reader.read().then(({ done, value }) => {
                     // When no more data needs to be consumed, close the stream
                     if (done) {
                        controller.close();
                        return;
                     }

                     // Enqueue the next data chunk into our target stream
                     controller.enqueue(value);
                     return pump();
                  });
               }
            }
         });
      }

      /*
         ⚠️ DANGER DANGER ⚠️
         This uses eval() on a fetch response.
         If the passed response could be data entered by a user, or is a response from a
         request to a domain we do not control, we have a serious security vulnerability.
         DO NOT CALL THIS WITH UNTRUSTED DATA
         ONLY EVER CALL THIS WITH DATA FROM STATIC FILES ON DOMAINS WE CONTROL
      */
      function unwrapTrustedFetchResponse(response: Response): Observable<void> {
         const reader = response.body.getReader();
         const readableStream = getReadableStream(reader);
         const streamResponse = new Response(readableStream);
         return from(streamResponse.blob())
            .pipe(
               concatMap(blob => from(blob.text())),
               map(blobText => eval.call(window, blobText)), // eslint-disable-line no-eval
            );
      }

      function evaluateNetworkLibs(
         fetchBackbone: Observable<Response>,
         fetchJoint: Observable<Response>,
      ): Observable<void> {
         return forkJoin([fetchBackbone, fetchJoint])
            .pipe(
               concatMap(([backboneResponse, jointResponse]) => {
                  return unwrapTrustedFetchResponse(backboneResponse).pipe(map(() => jointResponse));
               }),
               concatMap(jointResponse => unwrapTrustedFetchResponse(jointResponse)),
               notificationService.notifyOnError('loading the network graph'),
               tap(() => networkLibsEvaluated = true),
               finalize(() => vm.libsLoading = false),
               takeUntil(destroy$),
            );
      }

      vm.setupNetworkGraph = network => {
         vm.libsLoading = true;

         const evaluateLibs$ = networkLibsEvaluated
            ? of(null)
            : evaluateNetworkLibs(
               from(fetch('/furniture/js/lib/backbone.min.js')),
               from(fetch('/furniture/js/lib/joint.min.js')),
            );

         evaluateLibs$
            .subscribe(() => {
               vm.libsLoading = false;
               const graph = new joint.dia.Graph();
               // Make a template object used for all the boxes
               const moduleBoxTemplate = new joint.shapes.standard.Rectangle({
                  ports: {
                     groups: {
                        out: { position: { name: 'right', args: {} }, attrs: { '.port-body': { magnet: "true", r: "8" } } },
                        in: { position: { name: 'left', args: {} }, attrs: { '.port-body': { magnet: "passive", r: "5" } } }
                     },
                     items: []
                  },
                  portMarkup: '<circle class="port-body"/>'
               });
               moduleBoxTemplate.position(boxMargin, boxMargin);
               moduleBoxTemplate.resize(boxWidth, boxHeight);
               moduleBoxTemplate.attr({ body: { magnet: 'passive' } });
               // All nodes have an out port
               moduleBoxTemplate.addPort({ id: 'out', group: 'out', args: {} });

               // These variables hold the mapping between the jointjs model objects and our nodes
               const nodeBoxes = {}; // by nodeLocalId
               const nodesByBoxId = {};
               const connsByLinkId = {}; // holds [sourceLocalId, destLocalId] that link was added for (cf _connForLink function)
               const inputsByLinkId = {}; // which input port number link was added to
               const inputsInUse = {}; // by nodeLocalId

               const graphWidth = document.getElementById("cards").clientWidth - 40;
               let graphHeight = graphWidth / 2;
               const yStep = boxHeight + boxMargin;

               // we are going to lay the initial nodes out in 4 columns organised by type
               const typeColumn = { source: 0, process: 1, destination: 2, post: 3 };
               const columnHeights = { 0: 0, 1: 0, 2: 0, 3: 0 };
               _.forEach(_.sortBy(network.nodes, node => getModule(node.moduleId).name), node => {
                  const column = typeColumn[getModule(node.moduleId).type];
                  const yPos = columnHeights[column];
                  const xPos = (graphWidth - boxMargin) * column / 4 + yPos / 20; // very slight stagger to avoid links coinciding
                  columnHeights[column] += yStep;
                  graphHeight = Math.max(graphHeight, _.max(_.values(columnHeights)));
                  _addBoxForNode(node, xPos, yPos);
               });
               function _addBoxForNode(node: Node, x: number, y: number) {
                  x = _.clamp(x, 0, graphWidth - boxWidth - boxMargin);
                  y = _.clamp(y, 0, graphHeight - boxHeight - boxMargin);
                  const nodeBox = moduleBoxTemplate.clone();
                  nodeBox.translate(x, y);
                  const module = getModule(node.moduleId);
                  // Seems to be no way to get joint to add custom class to top element,
                  // but can add attributes to sub-elements, and there is no class to overwrite
                  nodeBox.attr("body", { class: module.type });
                  nodeBox.attr("label", { class: module.type });
                  nodeBox.attr('label/text', joint.util.breakText(module.name, { width: boxWidth }));
                  nodeBox.attr('rect/title', module.description);
                  nodeBox.addTo(graph);
                  nodeBoxes[node.nodeLocalId] = nodeBox;
                  nodesByBoxId[nodeBox.id] = node;
                  inputsInUse[node.nodeLocalId] = [];
               }

               function _removeNode(menuEntry, menuParams: MenuParams): void {
                  const elRect = vm.networkDiagram.el.getBoundingClientRect();
                  const models = graph.findModelsFromPoint({ x: menuParams.left - elRect.left, y: menuParams.top - elRect.top });
                  if (!models.length) return;
                  const modelId = models[0].id;
                  const node = nodesByBoxId[modelId];
                  const nodeBox = nodeBoxes[node.nodeLocalId];
                  nodeBox.remove();
                  PublishingButtonApi.networkRemoveNode(network, node);
                  delete nodeBoxes[node.nodeLocalId];
                  delete nodesByBoxId[modelId];
                  delete inputsInUse[node.nodeLocalId];
                  _removeExtraUnusedInputPorts();
                  _networkChanged();
               }

               function _addNode(module: PublishModule, menuParams: MenuParams): void {
                  const elRect = vm.networkDiagram.el.getBoundingClientRect();
                  const node = {
                     moduleId: module.id,
                     options: DynamicSchema.defaultValuesForSchema(module.schema),
                     nodeLocalId: PublishingButtonApi.networkNextNodeLocalId(network)
                  };
                  network.nodes[node.nodeLocalId] = node;
                  _addBoxForNode(node, menuParams.left - elRect.left - boxWidth / 2, menuParams.top - elRect.top - boxHeight / 2);
                  _createExtraUnusedInputPorts();
                  _networkChanged();
               }

               const removeMenu = { title: 'Node', list: [{ name: "Remove node", description: "" }], menuAction: _removeNode };

               const addMenus = [
                  { title: 'Sources', list: _.sortBy(_.filter(vm.modules, { type: 'source', selectable: true }), 'name'), menuAction: _addNode },
                  { title: 'Formats', list: _.sortBy(_.filter(vm.modules, { type: 'process', selectable: true }), 'name'), menuAction: _addNode },
                  { title: 'Destinations', list: _.sortBy(_.filter(vm.modules, { type: 'destination', selectable: true }), 'name'), menuAction: _addNode },
                  { title: 'Post', list: _.sortBy(_.filter(vm.modules, { type: 'post', selectable: true }), 'name'), menuAction: _addNode }
               ];

               // To avoid angular loops we must continually return the same array object in the same context
               vm.getNetworkMenu = (menuParams: MenuParams) => {
                  const elRect = vm.networkDiagram.el.getBoundingClientRect();
                  const models = graph.findModelsFromPoint({ x: menuParams.left - elRect.left, y: menuParams.top - elRect.top });
                  if (models.length && nodesByBoxId[models[0].id]) {
                     return removeMenu;
                  } else {
                     const column = _.clamp(Math.floor((menuParams.left - elRect.left) * 4 / graphWidth), 0, 3);
                     return addMenus[column];
                  }
               };

               function defaultLink( /*cellView, magnet*/): any {
                  const link = new joint.dia.Link();
                  link.set('router', { name: 'metro' });
                  return link;
               }

               function _setupLinkEvents(link: any): void{
                  const conn = connsByLinkId[link.id];
                  const inputNumber = inputsByLinkId[link.id];
                  link.on('remove', () => {
                     _.pull(network.connections, conn);
                     _.pull(inputsInUse[conn[1]], inputNumber);
                     _networkChanged();
                     _removeExtraUnusedInputPorts();
                  });
               }

               _.forEach(network.connections, conn => {
                  const link = defaultLink();
                  link.source({ id: nodeBoxes[conn[0]].id, port: "out" });
                  const portNumber = inputsInUse[conn[1]].length + 1;
                  inputsInUse[conn[1]].push(portNumber);
                  nodeBoxes[conn[1]].addPort({ id: "" + portNumber, group: 'in', args: {} });
                  link.target({ id: nodeBoxes[conn[1]].id, port: "" + portNumber });
                  link.addTo(graph);
                  connsByLinkId[link.id] = conn;
                  inputsByLinkId[link.id] = portNumber;
                  _setupLinkEvents(link);
               });

               function _createExtraUnusedInputPorts(): void {
                  _.forEach(network.nodes, node => {
                     const module = getModule(node.moduleId);
                     const nodeBox = nodeBoxes[node.nodeLocalId];
                     while (_inPortNumbers(nodeBox).length < module.minInputModules ||
                        _inPortNumbers(nodeBox).length === inputsInUse[node.nodeLocalId].length && (module.maxInputModules === null ||
                        module.maxInputModules > inputsInUse[node.nodeLocalId].length)) {
                        const portNumber = 1 + (_inPortNumbers(nodeBox).length ? Math.max(..._inPortNumbers(nodeBox)) : 0);
                        nodeBox.addPort({ id: portNumber.toString(), group: 'in', args: {} });
                     }
                  });
               }

               function _removeExtraUnusedInputPorts(): void {
                  _.forEach(network.nodes, node => {
                     const nodeBox = nodeBoxes[node.nodeLocalId];
                     while (_inPortNumbers(nodeBox).length > inputsInUse[node.nodeLocalId].length + 1) {
                        const portNumber = _inPortNumbers(nodeBox).length ? Math.max(..._inPortNumbers(nodeBox)) : 0;
                        nodeBox.removePort({ id: portNumber.toString(), group: 'in', args: {} });
                     }
                  });
               }

               function _inPortNumbers(nodeBox: NodeBox): number[] {
                  return nodeBox.getPorts()
                     .filter(port => port.group === 'in' )
                     .map(inPort => +inPort.id);
               }

               _createExtraUnusedInputPorts();

               // Add a new connection between two nodes, and arrange the
               // order so it is the correct relatively ordered target input
               function _addConnection(targetInputNumber: number, conn: [number, number]): void {
                  const connections = network.connections;
                  const wantedAfter = _.filter(inputsInUse[conn[1]], x => x < targetInputNumber).length;
                  // Need to work out where to insert conn so that it is
                  // after the right number of other input connections to
                  // the same target
                  let pos = 0;
                  let afterCount = 0;
                  while (afterCount < wantedAfter && pos < connections.length) {
                     if (connections[pos][1] === conn[1]) {
                        afterCount += 1;
                     }
                     pos += 1;
                  }
                  connections.splice(pos, 0, conn);
                  inputsInUse[conn[1]].push(targetInputNumber);
               }

               function _networkChanged(initialSetup: boolean = false): void {
                  // Change the vm variable inside an angular event so it is seen
                  $timeout(() => {
                     vm.selectedButton.hasBadNetwork = PublishingButtonApi.validateNetwork(network, getModule).join("; "); // empty string is falsey in js
                     _updateComplexNetworkNodes(vm.selectedButton);
                     _applyDefaultOptions(vm.selectedButton);
                     // For simplicity, any change at all using the network UI blocks the stratified UI
                     if (vm.selectedButton.$$stratified && !initialSetup) {
                        vm.selectedButton.$$stratified = false;
                        vm.selectedButton.hasComplicatedNetwork = "Network mode was used to change the network";
                     }
                  });
               }

               // Should we use an angular deep watch instead of calling manually?
               _networkChanged(true);

               function _connForLink(link: any): [number, number] {
                  // Gets the current [sourceLocalId, destLocalId] from graph link state, or null if unlinked (cf connsByLinkId)
                  if (!(link.get('source') && link.get('source').id && link.get('target') && link.get('target').id)) {
                     return null;
                  }
                  return [nodesByBoxId[link.get('source').id].nodeLocalId,
                           nodesByBoxId[link.get('target').id].nodeLocalId];
               }

               let draggingLink = null;
               graph.on('change:source change:target', (link) => {
                     // This event happens continuously as the link is dragged about.
                     // We will update its appearance when it is a valid connection
                     link.attr({ '.connection': { 'stroke-dasharray': _connForLink(link) ? null : "5,5" } });
                     if (draggingLink !== link) {
                        draggingLink = link; // save the link for use of the batch:stop event.
                     }
                  });
               let selectedCell = null;
               graph.on('batch:start', batch => {
                  if (batch.batchName === 'pointer' && batch.cell && nodesByBoxId[batch.cell.id]) {
                     $timeout(() => vm.selectedNode = nodesByBoxId[batch.cell.id]); // angular update needs a digest
                     if (selectedCell) {
                        selectedCell.attr("rect/stroke-dasharray", null);
                     }
                     selectedCell = batch.cell;
                     selectedCell.attr('rect/stroke-dasharray', "5,5");
                  }
               });
               graph.on('batch:stop', (batch: { batchName: string }) => {
                  // Not using "add-link" batch as although batch.cell is
                  // the object from which the link was created, seems to
                  // be no way to get hold of the actual link from this
                  // event. So we hackily use the last link we saw the
                  // change events on and the pointer batch event,
                  // which applies both to new and changed links
                  if (batch.batchName === 'pointer' && draggingLink) {
                     const oldConn = connsByLinkId[draggingLink.id];
                     const oldInputNumber = inputsByLinkId[draggingLink.id];
                     const newConn = _connForLink(draggingLink);
                     if (!oldConn && newConn) {
                        // newly created link.
                        const newInputNumber = +draggingLink.get('target').port;
                        _addConnection(newInputNumber, newConn);
                        connsByLinkId[draggingLink.id] = newConn;
                        inputsByLinkId[draggingLink.id] = newInputNumber;
                        _setupLinkEvents(draggingLink);
                        _createExtraUnusedInputPorts();
                        _networkChanged();
                     } else if (!newConn) {
                        // Make links that did not snap to a target just
                        // disappear. Why is this not default, or at least an option?
                        draggingLink.remove(); // remove event will remove connection of a previously live one
                     } else if (newConn[0] !== oldConn[0] || newConn[1] !== oldConn[1]) {
                        _.pull(network.connections, oldConn);
                        _.pull(inputsInUse[oldConn[1]], oldInputNumber);
                        const newInputNumber = +draggingLink.get('target').port;
                        _addConnection(+newInputNumber, newConn);
                        connsByLinkId[draggingLink.id] = newConn;
                        inputsByLinkId[draggingLink.id] = newInputNumber;
                        _createExtraUnusedInputPorts();
                        _removeExtraUnusedInputPorts();
                        _networkChanged();
                     }
                     draggingLink = null;
                  }
               });

               function validateConnection(cellViewS, magnetS, cellViewT, magnetT): boolean {
                  // Prevent linking from input ports.
                  if (magnetS && magnetS.getAttribute('port-group') === 'in') return false;
                  // Prevent linking from output ports to input ports within one element.
                  if (cellViewS === cellViewT) return false;
                  // Only allow linking to input ports.
                  if (!(magnetT && magnetT.getAttribute('port-group') === 'in')) return false;
                  const sourceNode = nodesByBoxId[cellViewS.model.id];
                  const targetNode = nodesByBoxId[cellViewT.model.id];
                  if (!sourceNode || !targetNode) return false;
                  const sourceModule = getModule(sourceNode.moduleId);
                  const targetModule = getModule(targetNode.moduleId);
                  // Must be legal module connection
                  if (!targetModule.selectableInputModuleIds.includes(sourceModule.id) && !targetModule.nonSelectableInputModuleIds.includes(sourceModule.id)) return false;
                  // Disallow existing connections
                  if (_.find(network.connections, conn => conn[0] === sourceNode.nodeLocalId && conn[1] === targetNode.nodeLocalId)) return false;
                  // Disallow re-using input ports
                  const portId = magnetT.getAttribute('port');
                  if (inputsInUse[targetNode.nodeLocalId].includes(+portId)) return false;
                  // If relocating an end of an existing connection, then
                  // the above code will not allow you to put it back on
                  // the same node. I am not going to worry about that
                  // now, but maybe relocating to different port should
                  // be possible.
                  return true;
               }

               vm.networkDiagram = {
                  width: graphWidth,
                  height: graphHeight,
                  gridSize: 1,
                  model: graph,
                  defaultLink: defaultLink,
                  defaultRouter: joint.routers.metro,
                  validateConnection: validateConnection,
                  // Enable marking available cells & magnets
                  markAvailable: true,
                  snapLinks: { radius: 15 },
                  preventContextMenu: false,
                  restrictTranslate: true, // prevent dragging off side
                  linkPinning: false // option to prevent links connected to paper
               };
               vm.networkDiagramEvents = {
                  // All events got registered on particular objects above,
                  // or are angular js events instead
               };
            });
      };

      function _getNodesByType(button: PublishingButton, type: any): Node[] {
         const nodes = [];
         _.forEach(button.phases, phase => {
            _.forEach(phase.phase.network.nodes, node => {
               if (getModule(node.moduleId).type === type) {
                  nodes.push(node);
               }
            });
         });
         return nodes;
      }

      function _applyDefaultOptions(button: PublishingButton): void {
         _.forEach(button.phases, phase => {
            _.forEach(phase.phase.network.nodes, node => {
               const module = vm.modules[node.moduleId];
               if (!module) return; // Ignore modules which have been disabled since button creation
               DynamicSchema.defaultValuesForSchema(module.schema, node.options);
            });
         });
      }

      function _updateComplexNetworkNodes(button: PublishingButton): void {
         button.preNodes = _getNodesByType(button, 'source');
         button.processNodes = _getNodesByType(button, 'process');
         button.destinationNodes = _getNodesByType(button, 'destination');
         button.postNodes = _getNodesByType(button, 'post');
      }

      modelPromises.push(PublishingButtonApi.fetchModules('edl').then((modules: PublishModuleMap) => {
         vm.modules = modules;
         vm.postModules = _.filter(modules, (module: PublishModule) => module.type === 'post');
      }));

      modelPromises.push(PublishingButtonApi.fetchButtons(true).then((buttons: PublishingButton[]) => {
         vm.buttons = buttons;
      }));

      modelPromises.push(PublishingButtonApi.fetchIcons().then((icons: Icon[]) => {
         vm.icons = icons;
      }));

      modelPromises.push((Page.accountId ? PublishingButtonApi.fetchAccountLocations() : PublishingButtonApi.fetchSiteLocations()).then((accountOrSiteLocations: any[]) => {
         locations = accountOrSiteLocations;
         locationsByKey = _.keyBy(accountOrSiteLocations, PublishingButtonApi.getLocationKey);
      }));

      modelPromises.push(AccountUsersApi.getAccountRoles().then((accountRoles) => {
         roles = accountRoles;
      }));

      $q.all(modelPromises)
         .then(() => {
            vm.buttons.forEach(button => {
               if (!button.$$stratified) {
                  _updateComplexNetworkNodes(button);
               }
               _applyDefaultOptions(button);
               PublishingButtonApi.fetchButtonRoleRestrictions(button, roles).then(restrictions => {
                  button.restrictions = restrictions;
               });
            });

            saveTransaction.registerDirtyWatchers(() => vm.buttons, [], $scope);
            vm.modelReady = true;
         })
         .catch((error: string) => {
            notificationService.show({
               text: 'Error reading publishing button configuration: ' + error,
               neverRemove: true,
               type: 'danger'
            });
         });

      vm.buttonLocationChange = ({id, type}) => {
         const location = { id, type };
         vm.selectedButton.location = location;
         vm.selectedButton.phases[0].location = location;
      };

      vm.getSelectableLocations = button => {
         // Allow the button's current location to be selectable as long as it is a legal location
         const buttonLocationKey = button.location ? vm.getLocationKey(button.location) : '';
         if (!vm._selectableLocationsCache) { // Cache to avoid template function call infinite loop
            vm._selectableLocationsCache = _.filter(locations, l => l.selectable || vm.getLocationKey(l) === buttonLocationKey);
         }
         return vm._selectableLocationsCache;
      };

      function isDisabled(elem: JQLite): boolean {
         return angular.element(elem).hasClass('disabled');
      }

      vm.openCardMenu = button => {
         if (button !== vm.selectedButton) {
            closeCardMenu();
            vm.selectButton(button);
         }

         button.$$cardMenuOpen = !button.$$cardMenuOpen;
      };

      vm.onSaveCreateClick = (button, event) => {
         if (isDisabled(event.target)) return;

         if (button !== vm.selectedButton) {
            vm.selectButton(button);
         }

         if (button.id) {
            vm.saveButton();
         } else {
            vm.createButton();
         }

         closeCardMenu();
      };

      vm.onDiscardClick = (button, event) => {
         if (isDisabled(event.target)) return;
         vm.launchDiscardChangesConfirm(button);
      };

      vm.openNewPublishingButtonDialog = () => {
         closeCardMenu();

         $modal.open({
            templateUrl: 'new-publishing-button-dialog-template',
            controller: 'NewPublishingButtonDialogController',
            resolve: {
               sourceModules: () => _.filter(vm.modules, (module: PublishModule) => module.type === 'source' && module.selectable),
               destinationModules: () => _.filter(vm.modules, (module: PublishModule) => module.type === 'destination' && module.selectable)
            }
         }).result
            .then(_addNewButton)
            .catch(angular.noop); // closed from user clicking outside popover
      };

      vm.openDeletePublishingButtonDialog = (button, event) => {
         if (isDisabled(event.target)) return;

         $modal.open({
            templateUrl: 'choice-modal-template',
            controller: 'ChoiceModalController',
            resolve: {
               asyncConfigGetter: () => () => {
                  return PublishingButtonApi.fetchButtonStatus(button)
                     .then(status => {
                        let extraDescription: string;
                        if (status.numberOfPublishedItems > 0) {
                           extraDescription = `Deleting this publishing button will move the \
                              ${pluralFilter(status.numberOfPublishedItems, 'associated published item')} to the Recycle Bin.`;

                           if (button.enabled) {
                              extraDescription += `\nTo hide this button but keep the existing published \
                                 ${pluralFilter(status.numberOfPublishedItems, 'item')} you can disable the button.`;
                           }
                        }

                        const buttons = [
                           { label: 'Delete', style: 'danger', action: deleteButton.bind(this, button) },
                        ];

                        if (button.enabled && status.numberOfPublishedItems > 0) {
                           buttons.push({ label: 'Disable', style: 'primary', action: disableButton.bind(this, button) });
                        }

                        return {
                           title: 'Delete publishing button',
                           description: 'Are you sure you want to delete this button?',
                           extraDescription: extraDescription,
                           buttons: buttons,
                        };
                     });
               },
            },
         }).result
            .then(() => closeCardMenu())
            .catch(ErrorHandler.swallowModalCloseErrors);
      };

      vm.addProcessNode = () => {
         const network = vm.selectedNetwork;
         const selectedDestinationNode = vm.selectedButton.primaryNetwork.destinationNode;
         const destinationModule = getModule(selectedDestinationNode.moduleId);
         const processModuleId = destinationModule.selectableInputModuleIds[0];
         const processNode: Node = {
            moduleId: processModuleId,
            options: DynamicSchema.defaultValuesForSchema(getModule(processModuleId).schema),
            nodeLocalId: PublishingButtonApi.networkNextNodeLocalId(network)
         };
         vm.selectedButton.processNodes.push(processNode);
         network.nodes[processNode.nodeLocalId] = processNode;
         network.connections.push([processNode.nodeLocalId, selectedDestinationNode.nodeLocalId]);
         vm.buttonProcessNodesChanged(vm.selectedButton, processNode);
         if (getModule(processModuleId).minInputModules) {
            _.forEach(vm.selectedButton.preNodes, preNode => network.connections.push([preNode.nodeLocalId, processNode.nodeLocalId]));
         }
      };

      vm.canDeleteProcessNode = () => {
         if (!vm.selectedButton.$$stratified) return false;
         const selectedDestinationModule = getModule(vm.selectedButton.primaryNetwork.destinationNode.moduleId);
         return selectedDestinationModule.minInputModules === null
            || (vm.selectedButton.processNodes.length > selectedDestinationModule.minInputModules);
      };

      vm.canAddProcessNode = () => {
         if (!vm.selectedButton.$$stratified) return false;
         const selectedDestinationModule = getModule(vm.selectedButton.primaryNetwork.destinationNode.moduleId);
         return selectedDestinationModule.maxInputModules === null
            || (vm.selectedButton.processNodes.length < selectedDestinationModule.maxInputModules);
      };

      vm.deleteProcessNode = (processNode) => {
         const index = vm.selectedButton.processNodes.indexOf(processNode);
         if (index !== -1) {
            vm.selectedButton.processNodes.splice(index, 1);
         }
         vm.buttonProcessNodesChanged(vm.selectedButton);
         PublishingButtonApi.networkRemoveNode(vm.selectedNetwork, processNode);
      };

      vm.createButton = () => {
         if (!vm.forms.selectedButtonForm.$valid || (vm.forms.nameForm && !vm.forms.nameForm.$valid)) return;
         PublishingButtonApi.createButton(vm.selectedButton).then(() => {
            return PublishingButtonApi.saveButtonRoleRestrictions(vm.selectedButton);
         }).then(() => {
            notificationService.show({
               text: 'Publishing button created',
               type: 'success'
            });
         }, (error: string) => {
            notificationService.show({
               text: 'Unable to create publishing button - '+error,
               type: 'danger'
            });
         });
      };

      vm.saveButton = () => {
         if (!vm.forms.selectedButtonForm.$valid || (vm.forms.nameForm && !vm.forms.nameForm.$valid)) return;
         if (vm.iconUploading) {
            vm.saveAfterIconUploaded = true;
            return;
         }
         PublishingButtonApi.saveButton(vm.selectedButton).then(() => {
            return PublishingButtonApi.saveButtonRoleRestrictions(vm.selectedButton);
         }).then(() => {
            notificationService.show({
               text: 'Publishing button saved',
               type: 'success'
            });
         }).catch((error: string) => {
            notificationService.show({
               text: 'Unable to save publishing button - ' + error,
               type: 'danger'
            });
         });
      };

      function deleteButton(button: PublishingButton): void {
         vm.selectButton(null);
         PublishingButtonApi.deleteButton(button, vm.buttons)
            .then(() => {
               notificationService.show({
                  text: 'Publishing button deleted',
                  type: 'success'
               });
            })
            .catch((error: string) => {
               notificationService.show({
                  text: 'Unable to delete publishing button - '+error,
                  type: 'danger'
               });
            });
      }
      vm.canDeleteIcon = icon => icon.from === 'account' && !_.some(vm.buttons, { iconId: icon.id });

      vm.deletePublishIcon = icon => {
         PublishingButtonApi.deletePublishIcon(icon);
         delete vm.icons[icon.id];
      };

      function disableButton(button: PublishingButton) {
         button.enabled = false;
         PublishingButtonApi.saveEnabled(button).then(() => {
            notificationService.show({
               text: 'Publishing button disabled',
               type: 'success'
            });
         }, (error: string) => {
               notificationService.show({
                  text: 'Unable to disable publishing button - ' + error,
                  type: 'danger'
               });
            });
      }

      vm.saveButtonName = () => {
         PublishingButtonApi.saveButtonName(vm.selectedButton).then(() => {
               notificationService.show({
                  text: 'Publishing button name saved',
                  type: 'success'
               });
            }, (error: string) => {
               notificationService.show({
                  text: 'Unable to save publishing button name - ' + error,
                  type: 'danger'
               });
            });
      };

      vm.canAddPostNode = () => {
         if (!vm.selectedButton.$$stratified) return false;
         return vm.postModules.length;
      };

      vm.addPostNode = () => {
         const network = vm.selectedNetwork;
         const module = vm.postModules[0];
         const node = {
            moduleId: module.id,
            options: DynamicSchema.defaultValuesForSchema(module.schema),
            nodeLocalId: PublishingButtonApi.networkNextNodeLocalId(network)
         };
         vm.selectedButton.postNodes.push(node);
         network.nodes[node.nodeLocalId] = node;
         const selectedDestinationNode = vm.selectedButton.primaryNetwork.destinationNode;
         network.connections.push([selectedDestinationNode.nodeLocalId, node.nodeLocalId]);
      };

      vm.removePostNode = (postNode) => {
         _.pull(vm.selectedButton.postNodes, postNode);
         PublishingButtonApi.networkRemoveNode(vm.selectedNetwork, postNode);
      };

      vm.length = (object) => _.keys(object).length;

      vm.performAction = PublishingButtonApi.performAction;

      function validateLocation(): void {
         if (!vm.selectedButton) return;
         if (!vm.forms.selectedButtonForm || !vm.forms.selectedButtonForm.location) return;
         vm.invalidReport = {};
         const location = locationsByKey[vm.getLocationKey(vm.selectedButton.location)];
         if (!location) {
            vm.invalidReport[''] = 'unknown-location';
            return;
         }
         if (location.type === 'cloud' || !location.publishingModuleVersions) {
            // Cloud is always ok, disconnected edge server we will have to assume is ok
            return;
         }
         _.forEach(vm.selectedButton.processNodes, (processNode) => {
            const moduleId = processNode.moduleId;
            const module = getModule(moduleId);
            if (!(moduleId in location.publishingModuleVersions)) {
               vm.invalidReport[moduleId] = 'not-installed';
            } else if (location.publishingModuleVersions[moduleId] < module.version) {
               vm.invalidReport[moduleId] = 'needs-upgrade';
            }
         });
      }

      $scope.$watch('forms.selectedButtonForm.location', validateLocation);
      $scope.$watch('selectedButton.location', validateLocation);
      $scope.$watch('selectedButton.primaryNetwork.processNodes', validateLocation, true);

      function defaultIconId(button: PublishingButton): any {
         const destinationModule = getModule(button.primaryNetwork.destinationNode.moduleId);
         const processNodeModule = !button.primaryNetwork.processNodes.length ? null : getModule(button.primaryNetwork.processNodes[0].moduleId);
         return (hasMultipleProcessNodeFormats(button) || destinationModule.useDefaultIconEvenIfSingleInputModule || !processNodeModule || processNodeModule.iconId === null) && destinationModule.iconId !== null
            ? destinationModule.iconId
            : processNodeModule.iconId;
      }

      vm.buttonProcessNodesChanged = (button, processNode) => {
         const isStandardIcon = button.iconId && button.iconId[0] === '_';
         if (!button.iconId || isStandardIcon) {
            button.iconId = defaultIconId(button);
         }

         const minInputModules = !button.primaryNetwork.processNodes.length ? 0 :
            _.max(_.map(button.primaryNetwork.processNodes, (node: Node) => getModule(node.moduleId).minInputModules));

         const network: Network = button.phases[0].phase.network;

         // Always 0 or 1 at the moment
         if (button.preNodes.length > minInputModules) {
            button.__hiddenSource = button.preNodes.pop();
            delete network.nodes[button.__hiddenSource.nodeLocalId];
            _.remove(network.connections, connection => connection[0] === button.__hiddenSource.nodeLocalId);
         }

         if (button.preNodes.length < minInputModules) {
            if (!button.__hiddenSource) {
               const sourceModule = _.find(vm.modules, module => module.type === 'source' && module.selectable);
               button.__hiddenSource = {
                  moduleId: sourceModule.id,
                  options: DynamicSchema.defaultValuesForSchema(sourceModule.schema),
                  nodeLocalId: PublishingButtonApi.networkNextNodeLocalId(network),
               };
            }
            network.nodes[button.__hiddenSource.nodeLocalId] = button.__hiddenSource;
            button.preNodes.push(button.__hiddenSource);
         }

         if (processNode) {
            // keep only options that are still legal for new module
            const module = getModule(processNode.moduleId);
            if ("schema" in module) {
               processNode.options = DynamicSchema.coerceValuesToSatisfySchema(module.schema, processNode.options);
            }
         }
      };

      vm.saveIconToButton = ($message, button) => {
         const savedIcon = angular.fromJson($message).results;
         if (!savedIcon.id) {
            return vm.iconSaveError();
         }
         vm.icons[savedIcon.id] = savedIcon;
         button.iconId = savedIcon.id;
         vm.iconUploading = false;
         if (vm.saveAfterIconUploaded) {
            vm.saveButton();
            vm.saveAfterIconUploaded = false;
         }
      };

      vm.iconSaveError = (error) => {
         vm.iconUploading = false;
         if (vm.saveAfterIconUploaded) {
            notificationService.show({
               text: 'Unable to save publishing button - ' + error,
               type: 'danger'
            });
            vm.saveAfterIconUploaded = false;
         }
      };

      vm.setButtonIcon = (button, iconId) => {
         button.iconId = iconId;
      };

      vm.iconStartUpload = (uploadFn) => {
         vm.iconUploading = true;
         uploadFn();
      };

      vm.getUploadError = (file) => {
         if (!file) return '';
         return file.errorMessage ? angular.fromJson(file.errorMessage).message : '';
      };

      function hasMultipleProcessNodeFormats(button: PublishingButton): boolean {
         const uniqueIds = _.uniqBy(button.primaryNetwork.processNodes, (processNode: Node) => processNode.moduleId);
         return uniqueIds.length > 1;
      }

      vm.canHaveMultipleProcessNodeFormats = (button) => getModule(button.primaryNetwork.destinationNode.moduleId).selectableInputModuleIds.length > 1;

      vm.outputFormatList = (button) => _.map(button.primaryNetwork.processNodes, (processNode: Node) => getModule(processNode.moduleId).name);
   }],
};
