import { Component, Input, OnChanges, OnDestroy, Inject, HostListener, SimpleChanges, ElementRef, ContentChildren, ContentChild, QueryList, AfterContentInit } from '@angular/core';
import { getStyle } from '../../utils/style-utils';

interface PieArc {
   clas: string;
   sx: number;
   sy: number;
   largeArcSweep: string;
   dx: number;
   dy: number;
   argsAsString: string;
}

export type ProportionTupleArray = [[status: string, proportion: number]];

@Component({
   selector: 'bb-pie-ring',
   templateUrl: './pie-ring.component.svg',
   styleUrls: ['./pie-ring.component.scss'],
})
export class PieRingComponent implements OnDestroy, OnChanges, AfterContentInit {
   @Input() proportions: ProportionTupleArray;
   @Input() proportion: number;
   @Input() dangerIfAbove: number | null;
   @Input() scale: number;

   @ContentChildren("PieRingItem") contentChildren!: QueryList<PieRingComponent>;
   @ContentChild('PieRingContainer', { read: ElementRef }) pieRingContainer: ElementRef;


   public arcs: PieArc[];
   public size: number;
   public center: number;
   public radius: number;
   public viewBox: string;

   private cwidth=0;
   private cheight=0;
   private strokeWidth: number;
   private afterContentInit = false;

   constructor(
      private elementRef: ElementRef,
   ) {}


   @HostListener('window:resize')
   onResize() {
      this.refreshComponentDimensions();
      this.draw();
   };

   ngOnChanges(changes: SimpleChanges) {
      for (const propName in changes) {
         if (changes.hasOwnProperty(propName)) {
            this.draw();
         }
      }
   }

   ngOnDestroy() {
      this.draw();
   }

   public ngAfterContentInit(): void {
      this.afterContentInit=true;
      this.refreshComponentDimensions();
      this.draw();
   }

private refreshComponentDimensions(): void {
   const holderElement = this.elementRef.nativeElement as HTMLElement;
   const domRect = holderElement.getBoundingClientRect();
   this.cwidth = Math.round(domRect.width);
   this.cheight = Math.round(domRect.height);
   const sw = getStyle(holderElement, 'stroke-width');
   this.strokeWidth = (sw !== null) ? parseInt(sw.replace(/[^0-9]+/g,""), 10) : 6;
}

   private draw() {
      if ( this.afterContentInit ) {
         this.size = Math.min(this.cwidth, this.cheight);
         this.center = this.size / 2.0;
         this.radius = (this.size - this.strokeWidth) / 2.0;
         if (!this.proportions) {
            const status = this.dangerIfAbove !== null && this.proportion >= this.dangerIfAbove ? 'danger' : 'standard';
            this.proportions = [[status, this.proportion]];
         }
         const scale = this.scale || 1;

         let sscaled = 0;
         let sx = Math.round(this.size / 2.0);
         let sy = Math.round(this.strokeWidth / 2.0);
         this.arcs = this.proportions.map(entry => {
            const clas = entry[0];
            const proportion = entry[1];
            const scaled = Math.max(Math.min(proportion/scale, 0.9999), 0);
            const radians = scaled * Math.PI * 2;
            const dx = Math.round(this.size / 2.0 + this.radius * Math.sin(radians));
            const dy = Math.round(this.strokeWidth / 2.0 + this.radius - this.radius * Math.cos(radians));
            const largeArcSweep = scaled - sscaled > 0.5 ? '1' : '0';
            const ret = {
               clas: clas,
               sx: sx,
               sy: sy,
               largeArcSweep: scaled-sscaled > 0.5 ? '1' : '0',
               dx: dx - sx,
               dy: dy - sy,
               argsAsString: `M ${sx} ${sy} A ${this.radius} ${this.radius} 0 ${largeArcSweep} 1 ${dx} ${dy}`,
            };
            sx = dx;
            sy = dy;
            sscaled = scaled;
            return ret;
         });
      }
   }

}
