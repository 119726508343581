interface Config {
   width: number;
   height: number;
   url: string;
}

export const popup: Injectable<Function> = ['$window', '$q', function($window, $q) { // eslint-disable-line @typescript-eslint/ban-types
   var deferreds = {};

   $window.PopupHandleResult = (deferredId, result) => {
      var deferred = deferreds[deferredId];
      deferred.resolve(result);
      delete deferreds[deferredId];
   };

   function open(config: Config, deferredId: string): Promise<any> {
      deferreds[deferredId] = $q.defer();
      var left = ($window.screen.width - config.width) / 2;
      var top = ($window.screen.height - config.height) / 2;
      $window.open(config.url, '_blank', 'width=' + config.width + ',height=' + config.height + ',top=' + top + ',left=' + left);
      return deferreds[deferredId].promise;
   }

   return {
      open: open
   };

}];
