/* Angular svg icons bank factory
 *
 * Creates an icon bank at the end of <body> that contains all icons accessible, grouped per
 * container. All containers are defined in global Map "sources", which associates a container
 * name (string) to an url that points to the correspondig svg icon stack file and a container
 * promise which, once resolved, returns the DOM element of the corresponding icon container.
 *
 * An icon can be shown with tag <fbdn-icon icon="[icon]"></fbdn-icon>, where [icon] refers to
 * the element id in the svg icon stack that refers to the icon itself. The best way to do it
 * consists in creating a frame with a tag that contains the icon, and use css to give a size
 * to both the frame and the icon (where the frame is at least as big as the icon); note that
 * the frame can be the fbdn-icon itself, as shown in the example below. The SVG icon uses the
 * maximal space given to the fbdn icon tag but never more, and is responsible to preserve its
 * aspect ratio. Below is an example of HTML/CSS code to use icon "stethLeft" from bank
 * "controlCentre". Note that, because of the use of css custom properties, a recent enough scss
 * processor is required; in particular, ruby-sass_3.3.6-1 fails to process such a scss file but
 * ruby-sass_3.5.6-1 succeeds.
 *
 * .fbdn-icon-frame {
 *    width: 1em;
 *    height: 1em;
 *    svg {
 *       width: 1em;
 *       height: 1em;
 *    }
 * }
 *
 * fbdn-icon[icon="icon-steth"] {
 *    --fbdn-icon-bank: controlCentre;
 *    --fbdn-icon-graphic: stethLeft;
 * }
 *
 * <fbdn-icon class="fbdn-icon-frame" icon="icon-steth"></fbdn-cc-icon>
 *
 */
import { NotificationService } from '../../services/notification.service';
import ServicesModule from '../../services/services.module';
import IconBankModule, { IconBankService } from './icon-bank.service';

const ngModule = angular.module('bb.icons', [
   IconBankModule.name,
   ServicesModule.name,
]);

const fbdnIconComponent: IComponentOptions = {
   bindings: {
      icon: '@',
   },
   // Inline template required to use this component within angular11+ component templates
   // https://stackoverflow.com/questions/41348403/ngupgrade-unable-to-use-templateurl-when-upgrading-angular1-components
   template: `
      <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMin meet" viewBox="0 0 28 28">
         <use href="{{vm.iconReference}}"></use>
      </svg>
   `,
   controllerAs: 'vm',
   controller: class FbdnIconController {
      static $inject = ['NotificationService', 'IconBankService'];

      /* Input */ public icon: string;

      public iconReference: string;

      constructor(
         private notificationService: NotificationService,
         private iconBankService: IconBankService,
      ) {}

      $onChanges() {
         const {promise, details} = this.iconBankService.getIcon(this.icon);

         promise.then(iconContainer => {
            const iconElement = iconContainer.querySelector("#" + details.iconGraphic);

            if (!iconElement) {
               this.notificationService.show({
                  text: "Could not find icon '" + details.iconGraphic + "' from icon bank '" + details.bankName + "'",
                  neverRemove: true,
                  type: 'danger'
               });
               return;
            }

            this.iconReference = '#' + iconElement.id;
         }).catch((error: string) => {
            this.notificationService.show({
               text: "Error: " + error,
               neverRemove: true,
               type: 'danger'
            });
         });
      }
   }
};

ngModule.component('fbdnIcon', fbdnIconComponent);

export default ngModule;
