<div *ngIf="modelReady">
   <mat-dialog-title class="new-button-dialog-title">
      Add Publishing Button
   </mat-dialog-title>
   <mat-dialog-content>
      <form [formGroup]="newButtonMap">
         <div *ngIf="sourceModules.length > 1" class="top-spacing">
            <mat-form-field appearance="outline">
               <mat-label>Source Processing</mat-label>
               <mat-select formControlName="sourceModule">
                  <mat-option [value]="module" *ngFor="let module of sourceModules">
                     {{ module.name }}
                  </mat-option>
               </mat-select>
            </mat-form-field>
         </div>
         <div *ngIf="destinationModules.length > 1" class="top-spacing">
            <mat-form-field appearance="outline">
               <mat-label>Destination</mat-label>
               <mat-select formControlName="destinationModule">
                  <mat-option [value]="module" *ngFor="let module of destinationModules">
                     {{ module.name }}
                  </mat-option>
               </mat-select>
            </mat-form-field>
         </div>
      </form>
   </mat-dialog-content>
   <mat-dialog-actions align="end" class="new-button-dialog-actions">
      <button mat-raised-button (click)="onDismiss()">Cancel</button>
      <button mat-raised-button color="primary" (click)="onConfirm()" [disabled]="invalidForm()">Create</button>
   </mat-dialog-actions>
</div>
