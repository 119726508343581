import { Component, Inject, OnInit } from "@angular/core";
import { NotificationData } from "../../api/notifications.interface";
import { NotificationApiService } from "../../api/notifications.api.service";
import { Observable } from "rxjs";
import { ConnectionPositionPair } from "@angular/cdk/overlay";
import { AlertService } from "../../services/alert.service";
import { Notification, NotificationType } from '../../types';

@Component({
    selector: 'bb-tab-notifications',
    templateUrl: './tab-notifications.component.html',
    styleUrls: ['./tab-notifications.component.scss',],
 })
 export class TabNotificationsComponent implements OnInit {
   public isOpen: boolean;
   public notifications$: Observable<NotificationData[]>;
   public alerts: Record<NotificationType, Observable<Notification[]>>;
   public totalAlerts$: Observable<number>;
   public totalAlertsByType: Record<NotificationType, Observable<number>>;
   positionPairs: ConnectionPositionPair[] = [
      {
        offsetX: 77,
        offsetY: -43,
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'bottom',
        panelClass: null,
      },
    ];
   constructor(
      @Inject('User') private currentUser: User,
      private notificationApiService: NotificationApiService,
      private alertService: AlertService,
   ){
      this.isOpen = false;
   }
   ngOnInit(): void {
      this.notifications$ = this.notificationApiService.fetchNotifications(this.currentUser.id);
      this.alerts = this.alertService.getNotifications();
      this.totalAlerts$ = this.alertService.getTotalAlertsCount();
      this.totalAlertsByType = this.alertService.getTotalAlertsCountByType();
   }

   public acknowledgeNotification(notification: NotificationData): void {
      this.notificationApiService.acknowledgeNotification(this.currentUser.id, notification);
   }

   public dismissAlert(notification: Notification): void {
      this.alertService.remove(notification);
   }
 }
