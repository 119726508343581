import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { PublishHelp, HelpResultOptions, PublishHelpResult } from './publish-help.interface';


@Injectable({ providedIn: 'root' })
export class PublishHelpApiService {
   constructor(private http: HttpClient) {}

   public getPublishHelp(mediaId: string): Observable<PublishHelp> {
      return this.http.get<PublishHelp>(`/api/publishes/${mediaId}/help`);
   }

   public postPublishHelp(mediaId: string, options: HelpResultOptions): Observable<PublishHelpResult> {
      return this.http.post<PublishHelpResult>(`/api/publishes/${mediaId}/help`, { options });
   }
}
