<div class="btn-group fbdn-button-justified">
   <a
      class="fbdn-button fbdn-button-horizontal fbdn-button-body btn btn-default btn-drop-main"
      target="_blank"
      href="/accounts/{{accountName}}/webupload"
      title="Upload media or documents"
   >
      <fbdn-icon class="button-icon" icon="upload"></fbdn-icon>
      Upload
   </a>
   <div class="fbdn-dropdown dropdown" bbMenuDirection="mouseenter">
      <button type="button" class="fbdn-dropdown-toggle dropdown-toggle btn btn-default btn-drop-main" dropdown-toggle></button>
      <ul class="dropdown-menu" role="menu">
         <li>
            <a target="_blank" href="/accounts/{{accountName}}/webupload">
               Upload media
            </a>
         </li>
         <li>
            <a target="_blank" href="/accounts/{{accountName}}/webupload">
               Upload documents
            </a>
         </li>
         <li>
            <a target="_blank" href="/accounts/{{accountName}}/metaupload">
               Upload metadata
            </a>
         </li>
      </ul>
   </div>
</div>
