<div *ngIf="state ==='loading' && !dataSource?.length" class="content-loading">
   <fbdn-icon icon="loading"></fbdn-icon>
</div>
<div *ngIf="dataSource?.length">
   <mat-table [dataSource]="dataSource" class="mat-elevation-z0">
      <ng-container matColumnDef="delete">
         <th mat-header-cell *matHeaderCellDef></th>
         <td mat-cell *matCellDef="let i=index">
            <button class="btn-link format-remove" (click)="remove(i)">
               <span class="material-icon">
                  cancel
               </span>
            </button>
         </td>
      </ng-container>
      <ng-container matColumnDef="rate">
         <th mat-header-cell *matHeaderCellDef> Frame Rate </th>
         <td mat-cell *matCellDef="let element">
            <div class="form-cell">
               <mat-form-field appearance="outline">
                  <mat-select [(value)]="element.standard" (selectionChange)="selectionChanged()">
                     <mat-option *ngFor="let option of standards" [value]="option.id">{{option.name}}</mat-option>
                  </mat-select>
               </mat-form-field>
            </div>
         </td>
      </ng-container>
      <ng-container matColumnDef="ratio">
         <th mat-header-cell *matHeaderCellDef> Aspect Ratio</th>
         <td mat-cell *matCellDef="let element">
            <div class="form-cell">
               <mat-form-field appearance="outline">
                  <mat-select [(value)]="element.aspect" (selectionChange)="selectionChanged()">
                     <mat-option *ngFor="let option of aspects" [value]="option.id">{{ option.name }}</mat-option>
                  </mat-select>
               </mat-form-field>
            </div>
         </td>
      </ng-container>
      <ng-container matColumnDef="resolution">
         <th mat-header-cell *matHeaderCellDef> Proxy Resolution </th>
         <td mat-cell *matCellDef="let element">
            <div class="form-cell">
               <mat-form-field appearance="outline">
                  <mat-select [(value)]="element.resolution" (selectionChange)="selectionChanged()">
                     <mat-option *ngFor="let option of resolutions.get(element.aspect)" [value]="option.id">{{ option.name }}</mat-option>
                  </mat-select>
               </mat-form-field>
            </div>
         </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
   </mat-table>
</div>
<button mat-raised-button class="add-format-button" color="primary" (click)="add()">Add format</button>
<div class="save-format" *ngIf="saveEnabled && state !== 'submitting'">
   <button mat-raised-button color="primary" type="submit" (click)="save()">
      Save Changes
   </button>
   <button mat-stroked-button color="primary" (click)="discardChanges()">
      Discard Changes
   </button>
</div>
