import { ChangeDetectionStrategy, Compiler, Component, Injector, OnInit } from '@angular/core';

import { SiteAccountsComponent } from './site-accounts.component';

// This XXXPageComponent exists as a lazy-loading mechanism for a page's module while we
// are in MPA structure and cannot therefore use Angular's router.
@Component({
   selector: 'bb-site-accounts-page',
   template: `
      <ng-container *ngIf="loaded">
         <!-- Once loaded, instantiate the component (start the component tree here) -->
         <ng-container *ngComponentOutlet="component"></ng-container>
      </ng-container>
   `,
   styles: [':host { display: block; height: 100%; }'],
   changeDetection: ChangeDetectionStrategy.Default,
})
export class SiteAccountsPageComponent implements OnInit {
   public loaded = false;
   public component: typeof SiteAccountsComponent;

   constructor(
      private compiler: Compiler,
      private injector: Injector,
   ) {}

   async ngOnInit() {
      /* Statically import the page's module.
       * This import() statement will be parsed by Angular's static analysis during the build and
       * generate the module bundle. Angular inserts the module script into the HTML at runtime
       * (does not appear as a script tag in our HTML, or in the dist index.html).
       * https://angular.io/guide/lazy-loading-ngmodules
       * https://www.typescriptlang.org/docs/handbook/release-notes/typescript-2-4.html#dynamic-import-expressions
       */
      const { SiteAccountsAppModule: module } = await import('./site-accounts.app.module');
      // Grab the root component of the page's component tree
      this.component = module.rootComponent;
      const factory = await this.compiler.compileModuleAsync(module);
      // Instantiate the module with the Angular injector
      factory.create(this.injector);
      this.loaded = true;
   }
}
