<div *ngIf="state ==='loading'" class="content-loading">
   <fbdn-icon icon="loading"></fbdn-icon>
</div>
<div *ngIf="state === 'ready'">
   <div class="form-group">
      <mat-radio-group class="radio-group">
         <mat-radio-button [value]="true" (change)="onChangeInherit($event)" [checked]="model.autoInherit">{{ pageContext === "Site" ? "Default (inherit from cloud)" : "Default (inherit from site)" }}</mat-radio-button>
         <mat-radio-button [value]="false" (change)="onChangeInherit($event)" [checked]="!model.autoInherit">Custom</mat-radio-button>
      </mat-radio-group>
   </div>
   <hr />
   <bb-meta-columns-editor
      [context]="columnsContext"
      [suggestions]="model.suggestions"
      [newColumn]="model.newMetaColumn"
      [(forceRefresh)]="forceRefresh"
      [(columns)]="model.metaColumns"
      [(saveEnabled)]="saveEnabled"
      [disabled]="state !== 'ready' || model.autoInherit"
   ></bb-meta-columns-editor>

   <div *ngIf="!model.metaColumns.length" class="has-error">
      <p class="help-block" >
         You cannot have an empty list of metadata columns
      </p>
   </div>

   <div class="form-group submit-group form-control-group">
      <div class="save-columns" *ngIf="saveEnabled && state !== 'submitting'">
         <button mat-raised-button color="primary" type="submit" (click)="saveMetaColumns()">
            Save Changes
         </button>
         <button mat-stroked-button color="primary" (click)="discardChanges()">
            Discard Changes
         </button>
      </div>
   </div>
</div>
