import { Coordinate } from './coordinate.interface';

export const FbdnDragSelect: InjectableDirectiveFactory =  ['$window', '$document', function($window, $document) {
   return {
      restrict: 'A',
      link: function(scope: IScope, element: JQLite) {
         var PRESS_EVENTS = 'touchstart mousedown';
         var RELEASE_EVENTS = 'touchend mouseup';
         var MOVE_EVENTS = 'touchmove mousemove';
         var ACTIVE_CLASS = 'drag-select-active';

         var body = angular.element($document[0].body);
         var overlay = angular.element('<div class="drag-select-overlay"></div>');
         body.append(overlay);

         var startCoords = null;

         element.on(PRESS_EVENTS, function(e) {
            startCoords = coords(e);
            body.on(MOVE_EVENTS, onMove);
            body.addClass(ACTIVE_CLASS);
         });

         body.on(RELEASE_EVENTS, function() {
            body.off(MOVE_EVENTS, onMove);
            body.removeClass(ACTIVE_CLASS);
            overlay.css('display', 'none');
         });

         function coords(e: JQueryMouseEventObject): Coordinate {
            return {
               x: e.pageX,
               y: e.pageY
            };
         }

         function onMove(e: JQueryMouseEventObject): void {
            var currentCoords = coords(e);
            var topLeft = {
               x: $window.Math.min(startCoords.x, currentCoords.x),
               y: $window.Math.min(startCoords.y, currentCoords.y)
            };
            var bottomRight = {
               x: $window.Math.max(startCoords.x, currentCoords.x),
               y: $window.Math.max(startCoords.y, currentCoords.y)
            };
            drawRectangle(topLeft, bottomRight);
            scope.$broadcast('dragSelect::move', topLeft, bottomRight);
            scope.$apply();
         }

         function drawRectangle(topLeft: Coordinate, bottomRight: Coordinate): void {
            overlay.css({
               display: 'block',
               left: topLeft.x,
               top: topLeft.y,
               width: bottomRight.x - topLeft.x,
               height: bottomRight.y - topLeft.y
            });
         }
      }
   };
}];
