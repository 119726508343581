import { NavigationService } from './navigation.service';

// This is in a separate file so navigation.service.ts can be imported into
// components with tests without requiring global angular to be defined. Other
// services may work without doing this because their types are interfaces
// not classes (i.e. do not exist at runtime)

const ngModule = angular.module('bb.utils.navigation', [])
   .service('NavigationService', NavigationService);

export default ngModule;
