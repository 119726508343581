import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { MatTable } from '@angular/material/table';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { ValueLabel } from '../../types';
import { AutocompleteOptionGroup } from '../autocomplete-item/autocomplete-option-group.interface';
import { ColumnContext, LoggingColumn, MetaColumnsDto } from '../types/metadata-column.interface';
import { ColumnSuggestion, MetadataSuggestion } from '../types/suggestion.interface';


@Component({
   selector: 'bb-meta-columns-editor',
   templateUrl: './meta-columns-editor.component.html',
   styleUrls: ['./meta-columns-editor.component.scss'],
})
export class MetaColumnsEditorComponent implements OnInit, OnChanges {
   @Input() context: ColumnContext;
   @Input() suggestions: MetadataSuggestion[];
   @Input() newColumn: MetaColumnsDto;
   @Input() disabled: boolean;
   @Input() columns: MetaColumnsDto[];
   @Output() columnsChange = new EventEmitter<MetaColumnsDto[]>();
   @Input() saveEnabled: boolean;
   @Output() saveEnabledChange = new EventEmitter<boolean>();
   @Input() forceRefresh: boolean;
   @Output() forceRefreshChange = new EventEmitter<boolean>();
   @ViewChild(MatTable) columnsTable: MatTable<MetaColumnsDto[]>;

   public dataSource: MetaColumnsDto[] | undefined;
   public displayedColumns: string[];
   public filteredOptions$: Observable<AutocompleteOptionGroup[]>;

   ngOnInit(): void {
      this.dataSource = this.columns;
      if (this.context === 'file-columns') {
         this.displayedColumns = ['key', 'displayName', 'hidden', 'searchable', 'editable', 'delete'];
      }
      else {
         this.displayedColumns = ['key', 'displayName', 'hidden', 'title', 'presets', 'delete'];
      }
   }

   ngOnChanges(changes: SimpleChanges): void {
      if (changes.forceRefresh && changes.forceRefresh.currentValue) {
         this.dataSource = this.columns;
         this.refresh();
         this.forceRefresh = false;
         this.forceRefreshChange.emit(this.forceRefresh);
      }
   }

   public selectKey(event: MatSelectChange, i: number): void {
      if (event.value !== this.dataSource[i].internalName) {
         this.dataSource[i].internalName = event.value;
         this.propagateChanges();
      }
   }

   public changePresets(event: Event, i: number): void {
      // This function is called from within the presets field, so we know it is a LoggingColumn
      const loggingColumn = this.dataSource[i] as LoggingColumn;
      const input = event.target as HTMLInputElement;
      const splitPresets = input.value.split(',');
      if (splitPresets.length !== loggingColumn.presets.length || !splitPresets.every((value, index) => value === loggingColumn.presets[index])) {
         loggingColumn.presets = [...splitPresets];
         this.propagateChanges();
      }
   }

   public removeColumn(atIndex: number): void {
      this.dataSource.splice(atIndex, 1);
      this.refresh();
      this.propagateChanges();
   }

   public addNewColumn(): void {
      this.dataSource.push({ ...this.newColumn });
      this.refresh();
      this.propagateChanges();
   }

   public autocompleteFormChange(position: number, value: string): void {
      let suggestionFound = false;
      this.dataSource[position].internalName = value;
      this.suggestions.forEach(element => {
         element.children.forEach(child => {
            if (child.internalName === value) {
               this.dataSource[position].displayName = child.displayName;
               this.dataSource[position].hidden = child.hidden;
               suggestionFound = true;
            }
         });
      });
      if (!suggestionFound) {
         this.dataSource[position].displayName = value;
      }

      this.filteredOptions$ = of(this.suggestions).pipe(
         map(suggestions => suggestions.map(suggestion => this.filterGroup(suggestion, value)))
      );

      this.refresh();
      this.propagateChanges();
   }

   public propagateChanges(): void {
      this.saveEnabled = true;
      this.saveEnabledChange.emit(this.saveEnabled);
      this.columnsChange.emit(this.columns);
   }

   private refresh(): void {
      this.columnsTable?.renderRows();
   }

   private filterChildren(child: ColumnSuggestion, value: string): ValueLabel {
      if (child.displayName.toLowerCase().includes(value) || child.internalName.toLowerCase().includes(value)) {
         return {
            value: child.internalName,
            label: child.displayName,
         };
      }
   }

   private filterGroup(suggestion: MetadataSuggestion, value: string): AutocompleteOptionGroup {
      value = value.toLowerCase();
      const filteredChildren = suggestion.children
         .map(child => this.filterChildren(child, value))
         .filter(vl => !!vl);
      return {
         groupName: suggestion.groupName,
         children: filteredChildren,
      };
   }
}
