import { createReducer, on } from '@ngrx/store';
import { EdgeServer, EdgeServerType } from '../../edge-servers/types';
import {
   createEdge,
   createEdgeFail,
   createEdgeSuccess,
   deleteEdge,
   deleteEdgeFail,
   deleteEdgeSuccess,
   fetchEdge, loadEdges,
   loadEdgesFail,
   loadEdgesSuccess,
   removeEdgeFromStore,
   saveEdge, saveEdgeFail,
   saveEdgeName, saveEdgeSuccess,
   upsertEdge,
   updateEdgeName
} from './edge-servers.actions';


export interface EdgeServersState {
   edges: EdgeServer[];
   edgeTypes: EdgeServerType[];
   persistEdgeLoading: boolean;
   loading: boolean;
   error: string | null;
}

export const initialState: EdgeServersState = {
   edges: [],
   edgeTypes: [],
   persistEdgeLoading: false,
   loading: false,
   error: null,
};

export const edgeServersReducer = createReducer(
   initialState,

   on(loadEdges, (state) => ({ ...state, loading: true })),
   on(loadEdgesSuccess, (state, { edges, edgeTypes }) => ({ ...state, loading: false, error: null, edges, edgeTypes })),
   on(loadEdgesFail, (state, { errorMessage }) => ({ ...state, loading: false, error: errorMessage })),

   on(fetchEdge, (state) => ({ ...state })),

   on(saveEdge, (state) => ({ ...state, persistEdgeLoading: true })),
   on(saveEdgeSuccess, (state, { edge }) => ({ ...state, persistEdgeLoading: false, error: null })),
   on(saveEdgeFail, (state) => ({ ...state, persistEdgeLoading: false })),

   on(createEdge, (state) => ({ ...state, persistEdgeLoading: true })),
   on(createEdgeSuccess, (state, {edge}) => ({
      ...state,
      edges: [...state.edges, edge],
      persistEdgeLoading: false,
   })),
   on(createEdgeFail, (state) => ({ ...state, persistEdgeLoading: false })),

   on(upsertEdge, (state, { edge }) => {
      const edges = [...state.edges];
      const edgeIndex = edges.findIndex(e => e.id === edge.id);
      if (edgeIndex === -1) {
         edges.push(edge);
      } else {
         edges[edgeIndex] = edge;
      }
      return { ...state, edges };
   }),

   on(deleteEdge, (state) => ({ ...state })),
   on(deleteEdgeSuccess, (state) => ({ ...state })),
   on(deleteEdgeFail, (state) => ({ ...state })),
   on(removeEdgeFromStore, (state, { id }) => ({
      ...state,
      edges: state.edges.filter(e => e.id !== id),
   })),

   on(saveEdgeName, (state) => ({ ...state })),
   on(updateEdgeName, (state, { id, name }) => {
      const edges = [...state.edges];
      const edgeIndex = edges.findIndex(e => e.id === id);
      edges[edgeIndex] = { ...edges[edgeIndex], name };
      return { ...state, edges };
   }),
);
