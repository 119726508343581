import { Component, OnInit, Input, Inject, OnDestroy } from '@angular/core';
import { EditorState } from '../types/editor-state.type';
import { PageContext, ColumnContext, MetaColumnsDto, FileColumn, LoggingColumn } from '../types/metadata-column.interface';
import { ColumnSuggestion, MetadataSuggestion } from '../types/suggestion.interface';
import { ColumnsApiService } from '../../services/columns.api.service';
import { Subject, forkJoin } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatRadioChange } from '@angular/material/radio';
import { AlertService } from '../../services/alert.service';

interface MetaColumnsModel {
   autoInherit: boolean;
   metaColumns: MetaColumnsDto[];
   defaultMetaColumns: MetaColumnsDto[];
   customMetaColumns: MetaColumnsDto[];
   suggestions: MetadataSuggestion[];
   newMetaColumn: MetaColumnsDto;
}

@Component({
   selector: 'bb-meta-columns',
   templateUrl: './meta-columns.component.html',
   styleUrls: ['./meta-columns.component.scss',],
})
export class MetaColumnsComponent implements OnInit, OnDestroy {
   @Input() pageContext: PageContext;
   @Input() columnsContext: ColumnContext;

   public state: EditorState = 'loading';
   public saveEnabled = false;
   public forceRefresh = false;
   public model: MetaColumnsModel;
   private destroy$ = new Subject<void>();
   private originalCustomColumns: MetaColumnsDto[] = [];
   private originalAutoInherit: boolean;

   constructor(
      private alertService: AlertService,
      @Inject('_') private _: Lodash,
      private columnsApiService: ColumnsApiService,
   ) {
      this.model = {
         customMetaColumns: [],
         defaultMetaColumns: [],
         metaColumns: [],
         suggestions: [],
         autoInherit: true,
         newMetaColumn: {
            displayName : '',
            internalName: '',
            hidden: false,
            editable: false,
            searchable: false,
            title: false,
            media: false,
            presets: [],
         },
      };
   }

   ngOnInit() {
      this.resetColumns();
   }

   ngOnDestroy() {
      this.destroy$.next();
      this.destroy$.complete();
   }

   public onChangeInherit(event: MatRadioChange): void {
      this.model.autoInherit = event.value;
      if (this.model.autoInherit) {
         this.model.metaColumns = this.model.defaultMetaColumns;
      } else {
         this.model.metaColumns = this.model.customMetaColumns;
      }
      this.forceRefresh = true;
      this.saveEnabled = this.contentChanged();
   }

   public discardChanges(): void {
      this.model.autoInherit = this.originalAutoInherit;
      if (this.model.autoInherit) {
         this.model.customMetaColumns = [...this.model.defaultMetaColumns];
         this.model.metaColumns = this.model.defaultMetaColumns;
      } else {
         this.model.customMetaColumns = [...this.originalCustomColumns];
         this.model.metaColumns = this.model.customMetaColumns;
      }
      this.saveEnabled = false;
      this.forceRefresh = true;
   }

   public saveMetaColumns(): void {
      const emptyColumns = !this.model.metaColumns.length || this.model.metaColumns.some(column => !column.displayName.length || !column.internalName.length);
      const duplicates = this.hasDuplicatedRows();
      if (emptyColumns || duplicates) {
         this.alertService.show({
            text: emptyColumns ? 'Metadata incomplete' : 'Metadata duplicated',
            type: 'warning',
         });
      } else {
         if (this.model.autoInherit) {
            this.model.customMetaColumns = [];
            this.model.defaultMetaColumns.forEach(element => this.model.customMetaColumns.push({...element}));
         }
         this.state = 'submitting';
         const metaColumns = this.model.metaColumns.map(column => {
            return this.columnsContext === 'file-columns'
               ? this._.omit(column, ['presets', 'title', 'media']) as FileColumn
               : this._.omit(column, ['searchable', 'editable']) as LoggingColumn;
         });
         this.columnsApiService.setColumns(this.pageContext, this.columnsContext, metaColumns, this.model.autoInherit)
               .pipe(
                  this.alertService.notifyOnError('saving columns'),
                  takeUntil(this.destroy$),
               )
               .subscribe(() => {
                  this.saveEnabled = false;
                  this.forceRefresh = true;
                  this.originalCustomColumns = [];
                  this.model.customMetaColumns.forEach(element => this.originalCustomColumns.push({...element}));
                  this.originalAutoInherit = this.model.autoInherit;
                  this.state = 'ready';
                  this.alertService.show({
                     text: 'Metadata columns have been saved successfully',
                     type: 'success',
                  });
            });
      }
   }

   private hasDuplicatedRows(): boolean {
      const displayNameArr = this.model.metaColumns.map(item => item.displayName);
      const internalNameArr = this.model.metaColumns.map(item => item.internalName);
      return (displayNameArr.some((item, index) => displayNameArr.indexOf(item) !== index))
         || internalNameArr.some((item, index) => internalNameArr.indexOf(item) !== index);
   }

   private resetColumns(): void {
      this.state = 'loading';
      forkJoin({
         metaColumns: this.columnsApiService.getColumns(this.pageContext, this.columnsContext),
         fileColumns: this.columnsApiService.getFileColumns(this.pageContext),
         defaultColumns: this.columnsApiService.getDefaultColumns(this.pageContext, this.columnsContext),
      })
         .pipe(
            this.alertService.notifyOnError('loading metadata'),
            takeUntil(this.destroy$),
         )
         .subscribe(({metaColumns, fileColumns, defaultColumns}) => {
            if (this.columnsContext === 'file-columns') {
               metaColumns.columns.forEach(metaColumn => this.model.customMetaColumns.push({...metaColumn, title:false, media:false, presets:[]}));
               defaultColumns.columns.forEach(defaultColumn => this.model.defaultMetaColumns.push({...defaultColumn, title:false, media:false, presets:[]}));
               this.model.suggestions.push({ groupName: '', children: this.columnsApiService.commonFileColumns });
            } else {
               metaColumns.columns.forEach(metaColumn => this.model.customMetaColumns.push({...metaColumn, hidden:false, searchable:false, editable:false}));
               defaultColumns.columns.forEach(defaultColumn => this.model.defaultMetaColumns.push({...defaultColumn, hidden:false, searchable:false, editable:false}));
               this.model.suggestions.push({ groupName: '', children: this.columnsApiService.commonLoggingColumns });
               const fileColumnsSuggestions: ColumnSuggestion[] = [];
               fileColumns.columns.forEach(element => fileColumnsSuggestions.push({internalName: element.internalName, displayName: element.displayName, hidden: element.hidden}));
               this.model.suggestions.push({ groupName: 'File columns', children: fileColumnsSuggestions});
            }
            this.model.autoInherit = metaColumns.autoInherit;
            if (this.model.autoInherit) {
               this.model.metaColumns = this.model.defaultMetaColumns;
               if (!this.model.customMetaColumns.length) { // init custom columns when first run
                  this.model.defaultMetaColumns.forEach(element => this.model.customMetaColumns.push({...element}));
               }
            } else {
               this.model.metaColumns = this.model.customMetaColumns;
            }
            this.model.customMetaColumns.forEach(element => this.originalCustomColumns.push({...element}));
            this.originalAutoInherit = this.model.autoInherit;
            this.state = 'ready';
            this.saveEnabled = false;
         });
      this.model.newMetaColumn = (this.columnsContext === 'file-columns')
         ? this.columnsApiService.addFileColumnDefaults()
         : this.columnsApiService.addLoggingColumnDefaults();
   }

   private contentChanged(): boolean {
      const objectsEqual = (o1, o2) => {
         return typeof o1 === 'object' && Object.keys(o1).length > 0
            ? Object.keys(o1).length === Object.keys(o2).length
               && Object.keys(o1).every(p => objectsEqual(o1[p], o2[p]))
            : o1 === o2;
      };
      return this.originalAutoInherit !== this.model.autoInherit ||
         this.originalCustomColumns.length !== this.model.customMetaColumns.length ||
         this.originalCustomColumns.every((o, idx) => objectsEqual(o, this.model.customMetaColumns[idx]));
   }
};
