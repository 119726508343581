import { createAction, props } from '@ngrx/store';

import { DirtyEntity } from '../../../shared/types';
import { EditableEdgeValues } from '../../edge-server-details/edge-server-settings';
import { EdgeServerTemplate } from '../../types';


export const addNewEdgeTemplate = createAction(
   '[Edge Cards] Add New Edge Template',
);
export const addTemporaryEdge = createAction(
   '[Edge Cards] Add Temporary Edge',
   props<{edgeTemplate: EdgeServerTemplate}>(),
);
export const removeTemporaryEdge = createAction(
   '[Edge Cards] Remove Temporary Edge',
   props<{id: string}>(),
);
export const removeFromEdgesInEdit = createAction(
   '[Edge Cards] Remove Temporary Edge From Edges In Edit',
   props<{id: string}>(),
);
export const removeFromDirtyEdges = createAction(
   '[Edge Cards] Remove Temporary Edge From Dirty Edges',
   props<{id: string}>(),
);

export const updateTemporaryEdgeName = createAction(
   '[Edge Cards] Update Temporary Edge Name',
   props<{id: string; name: string}>(),
);

export const upsertEdgeInEdit = createAction(
   '[Edge Cards] Upsert Edge In Edit',
   props<{id: string; value: EditableEdgeValues}>(),
);
export const updateEdgeDirtiness = createAction(
   '[Edge Cards] Update Edge Dirtiness',
   props<DirtyEntity>(),
);
export const updateAdvancedOptionsOpenStatus = createAction(
   '[Edge Cards] Update Advanced Options Open Status',
   props<{id: string; open: boolean}>(),
);

export const selectEdge = createAction(
   '[Edge Cards] Select Edge',
   props<{id: string | null}>(),
);
export const scrollToSelectedEdge = createAction(
   '[Edge Cards] Scroll to Selected Edge',
);
