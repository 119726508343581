import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Role, UserSummary } from '../../shared/types';
import { Assignment } from '../types/assignment.interface';
import { AsyncCacheService } from '../utils/async-cache.service';
import { ErrorMessageService } from './error-message.service';
import { AlertService } from './alert.service';


@Injectable({
   providedIn: 'root',
})
export class AccountUsersApiService {
   private accountUsersCache: any;

   constructor(
      private http: HttpClient,
      private asyncCacheService: AsyncCacheService<Assignment>,
      private errorMessageService: ErrorMessageService,
      private alertService: AlertService,
   ) {
      this.accountUsersCache = asyncCacheService.initAsyncCache('AccountUsersApiService');
   }

   getAccountInvites(accountId: string, offset?: number, limit?: number): Observable<UserSummary[]> {
      let url = '/api/accounts/' + accountId + '/invites';
      if( offset ) {
         url += '?offset=' + offset;
         if ( limit ) {
            url += '&limit=' + limit;
         }
      }
      return this.http.get<UserSummary[]>(url).pipe(
         catchError(err => this.handleError(err, of([{}] as UserSummary[]))),
      );
   }

   inviteUsers(accountId: string, data: UserSummary[]): Observable<any> {
      return this.http.post<any>('/api/accounts/' + accountId + '/invites', data).pipe(
         catchError(err => this.handleError(err, of(err))),
      );
   }

   getAccountUsers(accountId: string, forceFetch: boolean=false, offset?: number, limit?: number): Observable<UserSummary[]> {
      let path = '/api/accounts/' + accountId + '/userAccess';
      if( offset ) {
         path += '?offset=' + offset;
         if( limit ) {
            path += '&limit=' + limit;
         }
      }
      const api = this.http.get<UserSummary[]>(path).pipe(
         catchError(err => this.handleError(err, of([{}] as UserSummary[]))),
      );
      return this.accountUsersCache.fetch('account-' + accountId + '-users', api, forceFetch);
   }

   updateUserAccess(accountId: string, data: UserSummary): Observable<any> {
      return this.http.post<any>(`/api/accounts/${accountId}/userAccess/${data.id}`, data).pipe(
         catchError(err => this.handleError(err, of(err)))
      );
   }

   updateUserInvite(accountId: string, data: UserSummary): Observable<any> {
      return this.http.post<any>(`/api/accounts/${accountId}/invites/${data.id}`, data).pipe(
         catchError(err => this.handleError(err, of(err))),
      );
   }

   deleteUserAccess(accountId: string, id: string): Observable<any> {
      return this.http.delete<any>(`/api/accounts/${accountId}/userAccess/${id}`).pipe(
         catchError(err => this.handleError(err, of(err)))
      );
   }

   deleteUserInvite(accountId: string, id: string) {
      return this.http.post<any>(`/api/accounts/${accountId}/invites/${id}/trash`, {}).pipe(
         catchError(err => this.handleError(err, of(err))),
      );
   }

   deleteUserSession(accountId: string, id: string, disconnectmessage: string) {
      return this.http.post<any>(`/api/accounts/${accountId}/sessions/${id}/trash`, {disconnectmessage: disconnectmessage}).pipe(
         catchError(err => this.handleError(err, of(err)))
      );
   }

   getAccountRoles(siteId?: string, forceFetch: boolean=false): Observable<Role[]> {
      const queryParams = new HttpParams().set('siteId', siteId);
      const path = '/api/account_roles';
      const api = this.http.get<Role[]>(path, {params: queryParams}).pipe(
         catchError(err => this.handleError(err, of([{}] as Role[]))),
      );
      return this.accountUsersCache.fetch('account-' + siteId + '-roles', api, forceFetch);
   }

   getAccountSessions(accountId: string, forceFetch: boolean=false, offset?: number, limit?: number): Observable<UserSummary[]> {
      let path = `/api/accounts/${accountId}/sessions`;
      if ( offset ) {
         path += '?offset=' + offset;
         if( limit ) {
            path += '&limit=' + limit;
         }
      }
      const api = this.http.get<UserSummary[]>(path).pipe(
         catchError(err => this.handleError(err, of([{}] as UserSummary[]))),
      );
      return this.accountUsersCache.fetch('account-' + accountId + '-session', api, forceFetch);
   }

   private handleError(error: any, returnValue: Observable<any>): Observable<any> {
      this.alertService.show({
          type: 'danger',
          text: this.errorMessageService.errorMessage(error)
      });
      return returnValue;
   }
}
