import { RawCollection } from "../types";
import { difference, identity, equals, cloneDeep, removeFromArray, extend } from "./funct-utils";

interface SkeletonCacheNamespace {
   (key: string): {
      objects: any;
      collections: any;
   };
}

interface SkeletonOptions {
   marshall?: (model: any) => any;
   unmarshall?: (rawModel: any) => any;
   collectionOnly?: boolean;
}

export class SkeletonCacheService {
   private cache = {} as SkeletonCacheNamespace;

   public initSkeletonCache(namespace: string, options: SkeletonOptions = {}) {
      const initDefaults = {
         marshall: identity,
         unmarshall: identity,
         collectionOnly: false,
      };
      options = this.defaults(options, initDefaults);
      if (!(namespace in this.cache)) {
         this.cache[namespace] = { objects: {}, collections: {} };
      }
      const objectCache = this.cache[namespace].objects;
      const collectionCache = this.cache[namespace].collections;

      function add(model: Required<{id: string}>): void {
         if (model.id) {
            objectCache[model.id] = model;
         }
      }

      function get(modelId: string) {
         if (modelId in objectCache) return objectCache[modelId];
         const skeleton = {
            id: modelId ? modelId : null,
            $$deleting: false,
            $$selected: false,
            $$state: modelId ? 'uninitialised' : 'clean',
            $$saved: {},
            $$managedBySkeletonCache: true
         };
         add(skeleton);
         return skeleton;
      }

      function mergeIntoSkeleton(model: any) {
         if (options.collectionOnly) return model;
         const skeleton = get(model.id);
         model.$$state = 'clean';
         for(const key in model)
         {
            if (key === '$$saved')
            {
               continue;
            };
            if (!(key in skeleton.$$saved) || equals(skeleton.$$saved[key], skeleton[key])) {
               skeleton[key] = model[key];
            }
            skeleton.$$saved[key] = (model?.$$deepKeys && model.$$deepKeys.includes(key)) ? cloneDeep(model[key]) : model[key];
         };
         return skeleton;
      }

      function marshall(model: any) {
         return options.marshall(model);
      }

      function unmarshall(rawModel: any) {
         return mergeIntoSkeleton(options.unmarshall(rawModel));
      }

      function unmarshallCollection(rawCollection: RawCollection): RawCollection {
         // Maintain a single updated cached array for the collection of unmarshalled results
         let cookedResults = rawCollection.results.map(item =>  { return unmarshall(item); });
         if (rawCollection.channel) {
            const cachedResults = collectionCache[rawCollection.channel] || (collectionCache[rawCollection.channel] = []);
            const toAdd = difference(cookedResults, cachedResults);
            const toRemove = difference(cachedResults, cookedResults);
            cachedResults.push(...toAdd);
            removeFromArray(cachedResults, toRemove);
            cookedResults = cachedResults;
         }
         return extend({}, rawCollection, { results: cookedResults });
      }

      function unmarshallResults(rawCollection: RawCollection): RawCollection {
         var cookedResults = rawCollection.results.map(item => { return unmarshall(item); });
         return extend({}, rawCollection, { results: cookedResults });
      }

      return {
         get: get,
         add: add,
         marshall: marshall,
         unmarshall: unmarshall,
         unmarshallCollection: unmarshallCollection,
         unmarshallResults: unmarshallResults,
         mergeIntoSkeleton: mergeIntoSkeleton
      };
   }

   private defaults(target: any, defaults): any {
      for (const key in defaults) {
         if (!(key in target)) {
               target[key] = defaults[key];
         }
      }
      return target;
   }


}
