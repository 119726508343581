import { ErrorMessage } from './form.interface';

export const formValidation: Injectable<Function> = // eslint-disable-line @typescript-eslint/ban-types
      ['_', '$rootScope', '$q',
      function(_: Lodash, $rootScope: IRootScopeService, $q: IQService) {
   // A validation function returns a promise that resolves if the value is valid
   // and rejects with {error: string, message: string} if the value is not valid
   function validateRegexFactory(value, regex, shouldMatch=true, message="Invalid value (should match '{regex}')") {
      return $q(function(resolve, reject) {
         var regexp = new RegExp(regex);
         if (Boolean(value.match(regexp)) !== Boolean(shouldMatch)) {
            reject({error: 'regex', message: message.replace(/{regex}/g, regex).replace(/{value}/g, value)});
         } else {
            resolve();
         }
      });
   }

   return {
      // Returns null if the value is valid or [{ error: string, message?: string } ... ] if the value is invalid
      validateField: function(value, schema, otherValues): IPromise<ErrorMessage[] | void> {
         return $q(function(resolve, reject) {
            if (!value || _.isUndefined(value) || value === null) {
               if (schema.required) {
                  reject([{error: 'required'}]);
                  return;
               }
               resolve();
               return;
            }
            // At this point we know value is defined and not empty
            // Default validator: always correct
            var validators = [];

            if ('regexValidator' in schema) {
               validators.push(validateRegexFactory(value, schema.regexValidator.regex, schema.regexValidator.shouldMatch, schema.regexValidator.message));
            }

            if (schema.customValidator) {
               validators.push(eval(schema.customValidator)(value, otherValues)); // eslint-disable-line no-eval
            }

            if ('maxLength' in schema || 'minLength' in schema) {
               validators.push($q((res, rej) => {
                  let lengthFn = (text) => _.size(text);
                  if (schema.customLength) {
                     lengthFn = eval(schema.customLength); // eslint-disable-line no-eval
                  }
                  const length = lengthFn(value);
                  if ('maxLength' in schema && length > schema.maxLength) {
                     const charsOverMax = length - schema.maxLength;
                     rej({
                        error: 'maxLength',
                        message: `Too long by ${charsOverMax} character${charsOverMax > 1 ? 's' : ''}`,
                     });
                  } else if ('minLength' in schema && length < schema.minLength) {
                     const charsRemaining = schema.minLength - length;
                     rej({
                        error: 'minLength',
                        message: `Too short by ${charsRemaining} character${charsRemaining > 1 ? 's' : ''}`,
                     });
                  } else {
                     res();
                  }
               }));
            }

            function checkValidatorsAfter(n, messages: any[]) {
               if (n >= validators.length) {
                  if (messages.length) {
                     reject(messages);
                  } else {
                     resolve();
                  }
               } else {
                  validators[n]
                     .then(() => checkValidatorsAfter(n+1, messages))
                     .catch(message => {
                        messages.push(message);
                        checkValidatorsAfter(n+1, messages);
                     });
               }
            }
            checkValidatorsAfter(0, []);
         });
      },
      proceedIfValid: function(form) {
         return new Promise<void>((resolve, reject) => {
            if (!form) reject();

            // Trigger validation for untouched "required" fields
            form.$$controls.forEach(control => control.$validate());

            if (form.$pending) {
               var unregister = $rootScope.$watch(form.$pending, function() {
                  if (form.$valid) {
                     resolve();
                  } else {
                     reject();
                  }
                  unregister();
               });
            } else {
               if (form.$valid) {
                  resolve();
               } else {
                  reject();
               }
            }
         });
      }
   };
}];
