import { createReducer, on } from '@ngrx/store';
import {
   addNewService,
   addTemporaryService,
   removeFromDirtyServices,
   removeFromServicesInEdit,
   removeTemporaryService,
   scrollToSelectedService,
   selectService,
   updateServiceDirtiness,
   updateTemporaryServiceName,
   upsertServiceInEdit
} from './edge-service-cards.actions';
import { EditableServiceValues, TemporaryEdgeService } from './types';


export interface ServicesInEditValues {
   [serviceId: string]: EditableServiceValues;
}

export interface DirtyServices {
   [serviceId: string]: boolean;
}

export interface EdgeServiceCardsState {
   selectedServiceId: string | null;
   temporaryServices: TemporaryEdgeService[]; // Generated from a template, but not yet "created"/submitted to back end
                                              // i.e. user has clicked "new service" but not yet "create"
   servicesInEditValues: ServicesInEditValues;
   dirtyServices: DirtyServices;
}

export const initialState: EdgeServiceCardsState = {
   selectedServiceId: null,
   temporaryServices: [],
   servicesInEditValues: {},
   dirtyServices: {},
};

export const edgeServiceCardsReducer = createReducer(
   initialState,

   on(addNewService, (state) => ({ ...state })),
   on(addTemporaryService, (state, {tempService}) => ({
      ...state,
      temporaryServices: [...state.temporaryServices, tempService],
   })),
   on(updateTemporaryServiceName, (state, {id, name}) => {
      const temporaryServices = [...state.temporaryServices];
      const edgeIndex = temporaryServices.findIndex(e => e.$$temporaryId === id);
      temporaryServices[edgeIndex] = { ...temporaryServices[edgeIndex], name };
      return { ...state, temporaryServices };
   }),
   on(removeTemporaryService, (state, {id}) => ({
      ...state,
      temporaryServices: state.temporaryServices.filter(e => e.$$temporaryId !== id),
   })),
   on(removeFromServicesInEdit, (state, { id }) => {
      const { [id]: removedServiceEditVal, ...servicesInEdit } = state.servicesInEditValues;
      return { ...state, servicesInEditValues: servicesInEdit };
   }),
   on(removeFromDirtyServices, (state, { id }) => {
      const { [id]: removedDirtyVal, ...dirtyServices } = state.dirtyServices;
      return { ...state, dirtyServices };
   }),
   on(upsertServiceInEdit, (state, { id, value }) => ({
      ...state,
      servicesInEditValues: {
         ...state.servicesInEditValues,
         [id]: value,
      },
   })),
   on(updateServiceDirtiness, (state, { id, dirty }) => ({
      ...state,
      dirtyServices: {
         ...state.dirtyServices,
         [id]: dirty,
      }
   })),

   on(selectService, (state, { id }) => ({ ...state, selectedServiceId: id })),
   on(scrollToSelectedService, (state) => ({ ...state })),
);
