import { Observable, Subject } from 'rxjs';

interface DragAndDropServiceParams {
   data: any;
   dataMulti: any;
   element: HTMLElement;
   mousePosition: { x: number; y: number};
}

export class DragAndDropService {
   public readonly dragStarted$: Observable<DragAndDropServiceParams>;

   private dragAndDropSubject = new Subject<DragAndDropServiceParams>();

   constructor() {
      this.dragStarted$ = this.dragAndDropSubject.asObservable();
   }

   startDrag(params: DragAndDropServiceParams): void {
      this.dragAndDropSubject.next(params);
   }
}
