import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { difference, forEach, includes, map as _map, partial, remove, uniqBy } from 'lodash';
import { EMPTY, Observable, throwError, timer } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { showNotification } from '../../../store/notifications/notifications.actions';
import { ErrorMessageService } from '../../services/error-message.service';


interface UpdateDataProgress {
   itemuid: string;
   progress: number;
   report: string;
   target: number;
}

interface UpdateData {
   modified?: boolean;
   progress?: UpdateDataProgress;
}

export interface Update {
   channel: string;
   data: UpdateData;
   timestamp?: string;
}

// An ID we receive from POSTing to /api/events/create for longpolling against
interface CreatedEvent {
   eventId: string;
}

interface Channel {
   channel: string; // api path to 'subscribe' to (longpoll)
}

type ChannelCallback = (update: Update) => void;

// Map of api path -> callbacks + api path e.g.
/*
   {
      /api/entity/{id}: {
         {
            channel: '/api/entity/{id}',
            callbacks: [() => {}] // function to re-fetch the entity
         }
      },
      /api/another/entity/{id}: {
         {
            channel: '/api/another/entity/{id}',
            callbacks: [() => {}] // function to re-fetch the entity
         }
      }
   }
 */
interface CallbacksForChannel {
   [channel: string]: ChannelCallbackMap;
}

// Map of functions to call when the channel (api path) updates
interface ChannelCallbackMap {
   channel: Channel;
   callbacks: ChannelCallback[];
}


@Injectable()
export class UpdatesService {
   private eventId: string | null = null;
   private eventIdCreatedPromise: Promise<number | Channel[]> | null = null;
   private callbacksForChannels: CallbacksForChannel = {};

   private createFailureCount = 0;
   private subscribeFailureCount = 0;
   private longpollFailureCount = 0;
   private readonly MAX_RETRY_TIME = 32;

   constructor(
      private http: HttpClient,
      private store: Store,
      private errorMessageService: ErrorMessageService,
   ) {
      window.addEventListener('beforeunload', this.deleteEventId);
   }

   public init(callback: ChannelCallback) {
      const subscribeToUpdate = (channels: Channel[]) => {
         const newChannels = [];
         channels.forEach(channel => {
            if (!(channel.channel in this.callbacksForChannels)) {
               this.callbacksForChannels[channel.channel] = {
                  channel: channel,
                  callbacks: []
               };
               newChannels.push(channel);
            }
            if (!this.callbacksForChannels[channel.channel].callbacks.includes(callback)) {
               this.callbacksForChannels[channel.channel].callbacks.push(callback);
            }
         });

         if (newChannels.length) {
            this.createEventId(false).then((subscribedChannels) => {
               this.subscribeToChannels(difference(newChannels, subscribedChannels as Channel[]));
            });
         }
      };

      const unsubscribeToUpdate = (channels: Channel[]) => {
         channels.forEach(channel => {
            if(channel.channel in this.callbacksForChannels) {
               const idx = this.callbacksForChannels[channel.channel].callbacks.indexOf(callback);
               if(idx >=0) {
                  this.callbacksForChannels[channel.channel].callbacks.splice(idx, 1);
               }
            }
         });
      };

      this.createEventId(false);

      return { subscribeToUpdate, unsubscribeToUpdate };
   }


   private scheduleRetry(fn, failureCount: number): Observable<number> {
      const retryTime = Math.min(Math.pow(2, failureCount-1), this.MAX_RETRY_TIME) * 1000;
      return timer(retryTime).pipe(
         tap(fn()),
      );
   }

   private deleteEventId(): void {
      if (this.eventId) {
         this.http.post<void>(`/api/events/${this.eventId}/delete`, {}).subscribe();
         this.eventId = null;
      }
   }

   private createEventId(eventIdInstanceIsOutOfDate: boolean, resetEventId: boolean = false): Promise<number | Channel[]> {
      if ( resetEventId ) {
         this.setEventIdInstanceToOutOfDate();
      }
      if (this.eventIdCreatedPromise) {
         return this.eventIdCreatedPromise;
      }

      this.deleteEventId();

      return this.eventIdCreatedPromise = this.http.post<CreatedEvent>('/api/events/create', {}).pipe(
         map(({eventId}) => {
            this.eventId = eventId;
            this.createFailureCount = 0;
            const subscribeChannels = _map(this.callbacksForChannels, 'channel');
            this.subscribeToChannels(subscribeChannels).then(() => {
               if (eventIdInstanceIsOutOfDate) {
                  for (const channel in this.callbacksForChannels) {
                     this.callbacksForChannels[channel].callbacks.forEach((callback) => {
                        callback({ channel, data: null });
                     });
                  }
               }
               this.longpoll();
            });
            return subscribeChannels;
         }),
         catchError(err => {
            this.eventIdCreatedPromise = null;
            return this.scheduleRetry(this.createEventId.bind(this, eventIdInstanceIsOutOfDate, true), ++this.createFailureCount);
         }),
      ).toPromise();
   }

   private setEventIdInstanceToOutOfDate() {
      this.eventIdCreatedPromise = null;
      this.createEventId(true);
   }

   private removeDuplicateUpdates(updates: Update[]): Update[] {
      return uniqBy(updates, obj => {
         return [obj.channel, obj.timestamp, obj.data.modified].join();
      });
   }

   private longpoll(): void {
      this.http.get<Update[]>(`/api/events/${this.eventId}/longpoll`)
         .pipe(
            catchError((error) => {
               if (error.status === 504) {
                  // success but nothing happened
                  this.longpollFailureCount = 0;
                  this.longpoll();
               } else if (error.status === 502 || error.status === 0 || error.status === -1) {
                  // 0 on chrome or -1 on firefox when no http response due to network failure
                  this.scheduleRetry(this.longpoll.bind(this), ++this.longpollFailureCount);
               } else if (error.status === 410) {
                  this.setEventIdInstanceToOutOfDate();
               } else {
                  window.console.error(`Unknown longpoll result ${JSON.stringify(error)}`);
                  this.store.dispatch(showNotification({
                     notificationType: 'danger',
                     notificationText: 'Error fetching updates - ' + this.errorMessageService.errorMessage(error),
                     neverRemove: true,
                     noDuplicates: true,
                  }));
               }
               return throwError(error);
            }),
         )
         .subscribe(updates => {
            updates = this.removeDuplicateUpdates(updates);
            this.longpollFailureCount = 0;
            updates.forEach(update => {
               if (update.channel in this.callbacksForChannels) {
                  this.callbacksForChannels[update.channel].callbacks.forEach((callback) => {
                     // Catch exceptions here so that updates do not mysteriously stop after one error
                     try {
                        callback(update);
                     } catch (error) {
                        window.console.error('Exception processing update: ', update);
                        window.console.error(error);
                     }
                  });
               }
            });
            this.longpoll();
         });
   }

   private subscribeToChannels(channels: Channel[]): Promise<number | void> {
      // returns subscribe done promise
      if (!channels.length) {
         return Promise.resolve();
      }

      return this.http.post<void>(`/api/events/${this.eventId}/subscribe`, { channels }).pipe(
         tap(() => this.subscribeFailureCount = 0),
         catchError(error => {
            if (error.status === 410) {
               this.setEventIdInstanceToOutOfDate();
               // our promise goes on to fail and that's ok, createEventId will subscribe and poll
            } else if (error.status === 502 || error.status === 429 || error.status === 0 || error.status === 401) {
               return this.scheduleRetry(this.subscribeToChannels.bind(this, channels), ++this.subscribeFailureCount);
            } else {
               window.console.error(`Unknown subscribe result ${JSON.stringify(error)}`);
               this.store.dispatch(showNotification({
                  notificationType: 'danger',
                  notificationText: 'Error subscribing to updates ' + this.errorMessageService.errorMessage(error),
                  neverRemove: true,
                  noDuplicates: true,
               }));
            }
            return EMPTY;
         }),
      ).toPromise();
   }
}
