<h2 mat-dialog-title>Save to current location: {{ data.location }} </h2>
<div mat-dialog-content>
  <mat-form-field>
    <input matInput [(ngModel)]="data.title" cdkFocusInitial onfocus="this.select()">
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button-raise [mat-dialog-close]="data.title">Clip</button>
  <button mat-button (click)="onNoClick()">Cancel</button>
</div>
