const ngModule = angular.module('bb.service.icon-bank', []);

interface IconConfig {
   promise: Promise<HTMLElement>;
   details: IconDetails;
}

interface IconBank {
   [key: string]: IconDetails;
}

interface IconDetails {
   bankName: "controlCentre" | "common" | "jsPlayer";
   iconGraphic: string;
}

export class IconBankService {
   static $inject = ['$q'];

   private reportedErrors = new Set();

   private iconBank: IconBank = {
      loading:          { bankName: "controlCentre", iconGraphic: "bufferingAnim" },
      search:           { bankName: "controlCentre", iconGraphic: "search" },
      folder:           { bankName: "controlCentre", iconGraphic: "folder" },
      newFolder:        { bankName: "controlCentre", iconGraphic: "addFolder" },
      diagnostic:       { bankName: "controlCentre", iconGraphic: "stethLeft" },
      home:             { bankName: "controlCentre", iconGraphic: "landingHollow" },
      userGuide:        { bankName: "controlCentre", iconGraphic: "document" },
      training:         { bankName: "controlCentre", iconGraphic: "media" },
      edge:             { bankName: "controlCentre", iconGraphic: "servers" },
      notifications:    { bankName: "controlCentre", iconGraphic: "notification" },
      chat:             { bankName: "controlCentre", iconGraphic: "chatBalloon" },
      status:           { bankName: "controlCentre", iconGraphic: "status" },
      more:             { bankName: "controlCentre", iconGraphic: "bufferingAnim" },
      edgeFolder:       { bankName: "controlCentre", iconGraphic: "folder" },
      publishFolder:    { bankName: "controlCentre", iconGraphic: "folder" },
      source:           { bankName: "controlCentre", iconGraphic: "folder" },
      libraries:        { bankName: "controlCentre", iconGraphic: "twoFolders" },
      recycleNonEmpty:  { bankName: "controlCentre", iconGraphic: "recycleBinFull" },
      recycle:          { bankName: "controlCentre", iconGraphic: "recycleBinEmpty" },
      sources:          { bankName: "controlCentre", iconGraphic: "twoFolders" },
      endpoints:        { bankName: "controlCentre", iconGraphic: "twoFolders" },
      account:          { bankName: "controlCentre", iconGraphic: "production" },
      media:            { bankName: "controlCentre", iconGraphic: "media" },
      timecode:         { bankName: "controlCentre", iconGraphic: "pushpin" },
      upload:           { bankName: "controlCentre", iconGraphic: "arrowInCloud" },
      edit:             { bankName: "controlCentre", iconGraphic: "mediaClip" },
      edl:              { bankName: "controlCentre", iconGraphic: "mediaClip" },
      edgeFile:         { bankName: "controlCentre", iconGraphic: "document" },
      document:         { bankName: "controlCentre", iconGraphic: "document" },
      still:            { bankName: "controlCentre", iconGraphic: "slide" },
      managers:         { bankName: "controlCentre", iconGraphic: "manageManagers" },
      users:            { bankName: "controlCentre", iconGraphic: "manageUsers" },
      arrivals:         { bankName: "controlCentre", iconGraphic: "arrowInCloud" },
      stats:            { bankName: "controlCentre", iconGraphic: "barchart" },
      sites:            { bankName: "controlCentre", iconGraphic: "site" },
      sitegroups:       { bankName: "controlCentre", iconGraphic: "siteGroup" },
      accounts:         { bankName: "controlCentre", iconGraphic: "production" },
      endpoint:         { bankName: "controlCentre", iconGraphic: "servers" },
      service:          { bankName: "controlCentre", iconGraphic: "servers" },
      edgeServer:       { bankName: "controlCentre", iconGraphic: "servers" },
      edgeServers:      { bankName: "controlCentre", iconGraphic: "servers" },
      multicam:         { bankName: "controlCentre", iconGraphic: "multicam" },
      livestreams:      { bankName: "controlCentre", iconGraphic: "streaming" },
      livestream:       { bankName: "controlCentre", iconGraphic: "streamItem" },
      livestreamtype:   { bankName: "controlCentre", iconGraphic: "streamItem" },
      site:             { bankName: "controlCentre", iconGraphic: "site" },
      moreKebab:        { bankName: "common",        iconGraphic: "more_vert" },
      remove:           { bankName: "common",        iconGraphic: "chunkyMinus" },
      add:              { bankName: "common",        iconGraphic: "chunkyPlus" },
      publishing:       { bankName: "common",        iconGraphic: "sharing" },
      publishing_ng:    { bankName: "common",        iconGraphic: "sharing" },
      editing:          { bankName: "common",        iconGraphic: "chunkyPen" },
      configuration:    { bankName: "common",        iconGraphic: "settingsHollow" },
      delete:           { bankName: "common",        iconGraphic: "chunkyDelete" },
      unknown:          { bankName: "common",        iconGraphic: "questionMark" },
      questionMark:     { bankName: "common",        iconGraphic: "questionMark" },
      sharing:          { bankName: "common",        iconGraphic: "sharing" },
      support:          { bankName: "common",        iconGraphic: "questionMark" },
      zoom:             { bankName: "jsPlayer",      iconGraphic: "zoomInOut" },
      splurgeZoomIn:    { bankName: "jsPlayer",      iconGraphic: "zoomIn" },
      splurgeZoomOut:   { bankName: "jsPlayer",      iconGraphic: "zoomOut" },
      lowResolution:    { bankName: "jsPlayer",      iconGraphic: "resMin" },
      mediumResolution: { bankName: "jsPlayer",      iconGraphic: "resMed" },
      highResolution:   { bankName: "jsPlayer",      iconGraphic: "resMax" },
   };

   private sources = {
      controlCentre: { url: '/furniture/img/controlCentre-svgIcons.svg', containerPromise: null },
      jsPlayer: { url: '/furniture/img/jsPlayer-svgIcons.svg', containerPromise: null },
      common: { url: '/furniture/img/common-svgIcons.svg', containerPromise: null },
   };

   constructor(private $q: IQService) {}

   getIcon(icon: string): IconConfig {
      const iconDetails = this._getIconDetails(icon);
      const src = this.sources[iconDetails.bankName];
      if (!src.containerPromise) {
         src.containerPromise = this.$q.when(fetch(src.url).then((response) => { // Wrap fetch in AngularJS promise to please digest cycle
            if (response.ok) {
               return response.text().then((text) => {
                  const body = document.querySelector('body');
                  let iconBanks = body.querySelector('#fbdn-icon-bank');

                  if (!iconBanks) {
                     const bank = document.createElement('div');
                     bank.id = 'fbdn-icon-bank';
                     bank.style.display = "none";
                     body.appendChild(bank);
                     iconBanks = bank;
                  }

                  const iconContainer = document.createElement('div');
                  iconContainer.id = "fbdn-" + iconDetails.bankName + "-icon-container";
                  iconContainer.innerHTML = text;
                  iconBanks.appendChild(iconContainer);
                  return iconContainer;
               });
            } else {
               return this.$q.reject(response.statusText);
            }
         }));
      }
      return {
         promise: src.containerPromise,
         details: iconDetails,
      };
   }

   _getIconDetails(icon: string): IconDetails {
      if (!(icon in this.iconBank) || !this.iconBank[icon].bankName || !this.sources[this.iconBank[icon].bankName] || !this.iconBank[icon].iconGraphic ) {
         if (!this.reportedErrors.has(icon)) {
            if (icon in this.iconBank) {
               window.console.log("Bad icon '" + icon + "': bank '" + this.iconBank[icon].bankName + "' graphic '"+ this.iconBank[icon].iconGraphic + "'");
            }
            else {
               window.console.log("Icon: '" + icon +"'not in icon banks");
            }
            this.reportedErrors.add(icon);
         }
         return {
            iconGraphic: "hereAndThere",
            bankName : "controlCentre"
         };
      }
      else {
         return {
            iconGraphic: this.iconBank[icon].iconGraphic,
            bankName: this.iconBank[icon].bankName
         };
      }
   }
}

ngModule.service('IconBankService', IconBankService);

export default ngModule;
