import { HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Notification } from '../types';
import { ErrorMessageService } from './error-message.service';

export class NotificationService {
   static $inject = ['_'];

   private _notifications: Notification[] = [];

   constructor(
      private _: Lodash,
      private errorMessageService: ErrorMessageService,
      )
      {}

   get notifications(): Notification[] {
      return this._notifications;
   }

   public show(notification: Notification): void {
      if (notification.noDuplicates && this._notifications.find(n => n === notification)) {
         return;
      }
      this._notifications.unshift(notification);
      if (!notification.undoId && !notification.neverRemove) {
         this._.delay(() => this.remove(notification), 15000);
      }
   }

   public remove(notification: Notification) {
      this._.remove(this._notifications, notification);
   }

   /**
    * @param [action='fetching the data'] the action that the error has occurred on.
    * @returns pipeable operator which takes the source observable and pipes catchError, triggering
    * a notification message before throwing error to the next handler (probably the console).
    */
   public notifyOnError<T>(action='fetching the data') {
      return (source: Observable<T>): Observable<T> => {
         return source.pipe(
            catchError((error: HttpErrorResponse) => {
               this.show({
                  type: 'danger',
                  text: `An error occurred while ${action}: ${this.errorMessageService.errorMessage(error)}`,
               });
               return throwError(error);
            }),
         );
      };
   }

}
