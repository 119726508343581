import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, withLatestFrom } from 'rxjs/operators';

import { UsersApiService } from '../../shared/api/users.api.service';
import * as Users from '.';
import { ErrorMessageService } from '../../shared/services/error-message.service';


@Injectable()
export class UsersEffects {

   /* Search */
   searchUsers$ = createEffect(() => this.actions$.pipe(
      ofType(Users.searchUsers),
      concatMap(({searchParams}) => of(searchParams).pipe(
         withLatestFrom(this.store.select(Users.selectUsersByQuery(searchParams.searchText))),
      )),
      concatMap(([searchParams, foundUsers]) => {
         const getSearchUsersSuccessAction = (users: Users.User[]) => ({
            type: Users.searchUsersSuccess.type,
            users,
            query: searchParams.searchText,
         });

         // If users is truthy (empty or populated array), we have already done
         // a search for the given term, so resolve with previously found users
         return foundUsers
            ? of(getSearchUsersSuccessAction(foundUsers))
            : this.usersApiService.findUsers(searchParams, false)
               .pipe(
                  map((users) => getSearchUsersSuccessAction(users)),
                  catchError((error: HttpErrorResponse) => of({
                     type: Users.searchUsersFail.type,
                     usersSearchError: this.errorMessageService.errorMessage(error),
                  })),
               );
      }),
   ));

   /* Load User */
   loadUser$ = createEffect(() => this.actions$.pipe(
      ofType(Users.loadUser),
      concatMap(({id, forceRefresh}) => of([id, forceRefresh] as [string, boolean]).pipe(
         withLatestFrom(this.store.select(Users.selectUserById(id))),
      )),
      concatMap(([[id, forceRefresh], cachedUser]) => {
         const getLoadUserSuccessAction = (user: Users.User) => ({ type: Users.loadUserSuccess.type, user });
         return cachedUser && !forceRefresh
            ? of(getLoadUserSuccessAction(cachedUser))
            : this.usersApiService.fetchUser(id)
               .pipe(
                  map((user) => getLoadUserSuccessAction(user)),
                  catchError((error: HttpErrorResponse) => of({
                     type: Users.loadUserFail.type,
                     userLoadingError: this.errorMessageService.errorMessage(error),
                  })),
               );
      }),
   ));

   /* Load User */
   loadMe$ = createEffect(() => this.actions$.pipe(
      ofType(Users.loadMe),
      concatMap(({forceRefresh}) => of(forceRefresh).pipe(
         withLatestFrom(this.store.select(Users.selectMe)),
      )),
      concatMap(([forceRefresh, cachedMe]) => {
         const getLoadMeSuccessAction = (me: Users.Me) => ({ type: Users.loadMeSuccess.type, me });
         return cachedMe && !forceRefresh
            ? of(getLoadMeSuccessAction(cachedMe))
            : this.usersApiService.fetchMe()
               .pipe(
                  map((me) => getLoadMeSuccessAction(me)),
                  catchError((error: HttpErrorResponse) => of({
                     type: Users.loadMeFail.type,
                     userLoadingError: this.errorMessageService.errorMessage(error),
                  })),
               );
      }),
   ));

   constructor(
      private actions$: Actions,
      private store: Store,
      private usersApiService: UsersApiService,
      private errorMessageService: ErrorMessageService,
   ) {}
}
