<h4>Export</h4>
<a class="mat-raised-button" href="/api/media?format=xlsx&accountId={{accountId}}&metadata=">Download xlsx (Excel)</a>
<a class="mat-raised-button" href="/api/media?format=csv&accountId={{accountId}}&metadata=">Download csv</a>
<h4>Import</h4>
<input id="csvInput" type="file" style="display: none"/>
<button
   (click)="openFileInputDialog()"
   type="button"
   class="mat-raised-button btn"
   [disabled]="importProcessState !== 'idle'"
>
   <span *ngIf="importProcessState === 'idle'; else loading">
      Import from csv
   </span>
   <ng-template #loading>
      <bb-loading></bb-loading>
   </ng-template>
</button>
