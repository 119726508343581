import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

export class Config {
   public version: string;
}

@Injectable({
   providedIn: 'root'
})
export class ApiConfigService {
   public static settings: Config = {version: 'Invalid'};

   private readonly jsonFile = '/furniture/js/dist/angular/assets/api-version-config.json';
   private http: HttpClient;

   constructor(private httpBackEnd: HttpBackend) {
      this.http = new HttpClient(this.httpBackEnd);
      this.fetchConfigSettings()
         .pipe(
            catchError(err => {
               throw new Error(`Could not load file '${this.jsonFile}': ${JSON.stringify(err)}`);
            }),
         ).subscribe((response: Config) => {
            ApiConfigService.settings = response;
         });
   }

   public fetchConfigSettings(): Observable<Config> {
      return this.http.get<Config>(this.jsonFile);
   }
}
