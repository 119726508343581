import './dynamic-schema.factory';
import './popup.factory';
import './form-validation.factory';
import { NotificationService } from '../services/notification.service';
import { ErrorMessage, FormItem, SelectableOption } from './form.interface';
import { SchemaItem } from './schema.interface';

interface DynamicFormNgModelController extends INgModelController {
   $$errorMessages: ErrorMessage[];
}

export const dynamicForm: InjectableDirectiveFactory = [
      'DynamicSchema', 'Popup', '_', 'FormValidation', 'NotificationService',
      function(DynamicSchema, Popup, _: Lodash, FormValidation, notificationService: NotificationService) {
   var id = 0;

   return {
      restrict: 'E',
      scope: {
         schema: '=',
         form: '=',
         values: '=',
         performAction: '&',
         query: '&',
         submitConfig: '<',
      },
      templateUrl: '/furniture/js/app/src/shared/dynamic-form/dynamic-form.directive.html',
      require: '?^dynamicFormContainer',
      link: function(scope: any, element, attrs, dynamicFormContainer) {
         scope.forms = {};
         scope.submitting = false;
         let formItemsBySchemaItemName = null;
         if (dynamicFormContainer) {
            dynamicFormContainer.add(scope);
         }

         scope.ensureGroup = function(itemOrGroup) {
            if (itemOrGroup.$$asGroup) return itemOrGroup.$$asGroup;

            if (itemOrGroup.type === 'group') {
               if (!angular.isDefined(itemOrGroup.$$isItemGroup)) {
                  itemOrGroup.$$isItemGroup = true;
               }
               itemOrGroup.$$asGroup = itemOrGroup;
            } else {
               var group = {};
               _.extend(group, itemOrGroup);
               _.extend(group, {
                  label: itemOrGroup.label,
                  type: 'group',
                  $$isItemGroup: false,
                  items: [_.clone(itemOrGroup)]
               });
               itemOrGroup.$$asGroup = group;
            }

            return itemOrGroup.$$asGroup;
         };

         scope.selectOnly = function(formItem: FormItem) {
            return formItem.selectOnly;
         };

         scope.remainingCharactersMessage = function(formItem: FormItem, schemaItem: SchemaItem) {
            if (!formItem.name || !scope.schema[formItem.name]) {
               // not a data item
               return "";
            }
            const maxLength = scope.schema[formItem.name].maxLength || formItem.maxLength;
            if (!maxLength) {
               return "";
            }

            let length = (text) => _.size(text);
            if (schemaItem.customLength) {
               length = eval(schemaItem.customLength); // eslint-disable-line no-eval
            }

            const currentLength = length(scope.values[formItem.name].value);
            if (currentLength / maxLength >= 0.8) {
               return currentLength + " / " + maxLength;
            } else {
               return "";
            }
         };

         scope.getFieldType = function(formItem: FormItem): string {
            if (formItem.type) return formItem.type;
            const schemaItem = scope.schema[formItem.name];
            if (schemaItem.allowedValues) return 'select';
            if (schemaItem.type === 'text' || schemaItem.type === 'fractionOrInteger' || schemaItem.type === 'emailAddress') return 'text';
            if (schemaItem.type === 'textarea') return 'textarea';
            if (schemaItem.type === 'url') return 'url';
            if (schemaItem.type === 'number' || schemaItem.type === 'integer') return 'number';
            if (schemaItem.type === 'boolean') return 'checkbox';
         };

         scope.showLabel = function(formItem) {
            return (['button', 'hidden'].indexOf(formItem.type) === -1);
         };

         scope.getItemMultiSelectLimits = function(itemName, limitType) {
            const limit = scope.schema[itemName][limitType];
            return typeof limit !== 'number' ? scope.$eval(limit) : limit;
         };

         scope.showWhen = function(formItem: FormItem): boolean {
            return formItem.showWhen ? scope.$eval(formItem.showWhen) : true;
         };

         scope.disabled = function(formItem: FormItem): boolean {
            return scope.values[formItem.name].disabled || formItem.disabled || formItem.readOnly ||
               (formItem.enableWhen ? !scope.$eval(formItem.enableWhen) : false);
         };

         // A select field is considered required if none of the option values are '' || null,
         // which we allow as option values (should only have one per set of options though)
         scope.selectFieldIsRequired = function(formItem: FormItem): boolean {
            const valueLabels: SelectableOption[] = scope.values[formItem.name].valueLabels || formItem.valueLabels;
            return !valueLabels.some(vl => vl.value === '' || vl.value === null);
         };

         scope.selectableOptions = function(formItem: FormItem): SelectableOption[] {
            const valueLabels: SelectableOption[] = scope.values[formItem.name].valueLabels || formItem.valueLabels;
            const currentValue = scope.values[formItem.name].value;

            /* If current value is not ('' || null || undefined) AND not a valid option, add it marked "deprecated"
             * and prevent it from being re-selectable.
             * If current value is ('' || null || undefined) we want to let it select it as blank if it is not a
             * valid option (selectFieldIsRequired will also mark it as required). This forces a user to select
             * a value before submitting it where the currentValue is not a valid option.
            */
            if (currentValue !== ''
             && currentValue !== null
             && currentValue !== undefined
             && !valueLabels.some(vl => vl.value === currentValue)
            ) {
               valueLabels.unshift({
                  label: `${currentValue} (deprecated option)`,
                  value: currentValue,
                  hidden: true,
               });
            }

            // Remove hidden items unless they match current value (and are therefore a deprecated option)
            return valueLabels.filter(vl => !vl.hidden || vl.value === currentValue);
         };

         scope.setOptionsInScope = function(schemaItemOptions, schemaItemName) {
            if (!formItemsBySchemaItemName[schemaItemName]) return;
            var modelController = scope.forms.dynamic[formItemsBySchemaItemName[schemaItemName].name];
            if ('value' in schemaItemOptions) {
               scope.values[schemaItemName].value = schemaItemOptions.value;
            }
            if ('disabled' in schemaItemOptions) {
               scope.values[schemaItemName].disabled = schemaItemOptions.disabled;
            }
            if ('message' in schemaItemOptions) {
               scope.values[schemaItemName].message = schemaItemOptions.message;
            }
            if ('valueLabels' in schemaItemOptions) {
               scope.values[schemaItemName].valueLabels = schemaItemOptions.valueLabels;
            }
            if ('valid' in schemaItemOptions && modelController) {
               modelController.$setValidity('server', schemaItemOptions.valid);
            }
            if (modelController) {
               modelController.$pristine = false;
               modelController.$dirty = true;
            }
            if (scope.values[schemaItemName].disabled) {
               // Make newly disabled values satisfy schema, as form will not do so
               scope.values[schemaItemName].value = DynamicSchema.coerceValueToSatisfySchemaItem(scope.schema[schemaItemName], scope.values[schemaItemName].value);
            }
         };

         function processActionResult(result, deferredId?) {
            if (result.action === 'open-url') {
               return Popup.open({
                  url: result.url,
                  width: result.width,
                  height: result.height
               }, deferredId).then(processActionResult);
            } else if (result.action === 'set-options') {
               if (dynamicFormContainer) {
                  _.forEach(dynamicFormContainer.dynamicForms, formScope => _.forEach(result.options, formScope.setOptionsInScope));
               } else {
                  _.forEach(result.options, scope.setOptionsInScope);
               }
            } else if (result.action === 'display-message') {
               _.defaults(result, {autoRemove: true, preFormatted: false, type: "danger"});
               notificationService.show({
                  text: result.message,
                  type: result.type,
                  pre: result.preFormatted,
                  neverRemove: !result.autoRemove
               });
            } else {
               notificationService.show({
                  text: 'Unexpected response from server "' + result.action + '"',
                  type: 'danger'
               });
            }
         }

         scope.onClickButton = function(formItem: FormItem): void {
            id++;
            var state = 'deferred:' + id;
            scope.performAction({action: formItem.action, state: state}).then(function(result) {
                  return processActionResult(result, state);
               }, function(error) {
                  notificationService.show({
                     text: 'Unable to perform action "' + formItem.action + '" - ' + error,
                     type: 'danger'
                  });
               });
         };

         scope.parseComboOption = function(viewValue) {
            return viewValue.value ?? viewValue.$$comboText;
         };

         scope.formatComboOption = function(modelValue, inputItem) {
            var viewValue;
            if (angular.isObject(modelValue)) {
               viewValue = modelValue;
            } else {
               modelValue = modelValue.trim();
               viewValue = _.find(inputItem.valueLabels, {value: modelValue});
               if (!viewValue) {
                  viewValue = {
                     value: modelValue,
                     label: modelValue
                  };
               }
            }
            viewValue.$$comboText = viewValue.$$comboText || viewValue.label;
            viewValue.$$comboSubText = viewValue.$$comboSubText || (viewValue.value !== viewValue.label ? viewValue.value : '');
            return viewValue;
         };

         scope.createNewComboItem = function(term, inputItem) {
            return scope.formatComboOption(term, inputItem);
         };

         function ensureValuesCreated(): void {
            //This is to fix old code. Eventually (after resave all old publish buttons it can be removed)
            scope.values = angular.isArray(scope.values) ? {} : scope.values;
         }

         scope.$watch('values', ensureValuesCreated);
         scope.$watch('schema', ensureValuesCreated);

         function ensureSelectedOptionWithinOptions(): void {
            _.forEach(scope.form, function(formItem) {
               if (!('valueLabels' in formItem)) return;
               _.remove(formItem.valueLabels, {$$fromSelected: true});
               var selectedValue = scope.values[formItem.name].value;
               if (formItem.migrateValues && _.has(formItem.migrateValues, selectedValue)) {
                  selectedValue = formItem.migrateValues[selectedValue];
                  scope.values[formItem.name].value = selectedValue;
               }
               if (!_.find(formItem.valueLabels, {value: selectedValue})) {
                  if (formItem.selectOnly === false) {
                     formItem.valueLabels.push({
                        value: selectedValue,
                        label: selectedValue,
                        $$fromSelected: true
                     });
                  }
               }
            });
         }
         scope.$watch('form', ensureSelectedOptionWithinOptions);

         // setOptions can only run after ngModelControllers have been created
         scope.$watch('values', function() {
            scope.$evalAsync(function() {
               _.forEach(scope.form, setOptions);
            });
         });

         function groupBySchemaItemName(groups) {
            var bySchemaItemName = {};
            _.forEach(groups, function(itemOrGroup) {
               if (itemOrGroup.type === 'group') {
                  _.extend(bySchemaItemName, groupBySchemaItemName(itemOrGroup.items));
               } else {
                  bySchemaItemName[itemOrGroup.name] = itemOrGroup;
               }
            });
            return bySchemaItemName;
         }

         scope.$watch('form', function() {
            formItemsBySchemaItemName = groupBySchemaItemName(scope.form);
         });

         /* Run javascript hooks as necessary */
         function runHooks() {
            _.forEach(scope.form, function(formItem) {
               if (formItem.hookOnload) {
                  try {
                     var hookFunction = eval("("+formItem.hookOnload+")"); // eslint-disable-line no-eval
                     scope.$evalAsync(function() {
                        try {
                           hookFunction(formItem, scope);
                        } catch (ex) {
                           window.console.log("Error running hook for", formItem, "with exception", ex);
                        }
                     });
                  } catch (ex) {
                     window.console.log("Error calling input", formItem, "with exception", ex);
                  }
               }
            });
         }
         scope.$watch('form', runHooks);

         function setOptions(inputItem) {
            if (!inputItem.valueLabels) return;
            var modelValue = scope.values[inputItem.name].value;
            var existingViewValue = _.find(inputItem.valueLabels, {value: modelValue});
            if (existingViewValue && existingViewValue.autofill) {
               /* Translate autofill into the setOptions equivalent */
               processActionResult({
                  action: 'set-options',
                  options: _.mapValues(existingViewValue.autofill, value => ({value: value}))
               });
            } else if (existingViewValue && existingViewValue.setOptions) {
               processActionResult({
                  action: 'set-options',
                  options: existingViewValue.setOptions
               });
            } else if (inputItem.customValueSetOptions && (!existingViewValue || existingViewValue.$$fromSelected)) {
               processActionResult({
                  action: 'set-options',
                  options: inputItem.customValueSetOptions
               });
            }
         }

         function getModelController(inputItem: FormItem): DynamicFormNgModelController | null {
            if (!scope.forms?.dynamic) { // Sometimes when forms input changes this can be briefly undefined
               return null;
            }
            return scope.forms.dynamic[inputItem.name];
         }

         function handleValidationPass(modelController: DynamicFormNgModelController): void {
            const errorKeys = Object.keys(modelController.$error);
            errorKeys.forEach(error => modelController.$setValidity(error, true));
            modelController.$$errorMessages = [];
         }

         function handleValidationFail(modelController, validationErrors): void {
            // Remove errors resolved since last validation run
            Object.keys(modelController.$error).forEach(error => {
               if (!_.find(validationErrors, {error})) modelController.$setValidity(error, true);
            });

            // Apply current errors
            validationErrors.forEach(valErr => modelController.$setValidity(valErr.error, false));

            // Do not add required's error message. This is displayed separately in the form,
            // as it can be triggered without an on-change event (submitting form without any input)
            modelController.$$errorMessages = validationErrors.filter(v => v.error !== 'required');
         }

         function validateField(inputItem: FormItem) {
            const modelController = getModelController(inputItem);
            if (!modelController) return;

            const value = scope.values[inputItem.name].value;
            FormValidation.validateField(value, scope.schema[inputItem.name], scope.values)
               .then(() => handleValidationPass(modelController))
               .catch(validationErrors => handleValidationFail(modelController, validationErrors));
         }

         function validateAll(): void {
            scope.form.forEach(formItem => {
               const inputGroup = scope.ensureGroup(formItem);
               inputGroup.items.forEach(inputItem => validateField(inputItem));
            });
         }

         const validateAllDebounced = _.debounce(validateAll, 500, { leading: true });

         function setRequiredIf(itemSchema: SchemaItem): void {
            if (!itemSchema) return;

            if ('requiredIf' in itemSchema) {
               const [otherOptionName, condition, requiredIfValue] = itemSchema.requiredIf;
               const requiredCondition = {
                  equal: (a, b) => a === b,
                  notequal: (a, b) => a !== b,
               }[condition];
               const otherOptionValue = scope.values[otherOptionName].value;
               itemSchema.required = requiredCondition(otherOptionValue, requiredIfValue);
            }
         }

         scope.isRequired = function(item: FormItem): boolean {
            if (!item || !scope.values || !scope.values[item.name]) return;

            const itemSchema = scope.schema[item.name];
            setRequiredIf(itemSchema);
            const schemaRequired = !!(itemSchema && itemSchema.required);

            return (schemaRequired || item.required) && !scope.values[item.name].disabled;
         };

         scope.hasRequiredFields = (): boolean =>  {
            return scope.form && scope.form.some(formItem => scope.isRequired(formItem));
         };

         scope.hasError = (inputItem: FormItem, requiredErrCheck=false): boolean => {
            const modelController = getModelController(inputItem);
            if (!modelController) return false;

            const hasError = modelController.$invalid &&
               (modelController.$touched || scope.forms.dynamic.$submitted);

            return requiredErrCheck
               ? hasError && modelController.$error.required
               : hasError;
         };

         scope.getErrorMessages = function(inputItem: FormItem) {
            return getModelController(inputItem)?.$$errorMessages;
         };

         scope.onChange = function(inputItem: FormItem): void {
            setOptions(inputItem);

            const modelController = getModelController(inputItem);
            if (!modelController) return;
            modelController.$setValidity('server', true);

            validateField(inputItem);
            validateAllDebounced();
         };

         scope.getQuery = function(inputItem: FormItem): () => Promise<any> {
            if (!inputItem.query || !('query' in attrs)) return null;
            return function() {
               return scope.query({queryName: inputItem.query.name}).then(function(results) {
                  inputItem.valueLabels = results.results;
                  return results;
               });
            };
         };

         scope.getTypeaheadDirList = function(inputItem: FormItem, path: string) {
            const dirName = path.replace(/\/*[^/]*$/, ''); // Strips tail of the path (with preceding slash if any)
            return scope.query({queryName: inputItem.query.name, data: {search: dirName}}).then(function(response) {
               var dirList = [];
               angular.forEach(response.results, function(item) {
                  if (item.indexOf(path) >= 0 || path === '') {
                     dirList.push(item);
                  }
               });
               return dirList;
            });
         };

         function _marshallValues() {
            const marshalledValues = _.cloneDeep(scope.values);
            for (const prop in marshalledValues) {
               for (const prop2 in marshalledValues[prop]) {
                  if (prop2.startsWith('$$')) {
                     delete marshalledValues[prop][prop2];
                  }
               }
            }
            return marshalledValues;
         }

         scope.onSubmit = (): void => {
            if (scope.forms.dynamic.$invalid) return;
            scope.submitting = true;
            scope.submitConfig.onSubmit(_marshallValues())
               .then(() => {
                  scope.forms.dynamic.$setPristine();
                  scope.forms.dynamic.$setUntouched();
               })
               .finally(() => scope.submitting = false);
         };
      }
   };
}];
