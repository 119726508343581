import { Lib } from '../types';

/*
 * Used to communicate dialog open-ness between the (new) Angular and (legacy) angular.js
 * login timeout dialogs. As we have 2x separate http libraries (Angular HttpClient and
 * angular.js $http), we catch 401s and show the login timeout dialogs separately, in
 * LoginTimeoutInterceptor and http.ts respectively. This class provides a method of
 * preventing the other lib from opening its dialog.
 *
 * Must remain an angular.js service until every page is hybrid, we can then add the
 * Angular @Injectable() decorator, and either downgradeInjectable() or inject directly.
 */
export class LoginDialogLockService {
   private lockHeldBy: Lib | null = null;

   public acquireLockForLib(lib: Lib): boolean {
      if (!this.lockHeldBy) {
         this.lockHeldBy = lib;
         return true;
      }

      return this.lockHeldBy === lib;
   }

   public relinquishLock(): void {
      this.lockHeldBy = null;
   }
}
