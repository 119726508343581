export const FbdnDraggableContainer: InjectableDirectiveFactory = function() {
   return {
      restrict: 'A',
      controller: ['$scope', '$element', function($scope, $element: JQLite) {
         this.getElement = function getElement(): JQLite {
            return $element;
         };
      }]
   };
};
