import { AsyncConfigGetter, ChoiceModalConfig } from './choice-modal-config.interface';

interface ChoiceModalScope extends IScope {
   config: ChoiceModalConfig;
   doAction(action: () => void): void;
   dismissModal(): void;
}

// TODO convert to a component when $modal (uiModal) can open
// AngularJS 1.5 components or when we have a new solution FP-19002
// Template can be found in ng_choice_modal.html until then
export const ChoiceModalController: Injectable<IControllerConstructor> = [
      '$scope', '_', '$modalInstance', 'asyncConfigGetter',
      function($scope: ChoiceModalScope, _: Lodash, $modalInstance: any, asyncConfigGetter: AsyncConfigGetter) {
    asyncConfigGetter()
      .then(config => $scope.config = config);

   $scope.doAction = action => {
      if (_.isFunction(action)) {
         action();
         $modalInstance.close();
      };
   };

   $scope.dismissModal = () => $modalInstance.dismiss();
}];
