import { SaveTransaction } from "../utils/save-transaction/save-transaction.factory";

export const FbdnSortable: InjectableDirectiveFactory = ['_', 'SaveTransaction', 'DragAndDropService', function(_, saveTransaction: SaveTransaction, dragAndDropService) {
   return {
      link: function(scope: IScope, element, attrs: IAttributes) {
         var key = scope.$eval(attrs.fbdnSortableKey);
         var draggedData = null;

         function getArray(): { [key: string]: any }[] {
            return scope.$eval(attrs.fbdnSortable);
         }

         function restoreOrder(): void {
            _.forEach(getArray(), function(obj) {
               saveTransaction.rollbackProperty(obj, key);
            });
         }
         const dragAndDropServiceSubscription = dragAndDropService.dragStarted$.subscribe((params) => {
            draggedData = params.data;
         });

         scope.$on('draggable:end', function() {
            draggedData = null;
         });

         scope.$on('$destroy', () => {
            dragAndDropServiceSubscription.unsubscribe();
         });

         scope.$on('droppable:startHover', function(e, params) {
            var hoveredData = params.data;
            if (hoveredData === draggedData) return;
            var array = getArray();
            if (!_.includes(array, draggedData)) return;
            array = _.sortBy(array, 'priority');
            var index = array.indexOf(hoveredData);
            _.remove(array, draggedData);
            array.splice(index, 0, draggedData);
            _.forEach(array, function(object, i) {
               object[key] = i;
            });
            scope.$apply();
         });

         scope.$on('droppable:stopHover', function(e, params) {
            if (params.data === draggedData) {
               restoreOrder();
               scope.$apply();
            }
         });

         scope.$on('droppable:dropped', function() {
            scope.$eval(attrs.fbdnSortableOnDrop);
         });

      }
   };
}];
