<form class="form-inline" *ngIf="pageReady">
   <div *ngIf="dataSource?.length;else nousersavailable">
      <mat-table [dataSource]="getPagedDataSource()" class="mat-elevation-z0">
         <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
               <button
                  mat-icon-button
                  (click)="markAllChecked()"
                  >
                  <span class="material-icon"> check_box </span>
               </button>
            </th>
            <td mat-cell *matCellDef="let i = index">
               <mat-checkbox
                  class="row-select"
                  [checked]="dataSource[i].deleted"
                  (change)="checkRow($event.checked, i)"
                  >
               </mat-checkbox>
            </td>
         </ng-container>
         <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef>
               <span class="deleted-icon" *ngIf="context === 'Invites';else sessionoraccess"> Cancel invitation </span>
               <ng-template #sessionoraccess>
                  <span class="deleted-icon" *ngIf="context === 'Sessions';else access" > End session </span>
                  <ng-template #access>
                     <span class="deleted-icon" > Remove access </span>
                  </ng-template>
               </ng-template>
            </th>
            <td mat-cell class="bb-action-icon" *matCellDef="let element; let i = index">
               <button
                  mat-icon-button
                  class="btn-link user-remove"
                  (click)="markAsDeleted(i)"
                  [disabled]="isDisabled(i)"
                  [ngClass]="{'row-deleted': isDisabled(i)}"
                  >
                  <span class="material-icon"> cancel </span>
               </button>
            </td>
         </ng-container>

         <ng-container matColumnDef="username">
            <th mat-header-cell *matHeaderCellDef> User name </th>
            <td mat-cell *matCellDef="let element; let i = index" [ngClass]="{'row-deleted': isDisabled(i)}">
               <span *ngIf="isDisabled(i); else anchor">{{element.username}}</span>
               <ng-template #anchor><a [href]="userEditUrl(context === 'Sessions' ? element.user : element.id)"> {{element.username}} </a></ng-template>
            </td>
         </ng-container>
         <ng-container matColumnDef="fullname">
            <th mat-header-cell *matHeaderCellDef> Full Name </th>
            <td mat-cell *matCellDef="let element; let i = index" [ngClass]="{'row-deleted': isDisabled(i)}"> {{element.fullname}} </td>
         </ng-container>
         <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef> Email </th>
            <td mat-cell *matCellDef="let element; let i = index" [ngClass]="{'row-deleted': isDisabled(i)}">
               <span *ngIf="isDisabled(i); else anchor">{{element.email}}</span>
               <ng-template #anchor><a [href]="emailString(element.email)" target="_top"> {{element.email}}</a></ng-template>
            </td>
         </ng-container>
         <ng-container matColumnDef="ip">
            <th mat-header-cell *matHeaderCellDef> IP </th>
            <td mat-cell *matCellDef="let element; let i = index" [ngClass]="{'row-deleted': isDisabled(i)}"> {{element.ip}} </td>
         </ng-container>
         <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef> Status </th>
            <td mat-cell *matCellDef="let element; let i = index" [ngClass]="{'row-deleted': isDisabled(i)}"> {{element.status}} </td>
         </ng-container>
         <ng-container matColumnDef="from">
            <th mat-header-cell *matHeaderCellDef> From </th>
            <td mat-cell *matCellDef="let element; let i = index" [ngClass]="{'row-deleted': isDisabled(i)}"> {{element.openedtime | date:'dd LLLL YYYY HH:mm:ss zzz'}} </td>
         </ng-container>

         <ng-container matColumnDef="role">
            <th mat-header-cell *matHeaderCellDef> Role </th>
            <td mat-cell *matCellDef="let element; let i = index">
               <div class="form-cell-int">
                  <mat-form-field appearance="outline">
                     <mat-select
                        name = "role"
                        [(value)]="element.roleId"
                        [disabled]="isDisabled(i)">
                        <mat-option *ngFor="let role of selectableRoles" [value]="role.id" (click)="userChanged(i)">{{role.name}}</mat-option>
                     </mat-select>
                  </mat-form-field>
               </div>
            </td>
         </ng-container>
         <ng-container matColumnDef="version">
            <th mat-header-cell *matHeaderCellDef> Version </th>
            <td mat-cell *matCellDef="let element; let i = index">
               <div class="form-cell-int">
                  <mat-form-field appearance="outline">
                     <mat-select
                        [(value)]="element.version"
                        [disabled]="isDisabled(i)">
                        <mat-option *ngFor="let version of editorVersions" [value]="version.id" (click)="userChanged(i)">{{version.name}}</mat-option>
                     </mat-select>
                  </mat-form-field>
               </div>
            </td>
         </ng-container>
         <ng-container matColumnDef="skin">
            <th mat-header-cell *matHeaderCellDef> Skin </th>
            <td mat-cell *matCellDef="let element; let i = index">
               <div class="form-cell-int">
                  <mat-form-field appearance="outline">
                     <mat-select
                        [(value)]="element.skin"
                        [disabled]="isDisabled(i)">
                        <mat-option *ngFor="let skin of editorSkins" [value]="skin.id" (click)="userChanged(i)">{{skin.name}}</mat-option>
                     </mat-select>
                  </mat-form-field>
               </div>
            </td>
         </ng-container>

         <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
         <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </mat-table>
      <section *ngIf="dataSource?.length" class="bb-api-paginator">
         <bb-api-paginator
            [loading]="!pageReady"
            [totalRowCount]="dataSource?.length ? dataSource.length: 0"
            [pageSize]="pagination[context]?.limit"
            (paginationChanged)="onPaginationChange($event)"
         ></bb-api-paginator>
      </section>

      <div *ngIf="updatedAccessIdx.length || deletedAccessIdx.length">
         <div class="save-user" *ngIf="context === 'Sessions'">
            <mat-form-field appearance="outline">
               <mat-label>User message on session end</mat-label>
               <input
                     matInput
                     (input)="updateSharedDisconnectMessage()"
                     [formControl]="disconnectMessageForm"
                     [errorStateMatcher]="matcherFC"
                  >
                  <mat-error *ngIf="disconnectMessageForm.hasError('required')">
                     A message is required to disconnect a user
                  </mat-error>
               </mat-form-field>
         </div>
         <div class="save-user" >
            <button
               mat-raised-button
               color="primary"
               type="submit"
               (click)="save()"
               [disabled]="context === 'Sessions' && sessionsSharedDisconnectMessageEmpty()"
               >
               <span *ngIf="context === 'Sessions'; else savechanges">Disconnect User Sessions</span>
               <ng-template #savechanges> Save Changes </ng-template>
            </button>
            <button mat-stroked-button color="primary" (click)="discardChanges()">
               Discard Changes
            </button>
         </div>
      </div>
   </div>
   <ng-template #nousersavailable><span class="nousers">{{noUsersAvailable()}}</span></ng-template>
</form>

