const ngModule = angular.module('bb.fbdn-pop-next-to-window', []);

ngModule.component('fbdnPopNextToWindow', {
   transclude: true,
   template:
      `<div tabindex="-1" role="dialog" class="popover">
         <div class="popover-dialog">
            <div class="popover-content" ng-transclude></div>
         </div>
         <div class="popover-arrow"></div>
      </div>`,
   controller: ['$element', function($element) {
      $element[0].focus();
   }],
});

export default ngModule;
