// Uses a dialog to asks the user a question whose possible answers are
// simple, e.g., "yes" or "no" or "replace", "discard" or "cancel", etc.
// Not intended for use directly in HTML code but instantiated using
// createTemplate() function with argument "alternatives" described
// below.
//
// Template arguments:
//    alternatives: array of dictionaries denoting all options presented
//       to user and their associated action. Alternatives appear in the
//       same order as in the array. Each dictionary must associate key
//       "text" to the string shown on the corresponding button and
//       "action" to the function that performs the desired corresponding
//       action. Additional optional keys include "onClose", can be set
//       to true to at most one element and that makes associated action
//       to be triggered when the dialog is closed in any other manner
//       than choosing an answer. If more than one option has onClose set
//       to true, then the behavior is undefined.
//       For example:
//       [
//          {
//             text: "Override",
//             action: () => { /* overriding */ },
//             onClose: false
//          },
//          {
//             text; "Append suffix",
//             action: () => { /* append suffix */ },
//             onClose: false
//          },
//          {
//             text: "Cancel",
//             action: () => { /* cancel */ },
//             onClose: true
//          }
//       ]
//
// Attributes:
//    scope, title, modal, dialogStyle, containerStyle:
//       passed on as is to dialog template (see above)
//
// Public members:
//    init(args):
//       Called by createTemplate to receives the list of alternatives
//       given to the user as well as the root DOM element containing
//       the dialog. See createTemplate() for more information.
//
// Private members:
//    doAction(action):
//       Trigger action the user chose, close dialog and remove it from
//       DOM.

export const fbdnSimpleChoiceDialog: InjectableDirectiveFactory = [function() {
   return {
      restrict: 'E',
      transclude: true,
      templateUrl: 'fbdn-simple-choice-dialog-template',
      scope: {
         modal: '=',
         title: '@',
         dialogStyle: '@',
         containerStyle: '@',
         scope: '='
      },
      link: function(scope: any) {
         scope.scope = scope;
         var onCloseDeactivate = null;
         var domElem = null;

         scope.init = function(args) {
            // Sets the action to run when the dialog is closed using another way than using a button
            for (var alt of args.alternatives) {
               if (alt.onClose && !onCloseDeactivate) {
                  onCloseDeactivate = scope.dialog.registerEvent('close', alt.action);
                  break;
               }
            }

            // Collect directive tag and remove it from its parent when dialog is closed
            domElem = args.dom;
            scope.dialog.registerEvent('close', () => {
               domElem.parentNode.removeChild(domElem);
            });
         };

         // Perform action chosen, close dialog and destroys it
         scope.doAction = function(action) {
            if (action) {
               // Prevent the default close action to be triggered
               // when closing dialog and before the chosen action
               // is triggered.
               if (onCloseDeactivate) {
                  scope.dialog.releaseEvent(onCloseDeactivate);
               }

               scope.dialog.close();
               action();
            }
         };
      }
   };
}];
