import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { downgradeComponent } from '@angular/upgrade/static';

import { ForceDisconnectComponent } from './force-disconnect.component';
import { ForceDisconnectDialogController } from './force-disconnect-dialog.controller';
import { AddUsersComponent } from './add-users.component';
import { EditAccountUsersComponent } from './edit-account-users.component';

import { SharedModule } from '../../shared';

/*
   This page has angular.js components in between serverside rendered html.
   We are not wrapping each one in an Angular UpgradeComponent because it would
   be cumbersome and give us no benefit. They are small and independent enough
   to instead be rewritten directly into Angular components, and then added
   to the below module.

   Contrary to the hybrid pages with single entrypoints, this module does
   not get lazily-loaded. We instead import it into the RootAppModule.
*/

/*
   Also to be added as entryComponents in RootAppModule to
   facilitate angular components in between serverside rendered html
*/
const accountUsersComponents = [
   AddUsersComponent,
   EditAccountUsersComponent,
];

@NgModule({
   declarations: accountUsersComponents,
   imports: [
      CommonModule,
      ReactiveFormsModule,
      SharedModule,
   ],
   providers: [],
   exports: accountUsersComponents,
})
export class AccountUsersAppModule {
   public static readonly components = accountUsersComponents;
}

export const AccountUsersAjsModule = angular.module('hybd.account.users', []);

AccountUsersAjsModule
   .component('forceDisconnect', ForceDisconnectComponent)
   .controller('ForceDisconnectDialog', ForceDisconnectDialogController)
   .directive('bbAddUsers', downgradeComponent({component: AddUsersComponent}))
   .directive('bbEditAccountUsers', downgradeComponent({component: EditAccountUsersComponent}));
