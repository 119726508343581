import { createReducer, on } from '@ngrx/store';
import { keyBy } from 'lodash';

import {
   createService,
   createServiceFail,
   createServiceSuccess,
   deleteService,
   deleteServiceFail,
   deleteServiceSuccess,
   loadEdgeService,
   loadEdgeServices,
   loadEdgeServicesFail,
   loadEdgeServicesSuccess,
   loadServiceTemplates,
   loadServiceTemplatesSuccess,
   loadServiceTypes,
   loadServiceTypesSuccess,
   removeServiceFromStore,
   saveService,
   saveServiceFail,
   saveServiceName,
   saveServiceSuccess,
   updateServiceName,
   upsertService
} from './edge-server-services.actions';
import { EdgeService, ServiceTemplate, ServiceTemplateMap, ServiceTypeMap } from './types';



export interface EdgeServerServicesState {
   edgeId: string | null;

   services: EdgeService[];
   servicesLoading: boolean;

   serviceTypes: ServiceTypeMap | null;
   serviceTypesLoading: boolean;

   serviceTemplates: ServiceTemplateMap | null;
   serviceTemplatesLoading: boolean;

   persistServiceLoading: boolean;
   error: string | null;
}

export const initialState: EdgeServerServicesState = {
   edgeId: null,
   services: [],
   servicesLoading: false,

   serviceTypes: null,
   serviceTypesLoading: false,

   serviceTemplates: null,
   serviceTemplatesLoading: false,

   persistServiceLoading: false,
   error: null,
};

export const edgeServerServicesReducer = createReducer(
   initialState,

   on(loadEdgeServices, (state) => ({ ...state, servicesLoading: true })),
   on(loadEdgeServicesSuccess, (state, { services }) => ({ ...state, servicesLoading: false, error: null, services })),
   on(loadEdgeServicesFail, (state, { errorMessage }) => ({ ...state, servicesLoading: false, error: errorMessage })),
   on(loadEdgeService, (state) => ({ ...state })),

   on(loadServiceTypes, (state) => ({ ...state, serviceTypesLoading: true })),
   on(loadServiceTypesSuccess, (state, { serviceTypes }) => {
      const serviceTypeMap = keyBy(serviceTypes, 'id');
      return {
         ...state,
         serviceTypesLoading: false,
         error: null,
         serviceTypes: serviceTypeMap,
      };
   }),

   on(loadServiceTemplates, (state) => ({ ...state })),
   on(loadServiceTemplatesSuccess, (state, { serviceTemplates }) => {
      let n = 0;
      const templates: ServiceTemplate[] = serviceTemplates.map(template => {
         // Flag this as a template, as we will have a menu with mixed service types and templates
         const $$isTemplate = true;
         // Generate local ids for the templates, which do not come with ids naturally
         const id = `template-${++n}`;
         return { ...template, $$isTemplate, id };
      });

      const serviceTemplateMap = keyBy(templates, 'id') ;
      return {
         ...state,
         servicesLoading: false,
         error: null,
         serviceTemplates: serviceTemplateMap,
      };
   }),

   on(saveService, (state) => ({ ...state, persistServiceLoading: true })),
   on(saveServiceSuccess, (state) => ({ ...state, persistServiceLoading: false, error: null })),
   on(saveServiceFail, (state) => ({ ...state, persistServiceLoading: false })),

   on(createService, (state) => ({ ...state, persistServiceLoading: true })),
   on(createServiceSuccess, (state, { service }) => ({
      ...state,
      services: [...state.services, service],
      persistServiceLoading: false,
   })),
   on(createServiceFail, (state) => ({ ...state, persistServiceLoading: false })),

   on(upsertService, (state, { service }) => {
      const services = [...state.services];
      const index = services.findIndex(e => e.id === service.id);
      if (index === -1) {
         services.push(service);
      } else {
         services[index] = service;
      }
      return { ...state, services };
   }),

   on(deleteService, (state) => ({ ...state })),
   on(deleteServiceSuccess, (state) => ({ ...state })),
   on(deleteServiceFail, (state) => ({ ...state })),
   on(removeServiceFromStore, (state, { id }) => ({
      ...state,
      services: state.services.filter(e => e.id !== id),
   })),

   on(saveServiceName, (state) => ({ ...state })),
   on(updateServiceName, (state, { id, name }) => {
      const services = [...state.services];
      const edgeIndex = services.findIndex(e => e.id === id);
      services[edgeIndex] = { ...services[edgeIndex], name };
      return { ...state, services };
   }),
);
