import ClickToEditFactoryModule from './click-to-edit.factory';

import { ClickToEditDirective } from './click-to-edit.directive';
import { ClickToEditTextareaDirective } from './click-to-edit-textarea.directive';
import { ClickToEditNoButtonsDirective } from './click-to-edit-no-buttons.directive';

const ngModule = angular.module('bb.click-to-edit', [
   ClickToEditFactoryModule.name,
]);

ngModule
   .directive('clickToEdit', ClickToEditDirective)
   .directive('clickToEditTextarea', ClickToEditTextareaDirective)
   .directive('clickToEditNoButtons', ClickToEditNoButtonsDirective);

export default ngModule;
