import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
   selector: 'bb-upload-button',
   templateUrl: './upload-button.component.html',
   styleUrls: ['./upload-button.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadButtonComponent {
   @Input() accountName: string;
}
