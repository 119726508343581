<div class="card">
   <h4 class="mat-dialog-title">
      Batch user creation
   </h4>
   <div>
      <p>{{ getSuccessfulUserCount() }} out of {{ data.importedUsers.length | i18nPlural: usersPluralMap }} were successfully imported.</p>
      <div class="table-container">
         <table class="users">
            <thead>
               <tr>
                  <td>#</td>
                  <td>Full name</td>
                  <td>User name</td>
                  <td>Company</td>
                  <td>Email address</td>
                  <td>Status</td>
               </tr>
            </thead>
            <tbody>
               <tr
                  *ngFor="let u of data.importedUsers; let i = index"
                  [ngClass]="u.success ? 'add-success' : 'add-failure'"
               >
                  <td>{{ i + 1 }}</td>
                  <td>{{ u.fullname }}</td>
                  <td>{{ u.username }}</td>
                  <td>{{ u.company }}</td>
                  <td>{{ u.email }}</td>
                  <td>{{ u.message }}</td>
               </tr>
            </tbody>
         </table>
      </div>
   </div>
</div>
<div class="buttons-container">
   <button mat-raised-button color="primary" (click)="concludeImportProcess()">
      Finish
   </button>
</div>
