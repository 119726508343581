import AsyncCacheModule from '../shared/utils/async-cache.factory';
import HttpModule from '../shared/fbdn-angular-modules/http';
import PageFactoryModule from '../shared/utils/page.factory';
import SaveTransactionModule from '../shared/utils/save-transaction';
import { NewStatefulObject, SaveTransaction } from '../shared/utils/save-transaction/save-transaction.factory';
import { AsyncCache } from '../shared/utils/async-cache.factory';
import { StatefulObject } from '../shared/utils/save-transaction/save-transaction.factory';
import { Network, Node, Icon, PerformActionResult, Location, SourceDestinationModules  } from './interfaces';
import { PublishModule, PublishModuleMap, Role } from '../shared/types';

interface PublishingButtonBase {
   id: string;
   name: string;
   description: string;
   enabled: boolean;
   keepFor: number | null;
   phases: {[key: string]: any /* Phase */};
}

type NewPublishingButton = PublishingButtonBase & NewStatefulObject;

export interface PublishingButton extends NewPublishingButton, StatefulObject<PublishingButton> {
   iconId: string;
   phaseId: string;
   phaseLocalId: string;
   destinationNodes: Node[];
   hasBadNetwork: string;
   hasComplicatedNetwork: string;
   location: Location;
   postNodes: Node[];
   preNodes: Node[];
   primaryNetwork: { destinationNode: Node; processNodes: Node[] };
   processNodes: Node[];
   restrictions: any[];
   secondaryNetworks: any;
   viewUrl: string;
   __hiddenSource: Node;
   $$cardMenuOpen: boolean;
   $$deleting: boolean;
   $$dirty: boolean;
   $$formStateId: number;
   $$stratified: boolean;
   $$structured: boolean;
}

interface PublishingButtonDto extends PublishingButtonBase {
   iconId: string;
   type?: string;
   defaultColumns?: string[];
   sortKey?: number;
   tooltip?: string;
}

interface StratifiedNetwork {
   processNodes: Node[];
   destinationNode: Node;
}

const ngModule = angular.module('bb.api.publishing', [
   AsyncCacheModule.name,
   HttpModule.name,
   PageFactoryModule.name,
   SaveTransactionModule.name,
]);

ngModule.factory('PublishingButtonApi', ['$q', 'AsyncCache', 'http', 'Page', 'SaveTransaction', '_', 'DynamicSchema',
                                   function($q: IQService, asyncCache: AsyncCache, http: any, Page: Page, saveTransaction: SaveTransaction, _: Lodash, DynamicSchema: any) {
   let id = 0;
   const cache = asyncCache('PublishingButtonApi');

   function _indexModules(modules: PublishModule[]): PublishModuleMap {
      return _.keyBy(modules, 'id');
   }

   function _indexIcons(icons: Icon[]): { [id: string]: Icon } {
      return _.keyBy(icons, 'id');
   }

   function _marshallButton(button: PublishingButton): PublishingButtonDto {
      const result = _.clone(button);

      if (button.$$stratified) {
         // Save using the "stratified" network api if possible
         const phase = {
            id: result.phaseId,
            primaryNetwork: result.primaryNetwork,
            secondaryNetworks: result.secondaryNetworks,
            preNodes: result.preNodes,
            postNodes: result.postNodes
         };

         result.phases = {};
         result.phases[result.phaseLocalId || "0"] = {
            phase: phase,
            inputs: {}
         };
      }

      // button location applies to all phases
      if (button.location) {
         _.forEach(result.phases, phase => {
            phase.location = _.pick(button.location, ["type", "id"]);
         });
      }

      // otherwise we will just save result.phases directly
      delete result.location;
      delete result.phaseLocalId;
      delete result.phaseId;
      delete result.primaryNetwork;
      delete result.secondaryNetworks;
      delete result.preNodes;
      delete result.postNodes;
      delete result.hasBadNetwork;
      delete result.hasComplicatedNetwork;
      delete result.restrictions;
      delete result.viewUrl;
      delete result.__hiddenSource;
      return result;
   }

   function _unmarshallButton(button: PublishingButtonDto | NewPublishingButton): PublishingButton {
      const unmarshalledButton = button as PublishingButton;
      if (unmarshalledButton.$$structured) return unmarshalledButton;

      const phaseLocalIds = _.keys(unmarshalledButton.phases);
      if (phaseLocalIds.length === 1) {
         const buttonPhase = unmarshalledButton.phases[phaseLocalIds[0]]; // Object that links the button to the phase and has button-specific info
         const phase = buttonPhase.phase; // Phase itself
         unmarshalledButton.hasBadNetwork = phase.hasBadNetwork || ""; // force to empty string so we can regenerate it without triggering change detection
         unmarshalledButton.hasComplicatedNetwork = phase.hasComplicatedNetwork;
         unmarshalledButton.phaseId = phase.id;
         unmarshalledButton.phaseLocalId = phaseLocalIds[0];
         unmarshalledButton.location = buttonPhase.location;

         // We will replace all the nodes that came from the API JSON
         // into stratified parts with shared objects from the
         // un-stratified parts so both UIs are updating the same
         // options data
         const _sharedNode = (node: Node) => {
            return phase.network.nodes[node.nodeLocalId];
         };

         const _makeNetworkUseSharedNodes = (stratifiedNetwork: StratifiedNetwork) => {
            stratifiedNetwork.processNodes = _.map(stratifiedNetwork.processNodes, _sharedNode);
            stratifiedNetwork.destinationNode = _sharedNode(stratifiedNetwork.destinationNode);
            return stratifiedNetwork;
         };

         if (!(unmarshalledButton.hasBadNetwork || unmarshalledButton.hasComplicatedNetwork)) {
            if (phase.secondaryNetworks.length === 0) {
               unmarshalledButton.primaryNetwork = _makeNetworkUseSharedNodes(phase.primaryNetwork);
               unmarshalledButton.secondaryNetworks = _.map(phase.secondaryNetworks, _makeNetworkUseSharedNodes);
               unmarshalledButton.preNodes = _.map(phase.preNodes, _sharedNode);
               unmarshalledButton.processNodes = unmarshalledButton.primaryNetwork.processNodes;
               unmarshalledButton.destinationNodes = [unmarshalledButton.primaryNetwork.destinationNode];
               unmarshalledButton.postNodes = _.map(phase.postNodes, _sharedNode);
               unmarshalledButton.$$stratified = true;
            } else {
               unmarshalledButton.hasComplicatedNetwork = "Button has " + phase.secondaryNetworks.length + " secondary networks";
            }
         }

      } else {
         unmarshalledButton.hasComplicatedNetwork = "Button has " + phaseLocalIds.length + " phases";
      }

      _.forEach(unmarshalledButton.phases, phase => {
         _.forEach(phase.phase.network.nodes, (node: Node, nodeLocalId: string) => {
            // Make these nodes look like the stratified ones for the moment.
            node.nodeLocalId = +nodeLocalId; // js keys always strings, put an int-forced copy as a member
         });
      });

      unmarshalledButton.$$structured = true;
      unmarshalledButton.$$deleting = false;
      return unmarshalledButton;
   }

   function fetchSiteLocations(): any {
      return cache.fetch('sitelocations-'+Page.siteId, () => {
         return http({
            method: 'GET',
            url: '/api/sites/' + Page.siteId + '/publishLocations'
         });
      });
   }

   function fetchAccountLocations(): any {
      return cache.fetch('accountlocations-'+Page.accountId, () => {
         return http({
            method: 'GET',
            url: '/api/accounts/' + Page.accountId + '/publishLocations'
         });
      });
   }

   // Get a text key that can be used to index and identify locations both returned
   // by the methods above those returned inside buttons
   function getLocationKey(location: Location): string {
      return location.type + ':' + (location.id || '');
   }

   function fetchSiteModules(kind: string): Promise<any> {
      return http({
         method: 'GET',
         url: '/api/sites/' + Page.siteId + '/publishModules',
         params: {kind: kind}
      }).then(_indexModules);
   }

   function fetchAccountModules(kind: string): Promise<any> {
      return http({
         method: 'GET',
         url: '/api/accounts/' + Page.accountId + '/publishModules',
         params: {kind: kind}
      }).then(_indexModules);
   }

   function fetchSiteButtons(): Promise<any> {
      return http({
         method: 'GET',
         url: '/api/sites/' + Page.siteId + '/publishButtons',
         params: {phases: 'expand'}
      }).then(_.partialRight(_.forEach, _unmarshallButton));
   }

   function fetchAccountButtons(includeAll: boolean): Promise<any> {
      return http({
         method: 'GET',
         url: '/api/accounts/' + Page.accountId + '/publishButtons',
         params: {includeAll: !!includeAll, phases: 'expand'}
      }).then(_.partialRight(_.forEach, _unmarshallButton));
   }

   function fetchSiteIcons(): Promise<any> {
      return http({
         method: 'GET',
         url: '/api/sites/' + Page.siteId + '/publishIcons'
      }).then(_indexIcons);
   }

   function fetchAccountIcons(): Promise<any> {
      return http({
      method: 'GET',
         url: '/api/accounts/' + Page.accountId + '/publishIcons'
      }).then(_indexIcons);
   }

   function createSiteButton(button: PublishingButton): Promise<any> {
      return saveTransaction.saveObject(button, () => {
         return http({
            method: 'POST',
            url: '/api/sites/' + Page.siteId + '/publishButtons',
            data: _marshallButton(button)
         }).then(function(results) {
            button.id = results.id;
            return results;
         });
      });
   }

   function _newRestrictions(roles: Role[]): {roleId: string;roleName: string; allow: boolean}[] {
      return _.map(roles, (role: Role) => {
         return {
            roleId: role.id,
            roleName: role.name,
            allow: true
         };
      });
   }

   function fetchButtonRoleRestrictions(button: PublishingButton, roles: Role[]): any {
      const buttonId = button.$$temporaryId || button.id;

      const rolesFetcher = () => {
         return button.$$temporaryId
            ? $q.when(_newRestrictions(roles))
            : http({
               method: 'GET',
               url: '/api/publishButtons/' + buttonId + '/roleRestrictions'
            });
      };

      return cache.fetch(`restrictions-${buttonId}`, rolesFetcher);
   }

   function saveButtonRoleRestrictions(button: PublishingButton): Promise<any>{
      return http({
         method: 'POST',
         url: '/api/publishButtons/' + button.id + '/roleRestrictions',
         data: button.restrictions
      });
   }

   function fetchButtonStatus(button: PublishingButton): Promise<any> {
      return !button.id ? $q.when({ numberOfPublishedItems: 0 }) : http({
         method: 'GET',
         url: '/api/publishButtons/' + button.id + '/status'
      });
   }

   function createAccountButton(button: PublishingButton): Promise<any> {
      return saveTransaction.saveObject(button, () => {
         return http({
            method: 'POST',
            url: '/api/accounts/' + Page.accountId + '/publishButtons',
            data: _marshallButton(button)
         }).then(function(results) {
            button.id = results.id;
            return results;
         });
      });
   }

   function deleteButton(button: PublishingButton, collectionToUpdate: []): Promise<any> {
      button.$$deleting = true;
      return (!button.id ? $q.when() : http({
         method: 'POST',
         url: '/api/publishButtons/' + button.id + '/trash'
      })).then(function(results) {
         _.pull(collectionToUpdate, button);
         return results;
      }, function(error) {
         button.$$deleting = false;
         return $q.reject(error);
      });
   }

   function deletePublishIcon(icon: Icon): Promise<any> {
      return saveTransaction.saveObject(icon, () => {
         return http({
            method: 'POST',
            url: '/api/publishIcons/' + icon.id + '/trash'
         });
      });
   }

   function getSiteUploadIconUrl(): string {
      return '/api/sites/' + Page.siteId + '/publishIcons';
   }

   function getAccountUploadIconUrl(): string {
      return '/api/accounts/' + Page.accountId + '/publishIcons';
   }

   function saveButton(button: PublishingButton): Promise<any> {
      return saveTransaction.saveObject(button, () => {
         return http({
            method: 'POST',
            url: '/api/publishButtons/' + button.id,
            data: _marshallButton(button)
         });
      });
   }

   function saveButtonName(button: PublishingButton): any {
      return saveTransaction.save(button, 'name', () => {
         return http({
            method: 'POST',
            url: '/api/publishButtons/' + button.id,
            data: {
               id: button.id,
               name: button.name
            }
         });
      });
   }

   function _performAction(node: Node, url: string, state: string): Promise<PerformActionResult> {
      return http({
         method: 'POST',
         url: url,
         data: {
            options: node.options,
            data: {state: state}
         }
      });
   }

   function performActionSite(node: Node, action: string, state: string): Promise<any> {
      return _performAction(node, '/api/sites/' + Page.siteId + '/publishModules/' + node.moduleId + '/actions/' + action, state);
   }

   function performActionAccount(node: Node, action: string, state: string): Promise<any> {
      return _performAction(node, '/api/accounts/' + Page.accountId + '/publishModules/' + node.moduleId + '/actions/' + action, state);
   }

   function getNewButton(chosenModules: SourceDestinationModules, getModule: (id: string) => PublishModule): PublishingButton {
      const network = {nodes: {}, connections: []};
      const destinationModule = chosenModules.destination;
      const destinationNode = {
         moduleId: destinationModule.id,
         options: DynamicSchema.defaultValuesForSchema(destinationModule.schema),
         nodeLocalId: 1,
      };
      network.nodes[destinationNode.nodeLocalId] = destinationNode;
      const sourceModule = chosenModules.source;
      const sourceNode = {
         moduleId: sourceModule.id,
         options: DynamicSchema.defaultValuesForSchema(sourceModule.schema),
         nodeLocalId: 0
      };
      // We will only be adding the sourceNode to network if it is actually used
      let preNodes = []; // gets switched to [sourceNode] if any process module needs it
      const processNodes = [];
      let nextNodeId = 2;
      _.forEach(destinationModule.defaultInputModuleIds, (moduleId: string) => {
         const nodeId = nextNodeId;
         nextNodeId += 1;
         const processSchema = getModule(moduleId).schema;
         const node = {
            moduleId: moduleId,
            options: DynamicSchema.defaultValuesForSchema(processSchema),
            nodeLocalId: nodeId
         };
         network.nodes[nodeId] = node;
         if (getModule(moduleId).minInputModules) {
            // Process module does want input, so we will use sourceNode
            preNodes = [sourceNode];
            network.nodes[sourceNode.nodeLocalId] = sourceNode;
            network.connections.push([sourceNode.nodeLocalId, nodeId]);
         }
         network.connections.push([nodeId, destinationNode.nodeLocalId]);
         processNodes.push(node);
      });
      const button: NewPublishingButton = {
         $$state: 'new',
         $$temporaryId: ++id,
         id: null,
         name: destinationModule.name,
         description: destinationModule.description,
         keepFor: null,
         enabled: true,
         phases: {
            0: {
               phase: {
                  id: null,
                  hasComplicatedNetwork: false, // New buttons can start off with automatically managed networks
                  primaryNetwork: {
                     destinationNode: destinationNode,
                     processNodes: processNodes
                  },
                  secondaryNetworks: [],
                  preNodes: preNodes,
                  postNodes: [],
                  network: network
               },
               location: {
                  type: 'cloud',
                  id: null,
               },
               inputs: {}
            }
         }
      };
      return _unmarshallButton(button);
   }

   function validateNetwork(network: Network, getModule: (id: string) => PublishModule): string[] {
      // This is supposed to be doing roughly the same as 'validate' in buttons.py
      // We only have modules that are legal for us, others will have 'isUnknown' set.
      const problems = []; // Return a list of problems. (strings)
      _.forEach(network.connections, conn => {
         if (!network.nodes[conn[0]] || !network.nodes[conn[1]]) {
            problems.push("Hanging connection "+conn); // UI should not do this
         }
      });
      if (problems.length) return problems; // no point checking anything else when have bad connections
      _.forEach(network.nodes, node => {
         const module = getModule(node.moduleId);
         if (module.isUnknown) {
            problems.push("Invalid module "+node.moduleId);
         }
         const inputConnections =  _.filter(network.connections, conn => conn[1]===node.nodeLocalId);
         const outputConnections = _.filter(network.connections, conn => conn[0]===node.nodeLocalId);
         if (inputConnections.length < module.minInputModules) {
            problems.push("Too few inputs for "+module.name);
         }
         if (module.maxInputModules !== null && inputConnections.length > module.maxInputModules) {
            problems.push("Too many inputs for "+module.name);
         }
         _.forEach(inputConnections, conn => {
            const inputNode = network.nodes[conn[0]];
            const inputModule = getModule(inputNode.moduleId);
            if (!module.selectableInputModuleIds.includes(inputModule.id) && !module.nonSelectableInputModuleIds.includes(inputModule.id)) {
               problems.push(inputModule.name + " cannot be connected to " + module.name);
            }
         });
         if ((module.type==="process" || module.type==='source') && !outputConnections.length) {
            problems.push(module.name + " must go to a destination");
         }
      });
      return problems;
   }

   function networkNextNodeLocalId(network: Network): number {
      return (_.max(_.map(network.nodes, 'nodeLocalId')) || 0) + 1;
   }

   function networkRemoveNode(network: Network, node: Node): void {
      delete network.nodes[node.nodeLocalId];
      _.remove(network.connections, conn => conn[0]===node.nodeLocalId || conn[1]===node.nodeLocalId);
   }

   function saveEnabled(publishButton: PublishingButton): Promise<any> {
      return saveTransaction.save(publishButton, 'enabled', (publishingButton: PublishingButton) => {
         return http({
            method: 'POST',
            url: '/api/publishButtons/' + publishingButton.id,
            data: {
               enabled: publishingButton.enabled
            }
         });
      });
   }

   const contextApis = {
      site: {
         createButton: createSiteButton,
         fetchModules: fetchSiteModules,
         fetchButtons: fetchSiteButtons,
         fetchIcons: fetchSiteIcons,
         performAction: performActionSite,
         getUploadIconUrl: getSiteUploadIconUrl
      },
      account: {
         createButton: createAccountButton,
         fetchModules: fetchAccountModules,
         fetchButtons: fetchAccountButtons,
         fetchIcons: fetchAccountIcons,
         performAction: performActionAccount,
         getUploadIconUrl: getAccountUploadIconUrl
      }
   };

   return _.merge({
      _unmarshallButton: _unmarshallButton, // exported only so that NodeApi clients can unmarshall node buttons
      saveButton: saveButton,
      deleteButton: deleteButton,
      deletePublishIcon: deletePublishIcon,
      saveButtonName: saveButtonName,
      getNewButton: getNewButton,
      fetchButtonStatus: fetchButtonStatus,
      getLocationKey: getLocationKey,
      saveEnabled: saveEnabled,
      fetchButtonRoleRestrictions: fetchButtonRoleRestrictions,
      saveButtonRoleRestrictions: saveButtonRoleRestrictions,
      fetchAccountLocations: fetchAccountLocations,
      fetchSiteLocations: fetchSiteLocations,
      validateNetwork: validateNetwork,
      networkNextNodeLocalId: networkNextNodeLocalId,
      networkRemoveNode: networkRemoveNode
   }, contextApis[Page.context]);
}]);

export default ngModule;
