<form *ngIf="keyboardMaps?.length && form?.get('expansionMap').length" [formGroup]="form">
   <mat-table #table [dataSource]="keyboardMaps" formArrayName="expansionMap" class="mat-elevation-z0">
      <ng-container matColumnDef="mapname">
         <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
         <mat-cell *matCellDef="let element">
            {{element.name}}
         </mat-cell>
      </ng-container>

      <ng-container matColumnDef="downloadoption">
         <mat-header-cell *matHeaderCellDef> Download Options </mat-header-cell>
         <mat-cell *matCellDef="let element;">
            <a mat-raised-button color="primary" [href]="'/accounts/' + accountName + '/generatekeyexpansion?id=' + element.id">Download as XML</a>
         </mat-cell>
      </ng-container>

      <ng-container matColumnDef="exportaccount">
         <mat-header-cell *matHeaderCellDef> Export to Account </mat-header-cell>
         <mat-cell *matCellDef="let element; let i=index;" [formGroupName]="i">
            <mat-form-field>
               <mat-select formControlName="expansionMapNewAccount">
                  <mat-option [value]="account" *ngFor="let account of managedAccounts">
                     {{ account.name }}
                  </mat-option>
               </mat-select>
            </mat-form-field>
         </mat-cell>
      </ng-container>

      <ng-container matColumnDef="newexportname">
         <mat-header-cell *matHeaderCellDef> New Name </mat-header-cell>
         <mat-cell *matCellDef="let element; let i=index;" [formGroupName]="i">
            <div>
               <mat-form-field>
                  <mat-label>Enter new name</mat-label>
                  <input
                     type="text"
                     matInput
                     required
                     formControlName="expansionMapNewName"
                     [errorStateMatcher]="matcher"
                  >
                  <mat-error *ngIf="form.get('expansionMap').at(i).get('expansionMapNewName').hasError('required')">
                     This field cannot be empty
                  </mat-error>
                  <mat-error *ngIf="form.get('expansionMap').at(i).hasError('duplicatedMapName')">
                     This name is already in use
                  </mat-error>
               </mat-form-field>
            </div>
         </mat-cell>
      </ng-container>

      <ng-container matColumnDef="exportaction">
         <mat-header-cell *matHeaderCellDef>Export</mat-header-cell>
         <mat-cell *matCellDef="let element; let i=index;">
            <button mat-raised-button color="primary" [disabled]="isMapNameInvalid(i)" (click)="exportMap(element, i)">Export</button>
         </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;" ></mat-row>
   </mat-table>
</form>
<div *ngIf="!keyboardMaps?.length">
   No Keyboard Expansion Maps Defined: please create them in the editor first
</div>