import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';

import { keyInsertionOrder } from '../../../../shared/filters/key-insertion-order';
import { PasswordRules } from '../password-api.service';


type StrengthType = 'danger' | 'warning' | 'success';

interface PasswordStrength {
   value: number;
   type: StrengthType;
   text: 'unsafe' | 'moderate' | 'safe';
}


@Component({
   selector: 'bb-password-strength',
   templateUrl: './password-strength.component.html',
   styleUrls: ['./password-strength.component.scss'],
})
export class PasswordStrengthComponent {
   @Input() form: FormGroup;
   @Input() rules: PasswordRules;
   @Input() position: 'side' | 'above';

   public keyInsertionOrder = keyInsertionOrder;

   public ruleValidates(form: FormGroup, ruleKey: keyof PasswordRules): boolean {
      const { value, errors } = form.get('newPasswordGroup.newPassword');
      return value && !errors?.[ruleKey];
   }

   public getProgressBarColor(strengthType: StrengthType): ThemePalette {
      return strengthType === 'success'
         ? 'accent'
         : strengthType === 'danger'
            ? 'warn'
            : 'primary';
   }

   public getPasswordStrength(form: FormGroup, rules: PasswordRules): PasswordStrength {
      const passedRuleCount = this.getPassedValidatorsCount(form, rules);
      const rulesCount = Object.keys(rules).length;
      const strengthValue = rulesCount ? (100 * passedRuleCount / rulesCount) : 50;
      if (strengthValue < 50) {
         return { type: 'danger',  text: 'unsafe',   value: Math.max(strengthValue, 20) };
      } else if (strengthValue < 100) {
         return { type: 'warning', text: 'moderate', value: strengthValue };
      } else {
         return { type: 'success', text: 'safe',     value: strengthValue };
      }
   }

   private getPassedValidatorsCount(form: FormGroup, rules: PasswordRules): number {
      const { errors } = form.get('newPasswordGroup.newPassword');
      return Object.entries(rules).reduce((prev, [key, rule]) => {
         return errors?.[key] ? 0 : ++prev;
      }, 0);
   }
}
