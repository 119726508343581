import { createReducer, on } from '@ngrx/store';

import { showNotification } from './notifications.actions';
import { Notification } from '../../shared/types';

export interface NotificationsState {
   notification: Notification | null;
}

export const initialState: NotificationsState = {
   notification: null,
};

/*
 * Until the application is entirely in Angular, we need to relay notifications to the angular.js
 * NotificationService. This store provides a mechanism of doing so from within global/entity
 * @Effects classes which are loaded in the RootAppModule before the angular.js injector is available.
 *
 * On every page with notifications dispatched from within an @Effects class we must ensure that we
 * are subscribing to selectNotification to handle the relaying back to the angular.js NotificationService,
 * that is, until the Control Centre is an SPA (and if we then still want to show global notifications).
 *
   e.g. in a page's top-level component:
   @Inject('NotificationService') private notificationService: NotificationService // get from angular.js injector
   ...
      this.store.select(selectNotification)
         .pipe(filter(n => !!n))
         .subscribe(({type, text}) => this.notificationService.show({ type, text }));
 */

export const notificationsReducer = createReducer(
   initialState,

   on(showNotification, (state, { notificationText, notificationType }) => {
      const notification = { type: notificationType, text: notificationText };
      return { ...state, notification };
   }),
);
