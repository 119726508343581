import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

import { UpdateNotificationSettingsDto, UsersApiService } from '../../../shared/api/users.api.service';
import { AlertService } from '../../../shared/services/alert.service';
import { PageService } from '../../../shared/services/page.service';


@Component({
   selector: 'bb-email-notification-settings',
   templateUrl: './email-notification-settings.component.html',
   styleUrls: ['./email-notification-settings.component.scss'],
})
export class EmailNotificationSettingsComponent implements OnInit, OnDestroy {
   public notificationsEnabled: boolean;
   public loadingInitialData = true;
   public updating = false;

   private destroy$ = new Subject<void>();
   private page: Page;

   constructor(
      private usersApiService: UsersApiService,
      private pageService: PageService,
      private alertService: AlertService,
   ) {
      this.page = this.pageService.page;
   }

   ngOnInit() {
      this.usersApiService.fetchNotificationSettings(this.page.userId).pipe(
         finalize(() => this.loadingInitialData = false),
         this.alertService.notifyOnError('fetching the notification settings'),
         takeUntil(this.destroy$),
      ).subscribe(settings => {
         this.notificationsEnabled = !!(settings.find(setting => setting.type === 'email'));
      });
   }

   ngOnDestroy() {
      this.destroy$.next();
      this.destroy$.complete();
   }

   public updateEnabledStatus(enabled: boolean): void {
      this.updating = true;
      this.notificationsEnabled = enabled;

      const data: UpdateNotificationSettingsDto = {
         [enabled ? 'add' : 'remove']: [{type: 'email'}],
      };

      this.usersApiService.updateNotificationSettings(this.page.userId, data).pipe(
         finalize(() => this.updating = false),
         this.alertService.notifyOnError('updating your notification settings'),
         takeUntil(this.destroy$),
      ).subscribe({
         next: () => {
            this.alertService.show({
               type: 'success',
               text: 'Your email notification settings have successfully been updated',
            });
         },
         error: error => this.notificationsEnabled = !enabled, // Revert to previous state on update errors
      });

   }
}
