// This is mirrored in python see lib.py#checkvalidname
export const RESERVED_NAMES = [
   'forscene', 'clesh', 'admin', 'furniture',
   'httptest', 'logos', 'paypal', 'paypalreturn', 'banners',
   'kestreladmin', 'mobile', 'sms', 'speedtest',
   'login', 'logout', 'loginbox', 'multipleserver', 'register', // login.py urls
   'cookiecheck', 'requestreset', 'setpassword',
   'javaconfig', 'help', 'tutorial', 'quickguide', 'cleshload',
   'chat', 'meta', 'load', // Reserved names for client event system
   'tmp', 'garbage collected',
   'xmlrpc', 'rest', 'soap', 'api', // Reserved names for external APIs
   'videos', 'sourcefetch', 'mos', 'proxy', 'jobs', // Reserved names for proxybox interfaces
   'linkup', 'connect', 'ptth', 'ptth2',
   'accounts', 'users', 'site', 'user', 'edge',
   'invite', 'accept', 'reset', // More names for internal names
   'create',
   'update', 'contact', // for Android applet checking
   'sitegroup', // New sitegroup pages
   'cloud', // cloud admin pages
   'search', // available to cloud admins and in future - resellers
   'webhook', // base url for handler for 3rd party callbacks
   'jsplayer', // JavaScript player html page
   'blackbird', 'ascent', 'forte', // possible blackbird branding
   'control', 'cc', // possible control centre urls
   'batch', // url for batch requests
   'bandwidthtest', // bandwidthtest
   'diagnostic', // cloud diagnostic page
   'mfaenrol', // MFA enrolment page
];
