export enum LaunchModeName {
   inherit = 'inherit',
   cheerpj = 'cheerpj',
   cheerpjhighmem = 'cheerpjhighmem',
}

interface LaunchModeBase {
   label: string;
   params: EditParams;
   default: boolean;
}

interface EditParams {
   maxheap: number;
   openfile?: string;
   openfilelist?: string;
   roleid?: string;
}

interface CheerpJLaunchMode extends LaunchModeBase {
   kind: 'cheerpj';
   action: 'edit';
   launchFn(url: string): void;
}

export type LaunchMode = CheerpJLaunchMode;

export type LaunchModes = {
   [LaunchModeName.cheerpjhighmem]:   CheerpJLaunchMode;
   [LaunchModeName.cheerpj]:          CheerpJLaunchMode;
};

export interface LaunchModeDto {
   name: LaunchModeName;
   kind: 'cheerpj';
   path: string;
   label: string;
   default?: boolean;
}
