import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { USERS_PLURAL_MAP } from '../users-plural-map.const';
import { ImportedUser } from '../imported-user.interface';

export interface ImportSuccessData {
   importedUsers: ImportedUser[];
}

@Component({
   selector: 'bb-import-success',
   templateUrl: './import-success.component.html',
   styleUrls: ['./import-success.component.scss'],
   changeDetection: ChangeDetectionStrategy.Default,
})
export class ImportSuccessComponent {
   public readonly usersPluralMap = USERS_PLURAL_MAP;

   constructor(
      public dialogRef: MatDialogRef<ImportSuccessComponent>,
      @Inject(MAT_DIALOG_DATA) public data: ImportSuccessData,
   ) {}

   public getSuccessfulUserCount(): number {
      return this.data.importedUsers.filter(user => user.success).length;
   }

   public concludeImportProcess(): void {
      this.dialogRef.close();
   }
}
