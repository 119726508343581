export class DurationFilter {
   static readonly id = 'duration';
   static filter(): IFilterFunction {
      function pad(num: number): string | number {
         if (num <= 9) return '0' + num;
         return num;
      }

      return (timeInSeconds: number): string => {
         timeInSeconds = parseInt(String(timeInSeconds), 10);
         if (!timeInSeconds) return "";
         if (timeInSeconds<60) return timeInSeconds + "s";
         const seconds = timeInSeconds % 60;
         const minutes = (timeInSeconds - seconds) / 60;
         if (minutes<60) return minutes + ":" + pad(seconds)+"s";
         const oddMinutes = minutes % 60;
         const hours = (minutes - oddMinutes) / 60;
         return hours + ":" + pad(oddMinutes)+"m";
      };
   }
}
