<h2 mat-dialog-title>{{ dialogTitle }}</h2>
<mat-dialog-content [formGroup]="form">
    <mat-form-field appearance="outline" *ngFor="let inputType of publishOptions">
        <mat-label>{{ inputType?.label }}</mat-label>
        <input matInput formControlName="{{inputType?.name}}" *ngIf="inputType?.type == 'text'" placeholder="{{inputType?.label}}" required>
        <textarea matInput formControlName="{{inputType?.name}}" *ngIf="inputType?.type == 'textarea'" placeholder="{{inputType?.label}}" required> </textarea>
        <mat-select formControlName="{{inputType?.name}}" placeholder="{{inputType?.label}}" *ngIf="inputType?.type == 'select'" required>
            <mat-option *ngFor="let valueItem of inputType?.valueLabels" value="{{valueItem?.value}}">{{valueItem?.label}}</mat-option>
        </mat-select>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-dialog-close mat-stroked-button>Cancel</button>
    <button mat-raised-button [mat-dialog-close]="'publish'" mat-primary (click)="publish()" color="primary" [disabled]="form.invalid">Publish</button>
</mat-dialog-actions>