const ngModule = angular.module('bb.auto-updating-img', []);

export const AutoUpdatingImgComponent = {
   bindings: {
      url: '<',
      width: '<',
      height: '<',
   },
   template:
      `<img src="{{vm.updatedUrl}}" width="{{vm.width}}" height="{{vm.height}}">`,
   controllerAs: 'vm',
   controller: class AutoUpdatingImgController {
      static $inject = ['$element'];

      /* Input */ public url: string;
      /* Input */ public width: number;
      /* Input */ public height: number;

      public updatedUrl = '';

      private timeUntilRetryFail = 0;
      private interval: number;

      constructor(private $element: IRootElementService) {}

      $onInit() {
         this.interval = window.setInterval(() => {
            const imgElement = Array.from(this.$element[0].children)[0] as HTMLImageElement;
            if (this.timeUntilRetryFail <= 0 || imgElement.complete) {
               this.updatedUrl = this.url + '&' + new window.Date().getTime();
               this.timeUntilRetryFail = 30;
            } else {
               this.timeUntilRetryFail -= 1;
            }
         }, 1000);
      }

      $onDestroy() {
         window.clearInterval(this.interval);
      }
   }
};

ngModule.component('autoUpdatingImg', AutoUpdatingImgComponent);

export default ngModule;
