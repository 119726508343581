const ngModule = angular.module('bb.utils.skeleton-cache', []);

interface SkeletonOptions {
   marshall?: (model: any) => any;
   unmarshall?: (rawModel: any) => any;
   collectionOnly?: boolean;
}

interface RawCollection {
   results: any[];
   channel?: any;
}

ngModule.factory('SkeletonCache', ['_', function(_: Lodash) {
   var cache = {};

   return function(namespace: string, options: SkeletonOptions) {
      options = options || {};
      _.defaults(options, {
         marshall: _.identity,
         unmarshall: _.identity,
         collectionOnly: false
      });
      cache[namespace] = cache[namespace] || { objects: {}, collections: {} };
      var objectCache = cache[namespace].objects;
      var collectionCache = cache[namespace].collections;

      function add(model: Required<{id: string}>): void {
         if (model.id) {
            objectCache[model.id] = model;
         }
      }

      function get(modelId: string) {
         if (modelId in objectCache) return objectCache[modelId];
         var skeleton = {
            id: modelId ? modelId : null,
            $$deleting: false,
            $$selected: false,
            $$state: modelId ? 'uninitialised' : 'clean',
            $$saved: {},
            $$managedBySkeletonCache: true
         };
         add(skeleton);
         return skeleton;
      }

      function mergeIntoSkeleton(model: any) {
         if (options.collectionOnly) return model;
         var skeleton = get(model.id);
         model.$$state = 'clean';
         _.forEach(model, function(value, key) {
            if (key === '$$saved') return;
            if (!(key in skeleton.$$saved) || angular.equals(skeleton.$$saved[key], skeleton[key])) {
               skeleton[key] = value;
            }
            skeleton.$$saved[key] = _.includes(model.$$deepKeys, key) ? _.cloneDeep(value) : value;
         });
         return skeleton;
      }

      function marshall(model: any) {
         return options.marshall(model);
      }

      function unmarshall(rawModel: any) {
         return mergeIntoSkeleton(options.unmarshall(rawModel));
      }

      function unmarshallCollection(rawCollection: RawCollection): RawCollection {
         // Maintain a single updated cached array for the collection of unmarshalled results
         var cookedResults = rawCollection.results.map(item =>  unmarshall(item));
         if (rawCollection.channel) {
            var cachedResults = collectionCache[rawCollection.channel] || (collectionCache[rawCollection.channel] = []);
            var toAdd = _.difference(cookedResults, cachedResults);
            var toRemove = _.difference(cachedResults, cookedResults);
            cachedResults.push.apply(cachedResults, toAdd);
            _.pull.apply(null, [cachedResults].concat(toRemove));
            cookedResults = cachedResults;
         }
         return angular.extend({}, rawCollection, { results: cookedResults });
      }

      function unmarshallResults(rawCollection: RawCollection): RawCollection {
         var cookedResults = rawCollection.results.map(item => unmarshall(item));
         return angular.extend({}, rawCollection, { results: cookedResults });
      }

      return {
         get: get,
         add: add,
         marshall: marshall,
         unmarshall: unmarshall,
         unmarshallCollection: unmarshallCollection,
         unmarshallResults: unmarshallResults,
         mergeIntoSkeleton: mergeIntoSkeleton
      };
   };
}]);

export default ngModule;
