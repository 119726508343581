import { Component, OnInit, OnDestroy, Inject, Input, ViewChild } from '@angular/core';
import { finalize, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { IngestFormatsApiService} from '../../../shared/services/ingest-formats.api.service';
import { MatTable } from '@angular/material/table';
import { EditorState } from '../../../shared/components/types/editor-state.type';
import { AlertService } from '../../../shared/services/alert.service';
import { PageService } from '../../../shared/services/page.service';

export interface IngestFormat {
   standard: string;
   aspect: string;
   resolution: string;
}

export interface IdNameMap {
   id: string;
   name: string;
}

@Component({
   selector: 'bb-ingest-formats',
   styleUrls: ['./ingest-formats.component.scss' ],
   templateUrl: './ingest-formats.component.html',
})
export class IngestFormatsComponent implements OnInit, OnDestroy {
   @Input() maxRes: string;

   @ViewChild(MatTable) ingestFormatsTable: MatTable<IngestFormat[]>;

   public displayedColumns = ['rate', 'ratio', 'resolution', 'delete'];
   public dataSource: IngestFormat[] | undefined;
   public originalDataSource: IngestFormat[] | undefined;
   public state: EditorState;
   public saveEnabled = false;
   public resolutions: Map<string, IdNameMap[]>;

   public readonly standards: IdNameMap[] = [
      { id: 'PAL',  name: '25' },
      { id: 'NTSC', name: '30' },
      { id: 'FILM', name: '24' },
      { id: '29.97', name: '29.97' },
      { id: '23.976', name: '23.976' },
      { id: '50', name: '50' },
      { id: '59.94', name: '59.94' },
      { id: '60', name: '60' }
   ];
   public readonly aspects: IdNameMap[] = [
      { id: 'wide',   name: '16:9' },
      { id: 'normal', name: '4:3'  },
      { id: 'tall',   name: '9:16' }
   ];
   private readonly landscapeResolutions: IdNameMap[] = [
      { id: 'standard', name: 'Standard' },
      { id: '360',      name: '360p'     },
      { id: '540',      name: '540p'     },
      { id: '720',      name: '720p'     },
      { id: '1080',     name: '1080p'    }
   ];
   private readonly portraitResolutions = [
      { id: '640', name: '640p' }
   ];

   private ingestMaxResolution = 0;
   private destroy$ = new Subject<void>();
   private page: Page;

   constructor(
      private pageService: PageService,
      private alertService: AlertService,
      private ingestFormatsApiService: IngestFormatsApiService,
   ) {
      this.page = this.pageService.page;
   }

   ngOnInit() {
      this.state = 'loading';
      this.ingestMaxResolution = parseInt(this.maxRes, 10);
      if (Number.isNaN(this.ingestMaxResolution)) {
         this.ingestMaxResolution = 640;
      }
      this.resolutions = new Map();
      this.resolutions.set('wide', this.getAllowedResolutions(this.landscapeResolutions));
      this.resolutions.set('normal', this.getAllowedResolutions(this.landscapeResolutions));
      this.resolutions.set('tall', this.getAllowedResolutions(this.portraitResolutions));
      this.reset();
      this.saveEnabled = false;
   }

   ngOnDestroy() {
      this.destroy$.next();
      this.destroy$.complete();
   }

   public selectionChanged(): void {
      this.saveEnabled = true;
   }

   public save(): void {
      const formats = this.dataSource.map(elm => this.pack(elm));
      this.state = 'submitting';
      this.ingestFormatsApiService.setAccountIngestFormats(this.page.accountId, formats)
         .pipe(
            this.alertService.notifyOnError('saving ingest formats'),
            finalize(() => this.state = 'ready'),
            takeUntil(this.destroy$),
         )
         .subscribe(() => {
            this.reset();
            this.saveEnabled = false;
            this.alertService.show({
               text: 'Ingest Formats data has been saved successfully',
               type: 'success',
            });
         });
   }

   public discardChanges(): void {
      this.dataSource = this.originalDataSource.map(x => Object.assign({}, x));
      this.saveEnabled = false;
      this.refresh();
   }

   public add(): void {
      this.dataSource.push({standard: 'PAL', aspect: 'wide', resolution: 'standard'});
      this.saveEnabled = true;
      this.refresh();
   }

   public remove(i: number): void {
      this.dataSource.splice(i, 1);
      this.saveEnabled = true;
      this.refresh();
   }

   public pack(ingestFormat: IngestFormat): string {
      let result = ingestFormat.standard + '_' + ingestFormat.aspect;
      if (ingestFormat.resolution!=='standard') {
         result += '_' + ingestFormat.resolution;
      }
      return result;
   }

   public unpack(ingestFormat: string): IngestFormat {
      const bits = ingestFormat.split('_');
      let standard = this.standards.find((element) => element.id === bits[0] );
      if (!standard) {
         standard = this.standards[0];
      }
      let aspect = this.aspects.find((element) => element.id === (bits.length<2 ? '' : bits[1]) );
      if (!aspect) {
         aspect = this.aspects[0];
      }
      const resolution = bits.length<3 ? '' : bits[2].replace(/x$/, '').replace(/p$/, '');
      const format: IngestFormat = { standard: standard.id, aspect: aspect.id, resolution: resolution };
      return this.fixResolution(format);
   }

   private isAllowedResolution(res: IdNameMap): boolean {
      if (res.id === 'standard') {
         return true;
      }
      return (parseInt(res.id, 10) <= this.ingestMaxResolution);
   }

   private refresh(): void {
      this.ingestFormatsTable.renderRows();
   }

   private getAllowedResolutions(resolutions: IdNameMap[]): IdNameMap[] {
      return resolutions.filter(resolution => this.isAllowedResolution(resolution));
   }

   private fixResolution(format: IngestFormat): IngestFormat {
      if (!this.resolutions.get(format.aspect).find(element => element.id === format.resolution)) {
         format.resolution = this.resolutions.get(format.aspect)[0].id;
      }
      return format;
   }

   private reset(): void {
      this.state = 'loading';
      this.ingestFormatsApiService.getAccountIngestFormats(this.page.accountId)
         .pipe(
            this.alertService.notifyOnError('loading ingest formats'),
            finalize(() => this.state = 'ready'),
            takeUntil(this.destroy$),
         )
         .subscribe(formats => {
            this.dataSource = formats.map(format => this.unpack(format));
            this.originalDataSource = this.dataSource.map(x => Object.assign({}, x));
            this.state = 'ready';
         });
   }
}
