export const dynamicName: InjectableDirectiveFactory = ['$compile', function($compile) {
   return {
      priority: 100000,
      terminal: true,
      link: function(scope, element, attrs: any) {
         var deregister = scope.$watch(attrs.dynamicName, function(name) {
            element.attr('name', name as string);
            element.removeAttr(attrs.$attr.dynamicName);
            $compile(element)(scope.$new());
            deregister();
         });
      }
   };
}];
