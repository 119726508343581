import { formatDate } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

import { MID_DATE_FORMAT } from '../utils/date-format.const';
import { EN_GB_LOCALE } from '../utils/locale.const';

import { FormatDurationPipe } from './format-duration.pipe';

@Pipe({
   name: 'naturalDate',
})
export class NaturalDatePipe implements PipeTransform {
   constructor(
      private formatDurationPipe: FormatDurationPipe,
   ) {}

   public transform(value: string /* ISO-8601 UTC */): string {
      if (!value) return;

      const date = new Date(value);
      const now = new Date();
      const seconds = (now.getTime() - date.getTime()) / 1000;
      const minutes = seconds / 60;
      const hours = minutes / 60;
      const days = hours / 24;

      const isFuture = seconds < 0;
      const flooredSeconds = Math.abs(Math.floor(seconds));

      if (Math.abs(seconds) < 60) {
         return this.relativeTime(this.formatDurationPipe.transform(flooredSeconds), isFuture);
      } else if (Math.abs(minutes) < 60) {
         const formatted = this.formatDurationPipe.transform(flooredSeconds, { showMinutes: true, ignoreSeconds: true });
         return this.relativeTime(formatted, isFuture);
      } else if (Math.abs(seconds) < 24*3600 - 1) {
         const formatted = this.formatDurationPipe.transform(flooredSeconds, { ignoreSeconds: true });
         return this.relativeTime(formatted, isFuture);
      } else if (this.isTomorrow(date, now)) {
         return 'tomorrow';
      } else if (this.isYesterday(date, now)) {
         return 'yesterday';
      } else if (Math.abs(days) < 7) {
         const d = DateTime.fromJSDate(date).startOf('day');
         const n = DateTime.now().startOf('day');
         const daysDiff = Math.abs(n.diff(d, 'days').days);
         return this.relativeTime(`${daysDiff} days`, isFuture);
      }

      // All dates more than 7 days into the past or future fall through to here
      return formatDate(date, MID_DATE_FORMAT, EN_GB_LOCALE);
   }

   private relativeTime(timeUnits: string, isFuture: boolean): string {
      return isFuture ? `in ${timeUnits}` : `${timeUnits} ago`;
   }

   private isYesterday(date: Date, now: Date): boolean {
      const yesterday = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);
      return date.getFullYear() === yesterday.getFullYear() && date.getMonth() === yesterday.getMonth() && date.getDate() === yesterday.getDate();
   }

   private isTomorrow(date: Date, now: Date): boolean {
      const tomorrow = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
      return date.getFullYear() === tomorrow.getFullYear() && date.getMonth() === tomorrow.getMonth() && date.getDate() === tomorrow.getDate();
   }
}
