import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';
import { SimpleErrorStateMatcher } from '../../../utils/forms/parent-child-invalid-matcher';

export interface DialogResponse {
   inputText?: string;
   result: boolean;
}

export interface TextInputDialogModel {
   title: string;
   message: string;
   confirmBtnLabel: string;
   cancelBtnLabel?: string;
}

@Component({
   selector: 'bb-text-input-dialog',
   templateUrl: './text-input-dialog.component.html',
   styleUrls: ['./text-input-dialog.component.scss'],
})
export class TextInputDialogComponent {
   title: string;
   message: string;
   inputText: string;
   confirmBtnLabel: string;
   cancelBtnLabel: string;
   public inputTextFormControl = new FormControl('',
      {
         validators: [Validators.required],
      }
   );
   public matcher = new SimpleErrorStateMatcher();

   constructor(
      public dialogRef: MatDialogRef<TextInputDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: TextInputDialogModel,
   ) {
      this.dialogRef.disableClose = true;

      this.title = data.title;
      this.message = data.message;
      this.confirmBtnLabel = data.confirmBtnLabel;
      this.cancelBtnLabel = data.cancelBtnLabel;
   }

   onConfirm(): void {
      const ret: DialogResponse = { inputText: this.inputTextFormControl.value, result: true };
      this.dialogRef.close(ret);
   }

   onDismiss(): void {
      const ret: DialogResponse = { result: false };
      this.dialogRef.close(ret);
   }

   public getErrorMessage(): string {
      if ( this.inputTextFormControl.hasError('required') ) {
         return 'Input required';
      }
   }

   public inputAbsent(): boolean {
      return this.inputTextFormControl.value.lenght === 0;
   }

}

