import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { AccountDto } from '../../../shared/api/accounts/account.interface';
import { SubmitConfig } from '../../../shared/dynamic-form';
import { FormHelperService } from '../../../shared/dynamic-form/ng/form-helper.service';
import { Schema } from '../../../shared/dynamic-form/schema.interface';
import { AccountsApiService } from '../../../shared/api/accounts/accounts.api.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PageService } from '../../../shared/services/page.service';

export interface AccountContactData {
   contactName: string;
   contactEmail: string;
   contactCompany: string;
   contactPhone: string;
}

@Component({
   selector: 'bb-contact-form',
   templateUrl: './contact-form.component.html',
   styleUrls: ['./contact-form.component.scss'],
   changeDetection: ChangeDetectionStrategy.Default,
})
export class ContactFormComponent implements OnInit, OnDestroy {
   public form: FormGroup;
   public modelReady = false;
   public schema: Schema<AccountContactData> = {
      contactName:    { defaultValue: '', type: 'text',         label: 'Owner Name',   required: true, maxLength: 50 },
      contactEmail:   { defaultValue: '', type: 'emailAddress', label: 'Owner Email',  required: true, maxLength: 60 },
      contactCompany: { defaultValue: '', type: 'text',         label: 'Company',      maxLength: 80 },
      contactPhone:   { defaultValue: '', type: 'text',         label: 'Phone Number', maxLength: 40 },
   };
   ngDestroy$ = new Subject();

   public submitConfig: SubmitConfig = {
      submitLabel: 'Save',
      inProgressLabel: 'Saving',
      formName: 'Account Contact Data',
      onSubmit: this.submitForm.bind(this),
   };
   private page: Page;

   constructor(
      private pageService: PageService,
      private accountsApiService: AccountsApiService,
      private formHelperService: FormHelperService,
   ) {
      this.page = this.pageService.page;
   }

   ngOnInit() {
      this.accountsApiService.fetch(this.page.accountId)
      .pipe(takeUntil(this.ngDestroy$))
         .subscribe(account => {
            this.form = this.formHelperService.buildForm<AccountContactData>(this.schema, account);
            this.modelReady = true;
         });
   }

   ngOnDestroy(): void {
      this.ngDestroy$.next(true);
      this.ngDestroy$.complete();
   }

   private submitForm(data: AccountContactData): Observable<AccountDto> {
      return this.accountsApiService.updateAccount(this.page.accountId, data);
   }
}
