import { I18nPluralPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

import { NumberUtils } from '../utils/number-utils';

interface FormatDurationOptions {
   showDays?: boolean;
   showMinutes?: boolean;
   formatNull?: boolean;
   roughly?: boolean;
   terse?: boolean;
   ignoreSeconds?: boolean;
   useDecimalHours?: boolean;
   useDecimalHoursUnit?: boolean;
}

/**
 * Return a text representation of seconds in days, hours and seconds.
 * Copied from eagle/lib.formatlength, with additional options:
 * - @showMinutes (default=false). If true, also includes minutes in result
 * - @formatNull (default=false). If true, returns empty string
 *               instead of '0 xxx' for null or undefined values
 * - @terse (default=false). If true, abbreviate units instead e.g 'day' -> 'd'
 */
@Pipe({
   name: 'formatDuration',
})
export class FormatDurationPipe implements PipeTransform {
   constructor(private i18nPluralPipe: I18nPluralPipe) {}

   public transform(secondsLength: number,
      {
         showDays=true,
         formatNull,
         showMinutes,
         roughly,
         terse,
         ignoreSeconds,
         useDecimalHours,
         useDecimalHoursUnit,
      }: FormatDurationOptions = {},
   ): string {
      if (!formatNull && (secondsLength === undefined || secondsLength === null)) {
         return '';
      }

      let timeLeft = Math.round(secondsLength);

      if (useDecimalHours) {
         let decimalHours = (timeLeft / 3600).toFixed(1);
         return useDecimalHoursUnit ? decimalHours += ' hours' : decimalHours;
      }

      const out: string[] = [];
      if (showDays) {
         /* eslint-disable prefer-const */
         let days: number;
         [days, timeLeft] = NumberUtils.divmod(timeLeft, (60 * 60 * 24));
         if (days > 0) {
            const pluralMap = terse
               ? { other: '#d' }
               : { '=1': '1 day', other: '# days' };
            const daysStr = this.i18nPluralPipe.transform(days, pluralMap);
            out.push(daysStr);
         }
      }

      let hours: number;
      [hours, timeLeft] = NumberUtils.divmod(timeLeft, (60 * 60));
      if (hours > 0) {
         const pluralMap = terse
            ? { other: '#h' }
            : { '=1': '1 hour', other: '# hours' };
         const hoursStr = this.i18nPluralPipe.transform(hours, pluralMap);
         out.push(hoursStr);
      }

      if (showMinutes) {
         let minutes: number;
         [minutes, timeLeft] = NumberUtils.divmod(timeLeft, 60);
         if (minutes > 0) {
            const pluralMap = terse
               ? { other: '#m' }
               : { '=1': '1 minute', other: '# minutes' };
            const minutesStr = this.i18nPluralPipe.transform(minutes, pluralMap);
            out.push(minutesStr);
         }
      }

      if (!ignoreSeconds) {
         let seconds: number;
         [seconds, timeLeft] = NumberUtils.divmod(timeLeft, 1);
         if (seconds > 0) {
            const pluralMap = terse
               ? { other: '#s' }
               : { '=1': '1 second', other: '# seconds' };
            const secondsStr = this.i18nPluralPipe.transform(seconds, pluralMap);
            out.push(secondsStr);
         }
      }

      const delimiter = terse ? ' ' : ', ';
      if (roughly && out.length > 1) {
         // Reduce accuracy of count - give an idea of magnitude instead
         return out.slice(0, -1).join(delimiter);
      }

      if (out.length) {
         return out.join(delimiter);
      }

      return '0 minutes';
   }
}
