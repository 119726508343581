import { Me } from '../../store/users';

export class PermissionsChecker {
   static isAdmin(me: Me): boolean {
      return !!me.admin.length;
   }

   static isResellerManager(me: Me, resellerId: string): boolean {
      return me.resellers.some(reseller => reseller.id === resellerId);
   }

   static isSiteManager(me: Me, siteId: string): boolean {
      return me.sites.some(site => site.id === siteId);
   }

   static isAccountManager(me: Me, accountId: string): boolean {
      return me.accounts.some(acc => acc.id === accountId && acc.isManager);
   }
}
