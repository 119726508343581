import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { RootAppModule } from './root-app.module';

/** always prodMode */
enableProdMode();

platformBrowserDynamic().bootstrapModule(RootAppModule)
   .catch(err => console.error(err));
