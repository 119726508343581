import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { AsyncValidatorFn, FormBuilder, FormGroup, ValidatorFn } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { AutocompleteOptionGroup } from './autocomplete-option-group.interface';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

@Component({
   selector: 'bb-autocomplete-item',
   templateUrl: './autocomplete-item.component.html',
   styleUrls: ['./autocomplete-item.component.scss'],
})
export class AutocompleteItemComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
   @Input() label: string;
   @Input() groupOptions: AutocompleteOptionGroup[];
   @Input() validators: ValidatorFn[] = [];
   @Input() asyncValidators: AsyncValidatorFn[] = [];
   @Input() inValue: string;
   @Output() inValueChange = new EventEmitter<string>();
   @Input() disabled: boolean | undefined;
   @Input() required: boolean | undefined;
   @Input() resetAutocompleteButton: boolean | undefined;
   @Input() resetAfterSelection: boolean | undefined;
   @Output() selectionChanged = new EventEmitter<string>();

   @ViewChild('autoCompleteInput', { static: false, read: MatAutocompleteTrigger }) trigger: MatAutocompleteTrigger;

   public autocompleteForm: FormGroup;
   public resetAutocompleteBtn: boolean;
   private destroy$ = new Subject<void>();
   private autocompleteReady = false;

   constructor(private formBuilder: FormBuilder) {}

   ngOnInit() {
      this.resetAutocompleteBtn = (this.resetAutocompleteButton) ? true : false;
      this.autocompleteForm = this.formBuilder.group({
         autocomplete: [
            {value: this.inValue, disabled: this.disabled},
            this.validators,
            this.asyncValidators,
         ]
      });

      this.autocompleteForm.valueChanges
         .pipe(
            debounceTime(300),
            takeUntil(this.destroy$),
         )
         .subscribe(({autocomplete}) => this.inValueChange.emit(autocomplete));
   }

   ngOnDestroy() {
      this.destroy$.next();
      this.destroy$.complete();
   }

   ngAfterViewInit() {
      this.trigger.panelClosingActions
         .subscribe(e => {
            this.autocompleteForm.get('autocomplete').setValue(this.inValue);
         });
      this.autocompleteReady = true;
   }

   ngOnChanges(changes: SimpleChanges): void {
      if(this.autocompleteReady && changes.hasOwnProperty('inValue')) {
         this.autocompleteForm.get('autocomplete').setValue(this.inValue);
      }
   }

   public getErrorMessage(): string {
      if ( this.autocompleteForm.get('autocomplete').hasError('required') ) {
         return 'This field is required';
      }
      if ( this.autocompleteForm.get('autocomplete').hasError('invalid') ) {
         return 'This field is invalid';
      }
   }

   public optionChanged(value: string) {
      this.selectionChanged.emit(value);
      if (this.resetAfterSelection) {
         this.autocompleteForm.reset('');
      }
   }

   public resetAndReturn() {
      this.inValueChange.emit('');
      this.selectionChanged.emit('');
      this.autocompleteForm.reset('');
   }
}
