import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '../../shared';


/*
   This page has angular.js components in between serverside rendered html.
   We are not wrapping each one in an Angular UpgradeComponent because it would
   be cumbersome and give us no benefit. They are small and independent enough
   to instead be rewritten directly into Angular components, and then added
   to the below module.

   Contrary to the hybrid pages with single entrypoints, this module does
   not get lazily-loaded. We instead import it into the RootAppModule.

   c.f. account-configuration.app.module, site-configuration.app.module, user-configuration.app.module
*/

const cloudConfigurationComponents = [];

@NgModule({
   declarations: cloudConfigurationComponents,
   imports: [
      CommonModule,
      ReactiveFormsModule,
      SharedModule,
   ],
   providers: [],
   exports: cloudConfigurationComponents,
})
export class CloudConfigurationAppModule {}

export const CloudConfigurationAjsModule = angular.module('hybd.cloud.configuration', []);
