import { formatDate } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams, HttpParamsOptions } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { empty, Observable, of } from 'rxjs';
import { catchError, delay, map, mergeMap, take } from 'rxjs/operators';

import { Artefact } from '../../../interfaces/node.interface';
import { CombinedNode } from '../interfaces/combined-node.type';
import { TransferData } from '../../../interfaces/transfer-data.interface';
import { RepublishData } from '../../../interfaces/republish-data.interface';
import { MediaContent } from '../interfaces/media-node.interface';
import { NodeApiParams } from '../interfaces/node-api-params';
import { StringKeyValue } from '../../../../shared/types';
import { NodeSearchClause } from '../interfaces/node-search-clause';
import { ApiEndpointConfig } from '../../../../config/api-endpoints.config';
import { ApiConfigService } from '../../../../shared/services/api-config.service';
import { NewChildrenCombinedNode } from '../interfaces/new-children-combined-node.type';
import { PossibleTransfers } from '../interfaces/possible-transfers-type';
import { EdlContent } from '../../../edl-details/edl-details.component';
import { AlertService } from '../../../../shared/services/alert.service';
import { ErrorMessageService } from '../../../../shared/services/error-message.service';

@Injectable({
   providedIn: 'root'
})
export class NodesApiService {
   public nextChildOffset: string;
   private defaultAccumulateMetadataKeys: string[] = ['ftv-format', 'ftv-versions', 'ftv-workflow',
      'videoname', 'location', 'filename', 'filesize', 'clipname', 'sourcelocator'];
   private apiVersion;
   private headers;

   constructor(
      private httpClient: HttpClient,
      @Inject('User') private User: User,
      private alertService: AlertService,
      private errorMessageService: ErrorMessageService
   ) {
      this.apiVersion = ApiConfigService.settings.version;
      this.headers = new HttpHeaders().set('X-Api-Version', this.apiVersion);
   }

   // fetch Node parameters are still in review.
   public fetchNode(params: NodeApiParams): Observable<CombinedNode[]> {
      const searchKeyPart = params.searchClauses;
      // The cache key for this request includes the search and child order/position arguments
      //const key = 'fetchNode' + params.path + searchKeyPart + (params.childOrder || '') + (params.childFilter || '') + (params.nextChildOffset || '');
      // I kept the key for now, caching will be implemented later

      if (params.searchClauses && params.searchClauses.length > 0) {
         params.search = [...params.searchClauses.map(item => JSON.stringify(item))];
      }
      delete params.searchClauses;
      params.limit = 100;

      const httpParams: HttpParamsOptions = { fromObject: params as any };
      const options = { params: new HttpParams(httpParams), headers: this.headers };
      return this.httpClient.get<CombinedNode>('/api/nodes', options).pipe(
         catchError(err => this.handleError(err)),
         map(node => this.extractNodeChildren(node, params.path)),
         map(node => node.map(oneNode => this.clearChildren(oneNode))),
         map(nodes => nodes.map(oneNode => this.unmarshall(oneNode as CombinedNode))),
         map(nodes => nodes.map(oneNode => this.addSearchKey(oneNode, searchKeyPart))),

      );
   }

   public addSearchKey(node: CombinedNode, key: NodeSearchClause[]) {
      node.searchKey = this.encodeSearchKey(key);
      return node;
   }

   public encodeSearchKey(searchClauses: NodeSearchClause[]): string {
      return searchClauses.length > 0 ? JSON.stringify(searchClauses) : 'default';
   }

   public handleError(error: any): Observable<CombinedNode> {
      if (error.error?.error === 'timedOut') {
         this.alertService.show({
            type: 'warning',
            text: error.error?.message
         });
         return error.error?.errorData.node;
      } else {
         this.alertService.show({
            type: 'danger',
            text: this.errorMessageService.errorMessage(error)
         });
      }
      return of({} as CombinedNode);
   }

   public handleErrorMessage(error) {
      if (error.error instanceof ErrorEvent) {
         this.handleError(error.error.message);
      } else {
         this.handleError(error.message);
      }
      return [];
   }

   public fetchNodeDetails(params: NodeApiParams): Observable<CombinedNode> {
      const searchKeyPart = params.searchClauses;

      const httpParams: HttpParamsOptions = { fromObject: params as HttpParamsOptions } as HttpParamsOptions;
      const options = { params: new HttpParams(httpParams), headers: this.headers };
      return this.httpClient.get<CombinedNode>(ApiEndpointConfig.nodes, options).pipe(
         map(node => this.unmarshall(node as CombinedNode)),
         map(node => this.addSearchKey(node, searchKeyPart)),
      );
   }

   public fetchMediaContent(mediaId, accountId): Observable<MediaContent[]> {
      const key = 'fetchMediaContent' + mediaId + accountId;
      // I kept the key for now, caching will be implemented later
      const params = {
         accountId: accountId
      };

      const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
      const options = { params: new HttpParams(httpParams), headers: this.headers };
      return this.httpClient.get<MediaContent[]>(ApiEndpointConfig.mediaContent(mediaId), options);
   }

   public getDefaultAccumulateMetadataKeys(): string[] {
      return this.defaultAccumulateMetadataKeys;
   }

   public saveMedia(mediaId, data): Observable<any> {
      const options = {  headers: this.headers };
      return this.httpClient.post<any>(ApiEndpointConfig.media(mediaId), data, options);
   }

   public saveMediaData(mediaId, accountId, path, data: StringKeyValue[]): Observable<{ [key: string]: string }> {
      const params = {
         path: path
      };

      const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
      const options = { params: new HttpParams(httpParams), headers: this.headers };
      return this.httpClient.post<any>(ApiEndpointConfig.mediaData(mediaId,accountId), data, options);
   }

   public republish(mediaId, userPublishData) {
      const params: RepublishData = {};

      const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
      const options = { params: new HttpParams(httpParams), headers: this.headers };
      return this.httpClient.post<any>(ApiEndpointConfig.publishRepublish(mediaId), userPublishData, options);
   }

   public fetchEdlSources(id, accountId) {
      const key = 'fetchEdlSources' + id + accountId;
      // I kept the key for now, caching will be implemented later
      const params = {
         accountId: accountId
      };

      const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
      const options = { params: new HttpParams(httpParams), headers: this.headers };
      return this.httpClient.get<any>(ApiEndpointConfig.edlSources(id), options);
   }

   public fetchEdlPreviousVersions(id, path) {
      const key = 'fetchEdlPreviousVersions' + id + path;
      // I kept the key for now, caching will be implemented later
      const params = {
         path: path
      };

      const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
      const options = { params: new HttpParams(httpParams), headers: this.headers };
      return this.httpClient.get<any>(ApiEndpointConfig.edlPreviousVersions(id), options);
   }

   public fetchEdlContent(id, accountId): Observable<EdlContent[]> {
      const key = 'fetchEdlContent' + id + accountId;
      // I kept the key for now, caching will be implemented later
      const params = {
         accountId: accountId
      };

      const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
      const options = { params: new HttpParams(httpParams), headers: this.headers };
      return this.httpClient.get<EdlContent[]>(ApiEndpointConfig.edlContent(id), options);
   }

   public updateNode(data): Observable<CombinedNode[]> {
      const options = { headers: this.headers }; //
      const result = this.httpClient.post<CombinedNode>(ApiEndpointConfig.nodes, data, options).pipe(
         catchError(error => this.handleErrorMessage(error)),
         map(node => this.extractNodeChildren(node, data.path)),
         map(node => node.map(oneNode => this.clearChildren(oneNode))),
         map(nodes => nodes.map(oneNode => this.unmarshall(oneNode as CombinedNode))),
      );

      return result;
   }

   public createNode(path: string, data): Observable<CombinedNode> {
      const postData = {
         parentPath: path,
         userId: this.User.id,
         ...data
      };


      return this.httpClient.post<CombinedNode>(ApiEndpointConfig.nodes, postData).pipe(
         map(node => this.unmarshall(node as CombinedNode)),
         map(node => {
            node.parentPath = path;
            return node;
         })
      );
   }

   public createNodeWithOutRetry(path: string, data): Observable<CombinedNode> {
      const postData = {
         parentPath: path,
         userId: this.User.id,
         ...data
      };

      return this.httpClient.post<CombinedNode>(ApiEndpointConfig.nodes, postData).pipe(
         map(node => this.unmarshall(node as CombinedNode)),
         map(node => {
            node.parentPath = path;
            return node;
         })
      );
   }

   public saveFolderPermissions(path: string, permissions): Observable<CombinedNode[]> {
      const params = {
         path: path,
         permissions: permissions
      };

      const options = {  headers: this.headers };
      const result = this.httpClient.post<CombinedNode[]>(ApiEndpointConfig.nodes,params, options).pipe(
         map(node => [node]),
         catchError(error => this.handleErrorMessage(error))
      );
      return result;
   }

   public asyncTransfer(srcPath: string[], dstPath: string, operation: string, params: any, publishAddtionalData: any = {}): Observable<NewChildrenCombinedNode> {
      const tData: TransferData = {
         dstPath: dstPath,
         srcPath: srcPath,
         operation: operation,
         userId: this.User.id
      };
      if (publishAddtionalData) {
         tData.userPublishData = publishAddtionalData;
      }
      const httpParams: HttpParamsOptions = { fromObject: params as any } as HttpParamsOptions;
      const options = { params: new HttpParams(httpParams), headers: this.headers};
      return this.httpClient.post<NewChildrenCombinedNode>(ApiEndpointConfig.nodesTransfer, tData, options);
   }

   public fetchPossibleTransfers(srcPath: string[], dstPath: string, params: any): Observable<PossibleTransfers> {
      // I kept the key for now, caching will be implemented later
      const data = {
         srcPath: srcPath,
         dstPath: dstPath,
         userId: this.User.id
      };
      //
      const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
      const options = { params: new HttpParams(httpParams), headers: this.headers };
      return this.httpClient.post<PossibleTransfers>(ApiEndpointConfig.nodesTransferType, data, options);
   }

   public explodePath(path: string): string[] {
      return path.replace(/\/$/, "").split('/');
   }

   // Helpers

   private unmarshall(node: CombinedNode): CombinedNode {
      const format = 'dd/MM/yyyy';
      const locale = 'en-GB';

      node.level = this.getLevel(node.path);

      if (node.thumbId) {
         node.thumbUrl = "/api/thumbs/" + node.thumbId + "/image.jpg";
      }
      if (node.hereTime) {
         node.hereTime = formatDate(node.hereTime, format, locale);
      }
      if (node.createdTime) {
         node.createdTime = formatDate(node.createdTime, format, locale);
      }
      if (node.expiresTime) {
         node.expiresTime = formatDate(node.expiresTime, format, locale);
      }
      node.name = node.name.replace(' - Search results', "");
      node = this.setArtefactMetadata(node);
      return node;
   }

   private setArtefactMetadata(node: CombinedNode): CombinedNode {
      if (node.artefacts && node.artefacts.length) {
         node.artefactMetadata = _.groupBy(node.artefacts.flatMap(artefact => this.artefactMetadata(artefact))).value;
      } else {
         node.artefactMetadata = [];
      }
      return node;
   }

   private artefactMetadata(artefact: Artefact): StringKeyValue[] {
      if (_.startsWith(artefact.url, 'field59://')) return [{ key: 'Field59 Key', value: artefact.url.slice(10) }];
      return [];
   }

   private artefactLinks(artefact: Artefact) {
      return (!artefact.url || _.startsWith(artefact.url, 'field59://')) ? [] : [artefact.url];
   }

   private extractNodeChildren(node: CombinedNode, parentPath: string): CombinedNode[] {
      node.parentPath = this.getParentPathFromPath(parentPath);
      this.nextChildOffset = node.nextChildOffset;
      if (node.expandable && node.children && node.children.length) {
         return [node as CombinedNode, ...this.addParent(node.children, node) as CombinedNode[]];
      }
      return [node as CombinedNode];
   }

   private clearChildren(node: CombinedNode) {
      node.children = [];
      return node;
   }

   private addParent(children: CombinedNode[], parent: CombinedNode): CombinedNode[] {
      return children.map(child => {
         child.parentPath = parent.path;
         return child;
      });
   }

   private getLevel(path): number {
      return path ? this.explodePath(path).length - 1 : 0;
   }

   private getParentPathFromPath(path: string): string {
      return path.substring(0, path.lastIndexOf('/'));
   }
}
