import { createReducer, on } from '@ngrx/store';

import { EditableEdgeValues } from '../../edge-server-details/edge-server-settings';
import { EdgeServerTemplate } from '../../types';
import {
   addNewEdgeTemplate,
   addTemporaryEdge,
   removeTemporaryEdge,
   removeFromDirtyEdges,
   removeFromEdgesInEdit,
   scrollToSelectedEdge,
   selectEdge,
   updateEdgeDirtiness,
   updateTemporaryEdgeName,
   upsertEdgeInEdit,
   updateAdvancedOptionsOpenStatus
} from './edge-cards.actions';


interface EdgesInEditValues {
   [edgeId: string]: EditableEdgeValues;
}

interface EdgesInEditUIState {
   [edgeId: string]: {
      advancedOptionsOpen: boolean;
   };
}

export interface DirtyEdges {
   [edgeId: string]: boolean;
}

export interface EdgeCardsState {
   selectedEdgeId: string;
   temporaryEdges: EdgeServerTemplate[]; // Generated from a template, but not yet "created"/submitted to back end
                                         // i.e. user has clicked "new edge server" but not yet "create"
   edgesInEditValues: EdgesInEditValues;
   edgesInEditUIState: EdgesInEditUIState;
   dirtyEdges: DirtyEdges;
}

export const initialState: EdgeCardsState = {
   selectedEdgeId: null,
   temporaryEdges: [],
   edgesInEditValues: {},
   edgesInEditUIState: {},
   dirtyEdges: {},
};

export const edgeCardsReducer = createReducer(
   initialState,
   on(addNewEdgeTemplate, (state) => ({ ...state })),
   on(addTemporaryEdge, (state, {edgeTemplate}) => ({
      ...state,
      temporaryEdges: [...state.temporaryEdges, edgeTemplate],
   })),
   on(updateTemporaryEdgeName, (state, {id, name}) => {
      const temporaryEdges = [...state.temporaryEdges];
      const edgeIndex = temporaryEdges.findIndex(e => e.$$temporaryId === id);
      temporaryEdges[edgeIndex] = { ...temporaryEdges[edgeIndex], name };
      return { ...state, temporaryEdges };
   }),
   on(removeTemporaryEdge, (state, {id}) => ({
      ...state,
      temporaryEdges: state.temporaryEdges.filter(e => e.$$temporaryId !== id),
   })),
   on(removeFromEdgesInEdit, (state, { id }) => {
      const { [id]: removedEdgeEditVal, ...edgesInEdit } = state.edgesInEditValues;
      return { ...state, edgesInEditValues: edgesInEdit };
   }),
   on(removeFromDirtyEdges, (state, { id }) => {
      const { [id]: removedDirtyVal, ...dirtyEdges } = state.dirtyEdges;
      return { ...state, dirtyEdges };
   }),
   on(upsertEdgeInEdit, (state, { id, value }) => ({
      ...state,
      edgesInEditValues: {
         ...state.edgesInEditValues,
         [id]: {
            ...[state.edgesInEditValues][id],
            ...value,
            advancedOptions: {
               ...[state.edgesInEditValues][id]?.advancedOptions,
               ...value.advancedOptions,
            },
         },
      },
   })),
   on(updateEdgeDirtiness, (state, { id, dirty }) => ({
      ...state,
      dirtyEdges: {
         ...state.dirtyEdges,
         [id]: dirty,
      }
   })),
   on(updateAdvancedOptionsOpenStatus, (state, { id, open }) => ({
      ...state,
      edgesInEditUIState: {
         ...state.edgesInEditUIState,
         [id]: {
            ...[state.edgesInEditUIState][id],
            advancedOptionsOpen: open,
         },
      }
   })),
   on(selectEdge, (state, { id }) => ({ ...state, selectedEdgeId: id })),
   on(scrollToSelectedEdge, (state) => ({ ...state })),
);
