// Overlays a window-like space over parent element.
//
// Attributes:
//    scope (two way, object, optional):
//       Directive's scope. Use it to set complex data into
//          scope after the dialog is created (either by
//          monitoring the fbdn-dialog tag or when
//          createTemplate promise is resolved). Do not
//          set through scope what public member functions
//          manipulate. See below for their description.
//          Public members function are part of the scope
//          and can be called through it.
//    title (one way, string, optional):
//       Text to appear on the window's title.
//    modal (two way, boolean, optional with default false):
//       If set to true, forbids interactions with sibling
//       elements and their children.
//    container-style (one way, string, optional):
//       Sets the dialog container's CSS style
//    dialog-style (one way, string, optional):
//       Sets the dialog window's CSS style
//
// Public Members:
//    getRootElement():
//       Returns the DOM of the surrounding element containing
//       the dialog window. Surrounds the whole DOM element it
//       has been inserted into. Useful to set layout properties
//       of dialog window in conjunction with getDialogElement().
//    getDialogElement():
//       Returns the DOM of the dialog element. Useful to set
//       layout properties of dialog window in conjunction with
//       getRootElement.
//    registerEvent(eventName, action):
//       Associate the execution of action function with event
//       identified by string EventName. Arguments passed to
//       action depend on argument. This is not related to
//       angular $on, broadcast and $emit but angular events
//       managed within this directive should also be visible
//       through registerEvent. Returns an opaque handler for
//       the association created.
//    releaseEvent(handler):
//       Deactivate association between event and handler
//       registered using registerEvent (see above) that returns
//       the handler to use as parameter to releaseEvent.
//    setTitleBarButtons(buttons):
//       Set buttons that should appear or not appear on the
//       title bar of the window. Replace settings for all
//       buttons.
//           arguments:
//             buttons: string -> bool dictionary associating
//                a button by its name and whether is should
//                apppear (true) or not (false). If a button
//                is omitted in the dictionary, then it will
//                not appear (equiv. to false).
//
// Private Members:
//    fireEvent(eventName, args):
//       Runs all actions associated to event eventName registered
//       using public member "registeredEvent" (see above). args
//       is passed to each function call.
//
// Events:
//    "close":
//       Triggered when the dialog is closed, either when catching
//       event "close" (through angular events or fireEvent; see
//       above). Before any action is taken, fires event
//       "beforeClose" and after everything is done, fires event
//       "afterClose" both using angular and fireEvent, in an
//       undefined order.
//    "open":
//       Triggered when the dialog is opened, i.e., when it becomes
//       visible to the user as opposed to created and initialized.
//       Behaves the same way as event "close" described above.
//
//
// Example:
//    <fbdn-dialog title="Publishing log" scope="dialog" modal="true" style="width: 80%; max-height: 80%;">
//       <div style="display: flex; flex-direction: column; width: 100%;">
//          <div style="border-radius: 5px; border: 1px solid gray; font-family: courier; flex: 1; white-space: pre-wrap;">
//             {{ log }}
//          </div>
//          <div class="action-bar-h" style="margin-top: 5px;">
//             <button class="action-button" ng-click="dialog.close()">Close</span>
//          </div>
//       </div>
//    </fbdn-dialog>
export const fbdnDialog: InjectableDirectiveFactory = [function() {
   return {
      restrict: 'E',
      transclude: true,
      templateUrl: 'fbdn-dialog-template',
      scope: {
         scope: '=',
         title: '@',
         modal: '=',
         dialogStyle: '@',
         containerStyle: '@'
      },
      link: function(scope: any) {
         scope.scope = scope;
         var eventRegistry = {};

         scope.getRootElement = function() {
            return angular.element('#root')[0];
         };

         scope.getDialogElement = function() {
            return angular.element('#dialog')[0];
         };

         var doClose = function() {
            scope.$broadcast('beforeClose');
            fireEvent('beforeClose');
            scope.visible = false;
            fireEvent('afterClose');
            scope.$broadcast('afterClose');
         };

         scope.close = function() {
            scope.$broadcast('close');
         };

         var doOpen = function() {
            scope.$broadcast('beforeOpen');
            fireEvent('beforeOpen');
            scope.visible = true;
            fireEvent('afterOpen');
            scope.$broadcast('afterOpen');
         };

         scope.open = function() {
            scope.$broadcast('open');
         };

         function fireEvent(eventName, args?) {
            var handlers = eventRegistry[eventName];
            if (handlers) {
               for (var action of handlers) {
                  action(args);
               }
            }
         }

         scope.registerEvent = function(eventName, callback) {
            var handlers = eventRegistry[eventName];
            if (!handlers) {
               handlers = [];
            }

            handlers.push(callback);
            eventRegistry[eventName] = handlers;
            return { name: eventName, action: callback };
         };

         scope.releaseEvent = function(handler) {
            var handlers = eventRegistry[handler.name];
            if (handlers) {
               var index = handlers.indexOf(handler.action);
               if (index > -1) {
                  handlers.splice(index, index);
               }
            }
         };

         scope.setTitleBarButtons = function(buttons) {
            scope.titleBarButtons = buttons;
         };

         scope.registerEvent('close', doClose);
         scope.$on('close', () => fireEvent('close'));
         scope.registerEvent('open', doOpen);
         scope.$on('open', () => fireEvent('open'));
      }
   };
}];
