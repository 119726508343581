export const subPanelDirective: InjectableDirectiveFactory = ['$parse', '$timeout', function($parse: IParseService, $timeout: ITimeoutService) {
   return {
      restrict: 'C',
      require: ['^?fbdnAccordion', 'subPanel'],
      link: function(scope: IScope, _element: JQLite, _attrs: IAttributes, controllers: IController[]) {
         const fbdnAccordionController = controllers[0];
         if (!fbdnAccordionController) return;
         const subPanelController = controllers[1];
         fbdnAccordionController.add(subPanelController);
         subPanelController.fbdnAccordionController = fbdnAccordionController;

         scope.$on('$destroy', function() {
            fbdnAccordionController.remove(subPanelController);
         });
      },
      controller: ['$scope', '$element', '$attrs', function($scope: IScope, $element: JQLite, $attrs: IAttributes) {
         const self = this;
         let currentClone: JQLite | null = null;
         let contentTransclude: ITranscludeFunction = null;
         let contentElement: JQLite | null = null;

         self.add = function(_contentElement_: JQLite, _contentTransclude_: ITranscludeFunction) {
            contentElement = _contentElement_;
            contentTransclude = _contentTransclude_;

            ($element as any).children('.header').on('click', function() {
               $scope.$apply(function() {
                  if (self.isActive()) {
                     self.close();
                  } else {
                     self.open();
                  }
               });
            });
         };

         self.isActive = function(): boolean {
            return $element.hasClass('active');
         };

         function _open(clone: JQLite, immediate: boolean): void {
            contentElement.after(clone);
            currentClone = clone;

            if (immediate) {
               clone.show({ done: self.fbdnAccordionController.reflow });
            } else {
               clone.hide();
               $timeout(() => {
                  currentClone.slideDown({ done: self.fbdnAccordionController.reflow });
               }, 0, false);
            }
         }

         self.open = function(immediate: boolean): void {
            if (self.isActive() && !immediate) return;
            $element.addClass('active');
            if (currentClone) {
               _open(currentClone, immediate);
            } else {
               if (contentTransclude) {
                  contentTransclude(clone => {
                     _open(clone, immediate);
                  });
               }
            }
            self.fbdnAccordionController.closeOther(self, immediate, $element[0]);
         };

         function _removeFromDom(): void {
            if (self.isActive()) return;
            currentClone.scope().$destroy();
            currentClone.remove();
            currentClone = null;
            self.fbdnAccordionController.reflow();
         }

         self.close = function(immediate: boolean, openPanelElement?: HTMLElement): void {
            if (!self.isActive()) return;
            $element.removeClass('active');
            if (immediate) {
               _removeFromDom();
            } else {
               currentClone.slideUp(() => {
                  _removeFromDom();
                  if (openPanelElement) {
                     openPanelElement.scrollIntoView();
                  }
               });
            }
         };

         const parsed = $parse($attrs.fbdnAccordionOpen);
         if (parsed($scope)) {
            $scope.$evalAsync(function() {
               self.open();
            });
            parsed.assign($scope, false);
         }
      }]
   };
}];
