import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Site } from "../types/site.interface";
import { AsyncCacheService } from "../utils/async-cache.service";
import { SkeletonCacheService } from "../utils/skeleton-cache.service";
import { ApiUpdatesService } from "../utils/updates/api-updates.service";
import { PageService } from "./page.service";
import { UrlService } from "./url.service";
import { EdgeServersApiService } from "./edge-servers-api.service";
import { EdgeServicesApiService } from "./edge-services.api.service";
import { EndpointsApiService } from "./endpoints.api.service";
import { RAW_DATA } from "../constants/raw-data";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";

@Injectable({
   providedIn: 'root',
})
export class StatusApiService {
   private asyncCache: any;
   private skeletonCache: any;
   private apiUpdates: any;
   private rawDataOptions = { headers: RAW_DATA, };

   private sets = {
      EdgeServer: {
         api: this.edgeServersApiService,
         getUrl: (object) => {
            return this.urlService.makeUrl('edge-server', {userId: object.userId, edgeServerId: object.id});
         },
         validLink: (object) => {
            return !!object.userId  && !!object.id;
         }
      },
      Service: {
         api: this.edgeServicesApiService,
         getUrl: (object) => {
            return this.urlService.makeUrl('edge-server-services', {edgeServerId: object.edgeServer.id});
         },
         validLink: (object) => {
            return !!object?.edgeServer.id;
         }
      },
      Endpoint: {
         api: this.endpointsApiService,
         getUrl: (object) => {
            const edgeServerId = object.service.edgeServer?.id || object.edgeServerId;
            return this.urlService.makeUrl('edge-server-endpoints', {edgeServerId: edgeServerId, serviceId: object.service.id});
         },
         validLink: (object) => {
            const edgeServerId = object.service.edgeServer?.id || object.edgeServerId;
            return !!edgeServerId && !!object.service.id;
         }
      }
   };

   constructor(
      private http: HttpClient,
      private asyncCacheService: AsyncCacheService<Site[]>,
      private skeletonCacheService: SkeletonCacheService,
      private apiUpdatesService: ApiUpdatesService,
      private pageService: PageService,
      private urlService: UrlService,
      private edgeServersApiService: EdgeServersApiService,
      private edgeServicesApiService: EdgeServicesApiService,
      private endpointsApiService: EndpointsApiService,
   ) {
      this.asyncCache = this.asyncCacheService.initAsyncCache("StatusApiService");
      this.skeletonCache = this.skeletonCacheService.initSkeletonCache('StatusApiService', {
         unmarshall: (object) => {
            return this.getApi(object).unmarshall(object);
         },
         collectionOnly: true,
      });
      this.apiUpdates = this.apiUpdatesService.initApiUpdatesService("StatusUpdateService",
         (object) => {
            return this.getApi(object).fetch(object.id, true);
         }
      );
   }

   public getUrl(object): string {
      return this.sets[object.type].getUrl(object);
   }

   public validLink(object): boolean {
      return this.sets[object.type].validLink(object);
   }

   public fetchSiteStatus(siteId: string, forceFetch=false): Observable<any> {
      const api = this.http.get('/api/sites/' + siteId + '/status', this.rawDataOptions)
         .pipe(
            map(res => this.skeletonCache.unmarshallCollection(res)),
         );
      return this.asyncCache.fetch('site-status-' + siteId, api, forceFetch)
         .pipe(
            map((collection) => this.apiUpdates.subscribeToCollection(collection, this.fetchSiteStatus.bind(this), [siteId, true])),
         );
   }

   public fetchAccountStatus(accountId: string, forceFetch=false): Observable<any> {
      const api = this.http.get('/api/accounts/' + accountId + '/status', this.rawDataOptions)
         .pipe(
            map(res => this.skeletonCache.unmarshallCollection(res)),
         );
      return this.asyncCache.fetch('account-status-' + accountId, api, forceFetch)
         .pipe(
            map(collection => this.apiUpdates.subscribeToCollection(collection, this.fetchAccountStatus.bind(this), [accountId, true])),
         );
   }

   private getApi(object) {
      return this.sets[object.type].api;
   }
}
