export const fbdnActiveTabDirective: InjectableDirectiveFactory = [function() {
   return {
      require: '^fbdnTabs',
      scope: {
         activeTab: '=fbdnActiveTab'
      },
      link: function(scope, element, attrs, tabsController) {
         var currentTabContent;
         scope.$watch('activeTab', function(activeTab: any) {
            if (!activeTab) return;
            activeTab.$transcludeFn(function(clone) {
               if (currentTabContent) currentTabContent.remove();
               currentTabContent = clone;
               element.append(clone);
               tabsController.reflow();
               clone[0].scrollIntoView();
            });
         });
      }
   };
}];
