import { NavigationService } from '../utils/navigation/navigation.service';

export const uploadController = ['$scope', '$filter', 'NavigationService', '$window',
                                 function($scope, $filter, navigationService: NavigationService, $window) {
   $scope.state = 'choosing';
   $scope.options = {
      format: '',
      aspect: '',
      location: 'File Uploads/' + new Date().toDateString()
   };
   var batch = 1;

   // Don't allow files with the same name in the same batch
   // FileApi doesn't give access to the full path so can't distinguish
   // files in different paths with the same name
   $scope.$evalAsync(function() {
      $scope.$flow.opts.generateUniqueIdentifier = function(file) {
         return batch + '-' + file.name;
      };
   });

   $scope.$on('flow::fileAdded', function(e, $flow, flowFile) {
      if ($scope.state !== 'choosing') {
         $flow.cancel();
         $scope.state = 'choosing';
      }
      var filenameWithoutExtension = flowFile.name.replace(/\.[^/.]+$/, "");
      flowFile.nameOverride = filenameWithoutExtension;
   });

   $scope.$on('flow::fileSuccess', function(e, $flow, file, message) {
       var isRedirect = message.indexOf("redirect ") === 0;
       if (isRedirect) {
         var url = message.substring(9);
         file.response = {redirect: url};
         navigationService.openPopup(url);
       }
   });

   $scope.$on('flow::fileError', function(e, $flow, file, message) {
      file.errorMessage = message;
   });

   $scope.getResponse = function(file) {
      if (file.response) {
         return file.response;
      }
      var lastChunk = file.chunks[file.chunks.length - 1];
      var text = lastChunk.xhr.responseText;
      if (text && text.match(/^{/)) {
         var response = JSON.parse(text);
         if (response.error) {
            file.error = true;
            file.errorMessage = response.error;
         }
         return file.response = response;
      } else if (text && !text.match(/^</) && !text.match(/^redirect /)) { // <html> or <!DOCTYPE> presumably
         file.error = true;
         file.errorMessage = "Unexpected response from cloud";
      }
      return file.response = text;
   };

   $scope.showWarnings = function(file) {
      $scope.getResponse(file).showWarnings = true;
   };

   $scope.$on('flow::complete', function() {
      $scope.state = 'complete';
      batch++;
   });

   $scope.canUpload = function() {
      return $scope.state === 'choosing' && $scope.$flow.files.length;
   };

   $scope.canChoose = function() {
      return $scope.state !== 'uploading';
   };

   $scope.fileStatus = function(file) {
      if ($scope.state === 'choosing') return 'choosing';
      if (file.error) return 'error';
      if (file.isComplete()) return 'complete';
      if ($scope.state === 'uploading' && file.isUploading()) return 'uploading';
      if ($scope.state === 'uploading' && !file.isUploading()) return 'preparing';
      return 'unknown';
   };

   $scope.fileProgress = function(file) {
      return $filter('number')(file.progress() * 100, 0);
   };

   $scope.upload = function() {
      $scope.state = 'uploading';

      $scope.$flow.opts.query = function(flowFile) {
         return angular.extend({
            uploadname: flowFile.nameOverride
         }, $scope.options);
      };

      $scope.$flow.opts.headers = function() {
         var headers = {}; // seems can't use js object literal with dynamic key
         headers[$window.xsrf.headerName] = $window.xsrf.getCookie();
         return headers;
      }; // pass function so it's refreshed at start of each request

      $scope.$flow.upload();
   };
}];
