import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AsyncCacheService } from '../utils/async-cache.service';
import { LaunchMode, LaunchModeDto, LaunchModeName, LaunchModes } from '../types';


export interface EditorInfo {
   id: string;
   name: string;
}

@Injectable({
   providedIn: 'root',
})
export class EditorDataApiService {
   private asyncCache: any;

   constructor(
      private http: HttpClient,
      private asyncCacheService: AsyncCacheService<EditorInfo>,
   ) {
      this.asyncCache = this.asyncCacheService.initAsyncCache("EditorDataApiService");
   }

   public getEditorSkins(forceFetch=false): Observable<EditorInfo[]> {
      const path = '/api/editor/skins';
      const getSkins$ = this.http.get<EditorInfo[]>(path);
      return this.asyncCache.fetch(path, getSkins$, forceFetch);
   }

   public getEditorVersions(forceFetch=false): Observable<EditorInfo[]> {
      const path = '/api/editor/versions';
      const getVersions$ = this.http.get<EditorInfo[]>(path);
      return this.asyncCache.fetch(path, getVersions$, forceFetch);
   }

   public getLaunchModes(transform=false, forceFetch=false): Observable<LaunchModes | LaunchModeDto[]> {
      const api = this.http.get<LaunchModeDto[]>('/api/editor/launchModes');
      return this.asyncCache.fetch('launch-modes', api).pipe(
         map((launchModes: LaunchModeDto[]) => transform ? this.mapFromDto(launchModes) : launchModes),
      );
   };

   public getDefaultLaunchMode(launchModeName: LaunchModeName): Observable<LaunchMode> {
      return this.getLaunchModes(true)
         .pipe(
            map((launchModes) => {
               const [defaultLaunchModeName, m] = Object.entries(launchModes)
                  .find(([name, mode]) => mode.default) as [LaunchModeName, LaunchMode];
               return launchModeName in launchModes
                  ? launchModes[launchModeName]
                  : launchModes[defaultLaunchModeName];
            }),
         );
   };

   private mapFromDto(launchModes: LaunchModeDto[]): LaunchModes {
      return launchModes.map(mode => {
         const [action, maxheap] = mode.path.split('?');
         const [maxheapKey, maxheapValue] = maxheap.split('=');
         const launchFn = window.popupfull;
         return {
            name: mode.name,
            kind: mode.kind,
            label: mode.label,
            default: mode.default,
            params: { [maxheapKey]: maxheapValue },
            action,
            launchFn,
         };
      })
      .reduce((prev, curr) => {
         const { name, ...mode } = curr;
         return {
            ...prev,
            [name]: mode,
         };
      }, {} as LaunchModes);
   }
}
