import { Injectable } from '@angular/core';

type PageContext = 'cloud' | 'sitegroup' | 'site' | 'account' | 'user';

interface Page {
    context: PageContext;
    userId: string;
    accountId: string;
    siteId: string;
    sitegroupId: string;
    serviceId: string;
    edgeServerId: string;
    masterDomain: string;
    debug?: boolean;
 }

@Injectable({
  providedIn: 'root'
})
export class PageService {
  public page: Page;
  constructor() {
    this.page = (window as any).Page;
  }
}
