import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
   name: 'bps',
})
export class BpsPipe implements PipeTransform {
   transform(bytes: number, precision: number): string {
      if (isNaN(parseFloat(String(bytes))) || !isFinite(bytes)) {
         return '-';
      }
      if (!bytes) {
         return '0 bps';
      }
      if (typeof precision === 'undefined') {
         precision = 1;
      }
      const units = ['bps', 'kbps', 'Mbps', 'Gbps', 'Tbps', 'Pbps'];
      const figure = Math.max(0, Math.min(units.length - 1, Math.floor(Math.log(Math.abs(bytes)) / Math.log(1024))));
      return (bytes / Math.pow(1024, Math.floor(figure))).toFixed(precision) +  ' ' + units[figure];
   }
}
