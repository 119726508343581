import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
   providedIn: 'root',
})
export class IngestFormatsApiService {
   constructor(private http: HttpClient) { }

   getAccountIngestFormats(accountId: string) {
      return this.http.get<string[]>('/api/accounts/' + accountId + '/ingestFormats');
   }

   setAccountIngestFormats(accountId: string, formats: string[]) {
      return this.http.post<null>('/api/accounts/' + accountId + '/ingestFormats', formats);
   }
}
