import { createAction, props } from '@ngrx/store';

import { ServiceType } from './types/service-types.type';
import { EdgeService, ServiceTemplateDto } from './types';
import { EditableServiceValues, TemporaryEdgeService } from '../../edge-services/store/edge-service-cards/types';


export const loadEdgeServices = createAction(
   '[Edge Server Services] Load Services',
   props<{edgeId: string}>(),
);
export const loadEdgeServicesSuccess = createAction(
   '[Edge Server Services] Load Services Success',
   props<{services: EdgeService[]}>(),
);
export const loadEdgeServicesFail = createAction(
   '[Edge Server Services] Load Services Fail',
   props<{errorMessage: string}>(),
);
export const loadEdgeService = createAction(
   '[Edge Server Services] Load Edge Service',
   props<{id: string}>(),
);

export const loadServiceTypes = createAction(
   '[Edge Server Services] Load Service Types',
   props<{edgeId: string}>(),
);
export const loadServiceTypesSuccess = createAction(
   '[Edge Server Services] Load Service Types Success',
   props<{serviceTypes: ServiceType[]}>(),
);

export const loadServiceTemplates = createAction(
   '[Edge Server Services] Load Service Templates',
   props<{edgeId: string}>(),
);
export const loadServiceTemplatesSuccess = createAction(
   '[Edge Server Services] Load Service Templates Success',
   props<{serviceTemplates: ServiceTemplateDto[]}>(),
);

export const saveService = createAction(
   '[Edge Server Services] Save Service',
   props<{service: EdgeService; values: EditableServiceValues}>(),
);
export const saveServiceName = createAction(
   '[Edge Server Services] Save Service Name',
   props<{id: string; name: string}>(),
);
export const saveServiceSuccess = createAction(
   '[Edge Server Services] Save Service Success',
   props<{service: EdgeService}>(),
);
export const saveServiceFail = createAction(
   '[Edge Server Services] Save Service Fail',
   props<{errorMessage: string}>(),
);
export const createService = createAction(
   '[Edge Server Services] Create Service',
   props<{service: TemporaryEdgeService; values?: EditableServiceValues; edgeServerId: string}>(),
);
export const createServiceSuccess = createAction(
   '[Edge Server Services] Create Service Success',
   props<{service: EdgeService; tempId: string}>(),
);
export const createServiceFail = createAction(
   '[Edge Server Services] Create Service Fail',
   props<{errorMessage: string}>(),
);

export const upsertService = createAction(
   '[Edge Server Services] Upsert Service',
   props<{service: EdgeService}>(),
);
export const updateServiceName = createAction(
   '[Edge Server Services] Update Service Name',
   props<{id: string; name: string}>(),
);

export const deleteService = createAction(
   '[Edge Server Services] Delete Service',
   props<{id: string}>(),
);
export const deleteServiceSuccess = createAction(
   '[Edge Server Services] Delete Service Success',
   props<{id: string}>(),
);
export const deleteServiceFail = createAction(
   '[Edge Server Services] Delete Service Fail',
   props<{errorMessage: string}>(),
);
export const removeServiceFromStore = createAction(
   '[Edge Server Services] Remove Service From Store',
   props<{id: string}>(),
);
