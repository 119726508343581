<!-- This button triggers the overlay and is it's origin -->
<ng-container *ngIf="{notificationList: notifications$ | async, totalAlerts: totalAlerts$ | async} as allNotifications">
  <div>
    <a (click)="isOpen = !isOpen" type="button" class="tab-label" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
      <span *ngIf="allNotifications.notificationList?.length else no_notification" mat-icon-button class="material-symbols-outlined tab-icon material-icon"
      matBadge="{{allNotifications.notificationList?.length}}" matBadgeColor="warn" matBadgeSize="small"> notifications_active</span>
      <ng-template #no_notification>
        <span mat-icon-button class="material-symbols-outlined tab-icon material-icon"> notifications </span>
      </ng-template>
      Notifications
    </a>
    <!-- This template displays the overlay content and is connected to the button -->
    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayOpen]="isOpen"
      [cdkConnectedOverlayPositions]="positionPairs"
      [cdkConnectedOverlayHasBackdrop]="true"
      (backdropClick)="isOpen = false"
    >
      <div class="popover notifications">
        <div class="popover-arrow"></div>
        <div class="popover-dialog">
          <div class="popover-content">
            <div class="popover-body">
              <mat-tab-group>
                <mat-tab>
                  <ng-template mat-tab-label>
                    <h4 matBadge="{{allNotifications.notificationList?.length}}" matBadgeColor="accent" matBadgeOverlap="false">
                      Notifications
                    </h4>
                  </ng-template>
                  <ul >
                    <li *ngFor="let notification of allNotifications.notificationList">
                        <button  mat-icon-button class="material-icon" (click)="acknowledgeNotification(notification)" title="Acknowledge">close</button>
                        <button  mat-icon-button class="material-icon" [cdkCopyToClipboard]="notification.message" title="Copy"
                        matRipple
                        [matRippleCentered]="centered"
                        [matRippleColor]="grey"
                        >content_copy</button>
                        <span class="text" *ngIf="!notification.link">
                          {{notification.message}}
                        </span>
                        <span class="text" *ngIf="notification.link" matBadge="1" matBadgeColor="primary" matBadgeOverlap="false" matBadgeSize="small">
                          <a href="{{notification.link}}" target="_blank">{{notification.message}}</a>
                        </span>
                        <span class="bb-notification-date"> {{notification.created.toLocaleString()}}</span>
                    </li>
                  </ul>
                </mat-tab>
                <mat-tab>
                  <ng-template mat-tab-label>
                    <h4 matBadge="{{totalAlertsByType.danger| async}}" matBadgeColor="accent" matBadgeOverlap="false">
                      Problems
                    </h4>
                  </ng-template>
                  <ul>
                    <li *ngFor="let alert of alerts.danger | async">
                        <button  mat-icon-button class="material-icon" (click)="dismissAlert(alert)" title="Acknowledge">close</button>
                        <button  mat-icon-button class="material-icon" [cdkCopyToClipboard]="alert.text" title="Copy"
                          matRipple
                          [matRippleCentered]="centered"
                          [matRippleColor]="grey"
                        >content_copy</button>
                        <span class="text" *ngIf="!alert.link" matBadge="{{alert.count}}" matBadgeColor="primary" matBadgeOverlap="false" matBadgeSize="small">
                          <i *ngIf="alert.source">From: {{alert.source}}, </i>{{alert.text}}
                        </span>
                        <span class="text" *ngIf="alert.link" matBadge="{{alert.count}}" matBadgeColor="primary" matBadgeOverlap="false" matBadgeSize="small">
                          <i *ngIf="alert.source">From: {{alert.source}}, </i> <a href="{{alert.link}}" target="_blank">{{alert.text}}</a>
                        </span>
                        <span class="bb-notification-date"> {{alert.created.toLocaleString()}}</span>
                    </li>
                  </ul>
                </mat-tab>
                <mat-tab>
                  <ng-template mat-tab-label>
                    <h4 matBadge="{{totalAlertsByType.warning | async}}" matBadgeColor="accent" matBadgeOverlap="false" matBadgeSize="small">
                      Warning
                    </h4>
                  </ng-template>
                  <ul>
                      <li *ngFor="let alert of alerts.warning | async">
                          <button  mat-icon-button class="material-icon" (click)="dismissAlert(alert)" title="Acknowledge">close</button>
                          <button  mat-icon-button class="material-icon" [cdkCopyToClipboard]="alert.text" title="Copy"
                          matRipple
                          [matRippleCentered]="centered"
                          [matRippleColor]="grey"
                          >content_copy</button>
                          <span class="text" *ngIf="!alert.link" matBadge="{{alert.count}}" matBadgeColor="primary" matBadgeOverlap="false" matBadgeSize="small">
                            <i *ngIf="alert.source">From: {{alert.source}}, </i>{{alert.text}}
                          </span>
                          <span class="text" *ngIf="alert.link" matBadge="{{alert.count}}" matBadgeColor="primary" matBadgeOverlap="false" matBadgeSize="small">
                            <i *ngIf="alert.source">From: {{alert.source}}, </i><a href="{{alert.link}}" target="_blank">{{alert.text}}</a>
                          </span>
                          <span class="bb-notification-date"> {{alert.created.toLocaleString()}}</span>
                      </li>
                  </ul>
                </mat-tab>
                <mat-tab>
                  <ng-template mat-tab-label>
                    <h4 matBadge="{{totalAlertsByType.success| async}}" matBadgeColor="accent" matBadgeOverlap="false" matBadgeSize="small">
                      Done
                    </h4>
                  </ng-template>
                  <ul>
                    <li *ngFor="let alert of alerts.success | async">
                        <button  mat-icon-button class="material-icon" (click)="dismissAlert(alert)" title="Acknowledge">close</button>
                        <button  mat-icon-button class="material-icon" [cdkCopyToClipboard]="alert.text" title="Copy"
                          matRipple
                          [matRippleCentered]="centered"
                          [matRippleColor]="grey"
                        >content_copy</button>
                        <span class="text" *ngIf="!alert.link" matBadge="{{alert.count}}" matBadgeColor="primary" matBadgeOverlap="false" matBadgeSize="small">
                          <i *ngIf="alert.source">From: {{alert.source}}, </i>{{alert.text}}
                        </span>
                        <span class="text" *ngIf="alert.link" matBadge="{{alert.count}}" matBadgeColor="primary" matBadgeOverlap="false" matBadgeSize="small">
                          <i *ngIf="alert.source">From: {{alert.source}}, </i><a href="{{alert.link}}" target="_blank">{{alert.text}}</a>
                        </span>
                        <span class="bb-notification-date"> {{alert.created.toLocaleString()}}</span>
                    </li>
                  </ul>
                </mat-tab>
                <mat-tab>
                  <ng-template mat-tab-label>
                    <h4 matBadge="{{totalAlertsByType.info | async}}" matBadgeColor="accent" matBadgeOverlap="false">
                      Info
                    </h4>
                  </ng-template>
                  <ul>
                    <li *ngFor="let alert of alerts.info | async">
                        <button  mat-icon-button class="material-icon" (click)="dismissAlert(alert)" title="Acknowledge">close</button>
                        <button  mat-icon-button class="material-icon" [cdkCopyToClipboard]="alert.text" title="Copy"
                        matRipple
                          [matRippleCentered]="centered"
                          [matRippleColor]="grey"
                        >content_copy</button>
                        <span class="text" *ngIf="!alert.link" matBadge="{{alert.count}}" matBadgeColor="primary" matBadgeOverlap="false" matBadgeSize="small">
                          <i *ngIf="alert.source">From: {{alert.source}}, </i>{{alert.text}}
                        </span>
                        <span class="text" *ngIf="alert.link" matBadge="{{alert.count}}" matBadgeColor="primary" matBadgeOverlap="false" matBadgeSize="small">
                          <i *ngIf="alert.source">From: {{alert.source}}, </i><a href="{{alert.link}}" target="_blank">{{alert.text}}</a>
                        </span>
                        <span class="bb-notification-date"> {{alert.created.toLocaleString()}}</span>
                    </li>
                  </ul>
                </mat-tab>
              </mat-tab-group>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</ng-container>