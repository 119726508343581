import { Component, HostListener, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';
import { delay, finalize, map, takeUntil } from 'rxjs/operators';

import { ParentChildInvalidMatcher } from '../../../shared/utils/forms/parent-child-invalid-matcher';
import { CurrentSetPasswordDto, PasswordApiService, PasswordRules, ResetType, TokenSetPasswordDto } from './password-api.service';
import { AlertService } from '../../../shared/services/alert.service';


interface PasswordValidators {
   min_length(min: number): ValidatorFn;
   min_lowercase_num(min: number): ValidatorFn;
   min_uppercase_num(min: number): ValidatorFn;
   min_digit_num(min: number): ValidatorFn;
   does_not_contain_username(): ValidatorFn;
   not_empty(): ValidatorFn;
}

@Component({
   selector: 'bb-change-password',
   templateUrl: './change-password.component.html',
   styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
   @Input() resetType: ResetType;
   @Input() resetToken: string | undefined;
   // When using a resetToken (e.g. resetType = "just_reset" | "registration")
   // we pass the userId and username from the server (gleaned from the resetToken),
   // as a logged-in user should be able to reset another user's password via token.
   @Input() userId: string | undefined;
   @Input() username: string | undefined;

   public tooltipOffsetX = this.getTooltipOffsetX(window.innerWidth);
   public tooltipOffsetY = this.getTooltipOffsetY(window.innerWidth);
   public groupErrorMatcher = new ParentChildInvalidMatcher();
   public changePasswordComplete = false;
   public submitting = false;

   public formGroup$: Observable<FormGroup>;
   public rules$: Observable<PasswordRules>;

   private destroy$ = new Subject<void>();
   private passwordValidators: PasswordValidators = {
      min_length: (min: number) => ({value}) =>
         value.length < min
            ? { min_length: true }
            : null,
      min_lowercase_num: (min: number) => ({value}) =>
         value.replace(/[^a-z]/g, '').length < min
            ? { min_lowercase_num: true }
            : null,
      min_uppercase_num: (min: number) => ({value}) =>
         value.replace(/[^A-Z]/g, '').length < min
            ? { min_uppercase_num: true }
            : null,
      min_digit_num: (min: number) => ({value}) =>
         value.replace(/[^0-9]/g, '').length < min
            ? { min_digit_num: true }
            : null,
      does_not_contain_username: () => ({value}) =>
         value.includes(this.username)
            ? { does_not_contain_username: true }
            : null,
      not_empty: () => ({value}) =>
         !value?.length
            ? { not_empty: true }
            : null,
   };

   constructor(
      @Inject('User') public user: User,
      private alertService: AlertService,
      private passwordApiService: PasswordApiService,
      private formBuilder: FormBuilder,
   ) {}

   @HostListener('window:resize', ['$event'])
   onResize(event) {
      this.tooltipOffsetX = this.getTooltipOffsetX(event.target.innerWidth);
      this.tooltipOffsetY = this.getTooltipOffsetY(event.target.innerWidth);
   }

   ngOnInit() {
      this.userId ??= this.user.id;
      this.username ??= this.user.username;
      this.rules$ = this.passwordApiService.getPasswordRules();
      this.resetForm();
   }

   ngOnDestroy() {
      this.destroy$.next();
      this.destroy$.complete();
   }

   public setPassword(form: FormGroup): void {
      this.submitting = true;
      this.passwordApiService.setPassword(this.userId, this.getSetPasswordDto(form))
         .pipe(
            this.alertService.notifyOnError('setting a new password'),
            finalize(() => this.submitting = false),
            takeUntil(this.destroy$),
         )
         .subscribe(() => {
            if (this.resetType === 'currentPassword') {
               this.alertService.show({ text: 'Password updated successfully', type: 'success' });
               // Instead of form.reset(), just re-initialise the form so we don't have to set
               // the initial values again and manually mark each field as free of errors etc
               this.resetForm();
            } else {
               // If 'just_reset' or 'registration', the user is not logged in, so show
               // a success message and offer a link to redirect to the login page
               this.changePasswordComplete = true;
            }
         });
   }

   private getSetPasswordDto(form: FormGroup): CurrentSetPasswordDto | TokenSetPasswordDto {
      const newPassword: string = form.get('newPasswordGroup.newPassword').value;
      if (this.resetType === 'currentPassword') {
         return {
            currentPassword: form.get('currentPassword').value,
            newPassword,
         };
      } else {
         return {
            resetToken: this.resetToken,
            resetType: this.resetType,
            newPassword,
         };
      }
   }

   private resetForm() {
      this.formGroup$ = this.rules$.pipe(map(rules => this.buildForm(rules)));
   }

   private buildForm(rules: PasswordRules): FormGroup {
      const passwordPolicyValidators = this.getPasswordPolicyValidators(rules);
      const newPasswordGroup = this.formBuilder.group({
         newPassword: ['', passwordPolicyValidators],
         confirmNewPassword: ['', []],
      }, { asyncValidators: this.getPasswordsMatchValidator() });

      if (this.resetType === 'currentPassword') {
         return this.formBuilder.group({
            currentPassword: ['', [Validators.required]],
            newPasswordGroup,
         });
      } else if (this.resetType === 'just_reset' || this.resetType === 'registration') {
         return this.formBuilder.group({ newPasswordGroup });
      } else {
         throw new Error(`resetType "${this.resetType}" not implemented`);
      }
   }

   private getPasswordPolicyValidators(rules: PasswordRules) {
      return Object.entries(this.passwordValidators)
         .map(([key, validatorFactory]: [string, (min?: number) => ValidatorFn]) => {
            const rule = rules[key];
            /* Optional chaining so this does not break when a rule is removed
            from PasswordPolicy c.f. eagle/etc/kestrel/password_policy.conf */
            return rule?.enabled ? validatorFactory(rule.value) : null;
         })
         .filter(validator => !!validator);
   }

   private getPasswordsMatchValidator(): AsyncValidatorFn {
      return (group: AbstractControl): Observable<ValidationErrors | null> => {
         const password: string = group.get('newPassword').value;
         const confirmPassword: string = group.get('confirmNewPassword').value;
         return password === confirmPassword
            ? of(null)
            : of({ noMatch: true }).pipe(delay(1000)); // Delay so the validator doesn't shout at the user
                                                       // until they've likely stopped typing
      };
   }

   private getTooltipOffsetX(innerWidth: number): number {
      return this.screenWidthIsNarrow(innerWidth) ? -50 : 290;
   }

   private getTooltipOffsetY(innerWidth: number): number {
      return this.screenWidthIsNarrow(innerWidth) ? -50 : 145;
   }

   private screenWidthIsNarrow(innerWidth: number): boolean {
      return innerWidth < 1200;
   }
}
