<div *ngIf="!dataSource" class="content-loading">
   <fbdn-icon icon="loading"></fbdn-icon>
</div>

<mat-table matSort [hidden]="!dataSource" [dataSource]="dataSource">
   <ng-container matColumnDef="remove">
      <mat-header-cell *matHeaderCellDef>
         <button mat-icon-button (click)="removeSelectedData()" [disabled]="!someDataToRemove() || editingDatum || saving">
            <span class="material-icon">delete</span>
         </button>
      </mat-header-cell>
      <mat-cell *matCellDef="let element" [ngClass]="{editing: editingDatum?.datakey === element.datakey}">
         <span *ngIf="editingDatum?.datakey === element.datakey; else checkbox" class="edit-indicator">
            EDIT
         </span>
         <ng-template #checkbox>
            <mat-checkbox color="primary" [(ngModel)]="dataToRemove[element.datakey]" [disabled]="saving"></mat-checkbox>
         </ng-template>
      </mat-cell>
   </ng-container>
   <ng-container matColumnDef="datakey">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
         {{keyName}}
      </mat-header-cell>
      <mat-cell *matCellDef="let element" [ngClass]="{editing: editingDatum?.datakey === element.datakey}">
         {{element.datakey}}
      </mat-cell>
   </ng-container>
   <ng-container matColumnDef="value">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
         {{valueName}}
      </mat-header-cell>
      <mat-cell *matCellDef="let element" [ngClass]="{editing: editingDatum?.datakey === element.datakey}">
         <ng-container *ngIf="editingDatum?.datakey === element.datakey; else valueCell">
            <mat-form-field>
               <input matInput [type]="element.hide ? 'password' : 'text'" [(ngModel)]="element.value" [disabled]="saving"/>
               <mat-icon *ngIf="hideValues" matSuffix class="material-icon" (click)="element.hide = !element.hide">{{element.hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </mat-form-field>
         </ng-container>
         <ng-template #valueCell>
            {{hideValues ? "**********" : element.value}}
         </ng-template>
      </mat-cell>
   </ng-container>
   <ng-container matColumnDef="edit">
      <mat-header-cell *matHeaderCellDef>
         Edit
      </mat-header-cell>
      <mat-cell *matCellDef="let element; let i = index" [ngClass]="{editing: editingDatum?.datakey === element.datakey}">
         <ng-container class="material-icon" *ngIf="editingDatum?.datakey === element.datakey; else editTrigger">
            <button mat-icon-button type="submit" (click)="saveEdit(element)">
               <span class="material-icon">done</span>
            </button>
            <button mat-icon-button type="button" (click)="cancelRowEdit(i)">
               <span class="material-icon">undo</span>
            </button>
         </ng-container>
         <ng-template #editTrigger>
            <button
               mat-icon-button
               type="button"
               (click)="activateRowEdit(element)"
               class="edit-trigger"
               [disabled]="(editingDatum && editingDatum.datakey !== element.datakey) || addingNewData || saving"
            >
               <span class="material-icon">edit</span>
            </button>
         </ng-template>
      </mat-cell>
   </ng-container>

   <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
   <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

   <tr class="mat-row no-data" *matNoDataRow>
      <td class="mat-cell" colspan="4">No data present on this {{page.context}}</td>
   </tr>
</mat-table>

<div class="bottom-row">
   <button
      mat-icon-button
      [ngClass]="{hidden: addingNewData}"
      [disabled]="editingDatum"
      (click)="toggleIsAddingData()"
      class="add-data-trigger"
   >
      <span class="material-icon">add</span>
      <span class="add-data-message">
         Add {{dataSource?.data.length >= 1 ? 'another' : 'a'}} {{keyName}}/{{valueName}} pair
      </span>
   </button>

   <!-- *ngIf for before formGroup is instantiated and [hidden] for cancelling edit (without losing changes) -->
   <span *ngIf="newDataFormGroup" [hidden]="!addingNewData" class="add-data">
      <span class="add-indicator">Add</span>
      <form [formGroup]="newDataFormGroup">
         <mat-form-field floatLabel="auto" >
            <mat-label>{{keyName}}</mat-label>
            <input matInput type="text" formControlName="datakey"/>
            <mat-error *ngIf="hasError('datakey', 'required')">
               This field is required
            </mat-error>
            <mat-error *ngIf="hasError('datakey', 'keyclash')">
               A key/value pair with this key already exists
            </mat-error>
            <mat-error *ngIf="hasError('datakey', 'noUploadFormatOption')">
               Upload Format should instead be set in
               <a [href]="ingestFormatsLink">Ingest Formats</a>.
            </mat-error>
         </mat-form-field>
         <mat-form-field floatLabel="auto">
            <mat-label>{{valueName}}</mat-label>
            <input matInput [type]="hideValues && addingNewHidePasswords ? 'password' : 'text'" formControlName="value"/>
            <mat-icon *ngIf="hideValues" matSuffix  class="material-icon" (click)="addingNewHidePasswords = !addingNewHidePasswords">{{addingNewHidePasswords ? 'visibility_off' : 'visibility'}}</mat-icon>
            <mat-error *ngIf="hasError('value', 'required')">
               This field is required
            </mat-error>
         </mat-form-field>
         <button
            mat-icon-button
            type="submit"
            (click)="addNewExtraData()"
            [disabled]="newDataFormGroup.invalid || saving"
         >
            <span class="material-icon">done</span>
         </button>
         <button
            mat-icon-button
            type="button"
            (click)="toggleIsAddingData()"
            [disabled]="saving"
         >
            <span class="material-icon">cancel</span>
         </button>
      </form>
   </span>

   <div class="saving-indicator">
      <fbdn-icon *ngIf="saving" icon="loading"></fbdn-icon>
   </div>
</div>
