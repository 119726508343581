import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { PageService } from "../../shared/services/page.service";
import { StatusApiService } from "../../shared/services/status.api.service";
import { AlertService } from "../../shared/services/alert.service";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { CdkConnectedOverlay, ConnectionPositionPair } from "@angular/cdk/overlay";


@Component({
   selector: 'bb-tab-status',
   templateUrl: './tab-status.component.html',
   styleUrls: ['./tab-status.component.scss'],
})
export class TabStatusComponent implements OnInit, OnDestroy {
   @ViewChild(CdkConnectedOverlay) overlay!: CdkConnectedOverlay;
   public model = {
      ready: false,
      objects: [],
      filteredObjects: []
   };
   public showAll = false;
   public fetcher: Observable<any>;
   public popoverOpen = false;
   public arrowTop = true;
   public positionPairs: ConnectionPositionPair[] = [
      {
         offsetX: 2,
         offsetY: 0,
         originX: 'end',
         originY: 'center',
         overlayX: 'start',
         overlayY: 'top',
         panelClass: null,
      },
      {
         offsetX: 2,
         offsetY: 0,
         originX: 'end',
         originY: 'center',
         overlayX: 'start',
         overlayY: 'bottom',
         panelClass: null,
      },
   ];

   private destroy$ = new Subject<void>();

   constructor(
      private alertService: AlertService,
      private pageService: PageService,
      private statusApiService: StatusApiService
   ) {
   }


   ngOnInit() {
      if (this.pageService.page.context === 'site') {
         this.fetcher = this.statusApiService.fetchSiteStatus(this.pageService.page.siteId);
      } else if (this.pageService.page.context === 'account') {
         this.fetcher = this.statusApiService.fetchAccountStatus(this.pageService.page.accountId);
      }

      this.fetcher
         .pipe(
            takeUntil(this.destroy$),
         )
         .subscribe(
            (modelObjects) => {
               this.model.objects = modelObjects;
               this.model.ready = true;
               this.filter();
            },
            (error) => {
               this.alertService.show({
                  type: 'danger',
                  text: `An error occurred fetching the ${this.pageService.page.context} status: ${error.status} ${error.message}`
               });
            },
         );
   }

   ngOnDestroy() {
      this.destroy$.next();
      this.destroy$.complete();
   }

   public toggleShowAll(): void {
      this.showAll = !this.showAll;
      this.filter();
      if (this.overlay && this.overlay.overlayRef) {
         this.overlay.overlayRef.updatePosition();
       }
   };

   public filterObject(object): boolean {
      return this.showAll || object.status==='error';
   };

   public getUrl(object): string {
      return this.statusApiService.getUrl(object);
   };

   public validLink(object): boolean {
      return this.statusApiService.validLink(object);
   }

   public toggleStatus(): void {
      this.popoverOpen = !this.popoverOpen;
   }

   public objectsToRender() {
      return (this.showAll) ? this.model.objects: this.model.filteredObjects;
   }

   private isSameObject(object1, object2): boolean {
      return object1 === object2;
   }

   private filter(): void {
      let filteredObjects = this.model.objects.filter(object => {
         return object.status === 'error';
      });

      const FRACTION = 0.5;

      // Order by name then ID
      filteredObjects = filteredObjects.sort(object => object.name);
      filteredObjects = filteredObjects.sort(object => object.id);

      // Order into ingest, services then endpoints
      const SORT_KEYS = {
         EdgeServer: 1,
         Service: 2,
         Endpoint: 3,
      };
      filteredObjects = filteredObjects.sort(object => SORT_KEYS[object.type] || 0);

      // Put services after their server if it is the list
      filteredObjects.forEach((object, index) => {
         object.$$statusIndex = index;
         object.$$statusLevel = 1;
      });
      filteredObjects.forEach(object => {
         if (object.type !== 'Service') return;

         const serverInList = filteredObjects.find(fObject => this.isSameObject(fObject, object.edgeServer));
         if (!serverInList) return;
         object.$$statusIndex = serverInList.$$statusIndex + FRACTION;
         object.$$statusLevel = serverInList.$$statusLevel + 1;
      });
      filteredObjects = filteredObjects.sort(obj => obj?.$$statusIndex);

      // Put endpoints after their service
      // If service not in the list, tries to put after server if in list
      filteredObjects.forEach((object, index) => {
         object.$$statusIndex = index;
      });
      filteredObjects.forEach(object => {
         if (object.type !== 'Endpoint') return;

         const serviceInList = filteredObjects.find(fObj => this.isSameObject(fObj, object?.service));
         const serverInList = filteredObjects.find(fObj => this.isSameObject(fObj, object.service?.edgeServer));
         const itemInList = serviceInList || serverInList;
         if (!itemInList) return;
         object.$$statusIndex = itemInList.$$statusIndex + FRACTION;
         object.$$statusLevel = itemInList.$$statusLevel + 1;
      });
      filteredObjects = filteredObjects.sort(object => object?.$$statusIndex);
      this.model.filteredObjects = filteredObjects;
   }

}
