import { FormControl, FormGroupDirective, NgForm, } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

/**
 * Error when either control or its parent are invalid and dirty, touched, or submitted.
 * e.g. for grouped validators like 'new password' + 'confirm new password'
 */
export class ParentChildInvalidMatcher implements ErrorStateMatcher {
   isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
      const isSubmitted = form && form.submitted;
      return !!(control && (control.invalid || control.parent.invalid)
         && (control.dirty || control.touched || isSubmitted));
   }
}

export class SimpleErrorStateMatcher implements ErrorStateMatcher {
       isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
            const isSubmitted = form && form.submitted;
            return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
        }
}
