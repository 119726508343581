import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ManagerType, Role } from '../types';
import { UserSummary } from '../types/user-summary.interface';
import { ApiEndpointConfig } from '../../config/api-endpoints.config';

@Injectable({
   providedIn: 'root',
})
export class ManagerUserAccessApiService {

   constructor(
      private http: HttpClient,
   ) {}

   addManagerAccess(managerType: ManagerType, userId: string, roleId: string, id?: string) {
      const path = ApiEndpointConfig[managerType](id=id, userId=userId);
      return this.http.post<null>(path, { roleId: roleId});
   }

   deleteManagerAccess(managerType: ManagerType, userId: string, id?: string) {
      const path = ApiEndpointConfig[managerType](id=id, userId=userId);
      return this.http.delete(path);
   }

   getManagers(managerType: ManagerType, id?: string, offset: number = 0, limit: number = 100): Observable<UserSummary[]> {
      const path = ApiEndpointConfig[managerType](id=id) + '?offset=' + offset + '&limit=' + limit;
      return this.http.get<UserSummary[]>(path);
   }

   getUserInfo(username: string): Observable<UserSummary[]> {
      const path = '/api/users';
      const queryParams = new HttpParams()
         .set('searchText', username);
      return this.http.get<UserSummary[]>(path, {params: queryParams});
   }

   getRoles(): Observable<Role[]> {
      return this.http.get<Role[]>('/api/roles');
   }

}
