import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import { Me } from './me.interface';
import { User } from './user.interface';
import { loadMe, loadMeFail, loadMeSuccess, loadUser, loadUserFail, loadUserSuccess, searchUsers, searchUsersFail, searchUsersSuccess } from './users.actions';


export interface UsersState extends EntityState<User> {
   userLoading: boolean;
   userLoadingError: string;
   usersSearches: {
      [query: string]: User[];
   };
   usersSearching: boolean;
   usersSearchError: string | null;
   me: Me | null;
   meLoading: boolean;
   meLoadingError: string | null;
}

export const initialState: UsersState = {
   ids: [],
   entities: {},
   userLoading: false,
   userLoadingError: null,
   usersSearches: {},
   usersSearching: false,
   usersSearchError: null,
   me: null,
   meLoading: false,
   meLoadingError: null,
};

export const adapter: EntityAdapter<User> = createEntityAdapter<User>({sortComparer: false});

export const usersReducer = createReducer(
   initialState,

   on(loadUser, (state) => ({ ...state, userLoading: true })),
   on(loadUserSuccess, (state, { user }) => ({
      ...adapter.upsertOne(user, state),
      userLoading: false,
      userLoadingError: null,
   })),
   on(loadUserFail, (state, { userLoadingError }) => ({
      ...state,
      userLoading: false,
      userLoadingError,
   })),

   on(searchUsers, (state) => ({ ...state, usersSearching: true })),
   on(searchUsersSuccess, (state, { query, users }) => ({
      ...adapter.upsertMany(users, state),
      usersSearching: false,
      usersSearchError: null,
      usersSearches: {
         ...state.usersSearches,
         [query]: users,
      },
   })),
   on(searchUsersFail, (state, { usersSearchError }) => ({
      ...state,
      usersSearching: false,
      usersSearchError,
   })),

   on(loadMe, (state) => ({ ...state, meLoading: true })),
   on(loadMeSuccess, (state, { me }) => ({
      ...state,
      meLoading: false,
      meLoadingError: null,
      me,
   })),
   on(loadMeFail, (state, { meLoadingError }) => ({
      ...state,
      meLoading: false,
      meLoadingError,
   })),
);
