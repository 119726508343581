/**
 * @param {number} count of items
 * @param {string} nouns word to pluralise (can have multiple - ignored - words before)
 * For words that do not pluralise by simply adding 's'
 * include a / to separate the words where the word
 * after the / is the pluralised manifestation of the
 * singular word.
 *
 * Prefer Angular11+ i18nPluralPipe if using within hybrid application
 *
 * @example
 *    JS/TS
 *       pluralFilter(5, 'editor') // => '5 editors'
 *       pluralFilter(1, 'rush/rushes') => '1 rush'
 *
 *    HTML template
 *       30 | plural:'associated publish item' // => '30 associated publish items'
 *       100 | plural:'sheep/sheep' // => '100 sheep'
 */

export const pluralFilter = function() {
   return function(count: number, nouns: string): string {
      const bits = nouns.split('/');
      const singularNoun = bits[0];
      const pluralNoun = bits.length > 1 ? bits[1] : singularNoun + 's';
      return count + ' ' + (count !== 1 ? pluralNoun : singularNoun);
   };
};
