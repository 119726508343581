import { Pipe, PipeTransform } from '@angular/core';
import { HideDeletingFilter } from './hide-deleting.filter';

// Removes trailing / from filepath
@Pipe({
   name: HideDeletingFilter.id,
})
export class HideDeletingPipe implements PipeTransform {
   transform(array: any[]): any[] {
      return HideDeletingFilter.filter()(array);
   }
}
