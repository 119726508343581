<form (ngSubmit)="submitNewText()">
   <mat-form-field>
      <input type="text" autocomplete="off" matInput [(ngModel)]="text"/>
   </mat-form-field>
   <ng-container *ngIf="text !== initialText">
      <button mat-icon-button type="submit">
         <span class="material-icon">done</span>
      </button>
      <button mat-icon-button type="button" (click)="text = initialText">
         <span class="material-icon">close</span>
      </button>
   </ng-container>
</form>
