import { isNaN } from '../../utils/funct-utils';
import { Schema, SchemaItem } from '../schema.interface';

type Values = {
   [key in keyof Schema]: any;
};

export class DynamicSchema {
   public static defaultValuesForSchema(schema: Schema, values?: Values): Values {
      values = values || {};
      Object.entries(schema).forEach(([schemaItemName, schemaItem]) => {
         if (!values[schemaItemName]
            || (schemaItem.allowedValues && schemaItem.allowedValues.indexOf(values[schemaItemName].value) === -1)
         ) {
            values[schemaItemName] = {
               value: DynamicSchema._getDefaultValue(schemaItem)
            };
         }
      });
      return values;
   }

   public static coerceValueToSatisfySchemaItem(schemaItem: SchemaItem, value: any) {
      if (value === undefined) {
         return DynamicSchema._getDefaultValue(schemaItem);
      }
      if ('allowedValues' in schemaItem) {
         if (schemaItem.allowedValues.indexOf(value) !== -1) {
            return value;
         } else {
            return DynamicSchema._getDefaultValue(schemaItem);
         }
      }
      if (schemaItem.type==='text') {
         return ""+value;
      }
      if (schemaItem.type==='integer') {
         return DynamicSchema.coerceValueToSatisfyNumericConstraints(schemaItem, parseInt(value, 10));
      }
      if (schemaItem.type==='number') {
         return DynamicSchema.coerceValueToSatisfyNumericConstraints(schemaItem, parseFloat(value));
      }
      if (schemaItem.type==='boolean') {
         return !!value;
      }
      return DynamicSchema._getDefaultValue(schemaItem);
   }

   public static coerceValuesToSatisfySchema(schema: Schema, values: any) {
      return Object.fromEntries(
         Object.keys(schema).map(name => [
            name,
            {
               value: DynamicSchema.coerceValueToSatisfySchemaItem(schema[name], (values[name] || {}).value)
            }
        ])
      );
    }

   private static _getDefaultValue(schemaItem: SchemaItem) {
      if ('defaultValue' in schemaItem) {
         return schemaItem.defaultValue;
      } else {
         /* eslint-disable id-blacklist */
         return {
            text: '',
            textarea: '',
            emailAddress: '',
            integer: 0,
            number: 0,
            fractionOrInteger: 0,
            boolean: false
         }[schemaItem.type];
      }
   }

   private static coerceValueToSatisfyNumericConstraints(schemaItem: SchemaItem, value: any) {
      if (isNaN(value)) {
         return DynamicSchema._getDefaultValue(schemaItem);
      }
      if (schemaItem.evenNumber) {
         value = value &~ 1;
      }
      if (_.has(schemaItem, 'minValue')) {
         value = Math.max(value, schemaItem.minValue);
      }
      if (_.has(schemaItem, 'maxValue')) {
         value = Math.min(value, schemaItem.maxValue);
      }
      return value;
   }
}
