import { Injectable, Inject } from '@angular/core';
import { Observable, from, of, throwError } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';

import { SaveTransactionService } from '../utils/save-transaction/save-transaction.service';
import { Service } from '../../edge-services/service.interface';
import { Endpoint } from '../types/endpoint.interface';
import { AsyncCacheService } from '../utils/async-cache.service';
import { EndpointsApiService } from './endpoints.api.service';
import { ArrivalsFolder } from '../api/arrivals-folder.interface';
import { Assignment } from '../types/assignment.interface';
import { ApiUpdatesService } from "../utils/updates/api-updates.service";
import { HttpClient } from '@angular/common/http';
import { RAW_DATA } from '../constants/raw-data';
import { RawCollection } from '../types';
import { ArrivalsFoldersApiService } from './arrivals-folders-api.service';
import { SkeletonCacheService } from '../utils/skeleton-cache.service';
import { StatefulObject } from '../utils/save-transaction/transaction-objs';

interface Collection {
   results: any[];
   channel?: any;
}


@Injectable({
   providedIn: 'root',
})
export class ArrivalsFolderEndpointAssignmentsApiService {
   private apiUpdates: any;
   private asyncCache: any;
   private skeletonCache: any;
   private rawDataOptions = { headers: RAW_DATA, };

   constructor(
      private saveTransactionService: SaveTransactionService,
      private skeletonCacheService: SkeletonCacheService,
      private arrivalsFoldersApiService: ArrivalsFoldersApiService,
      private httpClient: HttpClient,
      private asyncCacheService: AsyncCacheService<Assignment>,
      private endpointsApiService: EndpointsApiService,
      private apiUpdatesService: ApiUpdatesService,
   ) {
      this.asyncCache = this.asyncCacheService.initAsyncCache('ArrivalsFolderEndpointAssignmentsApiService');
      this.apiUpdates = this.apiUpdatesService.initApiUpdatesService('ArrivalsFolderEndpointAssignmentsApiService');
      this.skeletonCache = this.skeletonCacheService.initSkeletonCache('ArrivalsFolderEndpointAssignmentsApiService', {
         marshall: this.marshall,
         unmarshall: x => this.unmarshall(x),
      });
   }

   public fetchForAccount(accountId: string, forceFetch: boolean=false): Observable<Assignment[]> {
      const api = this.httpClient.get<RawCollection>('/api/accounts/' + accountId + '/arrivalsFolderEndpointAssignments', this.rawDataOptions)
         .pipe(
            map((collection: Collection) => this.skeletonCache.unmarshallCollection(collection)),
         );
      return this.asyncCache.fetch('assignment-account-' + accountId, api, forceFetch)
         .pipe(
            map((collection) => this.apiUpdates.subscribeToCollection(collection, this.fetchForAccount.bind(this), [accountId, true], true)),
         );
   }

   public fetchForService(service: Service, forceFetch: boolean=false): Observable<Assignment[]> {
      const api = this.httpClient.get<RawCollection>('/api/services/' + service.id + '/arrivalsFolderEndpointAssignments', this.rawDataOptions)
         .pipe(
            map((collection: Collection) => this.skeletonCache.unmarshallCollection(collection)),
         );
      return this.asyncCache.fetch('assignment-service-' + service.id, api, forceFetch)
         .pipe(
            map((collection) => this.apiUpdates.subscribeToCollection(collection, this.fetchForService.bind(this) , [service, true], true)),
         );
   }

   public getNewAssignment(arrivalsFolder: ArrivalsFolder, endpoint: Endpoint): Assignment
   {
      return this.saveTransactionService.prepare({
         $$state: 'new',
         $$dirty: true,
         arrivalsFolder: arrivalsFolder,
         endpoint: endpoint
      });
   };

   public createAssignment(assignment: any): Observable<Assignment>
   {
      return this.saveTransactionService.saveObject(assignment, () => {
         return this.httpClient.post<Assignment>('/api/arrivalsFolderEndpointAssignments', this.skeletonCache.marshall(assignment));
      });
   };

   public deleteAssignment(assignments: Assignment[], assignment: Assignment): Observable<Assignment>
   {
      if (!assignment.arrivalsFolder.id) {
         this.deleteAssignmentFromAssignments(assignments, assignment);
         return of();
      }
      assignment.$$deleting = true;
      return this.httpClient.post<Assignment>('/api/arrivalsFolderEndpointAssignments/trash', this.skeletonCache.marshall(assignment))
         .pipe(
            tap(result => {
               this.deleteAssignmentFromAssignments(assignments, assignment);
               return result;
            }),
            catchError(error => {
               assignments = assignments.filter(existing => existing !== assignment && !assignment.$$deleting && existing.arrivalsFolder !== assignment.arrivalsFolder && existing.endpoint !== assignment.endpoint);
               assignment.$$deleting = false;
               return throwError(error);
            })
         );
   };

   public createAssignments(assignments: Assignment[]): Observable<Assignment[]>
   {
      if (!assignments.length) return of([]);
      return this.saveTransactionService.saveCollection(assignments, () => {
         return this.httpClient.post<Assignment[]>('/api/arrivalsFolderEndpointAssignments', assignments.map(assignment => this.skeletonCache.marshall(assignment)));
         });
   };

   private deleteAssignmentFromAssignments(assignments: Assignment[], assignment: Assignment): Assignment[]{
      const index = assignments.findIndex(elm => elm === assignment);
      if( index >= 0) {
         assignments.splice(index, 1);
      }
      return assignments;
   }

   private unmarshall(assignment: Assignment): Assignment
   {
      if (!assignment.endpoint) {
         assignment.endpoint = this.endpointsApiService.getSkeleton(assignment.endpointId);
      }
      if (!assignment.arrivalsFolder) {
         assignment.arrivalsFolder = this.arrivalsFoldersApiService.getSkeleton(assignment.arrivalsFolderId) as ArrivalsFolder;
      }
      return this.saveTransactionService.prepare(assignment);
   };

   private marshall(assignment: Assignment): Assignment
   {
      delete assignment.$$deleting;
      return {
         endpointId: assignment.endpoint.id,
         arrivalsFolderId: assignment.arrivalsFolder.id,
      };
   }
}
