const ngModule = angular.module('bb.fbdn-left-ellipsis-item', []);

ngModule.directive('fbdnLeftEllipsisItem', function() {
   return {
      require: '^fbdnLeftEllipsisSet',
      restrict: 'A',
      link: function(scope, element, attrs, ellipsisSetController) {
         ellipsisSetController.register(element);

         attrs.$observe('title', ellipsisSetController.recompute);

         scope.$on('$destroy', () => {
            ellipsisSetController.deregister(element);
         });
      }
   };
});

export default ngModule;
