import { Component, Input, OnInit, ViewChild } from '@angular/core';

@Component({
   selector: 'bb-auto-updating-img',
   template: "<img src='{{updatedUrl}}' width='{{width}}' height='{{height}}' #autoUpdatingImg >",
})
export class AutoUpdatingImgComponent implements OnInit {
   @Input() url: string;
   @Input() width: number;
   @Input() height: number;
   @ViewChild('autoUpdatingImg', { static: false }) imageElement: HTMLImageElement;

   public updatedUrl = '';

   private timeUntilRetryFail = 0;

   public ngOnInit(): void {
      setInterval(() => {
         if (this.timeUntilRetryFail <= 0 || this.imageElement.complete) {
            this.updatedUrl = this.url + '&' + new window.Date().getTime();
            this.timeUntilRetryFail = 30;
         } else {
            this.timeUntilRetryFail -= 1;
         }
       }, 1000);
   }
}
