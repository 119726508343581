import { from, Observable } from 'rxjs';

import HttpModule from '../../fbdn-angular-modules/http';
import AsyncCacheModule, { AsyncCache } from '../../utils/async-cache.factory';
import SkeletonCacheModule from '../../utils/skeleton-cache.factory';
import { ApiRawResponse } from './../api-raw-response.interface';
import { Skeleton } from '../../types/skeleton.interface';
import { AccountDto } from './account.interface';


interface CloneAccount {
   cloneFrom: string; // accountId to clone from
   reserveKey?: string; // Set to attempt to reserve the name for later use
   createKey?: string; // Set to use a previously reserved name
   unreserveKey?: string; // Set to clear a reservation
}
type CloneAccountDto = Partial<AccountDto> & CloneAccount;

interface GetAccountsQueryParams {
   name?: string;
   search?: string;
   extId?: string;
   siteId?: string;
   withDisabled?: boolean;
   onlyDisabled?: boolean;
   withStats?: boolean;
   permission?: string;
   sortBy?: string;
   startIndex?: number;
   offset?: string;
   limit?: number;
}

export interface NameReservation {
   name: string;
   status: 'valid' | 'reserved' | 'inuse';
   dupekey?: string;
   error?: string;
   account?: boolean;
   user?: boolean;
}

export interface Resolution {
   width: number;
   height: number;
}

export interface AccountsApi {
   fetch(accountId: string, forceFetch?: boolean): IPromise<AccountDto>;
   post(accountId: string, accountData: Partial<AccountDto>): IPromise<AccountDto>;
   cloneFrom(accountData: CloneAccountDto): IPromise<AccountDto>;
   getAccounts(queryParams?: GetAccountsQueryParams): IPromise<ApiRawResponse<AccountDto[]>>;
   fetchWorkflows(accountId: string): IPromise<any>;
   calculateMaxVideoResolution(workflows: any): Resolution;
   reserveName(name: string, reserveKey: string, cloneFrom: string): Observable<NameReservation>;
   unreserveName(name: string, unreserveKey: string, cloneFrom: string): Observable<void>;
   populateSkeleton(skeleton: Skeleton): IPromise<any>;
   getSkeleton(accountId?: string): Skeleton;
}

const ngModule = angular.module('bb.api.accounts', [
   AsyncCacheModule.name,
   SkeletonCacheModule.name,
   HttpModule.name,
]);

ngModule.factory('AccountsApi', ['$q', 'AsyncCache', 'SkeletonCache', 'http', '_',
                                 function($q: IQService, asyncCache: AsyncCache, SkeletonCache: any, http: any, _: Lodash): AccountsApi {
   const api: AccountsApi = this;
   const cache = asyncCache('AccountsApi');
   const skeletonCache = SkeletonCache('AccountsApi');

   function handleError(account: AccountDto, error: string): IPromise<any> {
      account.$$state = 'error';
      return $q.reject(error);
   }

   api.getAccounts = (queryParams = {}) => {
      return http({
         method: 'GET',
         url: '/api/accounts',
         params: queryParams,
         _rawData: true,
      }).then(skeletonCache.unmarshallCollection);
   };

   api.fetch = (accountId, forceFetch=false) => {
      return cache.fetch('account-' + accountId, () => {
         return http({
            method: 'GET',
            url: '/api/accounts/' + accountId
         }).then(skeletonCache.unmarshall, _.partial(handleError, skeletonCache.get(accountId)));
      }, forceFetch);
   };

   api.fetchWorkflows = (accountId) => {
      return cache.fetch('account-' + accountId + '-workflows', function() {
         return http({
            method: 'GET',
            url: '/api/accounts/' + accountId + '/workflows'
         });
      });
   };

   api.calculateMaxVideoResolution = (workflows) => {
      // we set a sensible minimum here just so our client
      // does not have to muck about. This is the default from
      // media-player-config.
      var maxVideoWidth = 640;
      var maxVideoHeight = 360;
      const maxVideoRegexp = /^([0-9]*)x([0-9]*)$/;
      for (const workflow of workflows) {
         if (workflow.editor?.maxVideo) {
            const m = maxVideoRegexp.exec(workflow.editor.maxVideo);
            if (m) {
               const width = parseInt(m[1], 10);
               const height = parseInt(m[2], 10);
               // rather than maxing width and height independently,
               // we'll choose the size with most total pixels, as
               // that is how videolib imposes its limits.
               if (width*height > maxVideoWidth*maxVideoHeight) {
                  maxVideoWidth = width;
                  maxVideoHeight = height;
               }
            }
         }
      }
      return {width: maxVideoWidth, height: maxVideoHeight};
   };

   api.post = (accountId, accountData) => {
      return http({
         method: 'POST',
         url: '/api/accounts/' + accountId,
         data: accountData,
      }).then(skeletonCache.unmarshall);
   };

   api.cloneFrom = (accountData) => {
      return http({
         method: 'POST',
         url: '/api/accounts',
         data: accountData,
      }).then(skeletonCache.unmarshall);
   };

   api.reserveName = (name, reserveKey, cloneFrom) => {
      return from<Observable<NameReservation>>(http({
         method: 'POST',
         url: '/api/accounts',
         data: { reserveKey, name, cloneFrom },
      }));
   };

   api.unreserveName = (name, unreserveKey, cloneFrom) => {
      return from<Observable<void>>(http({
         method: 'POST',
         url: '/api/accounts',
         data: { unreserveKey, name, cloneFrom },
      }));
   };

   api.populateSkeleton = (skeleton) => {
      return skeleton.id ? api.fetch(skeleton.id) : $q.when(skeleton);
   };

   api.getSkeleton = (id?: string) => {
      return skeletonCache.get(id);
   };
   return api;
}]);

export default ngModule;
