import { ClickMenuDirectionDirective } from './click-menu-direction.directive';
import { MouseenterMenuDirectionDirective } from './mouseenter-menu-direction.directive';

const ngModule = angular.module('bb.menu-direction', []);

ngModule
   .directive('mouseenterMenuDirection', MouseenterMenuDirectionDirective.factory)
   .directive('clickMenuDirection', ClickMenuDirectionDirective.factory);

export default ngModule;
