import { remove } from 'lodash';

interface NavigationLocation extends ILocationService {
   $$absUrl: string;
}

export class NavigationService {
   static $inject = ['$window', '$location'];

   private warningList = [];

   constructor(
      private $window: IWindowService,
      private $location: NavigationLocation,
   ) {}

   public preventUnload(event: BeforeUnloadEvent): void {
      event.preventDefault();
      event.returnValue = true;
   }

   public warnBeforeNavigationAway(): void {
      this.$window.addEventListener('beforeunload', this.preventUnload);
   }

   public allowNavigationAway(): void {
      this.$window.removeEventListener('beforeunload', this.preventUnload);
   }

   public addToWarningList(objOrId: any): void {
      if (this.warningList.includes(objOrId)) return;
      this.warningList.push(objOrId);
      this.warnBeforeNavigationAway();
   }

   public removeFromWarningList(objOrId: any): void {
      if (!this.warningList.includes(objOrId)) return;
      remove(this.warningList, (item) => item === objOrId);
      if (!this.warningList.length) {
         this.allowNavigationAway();
      }
   }

   public reload(message: string, elementIdToJumpTo?: string): void {
      this.allowNavigationAway();
      const parsed = this.$window.parseUri(this.$window.location.href);
      if (message) {
         parsed.queryKey.message = message;
      }
      if (elementIdToJumpTo) {
         parsed.queryKey.element_id_to_jump_to = elementIdToJumpTo;
      }
      window.location.href = this.serialiseUrl(parsed);
   }

   public removeFromQueryWithoutNavigation(key: string): void {
      const parsed = this.$window.parseUri(this.$window.location.href);
      if (this.$window.history && key in parsed.queryKey) {
         delete parsed.queryKey[key];
         // Prevent infinite digest caused
         // by directly calling history API
         this.$location.$$absUrl = this.serialiseUrl(parsed);
         this.$window.history.replaceState(null, '', this.serialiseUrl(parsed));
      }
   }

   public openPopup(url: string): void {
      this.$window.open(url);
   }

   private serialiseUrl(components: any): string {
      let queryString = this.$window.jQuery.param(components.queryKey);
      queryString = queryString ? '?' + queryString : '';
      let portString = components.port;
      portString = portString ? ':' + portString : '';
      return components.protocol + '://' + components.host + portString + components.path + queryString;
   }

}
