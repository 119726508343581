import { createAction, props } from '@ngrx/store';

import { DirtyEntity } from '../../../shared/types';
import { ServiceType } from '../../../store/edge-server-services/types';
import { TemporaryEdgeService, TemporaryServiceTemplate } from './types';


export const addNewService = createAction(
   '[Edge Service Cards] Add New Service',
   props<{serviceType: ServiceType}>(),
);
export const addTemporaryService = createAction(
   '[Edge Service Cards] Add Temporary Service',
   props<{tempService: TemporaryEdgeService | TemporaryServiceTemplate}>(),
);
export const removeTemporaryService = createAction(
   '[Edge Service Cards] Remove Temporary Service',
   props<{id: string}>(),
);
export const removeFromServicesInEdit = createAction(
   '[Edge Service Cards] Remove Temporary Service From Services In Edit',
   props<{id: string}>(),
);
export const removeFromDirtyServices = createAction(
   '[Edge Service Cards] Remove Temporary Service From Dirty Services',
   props<{id: string}>(),
);

export const updateTemporaryServiceName = createAction(
   '[Edge Service Cards] Update Temporary Service Name',
   props<{id: string; name: string}>(),
);

export const upsertServiceInEdit = createAction(
   '[Edge Service Cards] Upsert Service In Edit',
   props<{id: string; value: any}>(),
);
export const updateServiceDirtiness = createAction(
   '[Edge Service Cards] Update Service Dirtiness',
   props<DirtyEntity>(),
);

export const selectService = createAction(
   '[Edge Service Cards] Select Service',
   props<{id: string | null}>(),
);
export const scrollToSelectedService = createAction(
   '[Edge Service Cards] Scroll to Selected Service',
);
