export const dynamicSchema: Injectable<Function> = ['_', function(_: Lodash) { // eslint-disable-line @typescript-eslint/ban-types
   function _getDefaultValue(schemaItem) {
      if ("defaultValue" in schemaItem) {
         return schemaItem.defaultValue;
      } else {
         /* eslint-disable id-blacklist */
         return {
            text: "",
            textarea: "",
            emailAddress: "",
            integer: 0,
            number: 0,
            fractionOrInteger: 0,
            boolean: false
         }[schemaItem.type];
      }
   }

   function coerceValueToSatisfyNumericConstraints(schemaItem, value) {
      if (_.isNaN(value)) {
         return _getDefaultValue(schemaItem);
      }
      if (schemaItem.evenNumber) {
         value = value &~ 1;
      }
      if (_.has(schemaItem, 'minValue')) {
         value = Math.max(value, schemaItem.minValue);
      }
      if (_.has(schemaItem, 'maxValue')) {
         value = Math.min(value, schemaItem.maxValue);
      }
      return value;
   }

   function coerceValueToSatisfySchemaItem(schemaItem, value) {
      if (_.isUndefined(value)) {
         return _getDefaultValue(schemaItem);
      }
      if (_.has(schemaItem, 'allowedValues')) {
         if (_.includes(schemaItem.allowedValues, value)) {
            return value;
         } else {
            return _getDefaultValue(schemaItem);
         }
      }
      if (schemaItem.type==='text') {
         return ""+value;
      }
      if (schemaItem.type==='integer') {
         return coerceValueToSatisfyNumericConstraints(schemaItem, parseInt(value, 10));
      }
      if (schemaItem.type==='number') {
         return coerceValueToSatisfyNumericConstraints(schemaItem, parseFloat(value));
      }
      if (schemaItem.type==='boolean') {
         return !!value;
      }
      return _getDefaultValue(schemaItem);
   }

   function coerceValuesToSatisfySchema(schema, values) {
      return _.mapValues(schema, function(schemaItem, name) {
         return {
            value: coerceValueToSatisfySchemaItem(schemaItem, (values[name] || {}).value)
         };
      });
   }

   function defaultValuesForSchema(schema, values) {
      values = values || {};
      _.forEach(schema, function(schemaItem, schemaItemName) {
         if (!values[schemaItemName] || (schemaItem.allowedValues && schemaItem.allowedValues.indexOf(values[schemaItemName].value) === -1)) {
            values[schemaItemName] = {
               value: _getDefaultValue(schemaItem)
            };
         }
      });
      return values;
   }

   return {
      coerceValueToSatisfySchemaItem: coerceValueToSatisfySchemaItem,
      coerceValuesToSatisfySchema: coerceValuesToSatisfySchema,
      defaultValuesForSchema: defaultValuesForSchema
   };
}];
