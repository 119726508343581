import { HttpClient, HttpParams, HttpParamsOptions } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { extend } from '../utils/funct-utils';

import { UserSummary } from '../types/user-summary.interface';
import { AsyncCacheService } from '../utils/async-cache.service';
import { Skeleton } from '../types/skeleton.interface';
import { Site, SiteDto } from '../types/site.interface';

export interface Permissions {
   canSearchSiteUsers: boolean;
   canAccessEdgeEndpoints: boolean;
}

@Injectable({
   providedIn: 'root',
})
export class SitesApiService {
   private asyncCache: any;

   constructor(
      private http: HttpClient,
      private asyncCacheService: AsyncCacheService<Site[]| UserSummary[] | Permissions>,
   ) {
      this.asyncCache = this.asyncCacheService.initAsyncCache("SitesApiService");
   }

   public getSiteUsers(siteId: string, searchText?: string, accessAccountId?: string, type?: string, forceFetch: boolean=false): Observable<UserSummary[]> {
      const cacheApi = '/api/users-' + siteId + ((searchText) ? searchText: '') + ((accessAccountId) ? accessAccountId: '') + ((type) ? type: '');
      let queryParams = new HttpParams()
         .set('siteId', siteId);
      if (searchText) { queryParams = queryParams.set('searchText', searchText); }
      if (accessAccountId) { queryParams = queryParams.set('accessAccountId', accessAccountId); }
      if (type) { queryParams = queryParams.set('type', type); }
      const api = this.http.get<UserSummary[]>('/api/users', {params: queryParams});
      return this.asyncCache.fetch(cacheApi, api, forceFetch);
   }

   public fetchPermissions(siteId: string, forceFetch=false): Observable<Permissions> {
      const api = this.http.get<Permissions>('/api/sites/' + siteId + '/permissions');
      return this.asyncCache.fetch('/api/sites/' + siteId + '/permissions', api, forceFetch);
   }

   public getSkeleton(siteId: string): Skeleton {
      try {
         return this.asyncCache.find(siteId);
      }
      catch(error) {
         const skeleton = {
            id: siteId ? siteId : null,
            $$state: siteId ? 'uninitialized' : 'clean',
         };
         if ( siteId ) {
            this.asyncCache.fetch(siteId, of(skeleton));
         }
         return skeleton;
      }
   }

   public mergeIntoSkeleton(site: SiteDto): Site {
      const skeleton = this.getSkeleton(site.id);
      skeleton.$$state = 'clean';
      return extend(skeleton, site);
   }

   public fetchAll(): Observable<Site[]> {
      const api = this.http.get<SiteDto[]>('/api/sites');
      return this.asyncCache.fetch('sites-', api)
         .pipe(
            map((sites: SiteDto[]) => sites.map((site) => this.mergeIntoSkeleton(site))),
         );
   }

   public fetch(siteId: string): Observable<Site> {
      const skeleton = this.getSkeleton(siteId);
      const api = this.http.get<SiteDto>('/api/sites/' + siteId);
      return this.asyncCache.fetch(siteId, api)
         .pipe(
            catchError((error) => of(this.handleError(skeleton)) ),
            map((site: SiteDto) => this.mergeIntoSkeleton(site) )
         );
   }

   public populateSkeleton(skeleton): Observable<Site> {
      return this.fetch(skeleton.id);
   }

    private handleError(site: Skeleton): Skeleton {
      site.$$state = 'error';
      return site;
    }
}
