import UserModule from '../user.factory';
import TplModule from '../tpl.factory';
import { LoginDialogLockService } from '../services/login-dialog-lock.service';

const ngModule = angular.module('bb.http', [
   UserModule.name,
   TplModule.name,
]);

ngModule.factory('http', ['$window', '$http', '$q', 'tpl', 'User', '$timeout', 'LoginDialogLockService',
                         function($window, $http, $q, tpl, User, $timeout, dialogLockService: LoginDialogLockService) {

   function attachAbort(promise, abortFn) {
      var originalThen = promise.then;
      promise.then = function() {
         var originalPromise = originalThen.apply(promise, arguments);
         originalPromise.abort = abortFn;
         originalPromise = attachAbort(originalPromise, abortFn);
         return originalPromise;
      };
      return promise;
   }

   /* Copied from angular.js */
   function serializeValue(v) {
      if (angular.isObject(v)) {
         return angular.isDate(v) ? v.toISOString() : angular.toJson(v);
      }
      return v;
   }

   function forEachSorted(obj, iterator, context?) {
      var keys = Object.keys(obj).sort();
      for (const k of keys) {
         iterator.call(context, obj[k], k);
      }
      return keys;
   }
   /* End copied functions */

   function fbdnEncodeUriQuery(val, pctEncodeSpaces?) {
      // This is similar to encodeUriQuery in angular.js, but instead of
      // decoding semicolons from %3B, this one leaves them encoded instead
      return encodeURIComponent(val).
                   replace(/%40/gi, '@').
                   replace(/%3A/gi, ':').
                   replace(/%24/g, '$').
                   replace(/%2C/gi, ',').
                   replace(/%20/g, (pctEncodeSpaces ? '%20' : '+'));
   }

   function fbdnParamSerializer(params) {
      // This is like $HttpParamSerializerProvider in angular.js but uses
      // the above function for encoding query parameters
      if (!params) return '';
      var parts = [];
      forEachSorted(params, function(value, key) {
         if (value === null || angular.isUndefined(value)) return;
         if (angular.isArray(value)) {
            angular.forEach(value, function(v, k) { // eslint-disable-line no-unused-vars
               parts.push(fbdnEncodeUriQuery(key)  + '=' + fbdnEncodeUriQuery(serializeValue(v)));
            });
         } else {
            parts.push(fbdnEncodeUriQuery(key) + '=' + fbdnEncodeUriQuery(serializeValue(value)));
         }
      });

      return parts.join('&');
   }

   function makeError(error) {
      return {
         message: (error.data && error.data.message) ? error.data.message : error.statusText !== "" ? error.statusText : "http status " + error.status,
         status: error.status,
         data: error.data,
         toString: function() {
            return this.message;
         }
      };
   }

   let loginWindowPromise = null;
   let onLoginWindowClose = null;

   function doHttp(config) {
      // Angular's automatic handling of cookie -> header
      // uses its internal $browser.cookies(), which can be 100ms out of date
      config.xsrfCookieName = 'UNUSED';
      config.headers = config.headers || {};
      config.headers[$window.xsrf.headerName] = $window.xsrf.getCookie();

      var deferredAbort = $q.defer();
      angular.extend(config, { // eslint-disable-line no-undef
         timeout: deferredAbort.promise,
         paramSerializer: fbdnParamSerializer
      });

      var promise = $http(config).then(function(result) {
         if (!(result.data instanceof Object) || !('results' in result.data)) {
            return $q.reject(makeError(result));
         }
         if (config._rawData) {
            return result.data;
         }

         return result.data.results;
      }, function(error) {
         if (error.status === 401 && dialogLockService.acquireLockForLib('ajs')) {
            return $q(function(resolve, reject) {
               if (!loginWindowPromise) {
                  loginWindowPromise = tpl.createTemplate({
                     anchor: 'body',
                     template: 'fbdn-login-dialog',
                     attr: {
                        title: "Password for " + User.fullName + " (" + User.username + ") required",
                        message: "Your session has timed out. Please enter the password again.",
                        dialogStyle: 'width: 50%',
                        containerStyle: 'z-index: 2000',
                     }
                  }).then((handler) => {
                     var checkIfLoggedInPromise = null;
                     onLoginWindowClose = handler.scope.dialog.scope.registerEvent.bind(null, 'close');
                     onLoginWindowClose(() => {
                        loginWindowPromise = null;
                        dialogLockService.relinquishLock();
                        doHttp(config)
                           .then(results => resolve(results))
                           .catch(err => reject(err));
                        $timeout.cancel(checkIfLoggedInPromise);
                     });
                     handler.scope.dialog.open();
                     function scheduleLoggedInCheck() {
                        checkIfLoggedInPromise = $timeout(() => {
                           $http({
                              method: "GET",
                              url: "/api/me"
                           }).then(handler.scope.dialog.close, scheduleLoggedInCheck);
                        }, 30000);
                     }
                     scheduleLoggedInCheck();
                  });
               } else {
                  // Where we fire multiple simultaneous requests after having timed out/been logged out, the first
                  // to respond with a 401 will trigger the opening of the dialog. We want to retry the others that
                  // also failed once the dialog has been closed (the user has logged in).
                  // onLoginWindowClose is bound to the open dialog's close event.
                  onLoginWindowClose(() => {
                     doHttp(config)
                        .then(results => resolve(results))
                        .catch(err => reject(err));
                  });
               }
            });
         /* FP-21603
            When the user navigates away before an XHR is completed, it should be "aborted". One cannot
            actually cancel a sent HTTP request, so practically this means the response should just be ignored.
            Firefox does not drop XHRs on page unload/XHR onabort like Chromium does, it errors them instead
            which triggers our error notifications when a user leaves a page before requests complete.
            Return a new [unresolved and unrejected] promise when the XHR is aborted instead to avoid this.
            https://github.com/angular/angular.js/issues/14219
         */
         } else if (error.status === -1 && error.xhrStatus === 'abort') {
            return $q.defer().promise;
         }
         return $q.reject(makeError(error));
      });

      promise.abort = function() {
         if (deferredAbort) {
            deferredAbort.resolve();
         }
      };

      promise = attachAbort(promise, promise.abort);

      return promise;
   }

   return doHttp;
}]);

export default ngModule;
