import { AfterViewInit, Component, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Observable, Subject, Subscription } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';

import { ConfirmDialogComponent, ConfirmDialogModel } from '../dialogs';
import { ExtraDataApiService } from './extra-data.api.service';
import { ExtraData, ExtraDatum } from './extra-data.interface';
import { AlertService } from '../../services/alert.service';


@Component({
   selector: 'bb-extra-data',
   templateUrl: './extra-data.component.html',
   styleUrls: ['./extra-data.component.scss'],
})
export class ExtraDataComponent implements OnInit, AfterViewInit, OnDestroy {
   @Input() prefix = '';
   @Input() keyName = "Key";
   @Input() valueName = "Value";
   @Input() hideValues = false;
   @ViewChild(MatTable) table: MatTable<ExtraDatum>;
   @ViewChild(MatSort) sort: MatSort;

   public readonly skippedPrefix = 'key-'; // We skip displaying these unless selected with 'prefix' argument
   public readonly displayedColumns = ['remove', 'datakey', 'value', 'edit'];
   public dataSource: MatTableDataSource<ExtraDatum>;
   public newDataFormGroup: FormGroup;
   public dataToRemove: {[datakey: string]: boolean} = {};
   public editingDatum: ExtraDatum | null = null;
   public ingestFormatsLink: string;
   public addingNewData = false;
   public saving = false;
   public addingNewHidePasswords = true; // Default to hidden (if hidable) in the adding new data dialogue

   private destroy$ = new Subject<void>();
   private dialogSubscription: Subscription;

   constructor(
      @Inject('Page') public page: Page,
      private extraDataApiService: ExtraDataApiService,
      private dialog: MatDialog,
      private formBuilder: FormBuilder,
      private alertService: AlertService,
   ) {
      this.ingestFormatsLink = `${window.location.pathname}?element_id_to_jump_to=id_ingest_formats`;
   }

   ngOnInit() {
      this.newDataFormGroup = this.formBuilder.group({
         datakey: ['', [Validators.required, this.existingKeyValidator.bind(this), this.disallowUploadFormatValidator.bind(this)]],
         value: ['', [Validators.required]],
         hide: this.hideValues,
      });
   }

   ngAfterViewInit() {
      this.extraDataApiService.getExtraData()
         .pipe(
            this.alertService.notifyOnError(),
            takeUntil(this.destroy$),
         )
         .subscribe(extraData => {
            this.dataSource = new MatTableDataSource(this.unmarshallExtraData(extraData));
            this.dataSource.sort = this.sort;
            this.dataSource.sortData = this.caseInsensitiveSort.bind(this);
         });
   }

   ngOnDestroy() {
      this.destroy$.next();
      this.destroy$.complete();
   }

   public removeSelectedData(): void {
      const extraData: ExtraData = Object.entries(this.dataToRemove)
         .filter(datum => datum[1])
         .reduce((accum, curr) => {
            const datakey = curr[0];
            Object.assign(accum, { [datakey]: null });
            return accum;
         }, {});
      const datakeys = Object.keys(extraData).map(key => `"${key}"`).join(', ');

      this.dialogSubscription?.unsubscribe();
      const data: ConfirmDialogModel = {
         title: 'Delete Extra Data',
         message: `Are you sure you want to delete the following data: ${datakeys}?`,
         confirmBtnLabel: 'Delete',
         cancelBtnLabel: 'Cancel',
      };
      const dialogRef = this.dialog.open<ConfirmDialogComponent, ConfirmDialogModel, boolean>(
         ConfirmDialogComponent,
         { width: '50%', panelClass: ['confirm-dialog-component'], data },
      );
      this.dialogSubscription = dialogRef.afterClosed().subscribe(confirmed => {
         if (confirmed) {
            this.saveExtraData(extraData)
               .subscribe(() => {
                  this.dataToRemove = {};
                  this.alertService.show({
                     type: 'success',
                     text: `Data ${datakeys} successfully deleted.`,
                  });
               });
         }
      });
   }

   public someDataToRemove(): boolean {
      return Object.values(this.dataToRemove).some(toRemove => toRemove);
   }

   public cancelRowEdit(index: number): void {
      const datum = this.dataSource.data[index];
      datum.value = this.editingDatum.value;
      this.editingDatum = null;
   }

   public activateRowEdit(datum: ExtraDatum): void {
      this.editingDatum = {...datum};
   }

   public saveEdit(datum: ExtraDatum): void {
      const datumToSave: ExtraData = {
         [datum.datakey.trim()]: datum.value.trim(),
      };
      this.saveExtraData(datumToSave)
         .subscribe(() => {
            this.editingDatum = null;
            this.alertService.show({
               type: 'success',
               text: `Data "${datum.datakey}" successfully saved.`,
            });
         });
   }

   public addNewExtraData(): void {
      const datakeyControl = this.newDataFormGroup.get('datakey');
      const valueControl = this.newDataFormGroup.get('value');
      const datakey: string = datakeyControl.value.trim();
      const value: string = valueControl.value.trim();
      datakeyControl.disable();
      valueControl.disable();

      this.saveExtraData({[datakey]: value})
         .subscribe(() => {
            datakeyControl.enable();
            valueControl.enable();
            this.toggleIsAddingData();
            this.alertService.show({
               type: 'success',
               text: `"${datakey}: ${value}" successfully added.`,
            });
            this.newDataFormGroup.reset();
         });
   }

   public toggleIsAddingData(): void {
      this.addingNewData = !this.addingNewData;
      const datakey: string | null = this.newDataFormGroup.get('datakey').value;
      const value: string | null = this.newDataFormGroup.get('value').value;
      // Reset form (remove "required" validation errors) if no data entered
      if (!datakey && !value) {
         this.newDataFormGroup.reset();
      }
   }

   public hasError(key: string, error: string): boolean {
      const formControl = this.newDataFormGroup.get(key);
      return formControl.touched && formControl.errors?.[error];
   }

   private unmarshallExtraData(extraData: ExtraData): ExtraDatum[] {
      return Object.entries(extraData)
         .filter(([datakey, _value]) => (this.prefix !== "" ? datakey.startsWith(this.prefix) : ! datakey.startsWith(this.skippedPrefix)))
         .map(datum => {
            const [datakey, value] = datum;
            return {datakey:datakey.substring(this.prefix.length), value, hide: this.hideValues};
         });
   }

   private existingKeyValidator(control: AbstractControl): ValidationErrors | null {
      if (!this.dataSource) return null;
      const existingDatum = this.dataSource.data.find(extraDatum => extraDatum.datakey === control.value);
      if (existingDatum) {
         return { keyclash: true };
      }
      return null;
   }

   private disallowUploadFormatValidator(control: AbstractControl): ValidationErrors | null {
      if (!this.dataSource) return null;
      if (this.page.context === 'account' && control.value === 'UploadFormatOption') {
         return { noUploadFormatOption: true };
      }
      return null;
   }

   private saveExtraData(extraData: ExtraData): Observable<ExtraData> {
      this.saving = true;
      extraData = Object.fromEntries(Object.entries(extraData).map(([datakey,value]) => [this.prefix + datakey, value]));
      return this.extraDataApiService.setExtraData(extraData)
         .pipe(
            tap(extraDataResponse => {
               this.dataSource.data = this.unmarshallExtraData(extraDataResponse);
               this.table.renderRows();
            }),
            this.alertService.notifyOnError('submitting the data'),
            finalize(() => this.saving = false),
            takeUntil(this.destroy$),
         );
   }

   private caseInsensitiveSort(data: ExtraDatum[], sort: MatSort): ExtraDatum[] {
      const sortFactor = sort.direction === 'asc'
         ? 1
         : sort.direction === 'desc'
            ? -1
            : 0;
      if (sortFactor) {
         // sortData is called with a copy of the table's data so no need to make a copy before sorting in place
         data.sort((a, b) => {
            const aValue = a[sort.active] ? a[sort.active].toLocaleLowerCase() : null;
            const bValue = a[sort.active] ? b[sort.active].toLocaleLowerCase() : null;
            return aValue > bValue
               ? sortFactor
               : aValue < bValue
                  ? -sortFactor
                  : 0;
         });
      }
      return data;
   }
}
