<form class="form-inline">
   <div *ngIf="state ==='loading'" class="content-loading">
      <bb-loading></bb-loading>
   </div>
   <div *ngIf="state === 'ready'">
      <div *ngIf="sitePermissions.canSearchSiteUsers; else cannotSearchSiteUsers">
         <mat-hint class="hint-msg">
            Add a user by searching by username or entering their email address. They will be added to a list below
            where they can be reviewed before sending invites.
         </mat-hint>
         <div class="form-cell">
            <label>Add user</label>
            <bb-autocomplete-item
               [inValue]=""
               [groupOptions]="filteredOptions$ | async"
               (inValueChange)="autocompleteFormChange($event)"
               (selectionChanged)="onSelectionChanged($event)"
               [resetAfterSelection]="true">
            </bb-autocomplete-item>
         </div>
      </div>
      <ng-template #cannotSearchSiteUsers>
         <div class="form-cell">
            <label>Add user</label>
            <form (ngSubmit)="createUsername()">
               <mat-form-field>
                  <input
                     matInput
                     type="text"
                     [formControl]="usernameControl"
                  />
                  <mat-error *ngIf="usernameControl.invalid">
                     {{getErrorMessage()}}
                  </mat-error>
               </mat-form-field>
            </form>
         </div>
         <mat-hint class="hint-msg">
            Add users by typing a username or email address. They will be added to the list below.
         </mat-hint>
      </ng-template>
   </div>
   <div *ngIf="dataSource.length">
      <mat-table [dataSource]="dataSource" class="mat-elevation-z0">
         <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let i = index">
               <button class="btn-link user-remove" (click)="removeColumn(i)">
                  <span class="material-icon">
                     cancel
                  </span>
               </button>
            </td>
         </ng-container>
         <ng-container matColumnDef="username">
            <th mat-header-cell *matHeaderCellDef> User name </th>
            <td mat-cell *matCellDef="let element"> {{(emailTest(element.username) ? 'Send email to: ' + element.username : element.username)}} </td>
         </ng-container>
         <ng-container matColumnDef="fullname">
            <th mat-header-cell *matHeaderCellDef> Full Name </th>
            <td mat-cell *matCellDef="let element"> {{element.fullname}} </td>
         </ng-container>
         <ng-container matColumnDef="role">
            <th mat-header-cell *matHeaderCellDef> Role </th>
            <td mat-cell *matCellDef="let element;">
               <div class="form-cell-int">
                  <mat-form-field appearance="outline">
                     <mat-select
                        name = "role"
                        [(value)]="element.roleId"
                        required>
                        <mat-option *ngFor="let role of selectableRoles" [value]="role.id">{{role.name}}</mat-option>
                     </mat-select>
                  </mat-form-field>
               </div>
            </td>
         </ng-container>
         <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
         <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </mat-table>
      <div class="save-user" *ngIf="saveEnabled">
         <button mat-raised-button color="primary" type="submit" (click)="save()">
            Save Changes
         </button>
         <button mat-stroked-button color="primary" (click)="discardChanges()">
            Discard Changes
         </button>
      </div>
   </div>
</form>

