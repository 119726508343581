import { i18n } from '../../services/i18n.service';
import { NotificationService } from '../../services/notification.service';
import { Notification } from '../../types';

interface NotificationAreaScope extends IScope {
   notifications: Notification[];
   i18n(s: string): string;
   hasUndo(): boolean;
   removeNotification(n: Notification): void;
}

export const notificationAreaComponent: IComponentOptions = {
   templateUrl: '/furniture/js/app/src/shared/components/notification-area/notification-area.component.html',
   controllerAs: 'vm',
   controller: ['I18nService', '_', 'NotificationService',
                function(i18nService: i18n, _: Lodash, notificationService: NotificationService) {
      const vm: NotificationAreaScope = this;
      vm.notifications = notificationService.notifications;

      vm.i18n = text => {
         return i18nService.i18n(text);
      };

      vm.hasUndo = () => {
         return _.some(vm.notifications, (notification) => notification.undoId);
      };

      vm.removeNotification = notification => {
         notificationService.remove(notification);
      };
   }]
};
