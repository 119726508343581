import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

export interface ValidLogin {
   status: 'valid';
   token: string;
   hostnames: string[];
   urls: string[];
}

export interface InvalidLogin {
   status: 'error' | 'invalidmfa';
   error: string;
}

@Injectable({
   providedIn: 'root',
})
export class LoginApiService {
   constructor(
      private http: HttpClient,
      @Inject('User') private user: User,
   ) {}

   login(password: string) {
      return this.http.post<ValidLogin | InvalidLogin>(
         '/api/login',
         {
            username: this.user.username,
            password,
         },
      );
   }

   verifyMfa(password: string, mfaCode: string) {
      return this.http.post<ValidLogin | InvalidLogin>(
         '/api/login',
         {
            username: this.user.username,
            password,
            mfaCode,
         },
      );
   }
}
