import { createAction, props } from '@ngrx/store';

import { Me } from './me.interface';
import { User } from './user.interface';
import { UsersSearchParams } from './users-search-params.interface';


export const loadUser = createAction(
   '[Users] Load User',
   (id: string, forceRefresh: boolean = false) => ({ id, forceRefresh }),
);
export const loadUserSuccess = createAction(
   '[Users] Load User Success',
   props<{user: User}>(),
);
export const loadUserFail = createAction(
   '[Users] Load User Fail',
   props<{userLoadingError: string}>(),
);

export const searchUsers = createAction(
   '[Users] Search Users',
   props<{searchParams: UsersSearchParams}>(),
);
export const searchUsersSuccess = createAction(
   '[Users] Search Users Success',
   props<{query: string; users: User[]}>(),
);
export const searchUsersFail = createAction(
   '[Users] Search Users Fail',
   props<{usersSearchError: string}>(),
);

export const loadMe = createAction(
   '[Users] Load Me',
   (forceRefresh: boolean = false) => ({ forceRefresh }),
);
export const loadMeSuccess = createAction(
   '[Users] Load Me Success',
   props<{me: Me}>(),
);
export const loadMeFail = createAction(
   '[Users] Load Me Fail',
   props<{meLoadingError: string}>(),
);
