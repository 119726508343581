export enum WatchfolderEndpoints {
   LocalFolderEndpoint = 'LocalFolderEndpoint',
   SmbEndpoint = 'SmbEndpoint',
   AvidISISEndpoint = 'AvidISISEndpoint',
   S3Endpoint = 'S3Endpoint',
   AzureBlobEndpoint = 'AzureBlobEndpoint',
}

export enum StreamEndpoints {
   HlsEndpoint = 'HlsEndpoint',
   RtmpPushEndpoint = 'RtmpPushEndpoint',
   SdiEndpoint = 'SdiEndpoint',
   SrtEndpoint = 'SrtEndpoint',
   TeststreamEndpoint = 'TeststreamEndpoint',
   ZixiEndpoint = 'ZixiEndpoint',
}
