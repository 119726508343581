import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { ExtraData } from './extra-data.interface';
import { PageService } from '../../services/page.service';


@Injectable({
   providedIn: 'root',
})
export class ExtraDataApiService {
   private readonly contextPaths: any;
   private page: Page;

   constructor(
      private http: HttpClient,
      private pageService: PageService,
   ) {
      this.page = this.pageService.page;
      this.contextPaths = {
         account: `/api/accounts/${this.page.accountId}/extraData`,
         site:    `/api/sites/${this.page.siteId}/extraData`,
         user:    `/api/users/${this.page.userId}/extraData`,
      };
   }

   getExtraData() {
      const path = this.contextPaths[this.page.context];
      return this.http.get<ExtraData>(path);
   }

   setExtraData(extraData: ExtraData) {
      const path = this.contextPaths[this.page.context];
      return this.http.post<ExtraData>(path, extraData);
   }
}
