import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'bb-form-button',
  templateUrl: './dynamic-form-button.component.html',
  styleUrls: ['./dynamic-form-button.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DynamicFormButtonComponent),
      multi: true
    }
  ]
})
export class DynamicFormButtonComponent implements ControlValueAccessor {

   @Input('btnLabel') btnLabel: string;
   @Input('action') btnAction = false;
   public onChange: any;
   public onTouch: any;

   public disabled = false;
   public value = false;


   constructor()
   {};


   public btnPress() {
      this.onChange(true);
   }

   public btnRelease() {
      this.onChange(false);
   }

  public writeValue(value: boolean): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
