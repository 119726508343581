export const dynamicFormContainer: InjectableDirectiveFactory = [function() {
   return {
      restrict: 'A',
      scope: true,
      controller: function() {
         var self = this;
         self.dynamicForms = [];

         self.add = function(dynamicForm) {
            self.dynamicForms.push(dynamicForm);
         };
      }
   };
}];
