// Grab a style from the passed HTML Element if it exists, return it as a string or null
// This solution does not work for IE
export function getStyle(el: HTMLElement, styleProp: string): string | null {
   var defaultView = (el.ownerDocument || document).defaultView;
   // W3C standard way:
   if (defaultView && defaultView.getComputedStyle) {
      // sanitize property name to css notation
      // (hypen separated words eg. font-Size)
      styleProp = styleProp.replace(/([A-Z])/g, "-$1").toLowerCase();
      return defaultView.getComputedStyle(el, null).getPropertyValue(styleProp);
   }
   else
   {
      return null;
   }
}
