export const fbdnTabsDirective: InjectableDirectiveFactory = ['_', function(_: Lodash) {
   return {
      priority: 2,
      restrict: 'E',
      transclude: {
         tab: 'fbdnTab',
         formButtons: '?transcludeButtons',
      },
      scope: {
         tabChange: '&fbdnTabChange',
      },
      replace: true,
      templateUrl: '/furniture/js/app/src/shared/components/fbdn-tabs/fbdn-tabs.directive.html',
      controller: ['$scope', function($scope) {
         $scope.tabs = [];
         var self = this;

         $scope.selectTab = function(tab) {
            $scope.activeTab = tab;
            $scope.tabChange({ tabTitle: tab.tabTitle });
         };

         self.addTab = function addTab(tab) {
            $scope.tabs.push(tab);
         };

         self.reflow = function reflow() {
            $scope.$broadcast('reflow');
         };

         self.showDefault = function() {
            $scope.activeTab = _.find($scope.tabs, {tabDefault: true}) || $scope.tabs[0];
         };

         $scope.$watch(function() {
            return $scope.activeTab && $scope.activeTab.tabDisabled;
         }, function(hidden) {
            if (hidden) self.showDefault();
         });

         $scope.$evalAsync(function() {
            self.showDefault();
         });
      }]
   };
}];
