// Extends ngModel so new elements are set to valid until
// event "ngModel::submitAttempted" is received or the
// element is non-pristine
export const HideInvalidForNewDirective = ['_', function(_) {
   return {
      require: ['ngModel', '?^form'], // fbdn-tabs can make form not available sometimes
      link: function(scope, element, attrs, controllers) {
         var ngModelController = controllers[0];
         var formController = controllers[1];
         if (!formController) return;

         function forceValid() {
            return !formController.$submitAttempted && ngModelController.$pristine;
         }

         function stateChangeHandler() {
            // Run validators
            var viewValue = ngModelController.$modelValue;
            var i = ngModelController.$formatters.length;
            while(i--) {
               viewValue = ngModelController.$formatters[i](viewValue, true);
            }

            // Wait until after all validators run,
            // so they don't undo the forcing to valid
            scope.$evalAsync(function() {
               if (forceValid()) {
                  _.forEach(ngModelController.$error, function(errorState, errorKey) {
                        ngModelController.$setValidity(errorKey, true);
                  });
               }
            });
         }

         scope.$watch(function() {
            return ngModelController.$invalid;
         }, stateChangeHandler);

         scope.$watch(function() {
            return forceValid();
         }, stateChangeHandler);

         // Once a form is submitted, elements can be invalid
         // Can't wait for watcher on forceValid() to run,
         // to run, as need validity ASAP to be tested in client controllers
         scope.$on('ngModelController::submitAttempted', stateChangeHandler);
      }
   };
}];
