<button (click)="submit()" [disabled]="formEntity.$$saving" class="btn btn-primary btn-lg save" type="button">
   {{
      formEntity.id
         ? formEntity.$$saving ? 'Saving' : 'Save'
         : formEntity.$$saving ? 'Creating' : 'Create'
   }}
</button>
<button (click)="discard()" [disabled]="formEntity.$$saving" class="btn btn-lg cancel" type="button">
   {{formEntity.id ? 'Discard changes' : 'Discard'}}
</button>
