// Import angular.js from node_modules for the UpgradeModule
// This must happen before the UpgradeModule import so window.angular (i.e. angular.js) is available
import 'angular';

import { downgradeComponent, UpgradeModule } from '@angular/upgrade/static';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DoBootstrap, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_BASE_HREF } from '@angular/common';
import { HttpClientModule, HttpClientXsrfModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

/* Hybrid sidecar components */
import NotificationAreaAjsModule from './shared/components/notification-area/notification-area.module';
import ShowServerNotificationAjsModule from './shared/components/show-server-notification/show-server-notification.component.ajs';

/* Entrypoints for each page */
import { DiagnosticPageComponent } from './diagnostic-page/diagnostic.page.component';
import { AccountDashboardPageComponent } from './account/dashboard-page/dashboard.page.component';
import { PublishingButtonsPageComponent } from './publishing-buttons/publishing-buttons.page.component';
import { EdgeServersPageComponent } from './edge-servers/edge-servers.page.component';
import { EdgeServicesPageComponent } from './edge-services/edge-services.page.component';
import { EdgeEndpointsPageComponent } from './edge-endpoints/edge-endpoints.page.component';
import { SiteAccountsPageComponent } from './site/accounts/site-accounts.page.component';
import { MediaManagerPageComponent } from './media-manager/ng/media-manager/media-manager.page.component';
import { ArrivalsPageComponent } from './arrivals/arrivals.page.component';
import { IngestQueuePageComponent } from './ingest-queues/ingest-queue.page.component';

/* Modules for pages with multiple entrypoints/serverside content */
import { CloudConfigurationAppModule, CloudConfigurationAjsModule } from './cloud/configuration';
import { SiteConfigurationAppModule, SiteConfigurationAjsModule } from './site/configuration';
import { AccountConfigurationAppModule, AccountConfigurationAjsModule } from './account/configuration';
import { UserConfigurationAppModule, UserConfigurationAjsModule } from './user/configuration/user-configuration.app.module';
import { SiteUsersAppModule, SiteUsersAjsModule } from './site/users/site-users.app.module';
import { AccountUsersAjsModule, AccountUsersAppModule } from './account/users/account-users.app.module';

/* Standalone pages */
import { PublishHelpComponent } from './separate-pages/publish-help/publish-help.component';

/* Shared */
import { SharedHybridAjsModule, SharedModule } from './shared';

/* Hybrid pages */
import PublishingButtonsAjsModule from './publishing-buttons/publishing-buttons.module.ajs';

/* Interceptors */
import { LoginTimeoutInterceptor, ResponseNormaliserInterceptor, RetryInterceptor } from './shared/interceptors';

/* Store */
import { AppState } from './store/app.state';
import { edgeServersReducer } from './store/edge-servers/edge-servers.reducer';
import { edgeCardsReducer } from './edge-servers/store/edge-cards/edge-cards.reducer';
import { notificationsReducer } from './store/notifications/notifications.reducer';
import { edgeServiceCardsReducer } from './edge-services/store/edge-service-cards/edge-service-cards.reducer';
import { edgeServerServicesReducer } from './store/edge-server-services/edge-server-services.reducer';
import { EdgeServersEffects } from './store/edge-servers/edge-servers.effects';
import { EdgeServerServicesEffects } from './store/edge-server-services/edge-server-services.effects';
import { usersReducer } from './store/users/users.reducer';
import { UsersEffects } from './store/users/users.effects';

// 'ui.bootstrap' is an angular.js module which is currently still being used in UploadModule (app/src/shared/upload/upload.module.ts)
// which is currently used on the hybrid PublishingButtons page (search for UploadController). We import it here
// rather than referencing in angular.json scripts because it depends on angular.js being in the global scope
import '../../lib/ui-bootstrap-tpls-0.13.4.min.js';


const APP_NAME = 'hybd.fbdn';

const pageEntrypoints = [
   DiagnosticPageComponent,
   AccountDashboardPageComponent,
   PublishingButtonsPageComponent,
   EdgeServersPageComponent,
   EdgeServicesPageComponent,
   EdgeEndpointsPageComponent,
   SiteAccountsPageComponent,
   MediaManagerPageComponent,
   ArrivalsPageComponent,
   PublishHelpComponent,
   IngestQueuePageComponent,
];

const featureReducers = {
   edgeCardsState: edgeCardsReducer,
   edgeServiceCardsState: edgeServiceCardsReducer,
};

const reducers = {
   edgeServerServicesState: edgeServerServicesReducer,
   edgeServersState: edgeServersReducer,
   usersState: usersReducer,
   notificationsState: notificationsReducer,
   ...featureReducers,
};

@NgModule({
   /* Components in this module */
   declarations: pageEntrypoints,

   /* Module imports */
   imports: [
      BrowserModule,
      BrowserAnimationsModule,
      ReactiveFormsModule,
      FormsModule,
      UpgradeModule,
      HttpClientModule,
      HttpClientXsrfModule.withOptions({
         cookieName: window.xsrf.cookieName,
         headerName: window.xsrf.headerName,
      }),

      SharedModule,
      CloudConfigurationAppModule,
      SiteConfigurationAppModule,
      AccountConfigurationAppModule,
      UserConfigurationAppModule,
      SiteUsersAppModule,
      AccountUsersAppModule,

      StoreModule.forRoot<AppState>(reducers),

      /*
         FYI: NGRX Effects added via forRoot do not respect the Angular bootstrap process and
         instead instantiate before the Angular/angular.js hybrid application is bootstrapped
         (RootAppModule.ngDoBootstrap) which is what makes the angular.js $injector available.
         This is a problem if angular.js services are needed in Effects classes or their dependencies.

         Try to avoid injecting any angular.js dependencies directly into a forRoot Effects class.

         If angular.js dependencies are depended on by an Angular service used in an Effects constructor,
         the solution would be to subscribe to the actions$ stream and respond to a custom action, either
         one fired on page init from the page's root component, or one dispatched after ngDoBootstrap.

         Others enjoying this issue:
         https://github.com/ngrx/platform/issues/404
         https://github.com/ngrx/platform/issues/174
         https://github.com/ngrx/platform/issues/931
         FYI @brandonroberts' example does not work with our current MPA architecture
      */
      EffectsModule.forRoot([
         EdgeServersEffects,
         EdgeServerServicesEffects,
         UsersEffects,
      ]),

      StoreDevtoolsModule.instrument({
         maxAge: 25, // Retain last 25 states
      }),
   ],

   /* Service/injection token overrides */
   providers: [
      {
         provide: APP_BASE_HREF,
         useValue: '/',
      },
      // Ordering of "multi" providers is significant. HTTP Interceptors
      // will run in the order they are listed here.
      {
         provide: HTTP_INTERCEPTORS,
         useClass: LoginTimeoutInterceptor,
         multi: true,
      },
      {
         provide: HTTP_INTERCEPTORS,
         useClass: ResponseNormaliserInterceptor,
         multi: true
      },
      {
         provide: HTTP_INTERCEPTORS,
         useClass: RetryInterceptor,
         multi: true
      },
   ],
})
export class RootAppModule implements DoBootstrap {
   constructor(private upgrade: UpgradeModule) {}

   // This is where angular.js gets bootstrapped into the hybrid Angular application
   ngDoBootstrap() {
      this.upgrade.bootstrap((document as any), [APP_NAME], {strictDi: true});
   }
}

export const RootAjsModule = angular.module(APP_NAME, [
   'ui.bootstrap', // ../../lib/ui-bootstrap-tpls-0.13.4.min.js

   SharedHybridAjsModule.name,

   // Sidebar + global notification components (see CC-279 + CC-32)
   NotificationAreaAjsModule.name,
   ShowServerNotificationAjsModule.name,

   /*
      We cannot lazy load angular.js modules in a multi-page hybrid application,
      so include them as part of the root application instead and remove each
      when its page-specific angular.js code is removed.
   */
   CloudConfigurationAjsModule.name,
   SiteConfigurationAjsModule.name,
   AccountConfigurationAjsModule.name,
   UserConfigurationAjsModule.name,
   SiteUsersAjsModule.name,
   PublishingButtonsAjsModule.name,
   AccountUsersAjsModule.name,
]);

// Downgrade all the entrypoints as ngUpgrade structure requires the root to be angular.js
RootAjsModule
   .constant('_', _)
   .directive('bbDiagnosticPage', downgradeComponent({component: DiagnosticPageComponent}))
   .directive('bbAccountDashboardPage', downgradeComponent({component: AccountDashboardPageComponent}))
   .directive('bbPublishingButtonsPage', downgradeComponent({component: PublishingButtonsPageComponent}))
   .directive('bbEdgeServersPage', downgradeComponent({component: EdgeServersPageComponent}))
   .directive('bbEdgeServicesPage', downgradeComponent({component: EdgeServicesPageComponent}))
   .directive('bbEdgeEndpointsPage', downgradeComponent({component: EdgeEndpointsPageComponent}))
   .directive('bbSiteAccountsPage', downgradeComponent({component: SiteAccountsPageComponent}))
   .directive('bbUsersImport', downgradeComponent({component: SiteUsersAppModule.rootComponent}))
   .directive('bbMediaManagerPage', downgradeComponent({component: MediaManagerPageComponent}))
   .directive('bbArrivalsPage', downgradeComponent({component: ArrivalsPageComponent}))
   .directive('bbUsersImport', downgradeComponent({component: SiteUsersAppModule.rootComponent}))
   .directive('bbPublishHelp', downgradeComponent({component: PublishHelpComponent}))
   .directive('bbIngestQueuePage', downgradeComponent({component: IngestQueuePageComponent}));
