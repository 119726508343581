import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { ApiCacheService } from '../../../shared/utils/cache';


interface PasswordRule {
   mode: 'enforced' | 'suggested';
   message: string;
   enabled: boolean;
   value?: number;
}

export interface PasswordRules {
   [ruleName: string]: PasswordRule;
}

export interface CurrentSetPasswordDto {
   currentPassword: string;
   newPassword: string;
}

export type ResetType = 'currentPassword' | 'just_reset' | 'registration';
type TokenResetType = Exclude<ResetType, 'currentPassword'>;

export interface TokenSetPasswordDto {
   resetToken: string;
   resetType: TokenResetType;
   newPassword: string;
}

@Injectable({
   providedIn: 'root',
})
export class PasswordApiService {
   constructor(
      private http: HttpClient,
      private apiCache: ApiCacheService,
   ) {}

   public getPasswordRules(forceFetch = false): Observable<PasswordRules> {
      const path = '/api/passwordRules';
      const cached$ = this.apiCache.get<PasswordRules>(path);
      if (cached$ && !forceFetch) {
         return cached$;
      } else {
         const getPasswordRules$ = this.http.get<PasswordRules>(path).pipe(shareReplay(1));
         return this.apiCache.set(path, getPasswordRules$);
      }
   }

   public setPassword(
      userId: string,
      setPasswordDto: CurrentSetPasswordDto | TokenSetPasswordDto,
   ): Observable<void> {
      return this.http.post<void>(`/api/users/${userId}/setPassword`, setPasswordDto);
   }
}
