<div *ngIf="!columns?.length" class="content-loading">
   <fbdn-icon icon="loading"></fbdn-icon>
</div>
<div *ngIf="dataSource?.length">
   <mat-table [dataSource]="dataSource" class="mat-elevation-z0">
      <ng-container matColumnDef="delete">
         <th mat-header-cell *matHeaderCellDef></th>
         <td mat-cell *matCellDef="let i = index">
            <button class="btn-link format-remove" [disabled]="disabled" (click)="removeColumn(i)">
               <span class="material-icon">
                  cancel
               </span>
            </button>
         </td>
      </ng-container>
      <ng-container matColumnDef="key">
         <th mat-header-cell *matHeaderCellDef> Key </th>
         <td mat-cell *matCellDef="let element; let i = index">
            <div class="form-cell">
               <bb-autocomplete-item
                  [groupOptions]="filteredOptions$ | async"
                  [inValue]="element.internalName"
                  (inValueChange)="autocompleteFormChange(i, $event)"
                  [disabled]="disabled"
                  required=true>
               </bb-autocomplete-item>
            </div>
         </td>
      </ng-container>

      <ng-container matColumnDef="displayName">
         <th mat-header-cell *matHeaderCellDef> Display Name </th>
         <td mat-cell *matCellDef="let element">
            <div class="form-cell">
               <mat-form-field appearance="outline">
                  <input
                     matInput
                     [(ngModel)]="element.displayName"
                     (change)="propagateChanges()"
                     [disabled]="disabled"
                     name="name"
                     #name="ngModel"
                     required>
                  <mat-error *ngIf="name.errors?.required">
                     This field is required
                  </mat-error>
               </mat-form-field>
            </div>
         </td>
      </ng-container>

      <ng-container matColumnDef="hidden">
         <th mat-header-cell *matHeaderCellDef> Hidden </th>
         <td mat-cell *matCellDef="let element">
            <div class="form-cell">
                  <mat-checkbox  [(ngModel)]="element.hidden"
                                 (change)="propagateChanges()"
                                 [disabled]="disabled">
                  </mat-checkbox>
            </div>
         </td>
      </ng-container>

      <ng-container matColumnDef="title">
         <th mat-header-cell *matHeaderCellDef> Title </th>
         <td mat-cell *matCellDef="let element">
            <div class="form-cell">
               <mat-checkbox  [(ngModel)]="element.title"
                              (change)="propagateChanges()"
                              [disabled]="disabled">
               </mat-checkbox>
            </div>
         </td>
      </ng-container>

      <ng-container matColumnDef="presets">
         <th mat-header-cell *matHeaderCellDef> Presets </th>
         <td mat-cell *matCellDef="let element; let i = index">
            <div class="form-cell">
               <mat-form-field appearance="outline">
                  <input
                     matInput
                     placeholder="Comma separarated list"
                     [value]="element.presets.toString()"
                     (change)="changePresets($event, i)"
                     [disabled]="disabled" >
               </mat-form-field>
            </div>
         </td>
      </ng-container>

      <ng-container matColumnDef="searchable">
         <th mat-header-cell *matHeaderCellDef> Searchable </th>
         <td mat-cell *matCellDef="let element">
            <div class="form-cell">
                  <mat-checkbox  [(ngModel)]="element.searchable"
                                 (change)="propagateChanges()"
                                 [disabled]="disabled">
                  </mat-checkbox>
            </div>
         </td>
      </ng-container>

      <ng-container matColumnDef="editable">
         <th mat-header-cell *matHeaderCellDef> Editable </th>
         <td mat-cell *matCellDef="let element">
            <div class="form-cell">
                  <mat-checkbox  [(ngModel)]="element.editable"
                                 (change)="propagateChanges()"
                                 [disabled]="disabled">
                  </mat-checkbox>
            </div>
         </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
   </mat-table>
</div>
<div class="form-group submit-group">
   <div class="form-control-group">
      <button mat-raised-button class="add-column-button" color="primary" [disabled]="disabled" (click)="addNewColumn()">Add column</button>
   </div>
</div>


