export const fbdnTabDirective: InjectableDirectiveFactory = [function() {
   return {
      require: '^fbdnTabs',
      restrict: 'E',
      replace: true,
      transclude: 'element',
      scope: {
         tabTitle: '@fbdnTabTitle',
         tabDefault: '=fbdnTabDefaultIf',
         tabDisabled: '=fbdnTabDisabled'
      },
      link: function(scope: any, element, attrs, tabsController, transclude) {
         scope.isTableTab = 'tableTab' in attrs;
         tabsController.addTab(scope);
         scope.$transcludeFn = transclude;
      }
   };
}];
