export const fileSupportProvider = ['$windowProvider', function($windowProvider) {
   var config;

   this.$get = function() {
      if (config) return config;
      var $window = $windowProvider.$get();
      var document = $window.document;
      var api = $window.File && $window.FileReader && $window.FileList && $window.Blob;
      var multiple = 'multiple' in document.createElement('input');

      // iOS has a bug selecting multiple video files
      var iOS = /(iPad|iPhone|iPod)/g.test($window.navigator.userAgent);
      multiple = multiple && !iOS;

      config = {
         api: api,
         multiple: multiple,
         all: api && multiple
      };
      return config;
   };
}];
