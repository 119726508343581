import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, of, Subject, throwError } from 'rxjs';
import { catchError, concatMap, takeUntil } from 'rxjs/operators';

import { PublishHelpApiService } from '../../shared/api/publish-help/publish-help.api.service';
import { PublishHelpResult } from '../../shared/api/publish-help/publish-help.interface';


@Component({
   selector: 'bb-publish-help',
   templateUrl: './publish-help.component.html',
})
export class PublishHelpComponent implements OnInit, OnDestroy {
   @Input() initialResult: string; // Stringified PublishHelpResult from Python template

   public result: PublishHelpResult;
   private destroy$ = new Subject<void>();

   constructor(
      private publishHelpApiService: PublishHelpApiService,
   ) {}

   ngOnInit() {
      const parsedResult: PublishHelpResult = JSON.parse(this.initialResult);
      this.process(parsedResult).pipe(
         catchError(error => {
            this.result = { message: `Error: ${error.message}` };
            return throwError(error);
         }),
         takeUntil(this.destroy$),
      ).subscribe();
   }

   ngOnDestroy() {
      this.destroy$.next();
      this.destroy$.complete();
   }

   private process(result: PublishHelpResult): Observable<PublishHelpResult> {
      this.result = result;
      return result.action !== 'set-options'
         ? of(result)
         : this.publishHelpApiService.postPublishHelp(result.publishMediaId, result.options)
            .pipe(concatMap(results => this.process(results)));
   }
}
