const ngModule = angular.module('bb.new-publishing-button-dialog', []);

ngModule.controller('NewPublishingButtonDialogController', [
   '$scope', '$modalInstance', 'sourceModules', 'destinationModules', '_',
   function($scope, $modalInstance, sourceModules, destinationModules, _) {
      $scope.sourceModules = sourceModules;
      $scope.destinationModules = destinationModules;
      $scope.model = {
         sourceModule: sourceModules[_.keys(sourceModules)[0]],
         destinationModule: destinationModules[_.keys(destinationModules)[0]]
      };

      $scope.ok = function() {
         $modalInstance.close({source: $scope.model.sourceModule, destination: $scope.model.destinationModule});
      };

      $scope.cancel = function() {
         $modalInstance.dismiss();
      };
}]);

export default ngModule;
