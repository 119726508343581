<bb-loading *ngIf="loadingInitialData else checkbox"></bb-loading>

<ng-template #checkbox>
   <mat-checkbox
      color="primary"
      [disabled]="updating"
      [ngModel]="notificationsEnabled"
      (ngModelChange)="updateEnabledStatus($event)"
   >
      Enable Email Notifications
   </mat-checkbox>
</ng-template>
