import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ImportedUser } from '../imported-user.interface';
import { USERS_PLURAL_MAP } from '../users-plural-map.const';

export interface ImportReviewData {
   reviewUsersList: ImportedUser[];
   importableUsersCount: number;
}

@Component({
   selector: 'bb-import-review',
   templateUrl: './import-review.component.html',
   styleUrls: ['./import-review.component.scss'],
   changeDetection: ChangeDetectionStrategy.Default,
})
export class ImportReviewComponent {
   public readonly usersPluralMap = USERS_PLURAL_MAP;

   constructor(
      public dialogRef: MatDialogRef<ImportReviewComponent>,
      @Inject(MAT_DIALOG_DATA) public data: ImportReviewData,
   ) {}

   public importReviewedUsers(): void {
      this.dialogRef.close(true);
   }

   public abortImportProcess(): void {
      this.dialogRef.close(false);
   }
}
