import { Directive, ElementRef, HostListener, AfterViewInit, Input } from '@angular/core';

@Directive({
   selector: '[bbMenuDirection]',
})
export class MenuDirectionDirective implements AfterViewInit {
   @Input('bbMenuDirection') eventTrigger: 'mouseenter' | 'click';

   private readonly openUpClass = 'open-up';
   private menu: HTMLUListElement;

   constructor(private el: ElementRef<HTMLElement>) {}

   @HostListener('mouseenter') onMouseenter() {
      if (this.eventTrigger === 'mouseenter') {
         this.setOpenDirectionClass();
      }
   }

   @HostListener('click') onClick() {
      if (this.eventTrigger === 'click') {
         this.setOpenDirectionClass();
      }
   }

   ngAfterViewInit() {
      this.menu = this.el.nativeElement.querySelector<HTMLUListElement>('.dropdown-menu');
   }

   private setOpenDirectionClass(): void {
      // To check on original position
      this.menu.classList.remove(this.openUpClass);

      const rect = this.menu.getBoundingClientRect();
      const openUp = !(rect.top > 0 && rect.top + this.menu.offsetHeight < document.body.offsetHeight);

      if (openUp) {
         this.menu.classList.add(this.openUpClass);
      }
   }
}
