interface InjectableDirectiveScope extends IScope {
   dragoverTarget: boolean;
   dragover: boolean;
}

export const FbdnDroppable: InjectableDirectiveFactory =  ['$rootScope', '$parse', '_', function($rootScope, $parse, _) {
   return {
      restrict: 'A',
      scope: true,
      link: function(scope: InjectableDirectiveScope, element: JQLite, attrs: IAttributes) {
         var onDropSuccessCallback = $parse(attrs.fbdnDroppable);
         var canDropFunction = attrs.fbdnDroppableCanDrop ? $parse(attrs.fbdnDroppableCanDrop) : function() {
            return true;
         };
         var parentContoller = element.parents().controller('fbdnDroppable');
         var includeMargin = 'fbdnDroppableIncludeMargin' in attrs;

         scope.dragoverTarget = false;
         scope.dragover = false;

         if (attrs.fbdnDroppableDisabled) {
            scope.$watch(attrs.fbdnDroppableDisabled, function(disabled) {
               if (!disabled) {
                  register();
               } else {
                  deregister();
               }
            });
         } else {
            register();
         }

         scope.$watch('dragover', function(dragover: boolean) {
            element.toggleClass('dragover', dragover);
         });

         scope.$watch('dragoverTarget', function(dragoverTarget: boolean ) {
            element.toggleClass('dragover-target', dragoverTarget);
         });

         var deregisterDragMove = null;
         var deregisterDragEnd = null;

         function register() {
            if (!deregisterDragMove) deregisterDragMove = scope.$on('draggable:move', onDragMove);
            if (!deregisterDragEnd) deregisterDragEnd = scope.$on('draggable:end', onDragEnd);
         }

         function deregister() {
            if (deregisterDragMove) deregisterDragMove();
            if (deregisterDragEnd) deregisterDragEnd();
         }

         function onDragMove(evt, dragObject) {
            if(!canDrop(dragObject)) return false;
            var x = dragObject.mousePosition.x;
            var y = dragObject.mousePosition.y;
            var wasTouching = scope.dragover;
            var data = scope.$eval(attrs.fbdnDraggable);
            scope.dragover = isTouching(x, y);
            if (wasTouching && !scope.dragover) {
               $rootScope.$broadcast('droppable:stopHover', {
                  element: element,
                  data: data
               });
            } else if (!wasTouching && scope.dragover) {
               $rootScope.$broadcast('droppable:startHover', {
                  element: element,
                  data: data
               });
            }

            if (element === dragObject.element) return false;

            if (scope.dragover) {
               if (parentContoller) parentContoller.removeDragoverTarget();
               scope.dragoverTarget = true;
            } else {
               scope.dragoverTarget = false;
            }
            scope.$digest();
         }


         function onDragEnd(evt, dragObject) {
            if(!canDrop(dragObject)) return false;
            var x = dragObject.mousePosition.x;
            var y = dragObject.mousePosition.y;
            var touching = isTouching(x, y);
            if (touching && scope.dragover) {
               $rootScope.$broadcast('droppable:dropped', {
                  element: element,
                  data: scope.$eval(attrs.fbdnDraggable)
               });
            }

            if (onDropSuccessCallback && touching && scope.dragoverTarget) {
               onDropSuccessCallback(scope, {
                  dragObject: dragObject,
                  dropElement: {element: element} // wrap to avoid ng isecdom error
               });

               scope.$apply();
            }

            scope.dragover = false;
            scope.dragoverTarget = false;
            scope.$digest();
         }

         function canDrop(dragObject) {
            if (!('fbdnDroppableAllowSelfDrop' in attrs)) {
               if (element === dragObject.element) return false;
               if (dragObject.dataMulti && ('fbdnDraggable' in attrs) &&
                  _.includes(dragObject.dataMulti, $parse(attrs.fbdnDraggable)(scope))) {
                  return false;
               }
            }

            return canDropFunction(scope, {
               dragObject: dragObject
            });
         }

         function isTouching(x, y) {
            var bounds = element.offset();
            bounds.right = bounds.left + element.outerWidth(includeMargin);
            bounds.bottom = bounds.top + element.outerHeight(includeMargin);
            bounds.top = Math.max(bounds.top, element.offsetParent().offset().top);
            bounds.bottom = Math.min(bounds.bottom, element.offsetParent().offset().top + element.offsetParent().outerHeight());
            return x >= bounds.left && x <= bounds.right && y <= bounds.bottom && y >= bounds.top;
         }
      },
      controller: ['$scope', function($scope) {
         this.removeDragoverTarget = function removeDragoverTarget() {
            $scope.dragoverTarget = false;
            $scope.$digest();
         };
      }]
   };
}];
