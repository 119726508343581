<div class="metaupload-content">
    <div class="metauploadheading"> Metadata mapping will import to {{accountName}} account </div>
    <div *ngIf="fileSupportAll; else singleUpload">
        <div class="btns-row">
            <div id="fileinput-btn">
                <input type="file" style="display: none" name="uploadfiles" accept="text/csv" (change)="fileChanged($event)" multiple #multifile>
                <button mat-raised-button type="button" (click)="multifile.click()">Choose files</button>
            </div>
        </div>
    </div>
    <ng-template #singleUpload>
        <div id="btns-row">
            <div>
                <input type="file" style="display: none" name="uploadfiles" #id_metaupload accept="text/csv" (change)="fileChanged($event)">
                <button mat-raised-button type="button" id="id_metaupload_button" (click)="id_metaupload.click()"> Choose file</button>
            </div>
        </div>
    </ng-template>
    <div class="filestable">
        <table class="table">
            <tbody id="files-to-upload">
                <tr *ngFor="let file of files">
                <td>
                    {{file.name}}
                </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div *ngIf="files?.length">
        <button mat-raised-button color="grey" type="submit" id="submitbutton" [hidden]="!files?.length" [disabled]="!fileSupportAll && !files?.length">Upload {{files?.length > 1 ? files?.length : ''}} file{{files?.length > 1 ? 's' : ''}}</button>
    </div>
</div>
