import { MenuParams } from './menu-params.interface';

interface FbdnContextmenuBodyScope extends IScope {
   menuParams: MenuParams;
   getMenuTargetAttribute(attributeName: string): string;
}

export class FbdnContextmenuBodyDirective implements IDirective {
   restrict = 'E';
   readonly transclude  = 'element';

   constructor(
      private $document: IDocumentService,
      private $window: IWindowService,
   ){}

   static factory(...deps: [IDocumentService, IWindowService]) {
      return new FbdnContextmenuBodyDirective(...deps);
   }

   link = (scope: FbdnContextmenuBodyScope, _$element: JQLite, attrs: IAttributes, _ctrl: any, transclude: ITranscludeFunction) => {
      scope.$on('fbdnContextmenu:open', (_$event: any, params: MenuParams) => {
         if (params.handled) return;
         params.handled = true;
         scope.menuParams = params; // so screen position available in menu actions

         // have to supply this function as angular expressions not allowed to access DOM elements
         scope.getMenuTargetAttribute = (attributeName) => {
            return params.target.getAttribute(attributeName);
         };
         transclude(scope, (clone: JQLite) => {
            clone.css({left: 0, top: 0, visibility: "hidden"});
            angular.element('body').append(clone);
            const theclone = clone[0];
            const maxLeft = this.$window.innerWidth - theclone.offsetWidth;
            const maxTop = this.$window.innerHeight - theclone.offsetHeight;
            const finalLeft = maxLeft < params.left ? maxLeft : params.left;
            const finalTop = maxTop < params.top ? maxTop : params.top;
            clone.css({left: finalLeft, top: finalTop, "max-height": this.$window.innerHeight - finalTop, visibility: "initial"});

            clone.bind('contextmenu', (event: JQueryEventObject) => {
               event.preventDefault();
               angular.element(event.target).triggerHandler('click');
            });

            const closeContextMenu = () => {
               clone.remove();
               this.$document.off('click', closeContextMenu);
               this.$document.off('contextmenu', closeContextMenu);
            };

            scope.$on('$destroy', () => {
               closeContextMenu();
            });

            this.$document.on('click', closeContextMenu);
            this.$document.on('contextmenu', closeContextMenu);
         });
      });
   };
}

FbdnContextmenuBodyDirective.factory.$inject = ['$document', '$window'];
