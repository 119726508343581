import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { PublishAdditionalOptionDialogComponent } from '../publish-additional-options-dialog/publish-additional-option-dialog';

@Injectable({
    providedIn: 'root'
 })
export class PublishDialogService {
    constructor(private dialog: MatDialog) { }

    public openPublishDialog(errForm: any): Observable<any>{
        const dialogRef = this.dialog.open(PublishAdditionalOptionDialogComponent, {data:{publishOptions: errForm}} );
        return dialogRef.afterClosed();
    }
}
