import { ChangeDetectionStrategy, Compiler, Component, Injector, Input, OnInit } from '@angular/core';

import { PublishingButtonsAppModule } from './ng/publishing-buttons.app.module';
import { PublishingButtonsComponent } from './ng/publishing-buttons.component';

import { PublishingButtonsHybridAppModule } from './publishing-buttons-hybrid.app.module';
import { PublishingButtonsComponent as HybridPublishingButtonsComponent } from './publishing-buttons.component';

// This XXXPageComponent exists as a lazy-loading mechanism for a page's module while we
// are in MPA structure and cannot therefore use Angular's router.
@Component({
   selector: 'bb-publishing-buttons-page',
   template: `
      <ng-container *ngIf="loaded">
         <!-- Once loaded, instantiate the component (start the component tree here) -->
         <ng-container *ngComponentOutlet="component"></ng-container>
      </ng-container>
   `,
   changeDetection: ChangeDetectionStrategy.Default,
})
export class PublishingButtonsPageComponent implements OnInit {
   @Input() version: 'hybrid' | 'full-ng';

   public loaded = false;
   public component: typeof HybridPublishingButtonsComponent | typeof PublishingButtonsComponent;

   constructor(
      private compiler: Compiler,
      private injector: Injector,
   ) {}

   async ngOnInit() {
      /* Statically import the page's module.
       * This import() statement will be parsed by Angular's static analysis during the build and
       * generate the module bundle. Angular inserts the module script into the HTML at runtime
       * (does not appear as a script tag in our HTML, or in the dist index.html).
       * https://angular.io/guide/lazy-loading-ngmodules
       * https://www.typescriptlang.org/docs/handbook/release-notes/typescript-2-4.html#dynamic-import-expressions
       *
       * This page has both a 'hybrid' module and a 'full-ng' module because it was initially intended to be
       * iteratively rewritten component-by-component ('hybrid' module). It was then decided to use write it
       * in a new page from scratch, so the 'full-ng' module was created to contain this. Search for the usage
       * of <bb-publishing-buttons-page to find the 2x pages being served for each version.
       */
      if (this.version === 'hybrid') {
         const { PublishingButtonsHybridAppModule: module } = await import('./publishing-buttons-hybrid.app.module');
         this.compileModule(module);
      } else if (this.version === 'full-ng') {
         const { PublishingButtonsAppModule: module } = await import('./ng/publishing-buttons.app.module');
         this.compileModule(module);
      }
   }

   private async compileModule(
      module: typeof PublishingButtonsHybridAppModule | typeof PublishingButtonsAppModule,
   ): Promise<void> {
      // Grab the root component of the page's component tree
      this.component = module.rootComponent;
      const factory = await this.compiler.compileModuleAsync(module);
      // Instantiate the module with the Angular injector
      factory.create(this.injector);
      this.loaded = true;
   }
}
