import { Directive, ElementRef, Injector, Input } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({selector: 'fbdn-icon'}) // eslint-disable-line @angular-eslint/directive-selector
export class FbdnIconComponent extends UpgradeComponent {
   @Input() public icon: string;

   constructor(ref: ElementRef, inj: Injector) {
      super('fbdnIcon', ref, inj);
   }
}
