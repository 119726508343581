import { fbdnAccordionContentDirective } from './fbdn-accordion-content.directive';
import { fbdnAccordionComponent } from './fbdn-accordion.component.ajs';
import { subPanelDirective } from './sub-panel.directive';

const ngModule = angular.module('bb.fbdn-accordion', []);

ngModule
   .component('fbdnAccordion', fbdnAccordionComponent)
   .directive('subPanel', subPanelDirective)
   .directive('fbdnAccordionContent', fbdnAccordionContentDirective);

export default ngModule;
