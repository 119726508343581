import { FbdnDragSelectItem } from './fbdn-drag-select-item.directive';
import { FbdnDragSelect } from './fbdn-drag-select.directive';
import { FbdnDraggableContainer } from './fbdn-draggable-container.directive';
import { FbdnDraggablePrevent } from './fbdn-draggable-prevent.directive';
import { FbdnDraggable } from './fbdn-draggable.directive';
import { FbdnDroppableScroll } from './fbdn-droppable-scroll.directive';
import { FbdnDroppable } from './fbdn-droppable.directive';
import { FbdnSortable } from './fbdn-sortable.directive';

const ngModule = angular.module('bb.draggable', []);

ngModule
   .directive('fbdnDragSelectItem', FbdnDragSelectItem)
   .directive('fbdnDragSelect', FbdnDragSelect)
   .directive('fbdnDraggableContainer', FbdnDraggableContainer)
   .directive('fbdnDraggablePrevent', FbdnDraggablePrevent)
   .directive('fbdnDraggable', FbdnDraggable)
   .directive('fbdnDroppableScroll', FbdnDroppableScroll)
   .directive('fbdnDroppable', FbdnDroppable)
   .directive('fbdnSortable', FbdnSortable);

export default ngModule;
