import { Injectable } from '@angular/core';

import {
   HttpEvent,
   HttpInterceptor,
   HttpHandler,
   HttpRequest,
   HttpResponse,
   HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, concatMap } from 'rxjs/operators';


@Injectable()
export class RetryInterceptor implements HttpInterceptor {
   private retryCodes = [429];
   private defaultDelay = 1000; // ms
   private maxRetries = 100;


   intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      const tryit = (n: number) => {
         const useReq = n ? this.modifyRequestUrl(req, n) : req;
         return next.handle(useReq)
            .pipe(
               catchError((error: HttpErrorResponse) => {
                  if (!this.retryCodes.includes(error.status) || n > this.maxRetries) {
                     return throwError(error);
                  }
                  const retryAfter = this.getRetryAfter(error);
                  return this.delay(retryAfter).pipe(concatMap(() => tryit(n+1)));
               })
            );
      };
      return tryit(0);
   }

   private getRetryAfter(error: HttpErrorResponse): number {
      const retryAfterHeader = error.headers.get('Retry-After');
      if (retryAfterHeader) {
         const retryAfterSeconds = parseInt(retryAfterHeader, 10) + Math.random() - 0.5;
         if (!isNaN(retryAfterSeconds)) {
            return retryAfterSeconds * 1000;
         }
      }
      return this.defaultDelay;
   }

   private delay(ms: number): Observable<void> {
      return new Observable<void>(observer => {
         const timeout = setTimeout(() => {
            observer.next();
            observer.complete();
         }, ms);
         return () => clearTimeout(timeout);
      });
   }

   private modifyRequestUrl(req: HttpRequest<any>, retryCount: number): HttpRequest<any> {
      const fullUrl = new URL(req.url, window.location.origin);
      fullUrl.searchParams.set('_retry', retryCount.toString());
      return req.clone({
         url: fullUrl.href
      });
   }
}
