/*
* An icon can be shown with tag <bb-svg-icon icon="[icon]"></bb-svg-icon>, where [icon] refers to
* the element id in the svg icon stack that refers to the icon itself. The SVG icon uses the
* maximal space given to the svg icon tag but never more, and is responsible to preserve its
* aspect ratio. Below is an example of HTML/CSS code to use icon "stethLeft" from bank
* "controlCentre".
*
*/
import { Component, Input, OnInit } from '@angular/core';
import { AlertService } from '../../services/alert.service';
import { SvgIconService } from './svg-icon.service';
import { catchError, map, take } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ChangeDetectorRef } from '@angular/core';
import { IconConfig } from './svg-icon.service';
import { HttpClient } from '@angular/common/http';

@Component({
   selector: 'bb-svg-icon',
   templateUrl: './svg-icon.component.html',
   styleUrls: ['./svg-icon.component.scss'],
})
export class SvgIconComponent implements OnInit
{
   @Input() icon: string;

   public iconReference: string;
   public iconConfig: IconConfig;
   public iconReady = false;

   constructor(
      private svgIconService: SvgIconService,
      private alertService: AlertService,
   )
   {}

   ngOnInit() {
      this.iconConfig = this.svgIconService.getIcon(this.icon);
      this.iconConfig.obs$
         .pipe(
            take(1),
            catchError((error: string) => {
               this.alertService.show({
                  text: "Error: " + error,
                  neverRemove: true,
                  type: 'danger'
               });
               return throwError(error);
            }),
         )
         .subscribe(
            iconContainer => {
               const iconElement = iconContainer.querySelector("#" + this.iconConfig.details.iconGraphic);
               if (!iconElement) {
                  this.alertService.show({
                     text: "Could not find icon '" + this.iconConfig.details.iconGraphic + "' from icon bank '" + this.iconConfig.details.bankName + "'",
                     neverRemove: true,
                     type: 'danger'
                  });
                  return;
               }
               this.iconReference = '#' + iconElement.id;
               this.iconReady=true;
            }
         );
   }
}
